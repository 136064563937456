import styled from "styled-components"
import { FlexibleDiv } from "../Box"

export const TabHeaderWrap = styled(FlexibleDiv)`
  justify-content: flex-start;
  top: ${({ top }) => top || "0"};
  background-color: var(--uduxBlackPrimary);
  margin: ${({ margin }) => margin || "4rem auto"};
  position: ${({ position }) => position || "unset"};
  border: 0.5px solid var(--borderLight);
  max-width: ${({ width }) => width || "655px"};
  overflow-y: hidden;

  .navigation__routes__web {
    width: 100%;
    column-gap: ${({ noGap }) => (noGap ? "0" : "2%")};
    justify-content: space-between;
    padding: ${({ padding }) => padding || "1rem 1.5rem"};
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .tab__links {
      color: var(--uduxWhiteSecondary);
      white-space: nowrap;
      cursor: pointer;
      font-size: 13px;
      width: max-content;
      padding: ${({ pad_link }) => pad_link || "0.8rem 0.2rem"};
      transition: all 0.8s ease;

      :not(.active__tab):hover {
        color: var(--uduxYellowPrimary);
      }

      p {
        font-family: "Baguede", sans-serif;
        font-size: 14px;
        margin: 0;
      }
    }
    .active__tab {
      background-color: var(--uduxYellowPrimary);
      color: var(--uduxWhitePrimary);
      position: relative;
      cursor: default;
    }
  }

  .more__and__close {
    padding: 0.5rem;
    justify-content: space-between;

    .close-icon {
      font-size: 1.2rem;
      color: var(--textPrimary);
    }
  }

  @media only screen and (max-width: 600px) {
    margin: ${({ fsemMobileHeader }) => (fsemMobileHeader ? "0" : "2rem auto")};
    border: 0.5px solid var(--borderLight);

    .navigation__routes__web {
      width: 100%;
      padding: 0;
      column-gap: 0;
      overflow-y: hidden;
      justify-content: space-around;

      .tab__links {
        color: var(--uduxWhiteSecondary);
        white-space: nowrap;
        cursor: pointer;
        width: max-content;
        padding: 0.5rem 0.2rem;
        transition: all 0.3s ease;

        :not(.active__tab):hover {
          color: var(--uduxYellowPrimary);
        }

        p {
          font-size: 0.65rem;
        }
      }
      .active__tab {
        background-color: var(--uduxYellowPrimary);
        color: var(--uduxWhitePrimary);
      }
    }
  }
`

export const TabContentWrap = styled(FlexibleDiv)`
  width: 100%;
`
