import UduXGrayLogo from "assets/images/Auth/udux_logo_gray.svg";
import { FlexibleDiv } from "components/lib";
import { Types, useMainContext } from "context";
import { AuthResponseError } from "pages/Auth/AuthResponseError";
import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { slideTo, topSlideInFrom } from "utils";
import { AuthLayoutWrapper } from "./auth-layout.styles";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
// import SwiperCore, { EffectFade } from "swiper";

const checkHeight = window.innerHeight > 800 ? true : false;
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false;

export const AuthLayout = ({
  children,
  title = "Follow the Rhythm.",
  subText = "Build Your Tribe. Find Your X",
  galleryImages,
  activeSlide,
  galleryImage,
  ...props
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const apiResponseErrorRef = React.useRef(null);
  const formWrapRef = React.useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 994 });
  const [swiper, setSwiper] = React.useState(null);
  // SwiperCore.use([EffectFade]);

  const {
    state: { app },
    dispatch,
  } = useMainContext();
  const { apiResponseError, signUpFormStep } = app;
  const showBackButton = pathname !== "/login" && pathname !== "/oauth";

  React.useLayoutEffect(() => {
    topSlideInFrom(apiResponseErrorRef, { duration: 0.8, y: 30 });
  }, [apiResponseError]);

  React.useLayoutEffect(() => {
    if (apiResponseError) {
      slideTo(formWrapRef, { duration: 0.3, y: 5 });
    }
  }, [apiResponseError]);

  React.useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide, 500);
    }
  }, [swiper, activeSlide]);

  React.useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined });
  }, []);

  const handleGoBack = () => {
    if (signUpFormStep > 0 && pathname === "/signup") {
      return dispatch({ type: Types.SIGN_UP_FORM_STEP, payload: "SUB" });
    }

    return navigate(-1);
  };

  return (
    <AuthLayoutWrapper
      checkHeight={checkHeight}
      location={pathname}
      smallHeight={checkSmallScreenHeight}
      hasError={Boolean(apiResponseError)}
      {...props}
    >
      <FlexibleDiv className="bg__wrap__main">
        {isMobile ? (
          <div className="mobile__color__box__area">
            <div className="mobile__art__box__area"></div>
          </div>
        ) : (
          <React.Fragment>
            <div className="color__box__area"></div>
            <div className="art__box__area"></div>
          </React.Fragment>
        )}
      </FlexibleDiv>

      <FlexibleDiv className="content__box">
        {!isMobile && (
          <div className="gallery__pane" id="gallery__pane">
            <Swiper
              className="swiper"
              effect="fade"
              slidesPerView={1}
              initialSlide={activeSlide}
              onSwiper={setSwiper}
              allowTouchMove={false}
            >
              {galleryImages ? (
                galleryImages?.map((image, index) => (
                  <SwiperSlide className="slider__slide " key={index}>
                    <div
                      className="gallery_pane_img"
                      style={{
                        backgroundImage: `url(${image?.pageHoverImage})`,
                      }}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide className="slider__slide ">
                  <div
                    className="gallery_pane_img"
                    style={{
                      backgroundImage: `url(${galleryImage})`,
                    }}
                  />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        )}

        <div className="activity__pane">
          {showBackButton && (
            <button
              className="go__back__box"
              justifyContent="flex-start"
              onClick={handleGoBack}
            >
              <IoIosArrowRoundBack color="var(--uduxWhitePrimary)" size={30} />{" "}
              <span>Back</span>
            </button>
          )}
          <div className="logo__wrap">
            <img src={UduXGrayLogo} alt="uduX" />
          </div>

          <div className="content__box__layout">
            <div className="content__layout">
              <h2 className="title">{title}</h2>
              <p className="sub__text">{subText} </p>

              <div className="auth__response__error__main">
                <AuthResponseError
                  ref={apiResponseErrorRef}
                  error={apiResponseError}
                />
              </div>

              <div className="form__wrap" ref={formWrapRef}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </FlexibleDiv>
    </AuthLayoutWrapper>
  );
};
