import { Radio, Skeleton } from "antd"
import { FlexibleDiv } from "components/lib/Box"
import { Button } from "components/lib/Button"
import { AddPlaylistCard } from "components/lib/Cards"
import { NoDataDisplay } from "components/lib/NoDataDisplay"
import { useMainContext } from "context"
import { pull, uniq } from "lodash"
import {
  addToMultiplePlaylists,
  createCollabPlaylist,
  createSoloPlaylist,
  errorResponseHandler,
  getPlaylistsByQuery,
  successResponseHandler,
} from "network"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useMediaQuery } from "react-responsive"
import { getTrackTitle, topSlideInFrom, truncate } from "utils"
import { ATPMWrap } from "./add-to-playlist-modal"
import { useRef } from "react"
import { useLayoutEffect } from "react"

export const AddToPlaylistModal = ({ handleClose, open }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const [playlistTitle, setPlaylistTitle] = useState("")
  const [playlistType, setPlaylistType] = useState("solo")
  const [checkedPlaylists, setCheckedPlaylists] = useState("")
  const [cancelCreation, setCanceledCreation] = useState(false)
  const [createPlaylistOption, setCreatePlaylistOption] = useState(false)
  const {
    state: { player, users },
  } = useMainContext()
  const createPlaylistRef = useRef(null)
  const selectPlaylistRef = useRef(null)

  const { me } = users
  const { currentTrack } = player

  const { data, isLoading } = useQuery(
    ["get-user-playlist"],
    () => getPlaylistsByQuery(`user=${me?.id}`),
    {
      enabled: !!me?.id,
    }
  )
  const { mutate, isLoading: atmpLoading } = useMutation(addToMultiplePlaylists)
  const { mutate: mutateCreateSoloPlaylist, isLoading: cspLoading } =
    useMutation(createSoloPlaylist)
  const { mutate: mutateCreateCollabPlaylist, isLoading: ccpLoading } =
    useMutation(createCollabPlaylist)

  const onChange = e => {
    setPlaylistType(e.target.value)
  }

  useLayoutEffect(() => {
    topSlideInFrom(createPlaylistRef, { y: 20 })
  }, [createPlaylistOption])

  useLayoutEffect(() => {
    if (data && !!data.length) {
      topSlideInFrom(selectPlaylistRef, { y: -20 })
    }
  }, [cancelCreation, data])

  return (
    <ATPMWrap title={null} footer={null} visible={open} onCancel={handleClose}>
      <FlexibleDiv className="top__layer">
        <FlexibleDiv className="title__section">
          <p className="title">
            Add {truncate(getTrackTitle(currentTrack), 50)} to a playlist
          </p>
        </FlexibleDiv>
      </FlexibleDiv>

      {isLoading ? (
        <FlexibleDiv height="200px">
          <Skeleton active={isLoading} style={{ paddingTop: "1rem" }} />
        </FlexibleDiv>
      ) : createPlaylistOption ? (
        <FlexibleDiv
          ref={createPlaylistRef}
          className="select__playlist__content"
        >
          <FlexibleDiv className="creating__playlist">
            <FlexibleDiv className="input__wrap">
              <input
                value={playlistTitle}
                placeholder={`${playlistType} playlist #1`}
                onChange={({ target }) => setPlaylistTitle(target.value)}
              />
            </FlexibleDiv>

            <FlexibleDiv className="radio__group__wrap">
              <Radio.Group onChange={onChange} value={playlistType}>
                <Radio value={"solo"}>Solo</Radio>
                <Radio value={"collab"}>Collab</Radio>
              </Radio.Group>
            </FlexibleDiv>

            <FlexibleDiv className="cancel__create__playlist">
              <span
                onClick={() => {
                  setCreatePlaylistOption(false)
                  setCanceledCreation(true)
                }}
              >
                cancel
              </span>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      ) : (
        <FlexibleDiv
          ref={selectPlaylistRef}
          className="select__playlist__content"
        >
          {!!data?.length ? (
            <FlexibleDiv>
              {data.map(pt => (
                <AddPlaylistCard
                  key={pt.id}
                  onCheck={({ checked }, pl) => {
                    if (checked) {
                      setCheckedPlaylists(prevPl => uniq([...prevPl, pl]))
                    } else {
                      setCheckedPlaylists(prevPl => pull([...prevPl], pl))
                    }
                  }}
                  isMobile={isMobile}
                  {...pt}
                />
              ))}
            </FlexibleDiv>
          ) : (
            <NoDataDisplay height="250px" text={"You have no playlists."} />
          )}
        </FlexibleDiv>
      )}

      <FlexibleDiv className="btn__wrap" width="60%">
        {!!checkedPlaylists.length ? (
          <Button
            onClick={handleAddToMultiplePlaylists}
            loading={atmpLoading}
            color="var(--black)"
            width="100%"
            contained
            bold
          >
            <span>
              {checkedPlaylists.length === 1
                ? "Add To Playlist"
                : "Add To Playlists"}
            </span>
          </Button>
        ) : (
          <Button
            onClick={() => {
              if (Boolean(playlistTitle)) {
                return handleMutation()
              }
              setCreatePlaylistOption(true)
              setCanceledCreation(false)
            }}
            loading={cspLoading || ccpLoading}
            color="var(--black)"
            width="100%"
            contained
            bold
          >
            <span>Create Playlist</span>
          </Button>
        )}
      </FlexibleDiv>
    </ATPMWrap>
  )

  function handleAddToMultiplePlaylists() {
    const payload = {
      playlists: checkedPlaylists,
      tracks: [
        {
          sequence: 0,
          track: currentTrack?.track?.id,
        },
      ],
    }

    mutate(payload, {
      onSuccess: res => {
        // refetch()
        // successResponseHandler(res)
        setCheckedPlaylists("")
      },
      // onError: err => errorResponseHandler(err),
    })
  }

  function handleMutation() {
    if (playlistType === "solo") {
      mutateCreateSoloPlaylist(
        {
          title: playlistTitle,
          tracks: [
            {
              sequence: 0,
              track: currentTrack?.track?.id,
            },
          ],
        },
        {
          onSuccess: res => {
            successResponseHandler(res)
            handleClose()
          },
          onError: err => errorResponseHandler(err),
        }
      )
    }

    if (playlistType === "collab") {
      mutateCreateCollabPlaylist(
        {
          title: playlistTitle,
          private: true,
        },
        {
          onSuccess: res => {
            successResponseHandler(res)
            handleClose()
          },
          onError: err => errorResponseHandler(err),
        }
      )
    }
  }
}
