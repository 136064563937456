import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import React from "react"

const SpinnerIcon = ({ color, size }) => (
  <LoadingOutlined
    style={{
      fontSize: size,
      color: color,
      marginTop: "2px",
    }}
    spin
  />
)

export const SpinLoader = ({ color, size }) => (
  <Spin
    indicator={
      <SpinnerIcon color={color || "var(--yellowPrimary)"} size={size || 10} />
    }
  />
)
