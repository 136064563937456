import { Types, useMainContext } from "context";
import {
  getPlaylistFeatArtist,
  getPlaylistsByQuery,
  sendStreamData,
} from "network";
import { useCallback } from "react";
import ReactHlsPlayer from "react-hls-player/dist";
import {
  addToQueue,
  getCurrentTrackID,
  pauseAudio,
  playNextTrack,
} from "utils";
import { getUserAgent } from "utils/function-helpers/userAgent";

export const AudioPlayer = () => {
  const {
    state: { player, app },
    playerRef,
    dispatch,
  } = useMainContext();
  const {
    volume,
    repeat,
    isPlaying,
    isPaused,
    playingList,
    queuedTracks,
    currentTrack,
    isPlayerMuted,
  } = player;
  const { isLoggedIn } = app;



  const currentTrackTime = playerRef?.current?.currentTime;

  const whilePlaying = () => {
    dispatch({
      type: Types.CURRENT_PLAYING_TIME,
      payload: playerRef.current.currentTime,
    });

    if (
      !isLoggedIn &&
      !window.pathname.includes("widget") &&
      currentTrackTime > 25
    ) {
      dispatch({ type: Types.AUTH_STREAMING_WARNING, payload: true });
      pauseAudio(playerRef, dispatch);
    }
  };

  const fetchMoreTracks = useCallback(
    async (track, module) => {
      let tracksToAdd = [];

      if (module === "album" || module === "track") {
        let artist = undefined;
        track.forEach((t) => {
          artist = t.metadata.displayArtists.find(
            (da) => da.isMainArtist
          )?.artist;
        });

        try {
          const res = await getPlaylistFeatArtist(
            artist?.id,
            "sortByPopularity=asc"
          );

          tracksToAdd = !!res.length
            ? res.flatMap((r) => r.tracks).slice(0, 10)
            : [];
        } catch (error) {
          console.log(error);
        }
      }

      if (module === "playlist") {
        try {
          const res = await getPlaylistsByQuery("managedBy=ADMIN");

          tracksToAdd = !!res.length
            ? res.flatMap((r) => r.tracks).slice(0, 10)
            : [];
        } catch (error) {
          console.log(error);
        }
      }

      addToQueue(dispatch, tracksToAdd);
    },
    [dispatch]
  );

  return (
    <div style={{ display: "none" }}>
      {(currentTrack?.file?.url || currentTrack?.track?.file?.url) && (
        <ReactHlsPlayer
          loop={repeat}
          controls={false}
          volume={volume ** 2}
          playerRef={playerRef}
          muted={isPlayerMuted}
          onPlay={handleOnPlay}
          onEnded={handleOnEnded}
          onTimeUpdate={whilePlaying}
          autoPlay={isPaused || isPlaying}
          src={currentTrack?.file?.url || currentTrack?.track?.file?.url}
        />
      )}
    </div>
  );

  async function handleOnPlay() {
    if (isPlaying && queuedTracks.length < 3) {
      const module = playingList?.type?.toLowerCase() ?? "track";
      fetchMoreTracks(queuedTracks, module);
    }

    const payload = {
      source: "WEB",
      userAgent: getUserAgent(),
      track: getCurrentTrackID(currentTrack),
    };

    try {
      await sendStreamData(payload);
    } catch (error) {}
  }

  function handleOnEnded() {
    playNextTrack(dispatch, {
      track: currentTrack,
      checkAutoEnd: true,
    });
  }
};
