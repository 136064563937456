export const QueueIcon = ({ color = "#D9D9D9", width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "18"}
    height={height || "18"}
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g id="queue-icon" transform={props.transform || "translate(7 8)"}>
      <path
        d="M0 7.84766V9.80958V23.5431V25.505H15.6954H17.6574V23.5431V9.80958V7.84766H15.6954H1.96193H0ZM1.96193 9.80958H15.6954V23.5431H1.96193V9.80958Z"
        fill={color}
      />
      <path
        d="M3.92371 3.92383V5.88576H19.6191V21.5812H21.5811V5.88576V3.92383H19.6191H3.92371Z"
        fill={color}
      />
      <path
        d="M7.84784 0V1.96193H23.5433V17.6574H25.5052V1.96193V0H23.5433H7.84784Z"
        fill={color}
      />
    </g>
  </svg>
);
