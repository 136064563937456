import FindYourXBanner from "assets/images/general/find-your-x-banner.png"
// import { AppGuideTour } from "components"
import { AfroSkinModal } from "components/lib/Modals/AfroSkinModal/AfroSkinModal"
import { useMainContext } from "context"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { HomeActivityPane } from "./components"

export const VisitorsHomePage = () => {
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const el = useRef()
  const {
    state: { app },
  } = useMainContext()
  // const [startTour, setStartTour] = useState(true)
  const navigate = useNavigate()
  const { scrollToBottom } = app

  useEffect(() => {
    if (scrollToBottom && el.current) {
      el.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    }
  }, [scrollToBottom])

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenLoginModal(true)
    }, 120000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      {/* {startTour && <AppGuideTour setStartTour={setStartTour} />} */}

      <AfroSkinModal
        title={"Find your X"}
        content={
          "To continue exploring all that uduX has to offer please log in or create an account"
        }
        btnContent={"Login/Sign Up"}
        skinBanner={FindYourXBanner}
        handleClose={() => setOpenLoginModal(false)}
        handleBtnClick={() => navigate("/login")}
        open={openLoginModal}
      />

      <HomeActivityPane />
    </>
  )
}
