import { Dropdown } from "antd"
import {
  // DownloadIcon,
  FavoriteFilledIcon,
  FavoriteIcon,
  MoreIcon,
  PauseIcon,
  PlayIcon,
  ShuffleIcon,
} from "assets"
import uduXWhiteLogo from "assets/images/general/udux_white_logo.svg"
import { Types, useMainContext } from "context"
import dayjs from "dayjs"
import { take } from "lodash"
import {
  addReaction,
  errorResponseHandler,
  removeReaction,
  successResponseHandler,
} from "network"
import React, { memo } from "react"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import {
  capitalize,
  getCurrentTrackID,
  nowPlaying,
  pauseAudio,
  shuffleTrack,
  truncate,
  unShuffleTrack,
} from "utils"
import { PlaylistMoreMenu, SoloPlaylistJumbotron } from "./components"
import { PJSoloWrap } from "./components/styles"
import { PJWrap } from "./playlist-jumbotron.styles"

const PlaylistJumbotronDetails = memo(({ entity, tracks, module }) => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery({ minWidth: 1680 })
  const inSmMode = useMediaQuery({ maxWidth: 739 })

  const {
    state: { player, album, app },
    playerRef,
    dispatch,
  } = useMainContext()
  const { mutate: mutateLike } = useMutation(addReaction)
  const { mutate: mutateUnlike } = useMutation(removeReaction)

  const { isLoggedIn } = app
  const { isPlaying, shuffle, playingList, currentTrack } = player

  const da = entity?.metadata?.displayArtists
  const coverArt = getCoverArt()
  const mainArtist = module === "album" ? da?.find(d => d.isMainArtist) : ""

  return (
    <React.Fragment>
      {isDesktop && module === "playlist" && (
        <aside className="cover__art__playlist">
          <img src={coverArt} alt="cover art" />
        </aside>
      )}

      <section
        className={`${
          module === "album" ? "album__art" : "album__art__playlist"
        }`}
      >
        <aside className="hero__details">
          <aside>
            <p className="type__and__year">{getTypeAndYear()}</p>
            <h2 className="title">{getPlaylistTitle()}</h2>
          </aside>
          <div className="artist">
            {getPlaylistAvatar()}

            {module === "album" && (
              <p className="artist__names">
                {da && Array.isArray(da) ? (
                  take(da, 3)
                    .sort((a, b) => b.isMainArtist - a.isMainArtist)
                    .map((d, idx, list) => (
                      <span
                        className="artist__name"
                        key={idx}
                        onClick={() =>
                          navigate(`/artist/${d.artist ? d.artist.id : d.id}`)
                        }
                      >
                        {d.artist.stageName}
                        {idx + 1 < list.length
                          ? ","
                          : da.length > 3
                          ? ", ..."
                          : ""}{" "}
                      </span>
                    ))
                ) : (
                  <span
                    className="artist__name"
                    onClick={() =>
                      navigate(`/artist/${da.artist ? da.artist.id : da.id}`)
                    }
                  >
                    {truncate(da.artist.stageName, 30)}
                  </span>
                )}
              </p>
            )}
          </div>
        </aside>

        {!isDesktop && (
          <aside
            className={`${
              module === "album"
                ? "mobile__cover__art__view"
                : "mobile__cover__art__view__playlist"
            }`}
          >
            <img src={coverArt} alt="cover art" />
          </aside>
        )}
      </section>

      {isDesktop && module === "album" && (
        <aside className="cover__art">
          <img src={coverArt} alt="cover art" />
        </aside>
      )}

      <section
        className={`${
          module === "album" ? "album__stats" : "album__stats__playlist"
        }`}
      >
        <div className="stats__wrap">
          <div className="player__controls">
            {isPlayingPlaylist() ? (
              <div
                className="icon__wrap hover__fill"
                onClick={() => pauseAudio(playerRef, dispatch)}
              >
                <PauseIcon
                  width={30}
                  height={30}
                  transform={"translate(6 4)"}
                />
              </div>
            ) : (
              <div className="icon__wrap hover__fill">
                <PlayIcon
                  onClick={() => nowPlaying(entity, dispatch, playerRef)}
                  width={inSmMode ? "18" : "25"}
                  color="#fff"
                />
              </div>
            )}

            <div className="icon__wrap hover__fill">
              <ShuffleIcon
                width={inSmMode ? "18" : "22"}
                color={shuffle ? "var(--yellowPrimary)" : "#fff"}
                onClick={() =>
                  shuffle
                    ? unShuffleTrack(entity.tracks, dispatch)
                    : shuffleTrack(entity.tracks, dispatch)
                }
              />
            </div>
            <div
              className="icon__wrap hover__fill"
              onClick={() => toggleLike(entity?.id, capitalize(module))}
            >
              {entity?.userLiked ? (
                <FavoriteFilledIcon
                  width={inSmMode ? "23" : "30"}
                  height="30"
                />
              ) : (
                <FavoriteIcon
                  height="25"
                  transform={"translate(2 4)"}
                  width={inSmMode ? "23" : "30"}
                  color={`${
                    entity?.userLiked ? "var(--uduxYellowPrimary)" : "#fff"
                  }`}
                />
              )}
            </div>
            {/* <div className="icon__wrap hover__stroke">
              <DownloadIcon width={inSmMode ? "18" : "25"} color="#fff" />
            </div> */}
            <Dropdown
              trigger="click"
              placement="bottomLeft"
              overlay={<PlaylistMoreMenu />}
            >
              <div className="icon__wrap hover__fill">
                <MoreIcon height={inSmMode ? "15" : "19"} color="#fff" />
              </div>
            </Dropdown>
          </div>

          <div className="stats__display">
            <p className="statistics">
              <span className="number">{getTracksLength()}</span>
              <span className="info">Songs</span>
            </p>
            <p className="statistics">
              <span className="number">{entity?.statistics?.likes}</span>
              <span className="info">Likes</span>
            </p>
            <p className="statistics">
              <span className="number">{entity?.statistics?.spins}</span>
              <span className="info">Spins</span>
            </p>
            <p className="statistics">
              <span className="number">
                {entity?.statistics?.streamTime || 0}hr
              </span>
              <span className="info">Runtime</span>
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  )

  function toggleLike(entityId, entityType) {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true })
    }

    const payload = { entity: entityId, entityType }

    if (entity?.userLiked) {
      mutateUnlike(payload, {
        onSuccess: () => {
          dispatch({
            type: Types.UPDATE_CURRENT_ALBUM,
            payload: { ...album, userLiked: false },
          })
          successResponseHandler({
            message: `${entityType} un-liked successfully.`,
          })
        },
        onError: error => errorResponseHandler(error),
      })
    } else {
      mutateLike(
        { ...payload, type: "LIKE" },
        {
          onSuccess: () => {
            dispatch({
              type: Types.UPDATE_CURRENT_ALBUM,
              payload: { ...album, userLiked: true },
            })
            successResponseHandler({
              message: `${entityType} liked successfully.`,
            })
          },
          onError: error => errorResponseHandler(error),
        }
      )
    }
  }

  function getCoverArt() {
    if (module === "album") {
      return entity?.metadata?.coverArt?.url
    }

    if (module === "playlist") {
      return entity?.coverArt?.url
    }
  }

  function getTracksLength() {
    if (module === "album") {
      return tracks.length
    }

    if (module === "playlist") {
      return tracks.map(t => t.track).length
    }
  }

  function getPlaylistTitle() {
    if (module === "album") {
      return entity?.metadata?.title
    }

    if (module === "playlist") {
      return entity?.title
    }
  }

  function getTypeAndYear() {
    if (module === "album") {
      return `${entity?.metadata?.entityType}:
              ${dayjs(entity?.metadata?.originalReleaseDate).format("YYYY")}`
    }

    if (module === "playlist") {
      return "Public Playlist"
    }
  }

  function getPlaylistAvatar() {
    if (module === "album") {
      return (
        <div className="artist__avatar">
          <img
            src={mainArtist?.artist?.profilePicture?.url || coverArt}
            alt="artist name"
          />
        </div>
      )
    }

    if (module === "playlist" && entity?.type === "MANAGED") {
      return (
        <div className="udux__logo">
          <img src={uduXWhiteLogo} alt="udux" />
        </div>
      )
    }
  }

  function isPlayingPlaylist() {
    const trackId = getCurrentTrackID(currentTrack)
    return (
      isPlaying &&
      (playingList?.id === entity.id ||
        entity.tracks.some(t => t?.track?.id === trackId || t.id === trackId))
    )
  }
})

export const PlaylistJumbotron = memo(({ ...props }) => {
  const { entity, module } = props
  const entityType = entity?.type?.toLowerCase()

  const getWrapper = () => {
    if (
      entityType === "managed" ||
      entityType === "single" ||
      entityType === "album"
    ) {
      return (
        <PJWrap dominantColors={getDominantColors()}>
          <PlaylistJumbotronDetails {...props} />
        </PJWrap>
      )
    } else if (entityType === "solo" || entityType === "collaborative") {
      return (
        <PJSoloWrap dominantColors={getDominantColors()}>
          <SoloPlaylistJumbotron {...props} />
        </PJSoloWrap>
      )
    }
  }
  function getDominantColors() {
    if (module === "album") {
      return entity?.metadata?.coverArtDominantColors?.map(cd =>
        cd.startsWith("#") ? cd : `#${cd}`
      )
    }

    if (module === "playlist") {
      return entity?.coverArtDominantColors?.map(cd =>
        cd.startsWith("#") ? cd : `#${cd}`
      )
    }
  }

  return getWrapper()
})
