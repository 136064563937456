import { LoadingOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { BorderedTextfield, Button, FlexibleDiv } from "components";
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout";
import { errorResponseHandler, resetPassword } from "network";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "utils";
import { Container } from "./resetPassword.styles";
import galleryImage from "assets/images/Auth/reset_password.jpg";

const checkHeight = window.innerHeight > 800 ? true : false;

const ResetPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  const handleFormSubmit = async (values) => {
    setLoading(true);
    values["sourceService"] = "udux";
    try {
      await resetPassword(values);
      setLoading(false);
      navigate("/check-mail", { state: { prevRoute: "/reset-password" } });
    } catch (error) {
      errorResponseHandler(error);
      setLoading(false);
    }
  };

  return (
    <AuthLayout
      title="Reset Password"
      subText="Will only take a few seconds"
      galleryImage={galleryImage}
    >
      <Container checkHeight={checkHeight}>
        <Form form={form} onFinish={handleFormSubmit} className="form_box">
          <FlexibleDiv className="input">
            <BorderedTextfield
              type="email"
              paddingLeft="5px"
              borderWidth="0.5px"
              name="emailAddress"
              label="What’s your email"
              borderColor="var(--dampWhiteLight)"
              font_family={'"Baguede", sans-serif'}
              placeholder="What’s your email"
              validator={validateEmail}
              small
            />
          </FlexibleDiv>

          <FlexibleDiv justifyContent="center" className="btn_box">
            <Button
              width="220px"
              height="50px"
              font_size="16px"
              htmlType="submit"
              color="var(--black)"
              contained
              bold
              ltr
            >
              <span> {loading ? <LoadingOutlined /> : "Continue"}</span>
            </Button>
          </FlexibleDiv>
        </Form>

        <FlexibleDiv
          flexDir="column"
          className="btn__box"
          justifyContent="center"
        >
          <Link to="/signup" className="existing__user">
            Create an Account
          </Link>
        </FlexibleDiv>
      </Container>
    </AuthLayout>
  );
};

export default ResetPassword;
