import { isEmpty } from "lodash"

export function getTrackID(entity) {
  if (!isEmpty(entity)) {
    if (entity?.track?.id) {
      return entity.track.id
    }

    if (entity?.id) {
      return entity.id
    }
  }
}
