import axios from "axios"
import { instance } from "./axios"
import { Config } from "config"

export const getBillingPlans = async () => {
  const res = await instance.get(`/core/billing-plan`)
  return res.data
}

export const initiatePayment = async (provider, data) => {
  const res = await instance.post(
    `/core/payment/${provider}/initiate-payment`,
    data
  )

  return res.data
}

export const checkUserPhoneNumber = async phoneNumber => {
  const res = await instance.get(`/core/user/check-phone-exist/${phoneNumber}`)

  return res.data
}

export const subscribeMTNPhone = async data => {
  const res = await instance.post("/core/mtn-subscription", data)

  return res.data
}

export const verifyPayment = async (provider, reference) => {
  if (provider === "mtn") return

  const res = await instance.get(
    `/core/payment/${provider}/verify-payment/${reference}`
  )

  return res.data
}

export const headerEnrichment = async url => {
  const res = await axios.get(url)

  return res.data
}

export const saveMTNEnrichedUser = async data => {
  const res = await axios.post(Config.loggerUrl, data)

  return res.data
}

export const pollSubscriptionStatus = async () => {
  const res = await instance.get(`/core/user/subscription`)
  console.log("main", res)
  return res.data
}
