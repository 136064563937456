import { NoSearchHistoryIcon } from "assets/images/general/NoSearchHistoryIcon";
import { FlexibleDiv } from "components";
import { NoSearchHistoryWrap } from "./no-search-history.styles";

export const NoSearchHistory = () => {
  return (
    <NoSearchHistoryWrap flexDir="column">
      <FlexibleDiv className="icon__wrap">
        <NoSearchHistoryIcon />
      </FlexibleDiv>

      <FlexibleDiv flexDir="column">
        <p className="headline">We usually display “Recent Searches” here</p>
        <p className="text">
          However it doesn’t seem like you’ve searched for anything recently...
        </p>
      </FlexibleDiv>
    </NoSearchHistoryWrap>
  );
};
