import { memo, useEffect, useState } from "react"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { MdCancel as Cancel } from "react-icons/md"
import {
  BorderedFormItem,
  BorderedFormItemWrap,
  BorderedIconWrap,
} from "./bordered-textfield.styles"

export const BorderedTextarea = memo(
  ({
    name,
    type,
    label,
    rules,
    rows,
    cols,
    textarea,
    onChange,
    validator,
    placeholder,
    hasError,
    nullable,
    hasErrorMessage,
    checkAvailability,
    ...props
  }) => {
    const [status, setStatus] = useState()
    const [value, setValue] = useState("")

    props.hasError = checkAvailability ? hasError : Boolean(status?.err)
    props.hasSuccess = checkAvailability
      ? props.hasSuccess
      : Boolean(status?.success)

    useEffect(() => {
      if (hasError) {
        setStatus({ err: hasErrorMessage })
      }
    }, [hasError])

    const handleBlur = e => {
      if (hasError && hasErrorMessage) {
        return setStatus({ err: hasErrorMessage })
      } else {
        validator(e, e?.target?.value, setStatus)
      }
    }

    return (
      <BorderedFormItemWrap
        className="form-item-container"
        height={props.height}
        width={props.width}
        {...props}
      >
        {label && <label htmlFor={name}>{label}</label>}

        <div className="input__wrapper">
          <BorderedFormItem
            name={name}
            rules={
              validator
                ? [
                    {
                      validator: (rules, value) =>
                        validator(rules, value, setStatus, nullable),
                    },
                  ]
                : rules
            }
            lightMask={props.lightMask}
            {...props}
          >
            <textarea
              rows={rows}
              cols={cols}
              placeholder={placeholder}
              disabled={props.editLocked}
              onBlur={handleBlur}
              onChange={e => {
                if (onChange) {
                  return onChange(e)
                }

                setValue(e.target.value)

                if (checkAvailability) {
                  return checkAvailability(e?.target?.value)
                }

                validator && validator(e, e?.target?.value, setStatus)
              }}
            />
          </BorderedFormItem>

          <BorderedIconWrap height={props.height}>
            {status?.err && <Cancel className="cancel" />}
            {status?.success && (
              <BsFillCheckCircleFill
                className="check"
                color="var(--successPrimary)"
              />
            )}
          </BorderedIconWrap>
        </div>

        <p className="input__error__text">{status?.err}</p>
      </BorderedFormItemWrap>
    )
  }
)
