import FormItem from "antd/lib/form/FormItem";
import { useState } from "react";
import { DateSelectorWrap, StyledDatePicker } from "./date-selector.styles";

export default ({
  picker,
  handleChange,
  validator,
  rules,
  name,
  nullable,
  ...props
}) => {
  const [status, setStatus] = useState();

  const hasError = props.hasError || Boolean(status?.err);
  const hasSuccess = props.hasSuccess || Boolean(status?.success);

  return (
    <DateSelectorWrap hasError={hasError} hasSuccess={hasSuccess} {...props}>
      <FormItem
        name={name}
        rules={
          validator
            ? [
                {
                  validator: (rules, value) =>
                    validator(
                      rules,
                      value,
                      props.setStatus ?? setStatus,
                      nullable
                    ),
                },
              ]
            : rules
        }
        className="date__input__form__item"
      >
        <StyledDatePicker onChange={handleChange} picker={picker} {...props} />
      </FormItem>

      {props?.status?.err && (
        <div className="input_error_text">
          <p className="">{props?.status?.err}</p>
        </div>
      )}
    </DateSelectorWrap>
  );
};
