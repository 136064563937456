export const ParticleConfig = {
  fullScreen: true,
  fpsLimit: 30,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      onHover: {
        enable: true,
        mode: "repulse",
      },
      resize: true,
    },
    modes: {
      bubble: {
        distance: 400,
        duration: 2,
        opacity: 0.8,
        size: 40,
      },
      push: {
        quantity: 4,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
    },
  },
  particles: {
    links: {
      enable: false,
      opacity: 0.5,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: "none",
      enable: true,
      outMode: "bounce",
      random: true,
    },
    number: {
      density: {
        enable: true,
        area: 800,
      },
      value: 45,
    },
    opacity: {
      value: 0.5,
    },
    size: {
      random: true,
      value: 5,
    },
  },
  detectRetina: true,
}
