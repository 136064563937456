import { FlexibleDiv } from "components"
import styled from "styled-components"

export const QTIWrap = styled(FlexibleDiv)`
  cursor: pointer;
  align-items: center;
  padding: 1rem 0.2rem;
  max-width: 750px;
  transition: all 0.3s ease-in;
  background-color: var(--overlayDark);
  border-bottom: 0.5px solid var(--borderLighter);
  column-gap: 6%;

  :hover {
    background: var(--backgroundDarkLight);

    .show__on__hover {
      opacity: 1;
    }
  }

  .song__info__wrap {
    justify-content: flex-start;
  }

  .image__wrap {
    width: 65px;
    min-width: 65px;
    height: 62px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .action__icons__wrap {
    width: 105px;
    min-width: 105px;
    margin-right: 0.5rem;

    .icon__wrap {
    }
  }

  .action__icons__wrap__isMobile {
    width: 15px;
    margin-right: 1rem;
  }

  .show__on__hover {
    opacity: 0;
    transition: all 0.5s ease-in;
  }

  .text__wrap {
    margin-left: 25px;
    flex: 1;

    p {
      margin: 0;
      font-size: 16px;
    }
    span {
      color: var(--uduxWhiteSecondary);
      font-size: 13px;
    }

    span,
    p {
      width: 150px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "Baguede", sans-serif;
    }
  }

  .artist {
    :hover {
      text-decoration: underline;
    }
  }

  svg {
    path {
      transition: all 0.3s ease;
    }
    cursor: pointer;
    :hover {
      path {
        fill: var(--uduxYellowPrimary);
      }
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 1320px) {
    .image__wrap {
      width: 45px;
      min-width: 45px;
      height: 52px;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: 600px) {
  }
`
