import { Menu, Skeleton, Dropdown } from "antd"
import OrganizeSvg from "assets/images/general/organize.svg"
import { QueueIcon } from "assets/images/player/SVGExports"
import {
  AlbumCard,
  ApiResponseBanner,
  ArtisteCard,
  FlexibleDiv,
  Loader,
  NoDataDisplay,
  PlaylistCard,
  TrackListItem,
} from "components"
import { useMainContext } from "context"
import { take } from "lodash"
import { getPlaylistsByQuery, getSimilarArtistTracks } from "network"
import { TrackListWrap } from "pages/Playlist/playlist.styles"
import { SearchCategoryFilter } from "pages/Search"
import { NoSearchResult } from "pages/Search/components/NoSearchResult"
import {
  albumCategoryFilter,
  playlistCategoryFilter,
} from "pages/Search/components/SearchFilter/data-helper"
import React, { useCallback, useEffect, useState } from "react"
import { Fragment } from "react"
import { BiSearchAlt2 } from "react-icons/bi"
import { useParams } from "react-router-dom"
import { addToQueue, playTrack, useDebounce, useSearch } from "utils"

export const PlaylistOrganizer = ({
  entity,
  tracks,
  module,
  isOwner,
  loading,
  coverArt,
  mainArtist,
  moreFromData,
  similarFromData,
}) => {
  const ploTracks = getTracks()
  const [, setOrganizePlaylist] = useState("custom")
  const [responseBanner, setResponseBanner] = useState({
    open: false,
    type: "",
    response: "",
  })
  const [atpSearch, setAtpSearch] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [albumFilter, setAlbumFilter] = useState("all")
  const [playlistFilter, setPlaylistFilter] = useState("all")
  const debouncedSearch = useDebounce(atpSearch, 300)
  const { playlistId } = useParams()
  const {
    dispatch,
    playerRef,
    state: { player },
  } = useMainContext()

  const { queuedTracks, isPlaying } = player

  const { isLoading: searchLoading, data: searchData } = useSearch(
    debouncedSearch,
    atpSearch
  )

  const handleOrganizePlaylist = type => {
    setOrganizePlaylist(type)
  }

  const organizeDetailsMenu = (
    <Menu
      style={{ backgroundColor: "#000000", border: "1px solid #0F0F0F" }}
      items={[
        {
          key: "1",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("custom")}
            >
              <div>
                <QueueIcon width={18} />
              </div>
              <p>Custom Order</p>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("title")}
            >
              <QueueIcon width={18} />
              <p>Title</p>
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("creator")}
            >
              <QueueIcon width={18} />
              <p>Creator</p>
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("album")}
            >
              <QueueIcon width={18} />
              <p>Album</p>
            </div>
          ),
        },
        {
          key: "5",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("duration")}
            >
              <QueueIcon width={18} />
              <p>Duration</p>
            </div>
          ),
        },
      ]}
    />
  )

  const hasResult =
    searchData?.data &&
    Object.values(searchData?.data).some(d => Boolean(d.length))
  const albumsResult = isOwner && hasResult ? searchData?.data?.albums : []
  const artistsResult = isOwner && hasResult ? searchData?.data?.artists : []
  const playlistsResult =
    isOwner && hasResult ? searchData?.data?.playlists : []
  const tracksResult =
    isOwner && hasResult ? take(searchData?.data?.tracks, 5) : []

  const canShowAtpResult = Boolean(atpSearch) && hasResult
  const hasNoAtpResult = Boolean(atpSearch) && !hasResult

  const filteredPloTracks = searchValue
    ? ploTracks.filter(
        fl =>
          fl?.metadata?.title
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          fl?.metadata?.displayArtists?.some(da =>
            da.artist.stageName
              .toLowerCase()
              .includes(searchValue?.toLowerCase())
          )
      )
    : ploTracks

  return (
    <React.Fragment>
      <FlexibleDiv
        className="filter__playlist__box"
        justifyContent="space-between"
      >
        {isOwner ? (
          <div className="playlist--search">
            <h3 className="curate__playlist">
              Start curating the perfect playlist
            </h3>
            <FlexibleDiv className="search__input">
              <BiSearchAlt2 size={20} color="rgba(217, 217, 217, 0.5)" />
              <input
                onChange={({ target }) => setAtpSearch(target.value)}
                placeholder="Search for songs, albums or artists"
              />
            </FlexibleDiv>
          </div>
        ) : (
          <FlexibleDiv className="search__input">
            <BiSearchAlt2 size={20} color="rgba(217, 217, 217, 0.5)" />
            <input
              onChange={({ target }) => setSearchValue(target.value)}
              placeholder="Search this playlist"
            />
          </FlexibleDiv>
        )}

        <Dropdown
          trigger="click"
          overlay={organizeDetailsMenu}
          placement="bottomLeft"
        >
          <FlexibleDiv
            width="unset"
            className="organize__by"
            alignItems="center"
          >
            <img src={OrganizeSvg} alt="filter icon" />
            <p>Organize by</p>
          </FlexibleDiv>
        </Dropdown>
      </FlexibleDiv>

      {responseBanner.open && (
        <FlexibleDiv className="response__banner__wrap">
          <ApiResponseBanner
            onClose={() => setResponseBanner({})}
            {...responseBanner}
          />
        </FlexibleDiv>
      )}

      {!canShowAtpResult && !searchLoading && (
        <TrackListWrap pt={"1rem"} bgImg={coverArt} className="tracks__display">
          {!!ploTracks.length ? (
            !!filteredPloTracks.length ? (
              filteredPloTracks.map((track, idx) => (
                <TrackListItem
                  handlePlayTrack={handlePlayTrack}
                  idx={idx}
                  key={track.id}
                  {...track}
                  fullView
                />
              ))
            ) : (
              <NoDataDisplay
                height="250px"
                text="No track matched your search."
              />
            )
          ) : (
            <FlexibleDiv className="sorry__message">
              Sorry, This playlist has no songs.
            </FlexibleDiv>
          )}
        </TrackListWrap>
      )}

      {searchLoading ? (
        <Loader variant="purple" wrapperHeight="55vh" />
      ) : canShowAtpResult ? (
        <React.Fragment>
          {!!tracksResult.length && (
            <FlexibleDiv
              // ref={subContentRef}
              className="atp__display__result"
            >
              <FlexibleDiv className="tracks">
                <FlexibleDiv className="result__title">
                  <h2 className="songs">Songs</h2>
                </FlexibleDiv>

                <FlexibleDiv className="mapped__songs">
                  {tracksResult.map((track, idx) => (
                    <TrackListItem
                      key={track.id}
                      idx={idx}
                      {...track}
                      handleApiResponse={(data, type) =>
                        setResponseBanner({
                          open: true,
                          type: type,
                          response: data,
                        })
                      }
                      playlistId={playlistId}
                      addToPlaylist
                    />
                  ))}
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>
          )}

          {!!artistsResult.length && (
            <FlexibleDiv
              // ref={subContentRef}
              className="atp__display__result"
            >
              <FlexibleDiv className="result__title">
                <h2 className="artists">Artists</h2>

                <span className="see__all">see all</span>
              </FlexibleDiv>

              <FlexibleDiv
                // ref={subContentRef}
                className="mapped__content"
              >
                {artistsResult.map(artist => (
                  <ArtisteCard key={artist.id} {...artist} />
                ))}
              </FlexibleDiv>
            </FlexibleDiv>
          )}

          {!!playlistsResult.length && (
            <FlexibleDiv className="atp__display__result">
              <FlexibleDiv className="result__title">
                <h2 className="playlists">Playlists</h2>
              </FlexibleDiv>

              <FlexibleDiv className="category__filter">
                <FlexibleDiv className="category__filter__content">
                  <SearchCategoryFilter
                    activeLabel={playlistFilter}
                    filterBy={playlistCategoryFilter}
                    setActiveLabel={pf => setPlaylistFilter(pf)}
                  />
                </FlexibleDiv>
                <span className="see__all">see all</span>
              </FlexibleDiv>

              <FlexibleDiv
                // ref={subContentRef}
                className="mapped__content"
              >
                {playlistsResult
                  .filter(
                    p =>
                      (p.type === "MANAGED" && playlistFilter === "by_udux") ||
                      ((p.type === "SOLO" || p.type === "COLLABORATIVE") &&
                        playlistFilter === "by_xers") ||
                      playlistFilter === "all"
                  )
                  .map(playlist => (
                    <PlaylistCard key={playlist.id} {...playlist} />
                  ))}
              </FlexibleDiv>
            </FlexibleDiv>
          )}

          {!!albumsResult.length && (
            <FlexibleDiv className="atp__display__result">
              <FlexibleDiv className="result__title">
                <h2 className="artists__release">Artist Release</h2>
              </FlexibleDiv>

              <FlexibleDiv className="category__filter">
                <FlexibleDiv className="category__filter__content">
                  <SearchCategoryFilter
                    filterBy={albumCategoryFilter}
                    activeLabel={albumFilter}
                    setActiveLabel={af => setAlbumFilter(af)}
                  />
                </FlexibleDiv>
                <span className="see__all">see all</span>
              </FlexibleDiv>

              <FlexibleDiv
                // ref={subContentRef}
                className="mapped__content"
              >
                {albumsResult
                  .filter(
                    alb =>
                      (alb.type === "Album" && albumFilter === "album") ||
                      (alb.type === "Single" && albumFilter === "ep") ||
                      albumFilter === "all"
                  )
                  .map(album => (
                    <AlbumCard key={album.id} {...album} />
                  ))}
              </FlexibleDiv>
            </FlexibleDiv>
          )}
        </React.Fragment>
      ) : (
        hasNoAtpResult && <NoSearchResult />
      )}

      {module === "album" && (
        <React.Fragment>
          {loading ? (
            <FlexibleDiv className="display__result">
              <Skeleton active={loading} />
            </FlexibleDiv>
          ) : (
            <Fragment>
              {!!moreFromData.length && (
                <FlexibleDiv className="display__result">
                  <FlexibleDiv className="result__title">
                    <h2 className="heading__text">
                      Explore More By {mainArtist?.artist?.stageName}
                    </h2>
                  </FlexibleDiv>

                  <FlexibleDiv className="mapped__content">
                    {take(moreFromData, 10)?.map(album => (
                      <AlbumCard key={album.id} {...album} />
                    ))}
                  </FlexibleDiv>
                </FlexibleDiv>
              )}
            </Fragment>
          )}

          {loading ? (
            <FlexibleDiv className="display__result">
              <Skeleton active={loading} />
            </FlexibleDiv>
          ) : (
            <Fragment>
              {!!similarFromData.length && (
                <FlexibleDiv className="display__result">
                  <FlexibleDiv className="result__title">
                    <h2 className="heading__text">
                      Playlists Featuring {mainArtist?.artist?.stageName}
                    </h2>
                  </FlexibleDiv>

                  <FlexibleDiv className="mapped__content">
                    {take(similarFromData, 10)?.map(artist => (
                      <ArtisteCard key={artist.id} {...artist} />
                    ))}
                  </FlexibleDiv>
                </FlexibleDiv>
              )}
            </Fragment>
          )}
        </React.Fragment>
      )}

      {module === "playlist" && (
        <React.Fragment>
          <FlexibleDiv className="display__result">
            {loading ? (
              <Skeleton active={loading} />
            ) : (
              <React.Fragment>
                <FlexibleDiv className="result__title">
                  <h2 className="heading__text">It shouldn't end here...</h2>

                  <p className="text">
                    We recommend you sit back and plug into one or all of the
                    playlists below
                  </p>
                </FlexibleDiv>

                <FlexibleDiv className="mapped__content">
                  {take(similarFromData, 10)?.map(playlist => (
                    <PlaylistCard key={playlist.id} {...playlist} />
                  ))}
                </FlexibleDiv>
              </React.Fragment>
            )}
          </FlexibleDiv>
        </React.Fragment>
      )}
    </React.Fragment>
  )

  function getTracks() {
    if (module === "album") {
      return tracks
    }

    if (module === "playlist") {
      return tracks.map(t => t.track)
    }
  }

  function handlePlayTrack(payload) {
    playTrack(dispatch, playerRef, { track: payload, entity })
    addToQueue(dispatch, tracks)
  }
}
