import { LoadingOutlined } from "@ant-design/icons"
import { Form } from "antd"
import { BorderedTextfield, Button, FlexibleDiv } from "components"
import { PaymentRegisterLayout } from "components/layouts/PaymentsLayout/PaymentRegisterLayout/paymentRegisterLayout"
import { Types, useMainContext } from "context"
import { login } from "network"
import { useMutation } from "react-query"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import LoginPagePicture from "assets/images/payment/loginPagePicture.png"
import { validateBasicField, validatePassword } from "utils"
import { PLAWrapper } from "./payment-logging-auth.styles"
import { Config } from "config"
import { useEffect } from "react"

const checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

export const PaymentLoginAuth = () => {
  const [params] = useSearchParams()
  const { dispatch } = useMainContext()
  const navigate = useNavigate()
  const plan = params.get("type")
  const mode = params.get("mode")
  const [form] = Form.useForm()
  const { mutate, isLoading } = useMutation(login)

  const handleFormSubmit = async values => {
    mutate(
      {
        ...values,
        sourceService: Config.sourceService,
      },
      {
        onSuccess: res => {
          if (res) {
            const { user, tokens } = res

            const pathToReturn = `/payment/confirm?type=${plan}&mode=${mode}`

            localStorage.setItem("udx__user", JSON.stringify(user))
            localStorage.setItem("udx__tokens", JSON.stringify(tokens))

            dispatch({ type: Types.CURRENT_USER, payload: user })
            navigate(pathToReturn, {
              state: { prevRoute: `/payment/plan?type=${plan}&mode=${mode}` },
            })
          }
        },
        onError: error => {
          dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
        },
      }
    )
  }

  useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined })
  }, [dispatch])

  return (
    <PaymentRegisterLayout
      checkHeight={checkHeight}
      smallHeight={checkSmallScreenHeight}
      galleryImage={LoginPagePicture}
      backURL={`/payment/authentication?type=${plan}&mode=${mode}`}
    >
      <PLAWrapper checkHeight={checkHeight}>
        <Form form={form} onFinish={handleFormSubmit} className="form__box">
          <FlexibleDiv className="textfield__wrap">
            <BorderedTextfield
              type="text"
              paddingLeft="5px"
              name="emailAddress"
              label="What’s your email/username"
              placeholder="What’s your email/username"
              validator={validateBasicField}
            />
          </FlexibleDiv>

          <FlexibleDiv className="textfield__wrap">
            <BorderedTextfield
              type="password"
              name="password"
              label="Password"
              paddingLeft="5px"
              validator={validatePassword}
            />
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              width="180px"
              height="40px"
              htmlType="submit"
              color="var(--black)"
              contained
              radius="0px"
              bold
              ltr
            >
              <span>{isLoading ? <LoadingOutlined /> : "Confirm Details"}</span>
            </Button>
          </FlexibleDiv>
        </Form>

        <FlexibleDiv
          flexDir="column"
          className="btn__box"
          justifyContent="center"
        >
          <Link to="/reset-password" className="existing__user">
            Forgot your Password
          </Link>

          <Link to="/signup" className="existing__user">
            Create an Account
          </Link>
        </FlexibleDiv>
      </PLAWrapper>
    </PaymentRegisterLayout>
  )
}
