import UduXGrayLogo from "assets/images/Auth/udux_logo_gray.svg";
import defaultGalleryImage from "assets/images/Auth/verification_starting_page.png";
import { FlexibleDiv } from "components/lib";
import { useMainContext } from "context";
import { AuthResponseError } from "pages/Auth/AuthResponseError";
import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentRegisterLayoutWrapper } from "./paymentRegisterLayout.styles";
import { slideTo, topSlideInFrom } from "utils";

const checkHeight = window.innerHeight > 800 ? true : false;
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false;

export const PaymentRegisterLayout = ({
  children,
  title = "Follow the Rhythm.",
  subText = "Build Your Tribe. Find Your X",
  galleryImage = defaultGalleryImage,
  hideCustomBackButton = true,
  customGoBack,
  backURL,
}) => {
  const { pathname, search, hash } = useLocation();
  const navigate = useNavigate();
  const formWrapRef = React.useRef(null);
  const apiResponseErrorRef = React.useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 996 });
  const {
    state: { app },
    dispatch,
  } = useMainContext();
  const { apiResponseError, signUpFormStep } = app;
  const showBackButton = pathname !== "/login" && pathname !== "/oauth";

  React.useLayoutEffect(() => {
    if (apiResponseError) {
      slideTo(formWrapRef, { duration: 0.3, y: 5 });
    }
  }, [apiResponseError]);

  React.useLayoutEffect(() => {
    topSlideInFrom(apiResponseErrorRef, { duration: 0.8, y: 30 });
  }, [apiResponseError]);

  const handleGoBack = () => {
    // const path = `${hash}${search}`;

    if (backURL) {
      return navigate(backURL);
    }

    return navigate(-1, { hash, search });
  };

  return (
    <PaymentRegisterLayoutWrapper
      checkHeight={checkHeight}
      location={pathname}
      smallHeight={checkSmallScreenHeight}
      galleryImage={galleryImage}
    >
      <FlexibleDiv className="bg__wrap__main">
        {isMobile ? (
          <div className="mobile__color__box__area">
            <div className="mobile__art__box__area"></div>
          </div>
        ) : (
          <React.Fragment>
            <div className="color__box__area"></div>
            <div className="art__box__area"></div>
          </React.Fragment>
        )}
      </FlexibleDiv>

      <FlexibleDiv className="content__box">
        {!isMobile && <div className="gallery__pane" id="gallery__pane"></div>}

        <div className="activity__pane">
          {showBackButton && hideCustomBackButton && (
            <button
              className="go__back__box"
              justifyContent="flex-start"
              onClick={handleGoBack}
            >
              <IoIosArrowRoundBack color="var(--uduxWhitePrimary)" size={30} />{" "}
              <span>Back</span>
            </button>
          )}
          {!hideCustomBackButton && (
            <button
              className="go__back__box"
              justifyContent="flex-start"
              onClick={customGoBack}
            >
              <IoIosArrowRoundBack color="var(--uduxWhitePrimary)" size={30} />{" "}
              <span>Back</span>
            </button>
          )}
          <div className="logo__wrap">
            <img src={UduXGrayLogo} alt="uduX" />
          </div>

          <div className="content__box__layout">
            <div className="content__layout">
              <h2 className="title">{title}</h2>
              <p className="sub__text">{subText} </p>

              <div className="auth__response__error__main">
                {/* {apiResponseError && ( */}
                <AuthResponseError
                  ref={apiResponseErrorRef}
                  error={apiResponseError}
                />
                {/* )} */}
              </div>

              <div className="form__wrap">{children}</div>
            </div>
          </div>
        </div>
      </FlexibleDiv>
    </PaymentRegisterLayoutWrapper>
  );
};
