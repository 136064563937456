import { useState } from "react"
import { useContext } from "react"
import { createContext } from "react"
import { useMainContext } from "./mainContext"
import { useEffect } from "react"
import { useCallback } from "react"
import { UpdateProfileModal } from "components"
import { Fragment } from "react"

const UpdateProfileContext = createContext()

const defaultState = () => {
  let initialState = {
    isTempAccount: false,
  }

  return initialState
}

export const useUpdateProfileContext = () => {
  const context = useContext(UpdateProfileContext)

  if (context === undefined) {
    throw new Error(
      "Context: wrap the component inside HeaderEnrichmentContextProvider"
    )
  }

  return context
}

export const UpdateProfileProvider = ({ children }) => {
  const [state, setState] = useState(defaultState)
  const { state: __state } = useMainContext()
  const { users, app } = __state

  /********** side effects here **********/
  const checkTempUser = useCallback(() => {
    const { me } = users

    setState({
      isTempAccount: me?.isTempAccount,
    })
  }, [users])

  // console.log(users.me)

  useEffect(() => {
    checkTempUser()
  }, [checkTempUser])

  return app.isLoggedIn ? (
    <UpdateProfileContext.Provider
      value={{
        ...state,
      }}
    >
      {state.isTempAccount && <UpdateProfileModal open={state.isTempAccount} />}

      {children}
    </UpdateProfileContext.Provider>
  ) : (
    <Fragment>{children}</Fragment>
  )
}
