export const PlayOutlineIcon = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "22"}
    height={height || "22"}
    viewBox="0 0 40 40"
    {...props}
  >
    <g id="play" transform="translate(-28.354 5)">
      <path
        id="Path_1552"
        data-name="Path 1552"
        d="M34.493,37.026a1.138,1.138,0,0,1-.572-2.122L62.152,18.513,35.63,3.114V27.46a1.138,1.138,0,1,1-2.276,0V1.138A1.138,1.138,0,0,1,35.064.154L64.99,17.529a1.138,1.138,0,0,1,0,1.968L35.064,36.872A1.132,1.132,0,0,1,34.493,37.026Z"
        transform="translate(0 0)"
        fill="#fff"
      />
      <path
        id="Path_1553"
        data-name="Path 1553"
        d="M114.873,132.274l15.963,8.947-15.963,8.947Z"
        transform="translate(-75.624 -122.709)"
        fill="#fee187"
      />
      <path
        id="Path_1554"
        data-name="Path 1554"
        d="M100.276,136.711a1.138,1.138,0,0,1-1.138-1.138V117.678a1.138,1.138,0,0,1,1.694-.992l15.963,8.947a1.138,1.138,0,0,1,0,1.985l-15.963,8.947A1.138,1.138,0,0,1,100.276,136.711Zm1.138-17.09v14.01l12.5-7.005Z"
        transform="translate(-61.027 -108.113)"
        fill="#f5cea4"
      />
    </g>
  </svg>
);
