import moment from "moment";
import { Input } from "antd";
import dayjs from "dayjs";
import React from "react";
import { IoChevronDownCircleSharp } from "react-icons/io5";
import { validateBasicField } from "utils";
import { FlexibleDiv } from "../Box";
import DateSelector from "../DateInput/DateSelector";
import { DSSWrap } from "./date-sequence-selector.styles";

// var objectSupport = require("dayjs/plugin/objectSupport");
// dayjs.extend(objectSupport);

export const DateSequenceSelector = ({
  handleDateChange,
  small,
  label,
  nullable,
  defaultValue,
  ...props
}) => {
  const [date, setDate] = React.useState({});
  const [error, setError] = React.useState("");
  const [dayStatus, setDayStatus] = React.useState({});
  const [monthStatus, setMonthStatus] = React.useState({});
  const [yearStatus, setYearStatus] = React.useState({});
  const currentYear = dayjs().year();

  const handleChange = (dateType, newDate) => {
    const dateObject = {
      day: dateType === "day" ? newDate?.format("DD") : date.day || "",
      month: dateType === "month" ? newDate?.format("MM") : date.month || "",
      year: dateType === "year" ? newDate?.format("YYYY") : date.year || "",
    };

    setDate({ ...date, ...dateObject });
  };

  React.useEffect(() => {
    if (date.day && date.month && date.year) {
      handleDateChange(dayjs(`${date.year}/${date.month}/${date.day}`));
    }
  }, [date]);

  React.useEffect(() => {
    if (dayStatus?.err || monthStatus?.err || yearStatus?.err) {
      setError("Please select correct date of birth");
    } else {
      setError("");
    }
  }, [dayStatus, monthStatus, yearStatus]);

  const disableYear = (current) => {
    return current.year() < currentYear - 80 || current.year() > currentYear;
  };

  return (
    <DSSWrap small={small} hasLabel={Boolean(label)} {...props}>
      {label && (
        <FlexibleDiv className="dss-label-wrap">
          <p className="dss-label">{label}</p>
        </FlexibleDiv>
      )}

      <Input.Group>
        <div className="group__cell day__cell">
          <label htmlFor="day">Day</label>
          <DateSelector
            name="day"
            format="DD"
            picker="day"
            small={small}
            setStatus={setDayStatus}
            placeholder="Enter Date"
            disabledDate={disableYear}
            // defaultValue={dayjs(existingDate).day()}
            validator={validateBasicField}
            hasError={Boolean(dayStatus?.err)}
            hasSuccess={Boolean(dayStatus?.success)}
            font_family={'"Baguede", sans-serif'}
            handleChange={(e) => handleChange("day", e)}
            suffixIcon={
              <IoChevronDownCircleSharp
                color={
                  Boolean(dayStatus?.err)
                    ? "var(--uduxDangerPrimary)"
                    : Boolean(dayStatus?.success)
                    ? "var(--successPrimary)"
                    : "var(--pinkPrimary)"
                }
              />
            }
            nullable={nullable}
          />
        </div>

        <div className="group__cell month__cell">
          <label htmlFor="month">Month</label>

          <DateSelector
            name="month"
            format="MM"
            picker="month"
            small={small}
            placeholder="Enter Month"
            setStatus={setMonthStatus}
            disabledDate={disableYear}
            // defaultValue={dayjs(existingDate).month()}
            validator={validateBasicField}
            hasError={Boolean(monthStatus?.err)}
            hasSuccess={Boolean(monthStatus?.success)}
            font_family={'"Baguede", sans-serif'}
            handleChange={(e) => handleChange("month", e)}
            suffixIcon={
              <IoChevronDownCircleSharp
                color={
                  Boolean(monthStatus?.err)
                    ? "var(--uduxDangerPrimary)"
                    : Boolean(monthStatus?.success)
                    ? "var(--successPrimary)"
                    : "var(--pinkPrimary)"
                }
              />
            }
            nullable={nullable}
          />
        </div>

        <div className="group__cell year__cell">
          <label htmlFor="year">Year</label>
          <DateSelector
            name="year"
            format="YYYY"
            picker="year"
            small={small}
            placeholder="Enter Year"
            setStatus={setYearStatus}
            // defaultValue={dayjs(existingDate).year()}
            validator={validateBasicField}
            hasError={Boolean(yearStatus?.err)}
            hasSuccess={Boolean(yearStatus?.success)}
            disabledDate={disableYear}
            handleChange={(e) => handleChange("year", e)}
            font_family={'"Baguede", sans-serif'}
            suffixIcon={
              <IoChevronDownCircleSharp
                color={
                  Boolean(yearStatus?.err)
                    ? "var(--uduxDangerPrimary)"
                    : Boolean(yearStatus?.success)
                    ? "var(--successPrimary)"
                    : "var(--pinkPrimary)"
                }
              />
            }
            nullable={nullable}
          />
        </div>
      </Input.Group>

      {error && (
        <FlexibleDiv
          className="error__message"
          justifyContent="flex-start"
          margin="5px 0 0"
        >
          <p>{error}</p>
        </FlexibleDiv>
      )}
    </DSSWrap>
  );
};
