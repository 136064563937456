import { Button } from "antd"
import React from "react"
import { DropdownWrap } from "./dropdown.styles"

export const Dropdown = ({
  trigger,
  overlay,
  buttonClick,
  placement,
  children,
  menu,
  onVisibleChange,
}) => {

  return (
    <DropdownWrap
      overlay={overlay}
      trigger={trigger}
      placement={placement}
      onVisibleChange={onVisibleChange}
    >
      <Button onClick={buttonClick}>{children}</Button>
    </DropdownWrap>
  )
}
