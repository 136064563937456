import styled from "styled-components";


export const DDCMobileWrap = styled.div`
padding: 0px;
   
.ddc__card__main {
  background: ${({ backgroundImage }) => backgroundImage ? `linear-gradient(89.79deg, #000000A8 -100%, #000000AF 130.38%),
  url(${backgroundImage}) no-repeat center / cover;`: "var(--black)"};
  transition: all 0.3s ease;
  margin-top: 5px;
  height: 400px;
  width:100vw;
  
}
// sc-dkzDqf kBDRRH ddc__card__main
.main__display__image {
  width: 250px;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // ::before {
  // //   content: "";
  // //   width: 0;
  // //   height: 100%;
  // //   top: 0;
  // //   background-color: var(--overlayMain);
  // //   transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  // //   position: absolute;
  // // }
}

.playlist__display {
  width: 250px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  small.goto-playlist {
    display: inline-flex;
    margin-top: 1rem;
    cursor: pointer;
    text-decoration: underline;
  }
}

.is__empty {
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .empty__image__wrap {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .empty__text {
    margin-top: 1.5rem;
    font-family: "Baguede", sans-serif;
  }
}

.tracks__container {
  width: 620px;
  height: 100%;
  border: ${({ isPlaylist }) =>
    isPlaylist ? "none" : "0.25px solid var(--borderLight)"};
  border-left: 0;
  transition: all 0.3s ease-in;
}

.ddc__card__main:hover {
  background-color: var(--backgroundDarkLight);

  .tracks__container {
    border: ${({ isPlaylist }) =>
      isPlaylist ? "none" : "0.25px solid var(--borderMain)"};
    border-left: 0;
  }

  .main__display__image:before {
    width: 100%;
  }
}

.tracks__list {
  padding: 1rem;

}
`


export const DDCWrap = styled.div`
  padding: 0px;
   
  .ddc__card__main {
    background: ${({ dominantColors }) =>
      dominantColors?.length
        ? `linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : "var(--black)"};
    transition: all 0.3s ease;
    margin-top: 5px;
    height: 400px;
  }

  .main__display__image {
    width: 250px;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    // ::before {
    // //   content: "";
    // //   width: 0;
    // //   height: 100%;
    // //   top: 0;
    // //   background-color: var(--overlayMain);
    // //   transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    // //   position: absolute;
    // // }
  }

  .playlist__display {
    width: 250px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    small.goto-playlist {
      display: inline-flex;
      margin-top: 1rem;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .is__empty {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .empty__image__wrap {
      width: 100px;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .empty__text {
      margin-top: 1.5rem;
      font-family: "Baguede", sans-serif;
    }
  }

  .tracks__container {
    width: 620px;
    height: 100%;
    border: ${({ isPlaylist }) =>
      isPlaylist ? "none" : "0.25px solid var(--borderLight)"};
    border-left: 0;
    transition: all 0.3s ease-in;
  }

  .ddc__card__main:hover {
    background-color: var(--backgroundDarkLight);

    .tracks__container {
      border: ${({ isPlaylist }) =>
        isPlaylist ? "none" : "0.25px solid var(--borderMain)"};
      border-left: 0;
    }

    .main__display__image:before {
      width: 100%;
    }
  }

  .tracks__list {
    padding: 1rem;

  }

  @media screen and (max-width: 600px) {
    width: ${({ isPlaylist }) => (isPlaylist ? "" : "100%")};

    .ddc__card__main {
      background-color: var(--black);
      transition: all 0.3s ease;
      margin-top: 5px;
      height: 400px;
      width: 100%;
      background: ${({ mobile_bg }) =>
        mobile_bg
          ? `linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${mobile_bg}) no-repeat center /cover`
          : ""};
    }

    .main__display__image {
      display: none;
    }

    .tracks__container {
      width: ${({ isPlaylist }) => (isPlaylist ? "320px" : "100%")};
    }

    .playlist__display {
      width: 230px;
    }
  }
`
