import { AddToQueueIcon, EditPlaylistIcon, PrivateIcon } from "assets"
import { DeleteIcon } from "assets/images/general/SVGExports"
import { ShareIcon } from "assets/images/player/SVGExports"
import { StyledMenu } from "components"

export const PlaylistMoreMenu = ({ isOwner, handleDelete }) => {
  let items = [
    {
      label: (
        <div className="menu_box">
          <AddToQueueIcon width={18} />
          <p>Add to Queue</p>
        </div>
      ),
    },
  ]

  if (isOwner) {
    items.push(
      {
        label: (
          <div className="menu_box">
            <EditPlaylistIcon width={18} />
            <p>Edit Playlist Details</p>
          </div>
        ),
      },
      // HIDE: Till further notice
      // {
      //   label: (
      //     <div className="menu_box">
      //       <PrivateIcon width={18} />
      //       <p>Make Private</p>
      //     </div>
      //   ),
      // },
      {
        label: (
          <div className="menu_box" onClick={handleDelete}>
            <DeleteIcon width={18} />
            <p>Delete Playlist</p>
          </div>
        ),
      }
    )
  }

  items.push({
    label: (
      <div className="menu_box">
        <ShareIcon width={18} />
        <p>Share Playlist</p>
      </div>
    ),
  })

  return (
    <StyledMenu
      top="1rem"
      items={items.map((menuItem, idx) => ({ ...menuItem, key: idx }))}
    />
  )
}
