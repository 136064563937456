import { LoadingOutlined } from "@ant-design/icons"
import { Form } from "antd"
import { BorderedTextfield, Button, FlexibleDiv } from "components"
import {
  changePassword,
  errorResponseHandler,
  successResponseHandler,
} from "network"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { validateConfirmPassword, validatePassword } from "utils"
import { MyCompassWrap } from "./my-compass.styles"

export const MyCompass = () => {
  const [form] = Form.useForm()
  const { mutate, isLoading } = useMutation(changePassword, {})
  const isMobile = useMediaQuery({ maxWidth: 600 })

  return (
    <MyCompassWrap>
      <FlexibleDiv className="main__wrap top__layer">
        <Form form={form} onFinish={handleSubmitForm}>
          <FlexibleDiv className="section__info">
            <h2 className="title">Manage Password</h2>

            <FlexibleDiv className="btn__wrap">
              <Button
                background="var(--pinkPrimary)"
                color="var(--black)"
                radius="0"
                bold
              >
                <span>{isLoading ? <LoadingOutlined /> : "Save Changes"}</span>
              </Button>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv flexDir="column" className="form__section">
            <FlexibleDiv className="input">
              <BorderedTextfield
                type="password"
                name="currentPassword"
                label="Current Password"
                validator={validatePassword}
                small
              />
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <BorderedTextfield
                type="password"
                name="newPassword"
                label="New Password"
                font_family={'"Baguede", sans-serif'}
                validator={validatePassword}
                small
              />
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <BorderedTextfield
                type="password"
                name="confirmPassword"
                label="Confirm New Password"
                validator={validateConfirmPassword}
                small
              />
            </FlexibleDiv>

            {isMobile && (
              <FlexibleDiv className="btn__wrap__mobile">
                <Button
                  width="50%"
                  color="var(--black)"
                  radius="0"
                  contained
                  bold
                >
                  <span>
                    {isLoading ? <LoadingOutlined /> : "Save Changes"}
                  </span>
                </Button>
              </FlexibleDiv>
            )}
          </FlexibleDiv>
        </Form>
      </FlexibleDiv>

      <FlexibleDiv className="main__wrap divider logout">
        <FlexibleDiv className="section__info">
          <h2 className="title">My Access</h2>
        </FlexibleDiv>

        <FlexibleDiv className="form__section logout__section">
          <h4 className="logout__text">Log out of my uduX Account</h4>

          <FlexibleDiv justifyContent="flex-start" className="btn__box">
            <Button
              background="var(--grayMain)"
              color="var(--black)"
              radius="0"
              width="135px"
              onClick={() => {
                localStorage.removeItem("udx__user")
                localStorage.removeItem("udx__tokens")
                window.location.href = "/"
              }}
              bold
            >
              <span>Logout</span>
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </MyCompassWrap>
  )

  function handleSubmitForm(payload) {
    mutate(
      {
        password: payload.newPassword,
        oldPassword: payload.currentPassword,
      },
      {
        onSuccess: data => {
          successResponseHandler(data)
          form.resetFields({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          })
        },
        onError: error => errorResponseHandler(error),
      }
    )
  }
}
