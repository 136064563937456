import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/styles";

export const ConfirmEmailWrap = styled(FlexibleDiv)`
  width: 100%;
  /* padding-bottom: 5%; */

  .form__box {
    margin: 13% 0px;
  }

  .btn__box {
    margin-top: 4%;
  }

  .otp__input {
    outline: 0;
    height: 50px;
    font-size: 20px;
    width: 50px !important;
    background-color: transparent;
    font-family: "Baguede", sans-serif;
    border: 0.5px solid var(--dampWhiteLight);
  }

  .resend__code {
    margin-top: 2rem;
    cursor: pointer;
    transition: all 5.2s ease-in;

    :hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 600px) {
    .form__box {
      margin-top: 7% !important;
    }

    .otp__input {
      height: 40px;
      font-size: 14px;
      width: 40px !important;
    }
  }
`;
