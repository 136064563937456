export const SoloPlaylistIcon = ({
  color = "#eaeaea",
  width,
  height,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "22"}
    height={height || "14"}
    viewBox="0 0 25 18"
    className="fill__player__svg"
    fill="none"
    {...props}
  >
    <g id="playlist-2" transform="translate(0.25 -88.934)">
      <g
        id="Group_10139"
        data-name="Group 10139"
        transform="translate(0 89.184)"
      >
        <g id="Group_10138" data-name="Group 10138">
          <path
            id="Path_1670"
            data-name="Path 1670"
            d="M20.1,91.453v-.806H18.249v-.831H16.092v-.632H5.459v.632H3.3v.831H1.448v.806H0v6.1H1.448v.806H3.3v.831H5.459v.632H16.092v-.632h2.156v-.831H20.1v-.806h1.448v-6.1ZM1.448,96.917H.631V92.084h.816Zm1.855.806H2.079V91.278H3.3Zm2.156.831H3.934V90.447H5.459Zm.631.632H6.1V89.816h9.365v9.369H6.09Zm11.527-8.539v7.907H16.092V90.447h1.525Zm1.855.806v6.27H18.249V91.278h1.223Zm1.448,5.464H20.1V92.084h.816Z"
            transform="translate(0 -89.184)"
            fill={color}
            stroke={color}
            stroke-width="0.5"
          />
          <path
            id="Path_1671"
            data-name="Path 1671"
            d="M317.7,136.734h0Z"
            transform="translate(-304.331 -134.733)"
            fill={color}
            stroke={color}
            stroke-width="0.5"
          />
          <path
            id="Path_1672"
            data-name="Path 1672"
            d="M181.706,136.735l-3.773,1.145v2.326a1.289,1.289,0,0,0-.214-.078,1.619,1.619,0,0,0-1.047.092,1.349,1.349,0,0,0-.859,1.682,1.206,1.206,0,0,0,1.148.676,1.685,1.685,0,0,0,.663-.139,1.479,1.479,0,0,0,.939-1.221h0v-.026q0-.031,0-.061v-2.783l2.511-.762V140.2a1.3,1.3,0,0,0-.2-.071,1.682,1.682,0,0,0-1.836.787,1.126,1.126,0,0,0-.071.987,1.206,1.206,0,0,0,1.148.676,1.685,1.685,0,0,0,.663-.139,1.412,1.412,0,0,0,.925-1.459Zm-3.773,4.46a.865.865,0,0,1-.558.664c-.409.175-.859.081-.982-.206s.118-.678.527-.853a1.077,1.077,0,0,1,.42-.089.841.841,0,0,1,.216.027.507.507,0,0,1,.346.268h0a.4.4,0,0,1,.031.135Zm3.142.1a.924.924,0,0,1-.543.567c-.409.175-.859.081-.982-.206a.507.507,0,0,1,.044-.435,1,1,0,0,1,.483-.418,1.077,1.077,0,0,1,.42-.089.839.839,0,0,1,.216.027.507.507,0,0,1,.346.268h0a.384.384,0,0,1,.015.044Z"
            transform="translate(-168.333 -134.734)"
            fill={color}
            stroke={color}
            stroke-width="0.5"
          />
          <path
            id="Path_1673"
            data-name="Path 1673"
            d="M54.257,370.2a1.109,1.109,0,0,0-1.061.792H39.945v.631H53.2a1.107,1.107,0,0,0,2.122,0h2.815v-.631H55.318A1.109,1.109,0,0,0,54.257,370.2Zm0,1.583a.476.476,0,1,1,.476-.476A.477.477,0,0,1,54.257,371.781Z"
            transform="translate(-38.264 -358.37)"
            fill={color}
            stroke={color}
            stroke-width="0.5"
          />
        </g>
      </g>
    </g>
  </svg>
)
