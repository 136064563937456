import { Types } from "context";
import {
  addToFavorites,
  errorResponseHandler,
  removeFromFavorites,
  successResponseHandler,
} from "network";

export const playAudio = (playerRef, dispatch) => {
  dispatch({ type: Types.SET_PLAY_TRACK });
  playerRef.current.play();
};

export const pauseAudio = (playerRef, dispatch) => {
  playerRef.current.pause();
  dispatch({ type: Types.SET_PAUSE_TRACK });
};

export const addToQueue = (dispatch, payload) => {
  dispatch({ type: Types.ADD_TO_QUEUE, payload });
};

export const nowPlaying = (playlist, dispatch, playerRef) => {
  dispatch({ type: Types.SET_PLAY_TRACK });
  dispatch({ type: Types.IS_PLAYER_ACTIVE, payload: true });
  dispatch({ type: Types.SET_CURRENT_PLAYING, payload: playlist.tracks[0] });
  playerRef?.current?.play();

  if (Array.isArray(playlist.tracks)) {
    dispatch({ type: Types.SET_PLAYING_PLAYLIST, payload: playlist });
    addToQueue(dispatch, playlist.tracks);
  }
};

export const playTrack = (dispatch, playerRef, track) => {
  dispatch({ type: Types.SET_PLAY_TRACK });
  dispatch({ type: Types.IS_PLAYER_ACTIVE, payload: true });
  dispatch({ type: Types.SET_CURRENT_PLAYING, payload: track });
  playerRef?.current?.play();
};

export const changeRange = (
  e,
  playerRef,
  currentTimeType,
  duration,
  dispatch
) => {
  let sum;
  if (e.length > 1) {
    sum = (e[0] / 100) * duration;
  } else {
    sum = (e[0] / 100) * duration;
  }
  playerRef.current.currentTime = sum;
  dispatch({ type: currentTimeType, payload: sum });
};

export const changeVolume = (e, playerRef, currentVolumeType, dispatch) => {
  playerRef.current.volume = e;
  dispatch({ type: currentVolumeType, payload: e });
};

export const calculateTime = (secs) => {
  const minutes = Math.floor(secs / 60);
  const returnedMinutes = minutes < 10 ? `${minutes}` : `${minutes}`;
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${returnedMinutes}:${returnedSeconds}`;
};

export const playNextTrack = (
  dispatch,
  { track = {}, checkAutoEnd = false }
) => {
  dispatch({ type: Types.PLAY_NEXT_TRACK, payload: track });
};

export const playPreviousTrack = (dispatch, { track = {} }) => {
  dispatch({ type: Types.PLAY_PREV_TRACK, payload: track });
};

export const playNext = async (
  dispatch,
  type,
  tracks,
  isPlayingType,
  checkAutoEnd
) => {
  if (!!tracks.nextTrack) {
    localStorage.setItem("just-played", JSON.stringify(tracks.currentTrack));

    await dispatch({
      type: type,
      payload: {
        ...tracks,
        prevTrack: tracks.currentTrack,
        currentTrack: tracks.nextTrack,
        nextTrack:
          tracks.allTracks[tracks.allTracks.indexOf(tracks.nextTrack) + 1],
      },
    });
    await dispatch({
      type: isPlayingType,
      payload: true,
    });
  } else {
    dispatch({
      type: type,
      payload: {
        ...tracks,
      },
    });
    if (!!checkAutoEnd) {
      dispatch({
        type: isPlayingType,
        payload: false,
      });
    }
  }
};

export const playPrevious = async (dispatch, type, tracks, isPlayingType) => {
  if (!!tracks.prevTrack) {
    localStorage.setItem("just-played", JSON.stringify(tracks.currentTrack));

    await dispatch({
      type: type,
      payload: {
        ...tracks,
        prevTrack:
          tracks.allTracks[tracks.allTracks.indexOf(tracks.prevTrack) - 1],
        nextTrack: tracks.currentTrack,
        currentTrack: tracks.prevTrack,
      },
    });

    await dispatch({
      type: isPlayingType,
      payload: true,
    });
  } else {
    dispatch({
      type: type,
      payload: {
        ...tracks,
      },
    });
  }
};

export const shuffleTrack = (tracks, dispatch, trackType) => {
  dispatch({
    type: Types.SHUFFLE,
    payload: true,
  });

  let sortedTracks = Array.from(tracks);
  sortedTracks.sort(() => (Math.random() > 0.5 ? 1 : -1));

  dispatch({
    type: Types.PLAYING_LIST_ACTIVE_TRACKS,
    payload: sortedTracks,
  });
};

export const unShuffleTrack = (tracks, dispatch, trackType) => {
  dispatch({
    type: Types.SHUFFLE,
    payload: false,
  });

  let sortedTracks = Array.from(tracks);
  sortedTracks.sort((a, b) => a.sequence - b.sequence);

  dispatch({
    type: Types.PLAYING_LIST_ACTIVE_TRACKS,
    payload: sortedTracks,
  });
};

export const repeatTrack = (type, dispatch, repeat) => {
  dispatch({ type, payload: !repeat });
};

export const getFromPage = (path) => {
  if (path === "/") {
    return "Home";
  }
  if (path === "/favorites") {
    return "Liked Songs";
  }
  if (path === "/discover") {
    return "Discover";
  }
  if (path === "/library") {
    return "Library";
  }
  if (path === "/recently-played") {
    return "Recently Played";
  }
  // if (path.includes("playlist")) {
  //   return ( "My Playlist");
  // }
};

export const pushToPlayNext = (
  tracks,
  dispatch,
  trackType,
  track,
  trackIdx
) => {
  const element = tracks?.allTracks?.splice(trackIdx, 1)[0];

  tracks?.allTracks?.splice(
    tracks.allTracks.indexOf(tracks.currentTrack) + 1,
    0,
    element
  );

  dispatch({
    type: trackType,
    payload: {
      ...tracks,
      allTracks: [...tracks?.allTracks],
      nextTrack: track,
      prevTrack: tracks?.prevTrack,
    },
  });
};

export const likeItem = async (entity, entityType) => {
  const payload = {
    entity,
    entityType,
    type: "LIKE",
  };

  try {
    const result = await addToFavorites(payload);
    successResponseHandler(result);
  } catch (e) {
    errorResponseHandler(e);
  }
};

export const unLikeItem = async (entity, entityType) => {
  const payload = {
    entity: entity,
    entityType: entityType,
  };
  try {
    await removeFromFavorites(payload);
  } catch (e) {}
};
