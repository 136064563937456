import Lottie from "react-lottie";
import LoaderAnimation from "assets/images/general/loader.json";
import GreenLoaderAnimation from "assets/images/general/green-loader.json";
import PurpleLoaderAnimation from "assets/images/general/purple-loader.json";
import OrangeLoaderAnimation from "assets/images/general/orange-loader.json";
import LightYellowLoaderAnimation from "assets/images/general/light-yellow-loader.json";
import { FlexibleDiv } from "../Box";
import { LoaderWrapper } from "./x-loader.styles";

export const Loader = ({
  wrapperHeight = "100%",
  height = 150,
  width = 150,
  variant = "default",
}) => {
  const animationVariants = {
    default: LoaderAnimation,
    green: GreenLoaderAnimation,
    purple: PurpleLoaderAnimation,
    orange: OrangeLoaderAnimation,
    yellow: LightYellowLoaderAnimation,
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationVariants[variant],
    renderer: "svg",
  };

  return (
    <LoaderWrapper>
      <FlexibleDiv height={wrapperHeight}>
        <Lottie options={defaultOptions} height={height} width={width} />
      </FlexibleDiv>
    </LoaderWrapper>
  );
};
