import styled from "styled-components";

export const UploadCameraIconWrap = styled.div`
  background-color: transparent;
  transition: all 0.1s ease-in;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--yellowPrimary);
  }
`;

export const UploadCameraIcon = ({ color, width, height }) => (
  <UploadCameraIconWrap>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "36.488"}
      height={height || "30.407"}
      viewBox="0 0 36.488 30.407"
    >
      <g
        id="_36_Upload_foto"
        data-name="36 Upload foto"
        transform="translate(-2 -7)"
      >
        <path
          id="Path_1523"
          data-name="Path 1523"
          d="M21.491,28.893H5.649V22.971L9.24,18.831a1.9,1.9,0,0,1,2.89.046l3.092,3.808,5.856-7.091a1.9,1.9,0,0,1,2.907,0L28.206,20.7a9.127,9.127,0,0,0-6.715,8.189Zm-6-17.321A2.433,2.433,0,1,0,17.927,14,2.433,2.433,0,0,0,15.495,11.572Zm6,18.538H5.041a.608.608,0,0,1-.608-.608V10.041a.608.608,0,0,1,.608-.608H30.582a.608.608,0,0,1,.608.608V20.41a9.046,9.046,0,0,1,2.433.5V8.824A1.826,1.826,0,0,0,31.8,7H3.824A1.826,1.826,0,0,0,2,8.824V30.717a1.826,1.826,0,0,0,1.824,1.824H21.993a9.046,9.046,0,0,1-.5-2.433ZM30.582,21.6A7.906,7.906,0,1,0,38.488,29.5,7.906,7.906,0,0,0,30.582,21.6ZM33.623,29.5H32.407v4.865a.608.608,0,0,1-.608.608H29.366a.608.608,0,0,1-.608-.608v-4.88l-1.209.015a.609.609,0,0,1-.5-.962l3.041-4.257a.631.631,0,0,1,.99,0l3.041,4.257a.612.612,0,0,1-.5.962Z"
          fill={color || "#fff"}
        />
      </g>
    </svg>
  </UploadCameraIconWrap>
);
