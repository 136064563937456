import { Loader, OverflowScroll } from "components"
import { Types, useMainContext } from "context"
import {
  getPlaylistById,
  getPlaylistsByQuery,
  publicGetPlaylistById,
} from "network"
import { PlaylistWrap } from "pages/Playlist/playlist.styles"
import { useEffect } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { PlaylistJumbotron } from "../PlaylistJumbotron/PlaylistJumbotron"
import { PlaylistOrganizer } from "../PlaylistOrganizer"

export const PlaylistModule = () => {
  const { playlistId } = useParams()
  const {
    state: { playlist, users, app },
    dispatch,
  } = useMainContext()

  const { isLoggedIn } = app

  const { data, isLoading } = useQuery(["playlist", playlistId], () => {
    return isLoggedIn
      ? getPlaylistById(playlistId)
      : publicGetPlaylistById(playlistId)
  })

  const { data: spData, isLoading: spIsLoading } = useQuery(
    ["similar-playlists", playlistId],
    () => getPlaylistsByQuery("managedBy=ADMIN")
  )

  const { me } = users

  const playlistEntity =
    Boolean(playlist?.current?.id) && playlist?.current?.id === playlistId
      ? playlist?.current
      : data
  const tracks = playlistEntity?.tracks || []
  const coverArt = playlistEntity?.coverArt?.url

  const isOwner = playlistEntity?.user?.id === me?.id

  useEffect(() => {
    dispatch({ type: Types.UPDATE_CURRENT_PLAYLIST, payload: data })
  }, [dispatch, data])

  return (
    <OverflowScroll>
      {isLoading ? (
        <Loader wrapperHeight="85vh" />
      ) : (
        <PlaylistWrap>
          <PlaylistJumbotron
            tracks={tracks}
            module="playlist"
            entity={playlistEntity}
          />

          <PlaylistOrganizer
            module="playlist"
            tracks={tracks}
            isOwner={isOwner}
            coverArt={coverArt}
            loading={spIsLoading}
            entity={playlistEntity}
            similarFromData={spData}
          />
        </PlaylistWrap>
      )}
    </OverflowScroll>
  )
}
