export const EditPlaylistIcon = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "15"}
    height={height || "15"}
    viewBox="0 0 15 15"
    {...props}
  >
    <g id="edit-playlist-icon" transform="translate(0 0)">
      <g id="edit_playlist" transform="translate(-83.84 -68.677)">
        <path
          id="Path_3925"
          data-name="Path 3925"
          d="M95.739,71.5a.737.737,0,0,0-.214-.523l-2.05-2.086a.737.737,0,0,0-1.04,0l-8.383,8.346a.737.737,0,0,0-.214.523v2.079a.737.737,0,0,0,.737.737h2.086a.737.737,0,0,0,.516-.214l8.346-8.338A.737.737,0,0,0,95.739,71.5Zm-9.385,7.6h-1.04v-1l7.6-7.638,1.047,1.04Zm7.218-3.259v2.374a2.212,2.212,0,1,0,1.379,2.094V76.418l1.71-.531a.751.751,0,1,0-.432-1.439h0l-2.212.737a.67.67,0,0,0-.442.656Zm-.833,5.205a.737.737,0,1,1,.737-.737A.737.737,0,0,1,92.738,81.048Z"
          transform="translate(0 0)"
          fill={color || "#eaeaea"}
        />
      </g>
    </g>
  </svg>
)
