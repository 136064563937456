import { FlexibleDiv } from "components"
import styled from "styled-components"

export const FSEMPlayerWrap = styled(FlexibleDiv)`
  width: 100%;
  min-width: 450px;
  max-width: 500px;
  justify-content: center;
  padding-top: ${({ isMobile }) => (isMobile ? "0" : "2rem")};

  .fsem__wrap {
    width: 70%;
    min-width: 400px;

    .track__image__wrap {
      height: 350px;
      max-height: 400px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .track__title__control {
      padding: 1.5rem 0 1rem;

      .icon__wrap {
        width: 30px;
        height: 40px;
        cursor: pointer;
        align-items: center;
      }
      .icon__wrap.left {
        justify-content: flex-start;
      }
      .icon__wrap.right {
        justify-content: flex-end;
      }

      .track-title {
        flex: 3;

        p {
          margin-bottom: 0.5rem;
          font-family: "Baguede", sans-serif;
          color: var(--textPrimary);
        }

        .artist__wrap {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .artist {
          cursor: pointer;
          transition: all 10s ease-in;

          :hover {
            text-decoration: underline;
          }
        }
      }
    }

    .track__controls__wrap {
      padding-top: 0.5rem;

      .controls__main {
        height: 45px;
        column-gap: 20px;
        margin-bottom: 0.2rem;
      }

      .control__icons {
        cursor: pointer;
      }
    }

    .song__progress__bar {
      margin-top: 5px;
      position: relative;
      height: 6px;

      .track__inner {
        height: 100%;
        position: absolute;
        background-color: var(--yellowPrimary);
      }
    }
  }

  @media (max-width: 600px) {
    min-width: 250px;
    max-width: 400px;
    width: 95%;

    .fsem__wrap {
      min-width: initial;
      width: 100%;
      height: 100%;

      .track__image__wrap {
        height: 340px;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .controls__main {
      margin-top: 5%;
    }

    .song__progress__wrap {
      margin-top: 3%;
    }
    .song__progress__bar {
    }
  }

  @media (max-height: 812px) {
    .fsem__wrap {
      .track__image__wrap {
        height: 300px;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (max-height: 895px) {
    .fsem__wrap {
      .track__image__wrap {
        /* height: auto; */
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .controls__main {
      margin-top: 5%;
    }

    .song__progress__wrap {
      margin-top: 5%;
    }
  }

  /* iphone 6/7/8 plus */
  @media screen and (max-width: 414px) and (max-height: 736px) {
    width: 80%;

    .fsem__wrap {
      min-width: initial;
      width: 100%;
      height: 100%;

      .track__image__wrap {
        height: 280px;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .track__title__control {
        padding: 0.5rem;

        .track-title {
          flex: 3;
        }
      }
    }

    .controls__main {
      margin-top: 1%;
    }

    .song__progress__wrap {
      margin-top: 1%;
    }
  }

  /* iphone 6/7/8 */
  @media screen and (max-width: 375px) and (max-height: 667px) {
    width: 80%;

    .fsem__wrap {
      min-width: initial;
      width: 100%;
      height: 100%;

      .track__image__wrap {
        height: 220px;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .track__title__control {
        padding: 0.5rem;

        .track-title {
          flex: 3;
        }

        .title {
          font-size: 0.85rem;
        }
        .artist__wrap {
          font-size: 0.75rem;
        }

        .icon__wrap {
          svg {
            width: 18px;
          }
        }
      }
    }

    .track__controls__wrap {
      .control__icons {
        svg {
          width: 23px;
        }
      }

      .pause__icon,
      .play__icon {
        svg {
          width: 30px;
        }
      }
    }

    .controls__main {
      margin-top: 1%;
    }

    .song__progress__wrap {
      margin-top: 1%;
    }
  }
`
