import { truncate } from "./truncate"
const { isEmpty } = require("lodash")

export function getTrackTitle(entity, trunc = false) {
  if (!isEmpty(entity)) {
    let title

    if (entity.track?.metadata?.title) {
      title = entity.track.metadata.title
    }

    if (entity?.metadata?.title) {
      title = entity.metadata.title
    }

    if (entity?.title) {
      title = entity.title
    }

    return trunc ? truncate(title, 30) : title
  }
}

export function getTrackDesc(entity, trunc = false) {
  if (!isEmpty(entity)) {
    let description

    if (entity.track?.metadata?.description) {
      description = entity.track.metadata.description
    }

    if (entity?.metadata?.description) {
      description = entity.metadata.description
    }

    if (entity?.description) {
      description = entity.description
    }

    return trunc ? truncate(description, 0) : description
  }
}
