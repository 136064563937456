import styled from "styled-components";
import cardImg from "../../../assets/images/player/user-bg.png";

export const Container = styled.div`
  padding: 0 3% 3% 3%;
  overflow-x: hidden;

  section {
    min-height: calc(100vh - 90px);
    padding: 5px;
    border: ${({ isMobile }) => (isMobile ? "" : "1px solid #0f0f0f;")};
  }
`;
