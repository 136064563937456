import { Skeleton } from "antd"
import {
  FlexibleDiv,
  NoDataDisplay,
  PlaylistCard,
  TrackListItem,
} from "components"
import { useMainContext } from "context"
import {
  getCollabRequestReceived,
  getLikedPlaylists,
  getPlaylistsByQuery,
} from "network"
import { SearchCategoryFilter } from "pages/Search"
import { feedCategoryFilter } from "pages/Search/components/SearchFilter/data-helper"
import { useState } from "react"
import { useQueries } from "react-query"
import { useNavigate } from "react-router-dom"
import { FDWrap } from "./styles"
import { Space } from "antd"
import newInvite from "../../../assets/images/player/newInvite.png"
import inviteWarningIcon from "../../../assets/images/player/inviteWarning.svg"

export const FeedData = () => {
  const [feedFilter, setFeedFilter] = useState("solo")
  const navigate = useNavigate()
  const {
    state: { users, playlist },
  } = useMainContext()
  const { me } = users
  const { creatingPlaylist } = playlist

  const feedDataInfo = useQueries([
    {
      enabled: !!me?.id,
      queryKey: ["artist-popular-tracks", creatingPlaylist],
      queryFn: () => getPlaylistsByQuery(`user=${me?.id}`),
    },
    {
      queryKey: ["liked-playlist"],
      queryFn: () => getLikedPlaylists(),
    },
    {
      queryKey: ["collab-invites-received"],
      queryFn: () => getCollabRequestReceived(),
    },
  ])

  const isFetching = feedDataInfo.some(o => o.isLoading)
  const playlists = feedDataInfo[0].data
  const following = feedDataInfo[1].data
  const invitations = feedDataInfo[2].data
  const bg = me?.profile?.profilePicture?.url

  return isFetching ? (
    <FlexibleDiv minHeight="300px">
      <Skeleton active={isFetching} />
    </FlexibleDiv>
  ) : (
    <FDWrap bg={bg}>
      <FlexibleDiv className="feed-data-display-top">
        <FlexibleDiv className="header-info">
          <h2 className="your-x">Your X</h2>
        </FlexibleDiv>

        <FlexibleDiv className="category__filter">
          <FlexibleDiv className="category__filter__content">
            <SearchCategoryFilter
              activeLabel={feedFilter}
              filterBy={feedCategoryFilter}
              setActiveLabel={ff => setFeedFilter(ff)}
            />
          </FlexibleDiv>
          <span className="see__all" onClick={() => navigate(`/feed/see-all`)}>
            see all
          </span>
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv className="feed-data-display">
        {!!feedDataInfo?.length ? (
          getFeedData()
        ) : (
          <NoDataDisplay text="You have no feed at this time." />
        )}
      </FlexibleDiv>
    </FDWrap>
  )

  function getFeedData() {
    if (feedFilter === "solo" || feedFilter === "collab") {
      const filteredPl = playlists?.filter(pl =>
        pl.type.toLowerCase()?.includes(feedFilter)
      )

      return !!filteredPl?.length ? (
        filteredPl?.map(d => <PlaylistCard {...d} />)
      ) : (
        <NoDataDisplay
          height="350px"
          text={`No result found for '${feedFilter}'.`}
          transparentBng
        />
      )
    } else if (feedFilter === "following") {
      return !!following?.length ? (
        following.map(d => <PlaylistCard {...d} />)
      ) : (
        <NoDataDisplay
          height="350px"
          text={`No result found for '${feedFilter}'.`}
          transparentBng
        />
      )
    } else if (feedFilter === "invitations") {
      return !!invitations.length ? (
        <FlexibleDiv className="tracks__list">
          {invitations?.map((item, i) => {
            return (
              <TrackListItem
                {...item}
                collab={true}
                zIndex={true}
                collabCover={newInvite}
              />
            )
          })}
        </FlexibleDiv>
      ) : (
        <NoDataDisplay
          height="280px"
          width="fit-content"
          inviteImg={true}
          text={`No result found for '${feedFilter}'.`}
        />
      )
    }
  }
}
