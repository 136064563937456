import {
  AddSongIcon,
  ExpandOutlineIcon,
  FavoriteFilledIcon,
  FavoriteIcon,
  MoreIcon,
  NextOutlineIcon,
  PauseOutlineIcon,
  PlayOutlineIcon,
  PrevOutlineIcon,
  RepeatOutlineIcon,
  ShuffleOutlineIcon,
} from "assets"
import { ExpandIcon } from "assets/images/player/SVGExports"
import {
  AddToPlaylistModal,
  Dropdown,
  FlexibleDiv,
  ShareModal,
  TrackItemMoreMenu,
  TrackProgressControl,
  VolumeControl,
} from "components"
import { Config } from "config"
import { Types, useMainContext } from "context"
import { addReaction, removeReaction } from "network"
import React, { useLayoutEffect, useRef, useState } from "react"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import {
  addToQueue,
  fadeOut,
  getArtist,
  getDisplayArtist,
  getTrackID,
  getTrackTitle,
  isEmpty,
  pauseAudio,
  playAudio,
  playNextTrack,
  playPreviousTrack,
  repeatTrack,
  shuffleTrack,
  topSlideInFrom,
  unShuffleTrack,
} from "utils"
import { getDominantColors } from "utils/function-helpers/getDominantColors"
import { MiniPlayerWrap } from "./mini-player.styles"

export const MiniPlayer = React.memo(({ footerOnly }) => {
  const navigate = useNavigate()
  const {
    state: { player, users, app },
    playerRef,
    dispatch,
  } = useMainContext()
  const muteRef = useRef()
  const playerMainRef = useRef()
  const [liked, setLiked] = useState(0)
  const [openAddToPlaylistModal, setOpenAddToPlaylistModal] = useState(false)
  const [isSharing, setIsSharing] = useState({
    open: false,
    href: "",
  })
  const { mutate: mutateLike } = useMutation(addReaction)
  const { mutate: mutateUnlike } = useMutation(removeReaction)
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const { isLoggedIn } = app
  const {
    isPlayerActive,
    tracks,
    isPlaying,
    repeat,
    shuffle,
    isPlayerMuted,
    playingList,
    currentTrack,
    queuedTracks,
    prevTracks,
  } = player

  const currentTrackTitle = getTrackTitle(currentTrack, true)
  const trackId = getTrackID(currentTrack)

  // useEffect(() => {
  //   const seconds = Math.floor(playerRef?.current?.duration)
  //   setDuration(seconds)
  // }, [
  //   playerRef?.current?.loadedmetadata,
  //   playerRef?.current?.readyState,
  //   playerRef,
  // ])

  useLayoutEffect(() => {
    topSlideInFrom(playerMainRef, { duration: 0.3, y: 20 })
  }, [isPlayerActive])

  useLayoutEffect(() => {
    fadeOut(muteRef, { duration: 3 })
  }, [isPlayerMuted])

  return (
    <MiniPlayerWrap
      ref={playerMainRef}
      footerOnly={footerOnly}
      isActive={isPlayerActive}
      style={{ bottom: isMobile ? "8%" : 0 }}
      dominantColors={getDominantColors(currentTrack)}
    >
      {isPlayerActive && (
        <FlexibleDiv
          flexWrap="nowrap"
          justifyContent="space-between"
          className="player__home__footer"
        >
          <FlexibleDiv className="left__controls__wrap" flexWrap="nowrap">
            <div className="player__image__wrap">
              <div
                className="expand__wrap"
                onClick={() => {
                  const statePayload = {
                    prevTracks: prevTracks,
                    playingList: playingList,
                    queuedTracks: queuedTracks,
                  }
                  dispatch({ type: Types.SET_FSEM, payload: true })
                  navigate(`/player/${trackId}`, {
                    state: statePayload,
                  })
                }}
              >
                <ExpandOutlineIcon />
              </div>
              <img src={getImageDisplay()} alt={currentTrackTitle} />
            </div>

            <div className="song__title__wrap">
              <h4
                className="track__title"
                onClick={() =>
                  navigate(`/playlist/${playingList.id}?t=pl`, {
                    state: { module: "playlist" },
                  })
                }
              >
                {currentTrackTitle}
              </h4>
              <small>{getDisplayArtist(currentTrack, navigate)}</small>
            </div>
          </FlexibleDiv>

          <FlexibleDiv className="center__controls__wrap">
            <FlexibleDiv className="control__main__wrap" flexDir="column">
              <FlexibleDiv
                flexWrap="nowrap"
                justifyContent="center"
                className="controls__main"
              >
                <div
                  className="control__icons repeat__icon"
                  onClick={() => repeatTrack(Types.REPEAT, dispatch, repeat)}
                >
                  <RepeatOutlineIcon
                    color={repeat ? "var(--yellowPrimary)" : "#fff"}
                    height="100%"
                  />
                </div>
                <div
                  className="control__icons"
                  onClick={() =>
                    playPreviousTrack(dispatch, {
                      track: currentTrack,
                    })
                  }
                >
                  <PrevOutlineIcon width={28} height="100%" />
                </div>

                {isPlaying ? (
                  <div
                    className="control__icons pause__icon"
                    onClick={() => pauseAudio(playerRef, dispatch)}
                  >
                    <PauseOutlineIcon width={40} height="100%" />
                  </div>
                ) : (
                  <div
                    className="control__icons play__icon"
                    onClick={() => playAudio(playerRef, dispatch)}
                  >
                    <PlayOutlineIcon width={40} height={40} />
                  </div>
                )}
                <div
                  className="control__icons"
                  onClick={() => {
                    playNextTrack(dispatch, {
                      track: currentTrack,
                    })
                  }}
                >
                  <NextOutlineIcon width={28} height="100%" />
                </div>

                {isMobile ? (
                  <div
                    className="control__icons expand__icon"
                    onClick={() => {
                      const statePayload = {
                        prevTracks: prevTracks,
                        playingList: playingList,
                        queuedTracks: queuedTracks,
                      }
                      dispatch({ type: Types.SET_FSEM, payload: true })
                      navigate(`/player/${trackId}`, {
                        state: statePayload,
                      })
                    }}
                  >
                    <ExpandIcon width="18" height="20" />
                  </div>
                ) : shuffle ? (
                  <div
                    onClick={() =>
                      unShuffleTrack(
                        tracks,
                        dispatch,
                        Types.PLAYING_LIST_ACTIVE_TRACKS
                      )
                    }
                    className="control__icons shuffle__icon"
                  >
                    <ShuffleOutlineIcon
                      height="100%"
                      color="var(--yellowPrimary)"
                    />
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      shuffleTrack(
                        tracks,
                        dispatch,
                        Types.PLAYING_LIST_ACTIVE_TRACKS
                      )
                    }
                    className="control__icons shuffle__icon"
                  >
                    <ShuffleOutlineIcon height="100%" />
                  </div>
                )}
              </FlexibleDiv>

              <FlexibleDiv className="song__progress__wrap" flexDir="column">
                <TrackProgressControl />
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv className="right__controls__wrap" flexWrap="nowrap">
            {!!currentTrack?.isLiked ? (
              <div className="icons__wrap" onClick={handleUnlikeTrack}>
                <FavoriteFilledIcon width={30} height={30} />
              </div>
            ) : (
              <div className="icons__wrap" onClick={handleLikeTrack}>
                <FavoriteIcon width={30} height={28} />
              </div>
            )}

            <div
              className="icons__wrap"
              onClick={() => {
                if (isLoggedIn) {
                  setOpenAddToPlaylistModal(true)
                } else {
                  dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true })
                }
              }}
            >
              <AddSongIcon width={30} height={30} />
            </div>

            <Dropdown
              trigger="click"
              placement="topLeft"
              overlay={
                <TrackItemMoreMenu
                  handleAddToQueue={() => addToQueue(dispatch, currentTrack)}
                  handleGotoAlbum={handleGoToAlbum}
                  handleGotoArtistPage={handleGoToArtistPage}
                  handleShare={handleShare}
                  top="0rem"
                />
              }
            >
              <div className="icons__wrap more__icon">
                <MoreIcon height={30} />
              </div>
            </Dropdown>

            <div className="icons__wrap">
              <VolumeControl width={30} height={28} />
            </div>
          </FlexibleDiv>
        </FlexibleDiv>
      )}

      {isSharing.open && (
        <ShareModal
          open={isSharing.open}
          href={isSharing.href}
          handleClose={() =>
            setIsSharing({
              open: false,
              href: "",
            })
          }
        />
      )}

      {openAddToPlaylistModal && (
        <AddToPlaylistModal
          open={openAddToPlaylistModal}
          handleClose={() => setOpenAddToPlaylistModal(false)}
        />
      )}
    </MiniPlayerWrap>
  )

  function getImageDisplay() {
    if (!isEmpty(currentTrack)) {
      if (currentTrack?.track?.metadata) {
        return currentTrack.track.metadata.coverArt.url
      }

      if (currentTrack?.metadata) {
        return currentTrack.metadata.coverArt.url
      }
    }
  }

  function handleUnlikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true })
    }

    setLiked(false)
    const payload = {
      entity: getTrackID(currentTrack),
      entityType: "Track",
    }

    mutateUnlike(payload, {
      onSuccess: res => {
        console.log(res, "un-liked from mini player")
      },
      onError: err => {
        setLiked(false)
        console.error(err, "error un-liking from mini player")
      },
    })
  }

  function handleLikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true })
    }

    setLiked(true)
    const payload = {
      type: "LIKE",
      entity: getTrackID(currentTrack),
      entityType: "Track",
    }

    mutateLike(payload, {
      onSuccess: res => {
        console.log(res, "liked from mini player")
      },
      onError: err => {
        setLiked(false)
        console.error(err, "error liking from mini player")
      },
    })
  }

  function handleGoToAlbum() {
    const albumId = currentTrack?.track?.album || currentTrack?.metadata?.album

    if (albumId) {
      navigate(`/playlist/${albumId}?t=alb`, {
        state: {
          module: "album",
        },
      })
    } else {
      // show notification for no album exists.
      console.log(currentTrack, "ct ")
    }
  }

  function handleGoToArtistPage() {
    const artist = getArtist(currentTrack)

    if (artist?.artist?.id) {
      navigate(`/artist/${artist?.artist?.id}`)
    } else {
      // show notification for no album exists.
      console.log(currentTrack, "ct ")
    }
  }

  function handleShare() {
    const protocol = Config.prod
      ? "https://stage.udux.com/playlist"
      : "http://localhost:3000/playlist"

    const type = playingList.type === "playlist" ? "pl" : "alb"

    const href = `${protocol}/${playingList?.id}?t=${type}&sid=`

    setIsSharing({
      open: true,
      href,
    })
  }
})
