import { useRef, useEffect } from "react";

export const ImageLazyLoader = ({
  src,
  width,
  height,
  alt,
  placeholderSrc,
}) => {
  const imgRef = useRef(null);

  useEffect(() => {
    const file = new Image();
    file.onload = function () {
      if (imgRef.current) {
        imgRef.current.src = src;
      }
    };
    file.src = src;
  });

  return (
    <img
      src={placeholderSrc}
      width={width}
      height={height}
      ref={imgRef}
      alt={alt}
    />
  );
};
