import { FlexibleDiv } from "components/lib/Box";
import styled from "styled-components";

export const ArtisteCardWrap = styled(FlexibleDiv)`
  max-width: 200px;
  min-width: 200px;
  height: 280px;
  cursor: pointer;
  flex-direction: column;

  .picture__box {
    width: 100%;
    height: calc(280px - 55px);
    border: 0.5px solid var(--uduxGrayLightAlpha);
    border-bottom: 0;
    display: flex;
    align-items: center;
  }

  .image__wrap {
    width: 80%;
    height: 90%;
    margin: 0 auto;

    img {
      width: 100%;
      height: 95%;
      object-fit: cover;
      transition: all 0.2s ease-in;
    }
  }

  .artbox {
    width: 6%;
    height: 90%;
    background: ${({ dominantColors }) =>
      dominantColors?.length
        ? `linear-gradient(${dominantColors[1]}, ${dominantColors[0]})`
        : `linear-gradient(180deg, var(--navyBluePrimary) 10.39%, var(--darkOrange) 100.38%)`};
  }

  :hover {
    .image__wrap {
      img {
        filter: opacity(0.5);
      }
    }
  }

  .name__box {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ dominantColors }) =>
      dominantColors?.length
        ? `0.5px solid linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : "0.5px solid var(--gradientSecondary)"};
    background: ${({ dominantColors }) =>
      dominantColors?.length
        ? `linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : "var(--gradientSecondary)"};

    .name {
      text-align: center;
      margin: 0;
      font-family: "Baguede", sans-serif;
      text-transform: capitalize;
    }
  }
`;
