export const BannerImages = [
  {
    id: 1,
    img: "https://udux-home-page.s3.amazonaws.com/web-banner/1.png",
  },
  {
    id: 2,
    img: "https://udux-home-page.s3.amazonaws.com/web-banner/2.png",
  },
  {
    id: 3,
    img: "https://udux-home-page.s3.amazonaws.com/web-banner/3.png",
  },
  {
    id: 4,
    // img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner-xl/4.png",
    img: "https://udux-home-page.s3.amazonaws.com/web-banner/4.png",
  },
  {
    id: 5,
    img: "https://udux-home-page.s3.amazonaws.com/web-banner/5.png",
  },
]

// export const BannerImages = [
//     {
//       id: 1,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner-mobile/1.png",
//     },
//     {
//       id: 2,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner-mobile/2.png",
//     },
//     {
//       id: 3,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner-mobile/3.png",
//     },
//     {
//       id: 4,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner-mobile/4.png",
//     },
//     {
//       id: 5,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner-mobile/5.png",
//     },
//     {
//       id: 6,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner/1.png",
//     },
//     {
//       id: 7,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner/2.png",
//     },
//     {
//       id: 8,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner/3.png",
//     },
//     {
//       id: 9,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner/4.png",
//     },
//     {
//       id: 10,
//       img: "https://udux-homepage.s3.eu-west-2.amazonaws.com/web-banner/5.png",
//     },
//   ];
