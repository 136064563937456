import { MtnIcon } from "assets/images/Auth";
import PayWithPaystack from "assets/images/payment/payWithPaystack.png";
import PayWithStripe from "assets/images/payment/payWithStripe.png";
import { ActionBox } from "components/lib/ActionBox/ActionBox";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BackgroundImage from "assets/images/payment/ChoosePlatformImage.png";
import { PaystackSVG, StripeSVG } from "assets/images/payment/SVGExports";
import AuthImage from "assets/images/payment/choosePlatform.png";
import { ChoosePlatform } from "components/layouts/AuthLayout-Platform/ChoosePlatform";
import { PaymentPlanWrapper } from "./choose-payment-plan.styles";
import { Types, useMainContext } from "context";

export const ChoosePaymentPlan = () => {
  const { dispatch } = useMainContext();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const plan = params.get("type");
  const mode = params.get("mode");
  const [activeSlide, setActiveSlide] = useState(1);

  let cardsObjects = [
    {
      redirectURL: () => navigateToPaymentPage("paystack"),
      textURL: () => navigateToPaymentPage("paystack"),
      pageHoverImage: PayWithPaystack,
      boxIcon: <PaystackSVG fill={"white"} />,
      heroText: "Go Premium with",
      spanText: " PayStack",
    },
  ];

  const navigateToPaymentPage = (paymentMethod) => {
    //store the payment mode on the localStorage
    const payload = {
      plan,
      mode,
      method: paymentMethod,
    };

    dispatch({ type: Types.SET_PAYMENT_METHOD, payload });
    localStorage.setItem("udx_pay", JSON.stringify(payload));

    if (isUserLoggedIn()) {
      //go to the subscription confirmation page
      navigate(`/payment/confirm?type=${plan}&mode=${mode}`);

      // navigate(`/payment/mtn?type=${plan}&mode=${mode}`)
    } else {
      //navigate to the authentication page
      navigate(`/payment/authentication?type=${plan}&mode=${mode}`);
    }
  };

  const isUserLoggedIn = () => {
    const tokens = JSON.parse(localStorage.getItem("udx__tokens"));

    return tokens?.accessToken;
  };

  cardsObjects =
    mode === "mtn"
      ? [
          {
            redirectURL: () => navigateToPaymentPage("mtn"),
            textURL: () => navigateToPaymentPage("mtn"),
            pageHoverImage: AuthImage,
            boxIcon: <MtnIcon />,
            heroText: "Go Premium via",
            spanText: " MTN",
          },
          ...cardsObjects,
        ]
      : [
          ...cardsObjects,
          {
            redirectURL: () => navigateToPaymentPage("stripe"),
            textURL: () => navigateToPaymentPage("stripe"),
            pageHoverImage: PayWithStripe,
            boxIcon: <StripeSVG fill={"white"} />,
            heroText: "Go Premium with",
            spanText: " Stripe",
          },
        ];

  const handleActionTileHover = (slideIndex) => {
    setActiveSlide(slideIndex);
  };

  return (
    <>
      <PaymentPlanWrapper>
        <ChoosePlatform
          title="You choose..."
          galleryImages={cardsObjects}
          activeSlide={activeSlide}
          leftColor="#00725E"
          rightImage={BackgroundImage}
          backURL={"/payment"}
        >
          {cardsObjects.map((card, idx) => (
            <ActionBox
              key={idx}
              redirectURL={card.redirectURL}
              setHoverImage={() => handleActionTileHover(idx)}
              textURL={card.textURL}
              boxIcon={card.boxIcon}
              heroText={card.heroText}
              spanText={card.spanText}
            />
          ))}
        </ChoosePlatform>
      </PaymentPlanWrapper>
    </>
  );
};
