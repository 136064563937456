import {
  HomeIcon,
  // DiscoverIcon,
  FeedIcon,
  SearchIcon,
  LikedSongsIcon,
  CreatePlaylistIcon,
  QueueIcon,
  ArtistCatalogueIcon,
  RecommendedIcon,
  AddToPlaylistIcon,
} from "assets";
import { FaRegMoneyBillAlt } from "react-icons/fa";

export const getPlayerSidebarNavItems = (isLoggedIn, isMobile = false) => {
  const style =
    isMobile && isLoggedIn
      ? {
          transform: "scale(3)",
        }
      : {};

  const navList = [
    {
      sectionItems: [
        {
          path: "/",
          title: "Home",
          icon: <HomeIcon width={25} style={style} />,
        },
      ],
    },
  ];

  if (isLoggedIn && !isMobile) {
    navList[0].sectionItems.push(
      {
        path: "/search",
        title: "Search",
        icon: <SearchIcon width={25} />,
      },
      {
        path: "/feed",
        title: "My Feed",
        icon: <FeedIcon width={25} />,
      }
    );

    navList.push({
      sectionItems: [
        {
          path: "#",
          title: "Liked Songs",
          icon: <LikedSongsIcon width={25} />,
        },
        {
          path: "#",
          title: "Create Playlist",
          icon: <CreatePlaylistIcon width={25} />,
        },
      ],
    });
  } else if (isLoggedIn && isMobile) {
    navList[0].sectionItems.push(
      {
        path: "/search",
        title: "Search",
        icon: <SearchIcon width={25} style={style} />,
      },
      {
        path: "#",
        isCreatePlaylist: true,
        title: "Create Playlist",
        icon: <CreatePlaylistIcon width={25} style={style} />,
      }
    );
  }

  navList.push({
    sectionItems: [
      {
        path: "/payment",
        title: "Subscribe",
        icon: <FaRegMoneyBillAlt style={{ width: "25px", height: "23px" }} />,
      },
    ],
  });

  return navList;
};

// export const playlist = [
//   "In the office... ",
//   "Meeting Prep ",
//   "Made in Naija ",
//   "Daily Commute",
//   "Beach Vibes ",
//   "CEO things ",
//   "Naija is  ",
// ];

export const playlist = [
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=1",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=2",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=3",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
];

export const nowPlayingRightSideItems = [
  {
    title: "Artist Catalogue",
    icon: <ArtistCatalogueIcon width={18} />,
  },

  {
    title: "Recommended",
    icon: <RecommendedIcon width={18} />,
  },
  {
    title: "Add to Playlist",
    icon: <AddToPlaylistIcon width={18} />,
  },
  {
    title: "Manage Queue",
    icon: <QueueIcon width={18} />,
  },
];
