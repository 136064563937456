import uduXLogo from "assets/images/general/udux_logo_pale_white.svg";
import { GeneralSearchBar } from "pages/Search/components/GeneralSearchBar";
import React, { useState } from "react";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { FlexibleDiv, Section } from "../../../lib/Box/styles";
import { HeaderWrap } from "./header.styles";
import { SettingsWidget } from "./SettingsWidget/SettingsWidget";
import { Button } from "components/lib";

export const PlayerHeader = ({ footerOnly, isLoggedIn}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    setIsSearchOpen(pathname === "/search");
  }, [pathname]);

  return (
    <HeaderWrap
      style={{
        width: "calc(100% - 240px)",
        marginLeft: "245px" 
      }}
      isSearchOpen={isSearchOpen}
      footerOnly={footerOnly}
    >
      <FlexibleDiv
        height="100%"
        justifyContent="space-between"
        className="mobile__header"
      >
        <FlexibleDiv className="image__wrap" justifyContent="space-evenly">
          <div className="udux__logo__wrap" onClick={() => navigate("/")}>
            <img src={uduXLogo} alt="udux" />
          </div>
        </FlexibleDiv>

        {isLoggedIn ? (
          <FlexibleDiv className="compass_wrap">
            <SettingsWidget />
          </FlexibleDiv>
        ) : (
          <FlexibleDiv
            width="130px"
            className="routechange__wrap"
            justifyContent="space-between"
            flexWrap="nowrap"
          >
            <Button
              onClick={() => navigate("/login")}
              color={"var(--black)"}
              contained
            >
              <span>Login/Sign Up</span>
            </Button>
          </FlexibleDiv>
        )}
      </FlexibleDiv>

      {isLoggedIn ? (
        <Section
          height="100%"
          justifyContent="flex-start"
          flexWrap="nowrap"
          className="web__header"
        >
          <FlexibleDiv
            width="65px"
            className="routechange__wrap"
            justifyContent="space-between"
            flexWrap="nowrap"
          >
            <div onClick={() => navigate(-1)}>
              <HiArrowLongLeft />
            </div>
            <div onClick={() => navigate(1)}>
              <HiArrowLongRight />
            </div>
          </FlexibleDiv>

          <GeneralSearchBar />

          <FlexibleDiv className="compass_wrap">
            <SettingsWidget />
          </FlexibleDiv>
        </Section>
      ) : (
        <Section
          height="100%"
          justifyContent="flex-end"
          flexWrap="nowrap"
          className="web__header"
        >
          <FlexibleDiv
            width="180px"
            className="auth__btn__wrap"
            flexWrap="nowrap"
          >
            <Button
              onClick={() => navigate("/login")}
              color={"var(--black)"}
              contained
              bold
            >
              <span>Login/Sign Up</span>
            </Button>
          </FlexibleDiv>
        </Section>
      )}
    </HeaderWrap>
  );
};

