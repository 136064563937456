import { Menu } from "antd";
import {
  AddToPlaylistIcon,
  LibraryIcon,
  QueueIcon,
  RecommendedIcon,
  ShareIcon,
} from "../../assets/images/player/SVGExports";

export const moreOptionsMenu = (
  <Menu
    style={{ backgroundColor: "#000000", border: "1px solid #0F0F0F" }}
    items={[
      {
        key: "1",
        label: (
          <div className="menu_box">
            <RecommendedIcon width={18} />
            <p>Explore Similar Vibes</p>
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div className="menu_box">
            <QueueIcon width={18} />
            <p>Add to Queue</p>
          </div>
        ),
      },
      {
        key: "3",
        label: (
          <div className="menu_box">
            <LibraryIcon width={18} />
            <p>Save to Library</p>
          </div>
        ),
      },

      {
        key: "4",
        label: (
          <div className="menu_box">
            <ShareIcon width={18} />
            <p>Share</p>
          </div>
        ),
      },
    ]}
  />
);

export const moreDetailsMenu = (
  <Menu
    style={{ backgroundColor: "#000000", border: "1px solid #0F0F0F" }}
    items={[
      {
        key: "1",
        label: (
          <div className="menu_box">
            <RecommendedIcon width={18} />
            <p>Similar Vibes</p>
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div className="menu_box">
            <QueueIcon width={18} />
            <p>Go to Artist</p>
          </div>
        ),
      },
      {
        key: "3",
        label: (
          <div className="menu_box">
            <LibraryIcon width={18} />
            <p>Go to Album</p>
          </div>
        ),
      },

      {
        key: "4",
        label: (
          <div className="menu_box">
            <AddToPlaylistIcon width={18} />
            <p>Show Credits</p>
          </div>
        ),
      },

      {
        key: "5",
        label: (
          <div className="menu_box">
            <ShareIcon width={18} />
            <p>Share</p>
          </div>
        ),
      },
    ]}
  />
);

export const playlistMenu = (
  <Menu
    style={{ backgroundColor: "#000000", border: "1px solid #0F0F0F" }}
    items={[
      {
        key: "1",
        label: (
          <div className="menu_box">
            <AddToPlaylistIcon width={17} />
            <p>Add to playlist</p>
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div className="menu_box">
            <QueueIcon width={18} />
            <p>Add to Queue</p>
          </div>
        ),
      },
    ]}
  />
);
