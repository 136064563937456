import { FlexibleDiv } from "components";
import styled from "styled-components";

export const NoSearchHistoryWrap = styled(FlexibleDiv)`
  height: calc(100vh - 10rem);

  .icon__wrap {
    margin-bottom: 8rem;
  }

  .headline {
    font-size: 1.95rem;
    font-weight: 600;
    text-align: center;
  }

  .text {
    text-align: center;
    font-size: 13px;
  }

  @media screen and (max-width: 600px) {
    .icon__wrap {
      margin-bottom: 3rem;
    }

    .headline {
      font-size: 1.65rem;
      width: 80%;
    }

    .text {
      text-align: center;
      font-size: 12px;
      width: 80%;
    }
  }
`;
