import styled from "styled-components";
import { Select, Form } from "antd";

export const UduxSelect = styled(Select)`
  height: 100%;
  .ant-select-selector {
    height: 100%;
    background: transparent !important;
    border: none !important;
    border-radius: none;
    font-size: 14px;
    color: #ffffff;
    width: 100%;
    box-shadow: none !important;
    padding: ${({ padding }) =>
      `${padding} !important` || "5px 0 0 20px !important"};
    margin-bottom: 1px;
  }

  .ant-select-selection-placeholder {
    color: ${({ placeholder__color, color }) =>
      placeholder__color
        ? placeholder__color
        : color
        ? color
        : "var(--uduxWhitePrimary)"};
    font-family: "Bw ModelicaBold", sans-serif;
  }

  .ant-select-selection-item {
    color: ${({ color }) => color || "var(--uduxWhitePrimary)"};
    font-family: ${({ font__family }) =>
      font__family || `"Bw ModelicaBold", sans-serif`};
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    height: 100%;
    opacity: 1;
  }

  .ant-select-selection-search-input {
    color: ${({ color }) => color || "var(--uduxWhitePrimary)"};
    font-family: "Bw ModelicaBold", sans-serif;
  }
  .ant-select-arrow {
    right: -1px !important;
    svg {
      color: ${({ color }) => color || "var(--uduxWhitePrimary)"};
      font-size: 18px;
      margin-top: -10px;
    }
  }
  .cancel {
    svg {
      color: var(--uduXDangerPrimary);
      font-size: 15px;
      margin-top: -10px;
    }
  }
  .check {
    margin-left: 10px !important;
    svg {
      color: var(--uduxYellowPrimary);
    }
  }
  .edit__icon {
    display: none;
  }
  input {
    background-color: transparent;
    box-shadow: none;
    margin-top: 7px;
  }

  p {
    padding-top: 5px !important;
  }
`;

export const FormItem = styled(Form.Item)`
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin || "0 0 39px 0"};
  height: ${({ height }) => height || "40px"};
  padding: ${({ padding, borderwidth }) =>
    borderwidth ? "0" : padding || "0"};
  background: ${({ background }) => background || "transparent"};
  border-bottom: ${({ border }) =>
    border || "1px solid var(--uduxWhitePrimary)"};
  border-width: ${({ borderwidth }) => borderwidth || "1px"};
  border-radius: ${({ radius, borderwidth }) =>
    borderwidth ? "0" : radius ? radius : "4px"};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 14px;
  border-color: var(--uduxWhitePrimary);
  text-align: ${({ text__align }) => text__align || "left"};
  transition: all 0.3s ease;
  outline: none;
  overflow: hidden;

  :hover,
  :active,
  :focus,
  :visited {
    border-color: var(--uduxYellowPrimary);
    border-width: ${({ borderwidth }) => borderwidth || "1px"};
  }

  .ant-form-item-control-input-content {
    height: ${({ height }) => height || "45px"};
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
`;
