import ColorThief from "colorthief";

function rgbToHex(r, g, b) {
  return (
    "#" +
    [r, g, b]
      .map((x) => {
        if (x) {
          const hex = x.toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        }
      })
      .join("")
  );
}

function getDimensions(image) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.src = image;
    img.crossOrigin = "Anonymous";

    img.onload = () => {
      resolve({ width: img.width, height: img.height, image: img });
    };
  });
}

export const processImage = async (base64) => {
  const defaultRGB = "#000"; // for non-supporting envs
  const canvas = document.createElement("canvas");
  const context = canvas.getContext && canvas.getContext("2d");
  const { width, height, image } = await getDimensions(base64);

  let data;

  if (!context) {
    return "#000";
  }

  canvas.height = height;
  canvas.width = width;

  context.drawImage(image, 0, 0);

  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    /* security error, img on diff domain */
    return defaultRGB;
  }

  const colorThief = new ColorThief();
  const vib = colorThief.getPalette(image, 3, 5);
  const __vib = vib.map((item) => rgbToHex(item[0], item[1], item[2]));

  return __vib;
};
