import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react"
import { initialState, mainReducer } from "./reducer"
import { Types } from "./types"

const MainContext = createContext()
const localState = JSON.parse(localStorage.getItem("__udx__store"))
const lUser = JSON.parse(localStorage.getItem("udx__user"))

export const MainProvider = ({ children }) => {
  const playerRef = useRef()
  const [state, dispatch] = useReducer(mainReducer, localState || initialState)
  const [searchQuery, setSearchQuery] = useState("")
  const pathname = window.location.pathname
  const clearSearchQuery = useCallback(() => {
    setSearchQuery("")
  }, [])

  const me = state.users.me

  const contextValue = useMemo(() => {
    return {
      state,
      dispatch,
      playerRef,
      searchQuery,
      setSearchQuery,
      clearSearchQuery,
    }
  }, [
    state,
    dispatch,
    playerRef,
    searchQuery,
    setSearchQuery,
    clearSearchQuery,
  ])

  useEffect(() => {
    if (searchQuery && pathname !== "/search") {
      setSearchQuery("")
    }
  }, [pathname, searchQuery])

  useEffect(() => {
    if (me?.id) return

    if (lUser?.id) {
      dispatch({
        type: Types.CURRENT_USER,
        payload: { ...lUser },
      })
      dispatch({
        type: Types.IS_LOGGED_IN,
        payload: true,
      })
    }
  }, [me?.id])

  return (
    <MainContext.Provider value={contextValue}>{children}</MainContext.Provider>
  )
}

export const useMainContext = () => useContext(MainContext)
