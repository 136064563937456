import { ImageLazyLoader } from "components/lib";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Back, Logo } from "../../../../assets/images/general/SVGExports";
import {
  PALWrap,
  ContentWrapper,
} from "./payment-authentication-layout.styles";

export const PaymentAuthenticationLayout = ({
  children,
  img,
  alt,
  lazyImg,
  nav,
  color,
  checkout,
}) => {
  const navigate = useNavigate();
  return (
    <PALWrap checkout={checkout}>
      <div className="bg"></div>
      <ContentWrapper color={color} checkout={checkout}>
        {!checkout && (
          <div className="image-container">
            <ImageLazyLoader
              width="100%"
              height="100%"
              alt={alt}
              src={img}
              placeholderSrc={lazyImg}
            />
          </div>
        )}
        <div className="content-container">
          <div className="logo-container">
            <FaAngleLeft
              className="back mobile"
              onClick={() => (nav ? null : navigate(-1))}
            />
            <Back
              className="back desktop"
              onClick={() => (nav ? null : navigate(-1))}
            />
            <Logo className="logo" fill="#B2E4BF" />
          </div>
          {children}
        </div>
      </ContentWrapper>
    </PALWrap>
  );
};
