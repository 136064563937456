import styled from "styled-components"

export const PlayerLayoutWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  .player__layout__main {
    width: calc(100% - 240px);
    margin-top: 90px;
    margin-left: 245px;
    padding-right: 1.5rem;
    margin-bottom: ${({ playerActive }) => (playerActive ? "6.65rem" : "10px")};
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
      background: var(--borderLight);
      border-radius: 10px;
      width: 2px;
    }
    ::-webkit-scrollbar-thumb:active {
      background: var(--borderMain);
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-track:hover {
      background: transparent;
    }
    ::-webkit-scrollbar-track:active {
      background: var(--black);
    }

    @media screen and (max-width: 950px) {
      width: 100% !important;
      margin-left: auto !important;
      padding-right: 0;
      margin: ${({ footerOnly }) => (footerOnly ? "0" : "90px 0 0 0")};
    }

    @media screen and (max-width: 600px) {
      ::-webkit-scrollbar {
        display: none;
      }

      width: 100% !important;
      margin-left: auto !important;

      margin: ${({ fsemMobileHeader }) =>
        fsemMobileHeader ? "0" : "4.5rem auto 1rem"};
    }
  }
`
