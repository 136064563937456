import { CallIcon, InputIcon, MtnIcon, UduXIcon } from "assets/images/Auth"
import ContinueWithPhoneGI from "assets/images/Auth/continue_with_phone.png"
import SignUpGI from "assets/images/Auth/create_account.png"
import ExistingUserGI from "assets/images/Auth/existing_user.png"
import defaultGalleryImage from "assets/images/Auth/verification_starting_page.png"
import { FlexibleDiv } from "components"
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout"
import { ActionBox } from "components/lib/ActionBox/ActionBox"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Container } from "./loginType.styles"
import { checkAvailablePhone, loginWithPhone } from "network"
import { useMutation } from "react-query"
import { Types, useMainContext } from "context"
import { Config } from "config"
import { useEffect } from "react"

const checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

const LoginType = () => {
  const [loadingWithMTN, setLoadingWithMTN] = React.useState(false)
  const navigate = useNavigate()
  const { state, search } = useLocation()
  const { mutate, isLoading } = useMutation(loginWithPhone)
  const [activeSlide, setActiveSlide] = React.useState(3);
  const handleActionTileHover = (slideIndex) => {
    setActiveSlide(slideIndex);
  };

  const {
    dispatch,
    state: { users },
  } = useMainContext()
  const { me } = users

  const cardsObjects = [
    {
      redirectURL: () => navigate("/signup"),
      textURL: "/signup",
      pageHoverImage: SignUpGI,
      boxIcon: <UduXIcon />,
      heroText: "Sign Up for",
      spanText: " Free!",
    },
    {
      redirectURL: () => navigate("/login/basic", { state }),
      textURL: "/login/basic",
      pageHoverImage: ExistingUserGI,
      boxIcon: <InputIcon />,
      heroText: "Existing user?",
      spanText: " Login",
    },
    {
      redirectURL: () => navigate("/login/phone", { state }),
      textURL: "/login/phone",
      pageHoverImage: ContinueWithPhoneGI,
      boxIcon: <CallIcon />,
      heroText: "Continue with",
      spanText: " Phone Number",
    },
    {
      redirectURL: () => handleContinueWithMTN(),
      // textURL: () => window.open("https://devservice.udux.com/auth/mtn"),
      pageHoverImage: defaultGalleryImage,
      boxIcon: <MtnIcon />,
      heroText: "Continue with",
      spanText: " MTN",
      loading: loadingWithMTN || isLoading,
    },
  ]

  useEffect(() => {
    const isEnriched = search.includes("isMtn") || search.includes("msisdn")

    if (me?.isEnriched) {
      return
    }

    if (isEnriched) {
      // update context
      const qp = new URLSearchParams(search)
      const msisdn = qp.get("msisdn")
      const isMtn = qp.get("isMtn") === "true"

      dispatch({
        type: Types.HE_SERVICE,
        payload: {
          msisdn,
          isEnriched: true,
          isMtn,
        },
      })

      if (isMtn && msisdn.startsWith("234")) {
        async function requestPhoneLogin() {
          const phoneExists = await checkAvailablePhone(`+${msisdn}`)

          if (phoneExists?.exists) {
            // request for OTP and send user to opt input page.
            const payload = {
              phoneNumber: `+${msisdn}`,
            }

            mutate(payload, {
              onSuccess: () => {
                navigate("/login/verify-otp", { state: payload })
              },
              onError: error => {
                setLoadingWithMTN(false)
                dispatch({
                  type: Types.API_RESPONSE_ERROR,
                  payload: error,
                })
              },
            })
          } else {
            dispatch({
              type: Types.API_RESPONSE_ERROR,
              payload:
                "This user is not on record, please create an account to continue!",
            })
          }
        }

        requestPhoneLogin()
      } else {
        dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload:
            "Sorry, It seems you aren't on MTN network, please choose a different option",
        })
      }
    }
  }, [search, dispatch, me, mutate, navigate])
 


  return (
    <AuthLayout title="Follow the Rhythm." galleryImages={cardsObjects} activeSlide={activeSlide}>
      <Container checkHeight={checkHeight} smallHeight={checkSmallScreenHeight}>
        <FlexibleDiv className="cards_container">
          {cardsObjects.map((card, idx) => (
            <ActionBox
              key={idx}
              boxIcon={card.boxIcon}
              loading={card.loading}
              heroText={card.heroText}
              spanText={card.spanText}
              redirectURL={card.redirectURL}
              setHoverImage={() => handleActionTileHover(idx)}
            />
          ))}
        </FlexibleDiv>
      </Container>
    </AuthLayout>
  )

  async function handleContinueWithMTN() {
    setLoadingWithMTN(true)

    const protocol = Config.prod
      ? "udux.com/login"
      : "http://localhost:3000/login"

    const url = `${Config.headerEnrichmentUrl}?redirectUrl=${protocol}`
    console.log({ url })
    window.location.href = url
  }
}

export default LoginType
