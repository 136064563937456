import { useMainContext } from "context"
import { VisitorsHomePage } from "."
import { UserHomePage } from "./UserHomePage"

export const Home = () => {
  const {
    state: {
      app: { isLoggedIn },
    },
  } = useMainContext()

  return isLoggedIn ? <UserHomePage /> : <VisitorsHomePage />
}
