import { FlexibleDiv } from "components/lib/Box"
import { take } from "lodash"
import { memo } from "react"
import { useNavigate } from "react-router-dom"
import { TrackListItem } from "../TrackListItem"
import { ArtistPlaylistWrap } from "./artist-playlist-card.styles"
// import bgImage from "assets/images/general/Simi+Top+Tracks.png";
// import mobileBgImage from "assets/images/general/spotlight+x+mobile.png";
// import sideImage from "assets/images/general/Simi+Spotlight+-+Artist+of+the+Month.png";

import { Types, useMainContext } from "context"
import { playTrack } from "utils"
import { useMediaQuery } from "react-responsive"

const ArtistPlaylist = ({
  cardData = [],
  header,
  minimize,
  isEmpty,
  emptyText,
  emptyBg,
  emptyDisplayIcon,
  isPlaylist,
  isArtist,
  artistData,
  playlist,
  title,
  ...props
}) => {
  const navigate = useNavigate()
  const { state, playerRef, dispatch } = useMainContext()
  const isMobile = useMediaQuery({ maxWidth: 960 })

  const __colorPicker = {
    isPlaylist: playlist,
    isArtist: artistData,
  }
  const flagSelector = isPlaylist ? "isPlaylist" : "isArtist"

  const bgImage =
    "https://udux-home-page.s3.amazonaws.com/spotlight-x-web/Simi+Top+Tracks.png"
  const mobileBgImage =
    "https://udux-home-page.s3.amazonaws.com/spotlight-x-mobile/spotlight+x+mobile.png"
  const sideImage =
    "https://udux-home-page.s3.amazonaws.com/spotlight-x-web/Simi+Spotlight+-+Artist+of+the+Month.png"

  const selectBgImage = isMobile ? mobileBgImage : bgImage

  return (
    <ArtistPlaylistWrap bgImage={selectBgImage}>
      <FlexibleDiv
        flexWrap="no-wrap"
        className="artistPlaylist__card__main"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <div className="main__display__image ">
          <img src={sideImage} />
        </div>

        <div
          className="tracks__container"
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <div className="tracks__container-wrapper">
            <FlexibleDiv className="tracklist__header">
              <h4 className="tracklist__header_title">
                {title || "Artist Top Tracks"}
              </h4>

              <span className="tracklist__header__link">
                Go To Artist Profile
              </span>
            </FlexibleDiv>
            {isEmpty ? (
              <FlexibleDiv className="is__empty">
                <div className="empty__image__wrap">
                  <img src={emptyDisplayIcon} alt="empty" />
                </div>

                <p className="empty__text">{emptyText}</p>
              </FlexibleDiv>
            ) : (
              <FlexibleDiv className="tracks__list" flexDir="column">
                {take(cardData, 5).map((item, i) => {
                  return (
                    <TrackListItem
                      minimize
                      key={i}
                      handlePlayTrack={handlePlayTrack}
                      {...item}
                    />
                  )
                })}
              </FlexibleDiv>
            )}
          </div>
        </div>
      </FlexibleDiv>
    </ArtistPlaylistWrap>
  )

  function handleRedirect() {
    if (isPlaylist) {
      return navigate(`/playlist/${playlist.id}?t=pl`, {
        state: {
          module: "playlist",
        },
      })
    } else if (isArtist) {
      return navigate(`/artist/${artistData.id}`)
    }
  }

  function handlePlayTrack(track) {
    dispatch({ type: Types.SET_PLAYING_PLAYLIST, payload: playlist })
    dispatch({ type: Types.REPLACE_QUEUED_TRACKS, payload: cardData })
    playTrack(dispatch, playerRef, track)
  }
}

export const ArtistPlaylistCard = memo(ArtistPlaylist)
