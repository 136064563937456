import { CloseOutlineIcon } from "assets/images/player/SVGExports"
import { FlexibleDiv } from "components"
import { useMainContext } from "context"
import { useState } from "react"
import { createPortal } from "react-dom"
import { useMediaQuery } from "react-responsive"
import {
  cleanSongTitle,
  getDominantColors,
  truncate,
  useDelayUnmount,
} from "utils"
import { MSModalWrap, MobilePortalContainer } from "./share-modal-mobile.styles"
import { RiWhatsappFill } from "react-icons/ri"
import { FaFacebook, FaInstagramSquare } from "react-icons/fa"
import { AiFillTwitterCircle } from "react-icons/ai"

export const ShareModalMobile = ({ handleClose, open, href }) => {
  const [copied, setCopied] = useState(false)
  const [isMounted, setIsMounted] = useState(open)
  const shouldRenderChild = useDelayUnmount(isMounted, 500)
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const {
    state: { player },
  } = useMainContext()

  const { playingList } = player

  const mountedStyle = { animation: "smmAnimateIn 500ms ease-in" }
  const unmountedStyle = { animation: "ssmAnimateOut 510ms ease-in" }

  const MobileShareModalContent = (
    <MobilePortalContainer
      dominantColors={getDominantColors(playingList, "playlist")}
    >
      {shouldRenderChild && (
        <MSModalWrap
          style={isMounted ? mountedStyle : unmountedStyle}
          dominantColors={getDominantColors(playingList, "playlist")}
        >
          <FlexibleDiv className="top__layer">
            <FlexibleDiv className="playlist__cover__art">
              <div className="cover__art">
                <img src={playingList?.coverArt?.url} alt="cover" />
              </div>
              <div className="title__and__album">
                <p className="title">
                  {truncate(cleanSongTitle(playingList?.title), 30)}
                </p>
                <p className="desc">{truncate(playingList?.description, 50)}</p>
              </div>
            </FlexibleDiv>

            <div className="close__icon__wrap" onClick={closeModal}>
              <CloseOutlineIcon />
            </div>
          </FlexibleDiv>

          <FlexibleDiv className="share__content">
            <div className="link__box">
              {truncate(href, 39)}

              <span
                className="copy__btn"
                onClick={() => {
                  navigator.clipboard.writeText(href)
                  setCopied(true)
                }}
              >
                {copied ? "copied" : "copy"}
              </span>
            </div>
          </FlexibleDiv>

          <FlexibleDiv className="share__list__layer">
            <ul className="share__list">
              <li className="action__item">
                <RiWhatsappFill />
              </li>
              <li className="action__item">
                <FaFacebook />
              </li>
              <li className="action__item">
                <AiFillTwitterCircle />
              </li>
              <li className="action__item">
                <FaInstagramSquare />
              </li>
            </ul>
          </FlexibleDiv>
        </MSModalWrap>
      )}
    </MobilePortalContainer>
  )

  return isMobile ? createPortal(MobileShareModalContent, document.body) : null

  function closeModal() {
    setIsMounted(false)
    setTimeout(() => {
      handleClose()
    }, 500)
  }
}
