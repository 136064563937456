import { FlexibleDiv } from "components/lib/Box"
import styled from "styled-components"

export const PITWrap = styled(FlexibleDiv)`
  width: 300px;
  flex-direction: column;

  .image__wrap {
    height: 250px;
    overflow: hidden;

    img {
      width: 1080px;
      height: 100%;
      object-fit: cover;
    }
  }
  .title {
    font-family: "Bw ModelicaSS01Bold", sans-serif;
    color: #fdfeff;
    margin: 15px 0 7px 0;
    font-size: 1.1rem;
    line-height: 1em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 960px) {
      font-size: 10px;
    }
  }

  .subtitle {
    font-size: 0.9em;
    line-height: 1.2em;
    margin: 0;
    color: #fdfeff;
    font-family: "Bw ModelicaRegular", sans-serif;
    @media screen and (max-width: 960px) {
      font-size: 10px;
    }
  }
`
