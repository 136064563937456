export const InputIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37.547"
    height="37.547"
    viewBox="0 0 37.547 37.547"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_1162"
          data-name="Path 1162"
          d="M0,0H37.547V37.547H0Z"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="input" clip-path="url(#clip-path)">
      <path
        id="Path_1161"
        data-name="Path 1161"
        d="M32.289,3.01H4.129A3.138,3.138,0,0,0,1,6.139v6.242H4.129V6.108h28.16V28.057H4.129V21.768H1v6.273a3.111,3.111,0,0,0,3.129,3.1h28.16a3.111,3.111,0,0,0,3.129-3.1V6.139A3.128,3.128,0,0,0,32.289,3.01ZM16.645,23.332,22.9,17.075l-6.258-6.258V15.51H1v3.129H16.645ZM32.289,3.01H4.129A3.138,3.138,0,0,0,1,6.139v6.242H4.129V6.108h28.16V28.057H4.129V21.768H1v6.273a3.111,3.111,0,0,0,3.129,3.1h28.16a3.111,3.111,0,0,0,3.129-3.1V6.139A3.128,3.128,0,0,0,32.289,3.01ZM16.645,23.332,22.9,17.075l-6.258-6.258V15.51H1v3.129H16.645Z"
        transform="translate(0.564 1.699)"
        fill="#fff"
      />
    </g>
  </svg>
);
