import { Types } from "context/types"

export const searchReducer = (state, { type, payload }) => {
  switch (type) {
    case Types.FILTER_SEARCH:
      return {
        ...state,
        filterSearch: payload,
      }

    case Types.FILTER_ACTIVE_SEARCH:
      return {
        ...state,
        filterActiveSearch: payload,
      }

    case Types.SAVE_SEARCH_TRACK_RESULT:
      return {
        ...state,
        searchTracks: payload,
      }

    default:
      return state
  }
}
