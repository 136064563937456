import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import { useLocation } from "react-router-dom";
import { AlbumModule, PlaylistModule } from "./components";

export const Playlist = () => {
  const { state: urlState, search } = useLocation();
  const searchKey = new URLSearchParams(search).get("t");

  return <PlayerLayout footerOnly>{getRenderModule()}</PlayerLayout>;

  function getRenderModule() {
    if (searchKey === "alb" || urlState?.module === "album") {
      return <AlbumModule />;
    }

    if (searchKey === "pl" || urlState?.module === "playlist") {
      return <PlaylistModule />;
    }
  }
};
