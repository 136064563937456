export const MtnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="76.271"
    height="37.873"
    viewBox="0 0 76.271 37.873"
  >
    <g
      id="mtn-new-logo_1_"
      data-name="mtn-new-logo (1)"
      transform="translate(-372 -814)"
    >
      <path
        id="Path_2"
        data-name="Path 2"
        d="M824.712,1102.4v12.4h-3.388v-6.954l-2.263,3.349h-1.776l-2.489-3.349v6.954h-3.1v-12.4h3.127l3.574,4.86,3.205-4.86Zm.977,2.81V1102.4h11.575v2.806h-4.052v9.586h-3.457v-9.586Zm24.326-2.81v12.4h-2.845l-5.824-7.084v7.084h-3.1v-12.4h3.084l5.577,6.832V1102.4Z"
        transform="translate(-420.603 -275.874)"
        fill="#fff"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M410.133,851.873c-10.011,0-19.453-1.868-26.576-5.268C376.1,843.039,372,838.192,372,832.936s4.1-10.1,11.562-13.664c7.119-3.405,16.561-5.273,26.572-5.273s19.453,1.867,26.576,5.268c7.453,3.561,11.562,8.408,11.562,13.664s-4.1,10.1-11.562,13.664C429.586,850.005,420.144,851.873,410.133,851.873Zm0-35.076c-9.611,0-18.619,1.776-25.369,5-6.424,3.071-9.972,7.032-9.972,11.149s3.535,8.078,9.972,11.136c6.749,3.231,15.77,5.008,25.369,5.008s18.619-1.776,25.369-5c6.424-3.058,9.972-7.032,9.972-11.136s-3.535-8.078-9.972-11.136c-6.749-3.249-15.757-5.021-25.369-5.021Z"
        fill="#fff"
      />
    </g>
  </svg>
);
