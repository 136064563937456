import styled from "styled-components";
import { Section } from "../../../../../components/lib/Box/styles";

export const PlayerControlWrap = styled(Section)`
  min-width: 300px;
  position: relative;

  .header__section__wrap {
    flex-direction: column;
    .nowplaying__title {
      color: var(--uduxWhitePrimary);
      margin: 0;
      font-size: 18px;
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .from__pathtitle {
      color: var(--uduxWhiteSecondary);
      font-size: 13px;
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .playlist__title {
      color: var(--uduxYellowPrimary);
      font-size: 11px;
      max-width: 90%;
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
    }
    @media (min-width: 950px) {
      align-items: flex-start;
      .playlist__title {
        display: none;
      }
    }
  }
  .arrows__wrap {
    position: absolute;
    top: -60px;
    left: 0;
    @media (min-width: 950px) {
      display: none;
    }

    @media (max-width: 540px) {
      top: -40px;

      svg {
        width: 27px;
      }
    }
  }
  .image__wrap {
    height: 60%;
    width: 100%;
    border: 0.5px solid var(--uduxGrayPrimary);
    padding: 5px;
    margin: 20px 0 5px 0;
    max-height: 600px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .songtitle__wrap {
    position: relative;
    .moreicon {
      position: absolute;
      right: 0;
      bottom: 10px;
      cursor: pointer;
    }

    .currenttrack__title {
      margin: 0;
      text-align: center;
      color: var(--uduxWhitePrimary);
    }

    .currenttrack__artists__wrap {
      span {
        text-align: center;
        color: var(--uduxYellowPrimary);
        cursor: pointer;
        font-size: 0.9em;
      }
    }
  }

  .controlbuttons__wrap {
    margin: 15px 0 0 0;

    svg {
      cursor: pointer;
    }
    .playicon,
    .pauseicon {
      position: relative;
      width: max-content;
      svg {
        position: absolute;
        top: -27px;
      }
    }

    .pauseicon {
      svg {
        top: -19px;
      }
    }
  }

  .songprogress__wrap {
    small {
      color: var(--uduxGrayPrimary);
      font-size: 11px;
    }
    .songprogress__bar {
      margin-top: 5px;
      position: relative;

      .trackeInner {
        height: 4px;
        position: absolute;
        background-color: var(--uduxYellowPrimary);
        top: 0;
      }

      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        input[type="range"] {
          overflow: hidden;
          width: 100%;
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid var(--uduxGrayPrimary);
        }

        input[type="range"]::-webkit-slider-runnable-track {
          height: 10px;
          -webkit-appearance: none;
          color: #13bba4;
          margin-top: -1px;
        }

        input[type="range"]::-webkit-slider-thumb {
          width: 10px;
          -webkit-appearance: none;
          height: 10px;
          border-radius: 50%;
          cursor: ew-resize;
          background: var(--uduxYellowPrimary);
          box-shadow: -100% 0 0 100% var(--uduxYellowPrimary);
        }
      }
      /** FF*/
      input[type="range"]::-moz-range-progress {
        background-color: var(--uduxYellowPrimary);
      }
      input[type="range"]::-moz-range-track {
        background-color: var(--uduxYellowPrimary);
      }
      /* IE*/
      input[type="range"]::-ms-fill-lower {
        background-color: var(--uduxYellowPrimary);
      }
      input[type="range"]::-ms-fill-upper {
        background-color: var(--uduxYellowPrimary);
      }
    }
  }

  @media screen and (max-width: 950px) {
    width: 100%;
    min-width: 100% !important;
    border: 1px solid #0f0f0f;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0;
    height: calc(100% - 60px);
    span {
      margin: 7px 0;
    }
    .controlfooter__wrap {
      max-width: 90%;
      padding: 10px 0;
    }

    .header__section__wrap {
      .from__pathtitle,
      .nowplaying__title {
        text-align: center;
        min-height: max-content;
      }
    }

    .moreicon {
      svg {
        width: 5px;
      }
    }
    .controlbuttons__wrap {
      svg {
        width: 20px;
      }
      .pauseicon {
        svg {
          width: 10px;
        }
      }
      .playicon {
        svg {
          width: 30px;
        }
      }
    }

    .songprogress__wrap {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 950px) and (max-height: 740px) {
    padding: 10px 0;
    height: calc(100% - 50px);

    .nowplaying__title {
      font-size: 15px;
    }
    .playlist__title {
      font-size: 10px;
    }
    .image__wrap {
      height: 50%;
    }
  }

  @media (min-height: 1020px) and (max-width: 950px) {
    height: calc(100% - 80px);

    .header__section__wrap {
      .from__pathtitle {
        font-size: 16px;
        margin: 10px 0;
      }
      .nowplaying__title {
        font-size: 22px;
      }
    }

    .controlbuttons__wrap {
      svg {
        width: 30px;
      }
      .pauseicon {
        svg {
          width: 20px;
        }
      }
      .playicon {
        svg {
          width: 40px;
        }
      }
    }
  }

  @media (max-width: 950px) and (min-height: 1075px) {
    height: calc(100% - 100px);

    .header__section__wrap {
      .from__pathtitle {
        font-size: 20px;
      }
      .nowplaying__title {
        font-size: 26px;
      }
    }
    .image__wrap {
      height: 70%;
    }
  }

  @media (max-width: 950px) and (min-height: 1360px) {
    height: calc(100% - 100px);

    .image__wrap {
      height: 90%;
      max-height: 800px;
    }
  }

  @media screen and (max-width: 950px) and (max-height: 720px) {
    .header__section__wrap {
      .from__pathtitle {
        margin: 4px 0;
        font-size: 12px;
      }
    }
    .image__wrap {
      margin: 0 0 5px 0;
    }
  }
  @media screen and (min-width: 950px) and (max-height: 790px) {
    padding-bottom: 70px;
  }
  @media screen and (min-width: 950px) and (max-height: 700px) {
    padding-bottom: 120px;
    .image__wrap {
      margin: 0 0 5px 0;
    }
  }
`;
