import { Types, useMainContext } from "context";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "utils";
import { PlayerLayoutWrap } from "./player-layout.styles";
import { PlayerFooter } from "./PlayerFooter/PlayerFooter";
import { PlayerHeader } from "./PlayerHeader/PlayerHeader";
import { PlayerSidebar } from "./PlayerSidebar/PlayerSidebar";
import { FsemMobileHeader } from "./FsemMobileHeader";
import { useRef } from "react";
import { AfroSkinModal } from "components/lib";
import DontStopTheMusicBanner from "assets/images/general/dont-stop-music-banner.png";
import FindYourXBanner from "assets/images/general/find-your-x-banner.png";
import { useState } from "react";

export const PlayerLayout = ({ fsemMobileHeader, footerOnly, children }) => {
  const {
    state: { player, users, app },
    clearSearchQuery,
    searchQuery,
    dispatch,
  } = useMainContext();

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { isPlayerActive, currentTrack } = player;
  const { authStreamingWarning, privateAction } = app;
  const canShowFooter = isPlayerActive && !isEmpty(currentTrack);

  const userObject = useRef(JSON.parse(localStorage.getItem("udx__user")));

  const isLoggedIn =
    Boolean(users?.me?.id) || Boolean(userObject?.current?.user?.id);

  React.useEffect(() => {
    if (!pathname.includes("/search") && searchQuery) {
      clearSearchQuery();
    }
  }, [pathname, clearSearchQuery, searchQuery]);

  React.useEffect(() => {
    if (isLoggedIn && !Boolean(users?.me?.id)) {
      dispatch({ type: Types.IS_LOGGED_IN, payload: true });
      dispatch({
        type: Types.CURRENT_USER,
        payload: userObject?.current?.user,
      });
    }
  }, [isLoggedIn, users, dispatch]);

  return (
    <PlayerLayoutWrap
      footerOnly={footerOnly}
      playerActive={isPlayerActive}
      fsemMobileHeader={fsemMobileHeader}
    >
      {fsemMobileHeader ? (
        <FsemMobileHeader />
      ) : (
        <PlayerHeader isLoggedIn={isLoggedIn} footerOnly={footerOnly} />
      )}

      <PlayerSidebar isLoggedIn={isLoggedIn} />

      <main className="player__layout__main">{children}</main>

      {canShowFooter && <PlayerFooter location={location} />}

      <AfroSkinModal
        title={"Don’t stop the Music"}
        content={"To continue enjoying music on uduX please go to your account"}
        btnContent={"Login/Sign Up"}
        skinBanner={DontStopTheMusicBanner}
        handleClose={() =>
          dispatch({ type: Types.AUTH_STREAMING_WARNING, payload: false })
        }
        handleBtnClick={() => navigate("/login")}
        open={authStreamingWarning}
      />

      <AfroSkinModal
        title={"Find you X"}
        content={"To continue exploring uduX please go to your account"}
        btnContent={"Login/Sign Up"}
        skinBanner={FindYourXBanner}
        handleClose={() =>
          dispatch({ type: Types.SET_PRIVATE_ACTION, payload: false })
        }
        handleBtnClick={() => navigate("/login")}
        open={privateAction}
      />
    </PlayerLayoutWrap>
  );
};
