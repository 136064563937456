export const subscriptionPlans = {
  daily: {
    borderColor: "#FE8A00",
    price: 30,
    paystack: "99b989be-86a3-4910-af41-7a738e8bbbac",
    stripe: "99b989be-86a3-4910-af41-7a738e8bbbac",
    content:
      "Subscription will automatically renew at ₦30 daily, starting from Aug 23, 2023, unless you cancel before the day of your next renewal.",
    features: [
      "24 hours premium uduX access",
      "Ad-free",
      "Listen offline with unlimited skips",
      "Expert curated playlists",
    ],
  },
  weekly: {
    borderColor: "#9F9D53",
    price: 250,
    paystack: "43d4bec9-1bec-4b58-aefe-7c34cc18a4f4",
    stripe: "43d4bec9-1bec-4b58-aefe-7c34cc18a4f4",
    content:
      "Subscription will automatically renew at ₦250 daily, starting from Aug 23, 2023, unless you cancel before the day of your next renewal.",
    features: [
      "7 days premium uduX access",
      "Ad-free",
      "Listen offline with unlimited skips",
      "Expert curated playlists",
    ],
  },
  monthly: {
    borderColor: "#F3A01E",
    price: 900,
    paystack: "d0d3bbdf-edd3-488c-b17e-6070c2c85d05",
    stripe: "d0d3bbdf-edd3-488c-b17e-6070c2c85d05",
    content:
      "Subscription will automatically renew at ₦900 daily, starting from Aug 23, 2023, unless you cancel before the day of your next renewal.",
    features: [
      "30 days premium uduX access",
      "Everything included in Daily & Weekly",
      "Listen in HiFi on your supported devices",
      "Tracks and share your listening habits",
    ],
  },
};
