// import { useMediaQuery } from "react-responsive"
import { AlbumCardWrap } from "./album-card.styles"
import { truncate } from "utils"
import { IoMdPlay } from "react-icons/io"
import { FlexibleDiv } from "components/lib/Box"
import { useNavigate } from "react-router-dom"
import { take } from "lodash"

export const AlbumCard = ({ handleNowPlaying, ...props }) => {
  const navigate = useNavigate()
  const { id, metadata } = props
  // const isMobile = useMediaQuery({ maxWidth: 600 })

  const da = metadata?.displayArtists
  const shouldTruncate = checkNamesLength(take(da, 3))

  return (
    <AlbumCardWrap
      dominantColors={metadata.coverArtDominantColors.map(cac =>
        cac.startsWith("#") ? cac : `#${cac}`
      )}
    >
      <div className="inner__wrap">
        <div className="picture__box">
          <FlexibleDiv className="card__play__btn" onClick={handleNowPlaying}>
            <IoMdPlay size={45} color="var(--uduxYellowPrimary)" />
          </FlexibleDiv>
          <div className="image__wrap">
            <img src={metadata.coverArt.url} alt={metadata.title} />
          </div>
        </div>

        <div className="name__box">
          <div className="title__and__artists">
            <p
              className="name"
              onClick={() =>
                navigate(`/playlist/${id}?t=alb`, {
                  state: {
                    module: "album",
                  },
                })
              }
            >
              {truncate(metadata.title)}
            </p>

            {/* This is a delicate algorithm to show artist names separately while truncating excess for consistent display. */}
            <div className="artist__names">
              {da && Array.isArray(da) ? (
                take(da, 3).map((d, idx, list) => {
                  const artistId = d.artist ? d.artist.id : d.id

                  return (
                    <span
                      className="artist"
                      key={idx}
                      onClick={() => navigate(`/artist/${artistId}`)}
                    >
                      {shouldTruncate
                        ? idx + 1 < list.length
                          ? d.artist.stageName
                          : truncate(d.artist.stageName, 5)
                        : d.artist.stageName}
                      {idx + 1 < list.length
                        ? ","
                        : da.length > 3
                        ? ", ..."
                        : ""}{" "}
                    </span>
                  )
                })
              ) : (
                <span
                  className="artist"
                  onClick={() =>
                    navigate(`/artist/${da.artist ? da.artist.id : da.id}`)
                  }
                >
                  {truncate(da.artist.stageName, 30)}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </AlbumCardWrap>
  )

  function checkNamesLength(list) {
    if (Array.isArray(list)) {
      return list.map(d => d.artist.stageName).join(", ").length > 25
    }

    return false
  }
}
