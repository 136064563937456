export function getDominantColors(entity, type = "track") {
  if (type === "track") {
    if (entity?.track?.metadata?.coverArtDominantColors) {
      return entity.track.metadata.coverArtDominantColors?.map(cd =>
        cd.startsWith("#") ? cd : `#${cd}`
      )
    }

    if (entity?.metadata?.coverArtDominantColors) {
      return entity.metadata.coverArtDominantColors.map(cd =>
        cd.startsWith("#") ? cd : `#${cd}`
      )
    }
  }

  if (type === "playlist") {
    if (entity?.coverArtDominantColors) {
      return entity.coverArtDominantColors.map(cd =>
        cd.startsWith("#") ? cd : `#${cd}`
      )
    }

    if (entity?.metadata?.coverArtDominantColors) {
      return entity.metadata.coverArtDominantColors.map(cd =>
        cd.startsWith("#") ? cd : `#${cd}`
      )
    }
  }
}
