import styled from "styled-components";
import { Form } from "antd";

export const BorderedFormItemWrap = styled.div`
  width: 100%;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "3em"};
  position: relative;
  margin-bottom: 12px;

  @media (max-width: 550px) {
    margin-bottom: 8px;
  }

  .input__error__text {
    margin-top: 5px;
    color: var(--uduxDangerPrimary);
    font-size: 0.75rem;
    line-height: 1.2;
    text-align: left;
    margin: 0;
  }

  label {
    color: var(--dampWhiteLight);
    margin-bottom: 0rem;
    display: inline-flex;
    font-size: ${({ small }) => (small ? "10px" : "10px")};
    font-family: "Bw ModelicaBold", sans-serif;
  }

  .password__eye {
    position: absolute;
    font-size: 12px;
    color: var(--uduxYellowPrimary);
    right: ${({ hasSuccess, hasError }) =>
      hasSuccess || hasError ? "25px" : "10px"};
    cursor: pointer;
    align-items: center;

    /* Hide if in error state */
    /* display: ${({ hasError }) => (hasError ? "none" : "flex")}; */

    > img {
      width: 16px;
    }
  }

  .input__wrapper {
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
  }

  @media only screen and (max-width: 380px) {
    height: 2.5em;
  }
`;

export const BorderedFormItem = styled(Form.Item)`
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;
  background: ${(props) => props.background || "transparent"};
  border: ${(props) => props.border || "1px solid #707070"};
  border-width: ${({ borderWidth }) => borderWidth || "0.5px"};
  border-radius: ${(props) =>
    props.borderWidth ? "0" : props.radius ? props.radius : "0px"};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 14px;
  border-color: ${({ hasError, hasSuccess }) => {
    return hasSuccess
      ? "var(--successPrimary)"
      : hasError
      ? "var(--uduxDangerPrimary)"
      : "var(--dampWhiteLight)";
  }};
  text-align: left;
  transition: all 0.3s ease;
  outline: none;
  overflow: hidden;
  height: ${({ height }) => height || "3em"};
  position: relative;

  div {
    height: 100%;
  }

  :hover,
  :active,
  :focus,
  :visited {
    border-color: ${({ hoverColor }) =>
      hoverColor || "var(--uduxYellowPrimary)"};
    border-width: ${(props) => props.borderWidth || "0.5px"};
  }

  input,
  textarea {
    width: 100%;
    height: ${(props) => props.height || "100%"};
    background-color: transparent;
    outline: none;
    border: none;
    margin: -3px 0 0 0;
    color: ${({ color }) => color || "var(--uduxWhitePrimary)"};
    font-family: ${({ font_family }) => font_family || '"Baguede", sans-serif'};
    font-size: ${({ small }) => (small ? "12px" : "15px")};
    text-align: left;
    border: none;
    padding: ${(props) => props.padding || "0 0px"};
    padding-left: ${({ paddingLeft }) => paddingLeft || "5px"};

    ::placeholder {
      color: ${(props) => props.placeholder_color || "var(--dampWhiteLight)"};
      font-size: ${({ small }) => (small ? "12px" : "15px")};
      text-align: left;
      font-weight: 400;
      font-family: ${({ font_family }) =>
        font_family || '"Baguede", sans-serif'};
      opacity: 0.9;
    }
    :hover,
    :active,
    :focus,
    :visited {
      box-shadow: none;
      background-color: transparent;
      border: none;
    }

    svg {
      color: var(--uduxYellowPrimary);
      margin: -2px 2px 0 0;
    }
    ::before {
      display: none;
    }
    :active,
    :focus {
      ::placeholder {
        color: transparent;
      }
    }
  }
  textarea {
    text-align: start;
    padding: ${({ padding }) => padding || "10px"};
  }

  input:disabled {
    color: var(--uduxGrayPrimary);
    cursor: not-allowed;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: ${({ lightMask }) =>
      lightMask
        ? `0 0 0 30px ${lightMask} inset`
        : "0 0 0 30px var(--black) inset"};
    box-shadow: ${({ lightMask }) =>
      lightMask
        ? `0 0 0 30px ${lightMask} inset`
        : "0 0 0 30px var(--black) inset"};
    -webkit-text-fill-color: ${({ lightMask }) =>
      lightMask ? "#fff" : "#fff"};
  }

  .ant-form-item-control-input-content {
    height: ${({ height }) => height || "100%"};
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;

    input,
    textarea {
      font-size: 12px;

      ::placeholder {
        font-size: 12px;
      }
    }
  }
`;

export const BorderedIconWrap = styled.div`
  width: max-content;
  height: 100%;
  max-width: max-content;
  z-index: 9;
  position: absolute;
  right: 7px;
  bottom: 0;
  display: flex;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }

  .check {
    color: #ace0b6;
  }
  .cancel {
    width: 12px;
    height: 12px;
    color: #f54b4b;
  }
`;
