export const filterList = [
  {
    name: "All",
    label: "all",
  },
  {
    name: "Albums/EP's",
    label: "albums/eps",
  },
  {
    name: "Playlists",
    label: "playlists",
  },
  {
    name: "Songs",
    label: "songs",
  },
  {
    name: "Artists",
    label: "artists",
  },
  // {
  //   name: "Genre & Moods",
  //   label: "genre&moods",
  // },
  {
    name: "Profiles",
    label: "profiles",
  },
]

export const playlistCategoryFilter = [
  {
    name: "All",
    label: "all",
  },
  {
    name: "By uduX",
    label: "by_udux",
  },
  {
    name: "By X'ers",
    label: "by_xers",
  },
]

export const albumCategoryFilter = [
  {
    name: "All",
    label: "all",
  },
  {
    name: "Album",
    label: "album",
  },
  {
    name: "EP's",
    label: "ep",
  },
]

export const artistAlbumCategoryFilter = [
  {
    name: "All",
    label: "all",
  },
  {
    name: "Singles",
    label: "single",
  },
  {
    name: "Albums/EP's",
    label: "album",
  },
]

export const userCategoryFilter = [
  {
    name: "All",
    label: "all",
  },
  {
    name: "Collaborators",
    label: "collaborators",
  },
  {
    name: "X'ers",
    label: "xers",
  },
]

export const filterCategory = {
  all: "combined",
  artists: "artists",
  profiles: "users",
  songs: "tracks",
  playlists: "playlists",
  "albums/eps": "albums",
  // "genre&moods": "genre",
}

export const activeCategory = {
  combined: "all",
  artists: "artists",
  users: "profiles",
  tracks: "songs",
  playlists: "playlists",
  albums: "albums/eps",
  // genre: "genre&moods",
}

export const feedCategoryFilter = [
  {
    name: "Solo",
    label: "solo",
  },
  {
    name: "Collab",
    label: "collab",
  },
  {
    name: "Following",
    label: "following",
  },
  {
    name: "Invitations",
    label: "invitations",
  },
]
