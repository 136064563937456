import { Section } from "components"
import styled from "styled-components"

export const FSEMWrap = styled(Section)`
  border: none !important;
  overflow-y: auto;
  height: ${({ isMobile }) => (isMobile ? "500px" : "550px")};

  .display__row {
    display: flex;
    flex-wrap: nowrap;
    margin: 1.5rem auto 1rem;
    overflow: hidden;
    column-gap: 20px;
  }

  .section__display__controls {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .title {
      margin: 0;
      color: var(--textPrimary);
      font-family: "Baguede", sans-serif;
      text-transform: capitalize;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: ${({ isMobile }) => (isMobile ? "0" : "2rem")};
    padding: ${({ noSmPad, isMobile }) =>
      isMobile && noSmPad ? "0" : "0 1rem"};

    .display__row {
      margin: 2rem auto;
      column-gap: 10px;
    }

    .section__display__controls {
      margin-bottom: 1rem;
      padding: ${({ isMobile }) => (isMobile ? "0" : "0 0.5rem")};
      align-items: flex-start;

      .title {
        font-size: ${({ isMobile }) => (isMobile ? "0.85" : "1rem")};
        max-width: ${({ isMobile }) => (isMobile ? "initial" : "280px")};
      }

      .see__all {
        padding-top: 0.35rem;
        font-size: 0.65rem;
      }
    }
  }

  @media screen and (max-height: 670px) {
    height: 280px;

    .display__row {
      margin: 0.5rem auto 0.85rem;
      column-gap: 10px;
    }
  }
`

export const ExplorerBox = styled(Section)`
  column-gap: 5%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 600px) {
    flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
    row-gap: 4rem;
  }
`
