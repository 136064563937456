export const capitalize = (str) => {
  if (!str) return;

  if (str.length === 1) {
    return str.toUpperCase();
  }

  const firstCP = str.codePointAt(0);
  const index = firstCP > 0xffff ? 2 : 1;

  return String.fromCodePoint(firstCP).toUpperCase() + str.slice(index);
};
