export const CreatePlaylistIcon = ({
  color = "#eaeaea",
  width = "20.731",
  height = "18.365",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className="fill__player__svg"
  >
    <g
      id="Group_3664"
      data-name="Group 3664"
      transform="translate(856.325 -1877.675)"
    >
      <g id="music-player" transform="translate(-856 1878)">
        <path
          id="Path_1194"
          data-name="Path 1194"
          d="M29.264,27.715H10.818A.774.774,0,0,1,10,26.994V10.722A.774.774,0,0,1,10.818,10H29.264a.774.774,0,0,1,.818.722V26.994A.774.774,0,0,1,29.264,27.715Z"
          transform="translate(-10 -10)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <path
          id="Path_1195"
          data-name="Path 1195"
          d="M62.461,73.039H60.674a.2.2,0,0,1-.2-.2V71.047a.2.2,0,0,1,.2-.2h1.788a.2.2,0,0,1,.2.2v1.788A.2.2,0,0,1,62.461,73.039Z"
          transform="translate(-58.405 -68.354)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <path
          id="Path_1196"
          data-name="Path 1196"
          d="M62.461,167.389H60.674a.2.2,0,0,1-.2-.2V165.4a.2.2,0,0,1,.2-.2h1.788a.2.2,0,0,1,.2.2v1.788A.2.2,0,0,1,62.461,167.389Z"
          transform="translate(-58.405 -159.684)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <path
          id="Path_1197"
          data-name="Path 1197"
          d="M62.461,261.739H60.674a.2.2,0,0,1-.2-.2v-1.788a.2.2,0,0,1,.2-.2h1.788a.2.2,0,0,1,.2.2v1.788A.2.2,0,0,1,62.461,261.739Z"
          transform="translate(-58.405 -250.686)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <line
          id="Line_4"
          data-name="Line 4"
          y2="2.197"
          transform="translate(3.7 12.945)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <line
          id="Line_5"
          data-name="Line 5"
          y1="2.197"
          transform="translate(2.064 12.945)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <path
          id="Path_1198"
          data-name="Path 1198"
          d="M148.18,383.269a.2.2,0,0,1,0,.354l-.848.49-.848.49a.2.2,0,0,1-.307-.177v-1.958a.2.2,0,0,1,.307-.177l.848.49Z"
          transform="translate(-140.607 -369.403)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <line
          id="Line_6"
          data-name="Line 6"
          x1="5.417"
          transform="translate(12.6 14.043)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <line
          id="Line_7"
          data-name="Line 7"
          x1="1.495"
          transform="translate(9.469 14.043)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <circle
          id="Ellipse_9"
          data-name="Ellipse 9"
          cx="0.818"
          cy="0.818"
          r="0.818"
          transform="translate(10.964 13.225)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <line
          id="Line_8"
          data-name="Line 8"
          transform="translate(5.666 3.587)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <line
          id="Line_9"
          data-name="Line 9"
          x2="10.376"
          transform="translate(7.613 3.587)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <line
          id="Line_10"
          data-name="Line 10"
          x2="12.322"
          transform="translate(5.666 6.568)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <line
          id="Line_11"
          data-name="Line 11"
          transform="translate(5.666 9.972)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
        <line
          id="Line_12"
          data-name="Line 12"
          x2="10.376"
          transform="translate(7.613 9.972)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.65"
        />
      </g>
    </g>
  </svg>
);
