import styled from "styled-components"
import { Menu } from "antd"

export const StyledMenu = styled(Menu)`
  background: #000 !important;
  position: absolute;
  z-index: 1000;
  list-style: none;
  top: 6px;
  right: 24px !important;
  // border: 2px solid red !important;


  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    background-color: transparent;
    color: #d9d9d9;
    font-size: 0.75rem;
    // border-bottom: 0.2px solid var(--borderLighter);
    // padding: ${({ item_padding }) => item_padding || "0rem 1 rem"};
    padding: 10px 9px;
  }

  .ant-dropdown-menu-item-active {
    background-color: transparent;
  }

  .ant-dropdown-menu-item span {
    color: #d9d9d9;
  }

  /* inputs for file uploads */
  .file__uploader__input__wrap {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s;

    svg {
      margin-right: 1rem;
    }

    label {
      display: flex;
      align-items: center;
      transition: all 0.3s;

      svg {
        margin-right: 1rem;
      }
    }

    input[type="file"] {
      color: transparent;
      position: absolute;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      cursor: pointer;
    }

    :hover {
      color: var(--yellowPrimary);
      label {
        color: var(--yellowPrimary);
      }
    }
  }
`
