export const RecommendedIcon = ({
  color = "#D9D9D9",
  width = "19",
  height = "20",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className="fill__player__svg"
    fill="none"
  >
    <path
      d="M7.91626 5.14648V7.52148H5.41626V5.14648H7.91626ZM9.16626 3.95898H4.16626V8.70898H9.16626V3.95898ZM7.91626 11.4798V13.8548H5.41626V11.4798H7.91626ZM9.16626 10.2923H4.16626V15.0423H9.16626V10.2923ZM14.5829 5.14648V7.52148H12.0829V5.14648H14.5829ZM15.8329 3.95898H10.8329V8.70898H15.8329V3.95898ZM10.8329 10.2923H12.0829V11.4798H10.8329V10.2923ZM12.0829 11.4798H13.3329V12.6673H12.0829V11.4798ZM13.3329 10.2923H14.5829V11.4798H13.3329V10.2923ZM10.8329 12.6673H12.0829V13.8548H10.8329V12.6673ZM12.0829 13.8548H13.3329V15.0423H12.0829V13.8548ZM13.3329 12.6673H14.5829V13.8548H13.3329V12.6673ZM14.5829 11.4798H15.8329V12.6673H14.5829V11.4798ZM14.5829 13.8548H15.8329V15.0423H14.5829V13.8548ZM18.3329 5.54232H16.6663V3.16732H14.1663V1.58398H18.3329V5.54232ZM18.3329 17.4173V13.459H16.6663V15.834H14.1663V17.4173H18.3329ZM1.66626 17.4173H5.83293V15.834H3.33293V13.459H1.66626V17.4173ZM1.66626 1.58398V5.54232H3.33293V3.16732H5.83293V1.58398H1.66626Z"
      fill={color}
      fill-opacity="0.9"
    />
  </svg>
);
