import { Loader } from "components"
import { Types, useMainContext } from "context"
import { login } from "network"
import { useState } from "react"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import StepOne from "./Steps/StepOne/StepOne"
import StepTwo from "./Steps/StepTwo/StepTwo"

const PaymentRegister = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [stepOneData, setStepOneData] = useState({})
  const [params, setParams] = useSearchParams()
  const plan = params.get("type")
  const navigate = useNavigate()
  const { dispatch } = useMainContext()
  const { mutate, isLoading } = useMutation(login)

  const onFinish = async (values, mutate) => {
    values = { ...stepOneData, ...values }

    //get required objects
    const payload = {
      password: values.password,
      acceptedTermsAndConditions: values.acceptedTermsAndConditions,
      gender: values.gender,
      countryCode: values.countryCode,
      phoneNumber: `${values.countryCode}${values.phoneNumber}`,
      emailAddress: values.emailAddress,
      dateOfBirth: values.dateOfBirth,
      username: values.username,
    }

    //login payload
    const loginPayload = {
      emailAddress: values.username,
      password: values.password,
    }

    mutate(payload, {
      onSuccess: () => {
        dispatch({ type: Types.CURRENT_USER, payload: {} })

        //login the user
        loginUser(loginPayload)
      },
      onError: error => {
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    })
  }

  const loginUser = async values => {
    mutate(values, {
      onSuccess: res => {
        const { data } = res
        const { user } = data.data

        localStorage.setItem("udx__user", JSON.stringify(data))
        dispatch({ type: Types.CURRENT_USER, payload: user })
        navigate(`/payment/confirm?type=${plan}`, {
          state: { prevRoute: "/payment" },
        })
      },
      onError: error => {
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    })
  }

  const setStepOne = values => {
    setStepOneData(values)
    setCurrentStep(2)
  }

  const moveToStepOne = () => {
    setCurrentStep(1)
  }

  return (
    <>
      {isLoading ? (
        <Loader variant="green" height="100vh" />
      ) : (
        <>
          {currentStep === 1 ? (
            <StepOne getStepOneData={setStepOne} />
          ) : (
            <StepTwo goBack={moveToStepOne} registerUser={onFinish} />
          )}
        </>
      )}
    </>
  )
}

export default PaymentRegister
