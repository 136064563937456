import { LoadingOutlined } from "@ant-design/icons"
import { Button, FlexibleDiv } from "components"
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout"
import { Types, useMainContext } from "context"
import { verifyOtp } from "network"
import { useEffect, useState } from "react"
import OtpInput from "react-otp-input"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { useLocation, useNavigate } from "react-router-dom"
import { Container } from "./otpForm.styles"
import { Config } from "config"
import galleryImage from "assets/images/Auth/enter_otp.jpg"

const Index = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { dispatch } = useMainContext()
  const [otpValue, setOtpValue] = useState("")
  const { mutate, isLoading } = useMutation(verifyOtp)
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const handleChange = e => setOtpValue(e)

  useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined })
  }, [dispatch])

  return (
    <AuthLayout
      title="Follow the Rhythm."
      subText="We need the OTP sent to your phone"
      galleryImage={galleryImage}
    >
      <Container>
        <FlexibleDiv className="form_box">
          <OtpInput
            numInputs={6}
            value={otpValue}
            onChange={handleChange}
            inputStyle="otp__input"
            separator={
              <span style={{ margin: isMobile ? "0 5px" : "0px 10px" }}></span>
            }
          />
        </FlexibleDiv>

        <FlexibleDiv className="btn__box">
          <Button
            width="220px"
            height="50px"
            font_size="16px"
            color="var(--black)"
            onClick={handleFormSubmit}
            contained
            bold
            ltr
          >
            <span> {isLoading ? <LoadingOutlined /> : "Continue"}</span>
          </Button>
        </FlexibleDiv>
      </Container>
    </AuthLayout>
  )

  async function handleFormSubmit() {
    if (otpValue < 5) {
      return false
    }
    if (!state?.phoneNumber) {
      return navigate("/login/phone")
    }

    const phone = state.phoneNumber

    const payload = {
      otp: otpValue,
      sourceService: Config.sourceService,
      phoneNumber: phone.startsWith("+") ? phone : `+${phone}`,
    }

    mutate(payload, {
      onSuccess: res => {
        if (res) {
          const { user, tokens } = res

          const pathToReturn = state?.from
            ? `${state.from.pathname}${state.from.hash}${state.from.search}`
            : "/"

          localStorage.setItem("udx__user", JSON.stringify(user))
          localStorage.setItem("udx__tokens", JSON.stringify(tokens))

          dispatch({ type: Types.CURRENT_USER, payload: user })
          dispatch({ type: Types.IS_LOGGED_IN, payload: true })
          navigate(pathToReturn)
        }
      },
      onError: error => {
        setOtpValue("")
        dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload: error,
        })
      },
    })
  }
}

export default Index
