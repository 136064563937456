export const AddToQueueIcon = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "14"}
    height={height || "14"}
    viewBox="0 0 15 15"
    {...props}
  >
    {/* <g id="shuffle-icon" transform="translate(2 6)"> */}
    <g
      id="Group_10145"
      data-name="Group 10145"
      transform="translate(-5887 -4144)"
    >
      <g id="queue" transform="translate(5887 4144)">
        <path
          id="Path_3922"
          data-name="Path 3922"
          d="M13.693,263.266h-.376V259.88a1.608,1.608,0,1,0-2.479-.35,5.434,5.434,0,0,1-3.785,1.436,5.434,5.434,0,0,1-3.785-1.436,1.608,1.608,0,1,0-2.479.35v3.386H.415a.415.415,0,1,0,0,.83H13.693a.415.415,0,1,0,0-.83Zm-1.743,0v-2.974a1.612,1.612,0,0,0,.537,0v2.974Zm.269-5.336a.778.778,0,1,1-.778.778.779.779,0,0,1,.778-.778Zm-1.1,2.454v2.882H2.988v-2.882A6.544,6.544,0,0,0,11.121,260.384ZM1.889,257.93a.778.778,0,1,1-.778.778A.779.779,0,0,1,1.889,257.93Zm-.269,2.363a1.611,1.611,0,0,0,.537,0v2.974H1.621Z"
          transform="translate(0 -249.988)"
          fill={color || "#eaeaea"}
        />
        <path
          id="Path_3923"
          data-name="Path 3923"
          d="M112.245,7.11h5.477a1.246,1.246,0,0,0,1.245-1.245V2.556a1.246,1.246,0,0,0-1.245-1.245H117v-.9a.415.415,0,1,0-.83,0v.9h-2.379v-.9a.415.415,0,1,0-.83,0v.9h-.719A1.246,1.246,0,0,0,111,2.556V5.865A1.246,1.246,0,0,0,112.245,7.11Zm-.415-4.553a.415.415,0,0,1,.415-.415h5.477a.415.415,0,0,1,.415.415V5.865a.415.415,0,0,1-.415.415h-5.477a.415.415,0,0,1-.415-.415Z"
          transform="translate(-107.929 0)"
          fill={color || "#eaeaea"}
        />
        <path
          id="Path_3924"
          data-name="Path 3924"
          d="M209.259,108.747a.415.415,0,0,0,.562-.17l.233-.437a.413.413,0,0,0,.049-.2v-.784h.65v.68l-.185.346a.415.415,0,1,0,.732.391l.234-.437a.417.417,0,0,0,.049-.2v-1.2a.415.415,0,0,0-.415-.415h-1.479a.415.415,0,0,0-.415.415v1.095l-.185.346A.415.415,0,0,0,209.259,108.747Z"
          transform="translate(-203.257 -103.389)"
          fill={color || "#eaeaea"}
        />
      </g>
    </g>
  </svg>
)
