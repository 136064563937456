import { FlexibleDiv } from "components"
import styled from "styled-components"

export const MoreModalWrap = styled(FlexibleDiv)`
  position: absolute;
  bottom: 0;
  z-index: 999;
  height: 250px;
  background: ${({ dominantColors }) =>
    !!dominantColors?.length
      ? `linear-gradient(180deg, ${dominantColors[0]} 2.59%, ${dominantColors[1]} 99.97%, ${dominantColors[2]} 99.98%)`
      : `linear-gradient(
      180deg,
      #39452d 2.59%,
      #232b1a 99.97%,
      #9f6f2d 99.98%,
      #bb8233 99.99%
    )`};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .top__layer {
    flex: 1;
    height: 80px;
    padding: 0.5rem 1rem;
    justify-content: space-between;
  }

  .track__cover__art {
    width: 70%;
    justify-content: flex-start;
  }

  .cover__art {
    width: 60px;
    height: 60px;
    position: relative;
    margin-right: 0.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title__and__album {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--textPrimary);

    p {
      margin: 0;
    }
  }

  .close__icon__wrap {
    cursor: pointer;
    width: 20px;
  }

  .action__list__layer {
    flex: 3;
  }

  .action__list {
    height: 100%;
    margin: 0;
    width: 100%;
    padding: 0 1rem;

    .action__item {
      display: flex;
      height: 22%;
      padding: 2% 0;
      list-style: none;
      font-size: 0.75rem;
      align-items: center;
      color: var(--textPrimary);
      width: 100%;

      :not(:last-child) {
        border-bottom: 1px solid transparent;
        border-image: linear-gradient(
          0.25turn,
          rgb(255 255 255 / 2%),
          rgb(172 172 172 / 50%),
          rgba(255, 255, 255, 0)
        );
        border-image-slice: 1;
      }

      svg {
        margin-right: 1rem;
      }
    }
  }

  @keyframes inAnimation {
    0% {
      transform: translateY(300px);
      opacity: 0.5;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes outAnimation {
    20% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(300px);
      opacity: 0;
    }
  }
`

export const MobilePortalWrap = styled(FlexibleDiv)`
  top: 0;
  z-index: 9999;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  transition: all 0.3s ease;
  background-color: var(--overlayMain);
`
