import React, { useState, useEffect } from "react"
import { ActionBoxWrapper } from "./ActionBox.styles"
import { Link } from "react-router-dom"
import { SpinLoader } from "../Loader"

export const ActionBox = ({
  setHoverImage,
  redirectURL,
  boxIcon,
  heroText,
  spanText,
  textURL,
  loading,
}) => {
  //Adding the redirectURL in a function hook will
  //stop it from throwing error on click.
  //Changes were made on the new React Doc

  return (
    <ActionBoxWrapper
      className="card__box"
      alignItems="center"
      onClick={redirectURL}
      onMouseEnter={setHoverImage}
      loading={loading}
    >
      <div className="pad__content">
        <div className="card__icon__box">{boxIcon}</div>
      </div>
      {loading ? (
        <div className="card__text__box--loading">
          <SpinLoader />
        </div>
      ) : (
        <div className="card__text__box">
          {textURL && (
            <Link to={{ textURL }} className="text">
              {heroText} <span className="udux__yellow">{spanText}</span>
            </Link>
          )}
          {!textURL && (
            <div className="text">
              {heroText} <span className="udux__yellow">{spanText}</span>
            </div>
          )}
        </div>
      )}
    </ActionBoxWrapper>
  )
}
