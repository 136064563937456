import React, { useState } from "react";
import { IoChevronDownCircleSharp } from "react-icons/io5";
import { countryCode, getCountryCode, validateCountryCode } from "utils";
import { FlexibleDiv } from "../Box";
import {
  CountryCodeSelect,
  CountryFormItem,
  CountrySelectorWrap,
  Option,
  SearchCountryField,
} from "./country-selector.styles";

export const CountrySelector = ({
  bordered = true,
  small,
  listHeight = 300,
  ...props
}) => {
  const [filtered, setFiltered] = useState(countryCode);
  // const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [status, setStatus] = useState();

  const handleFilter = (e) => {
    const final = countryCode.filter(({ name }) => {
      let lowerCountry = name.toLowerCase();
      let lowerInput = e.toLowerCase();
      return !!lowerCountry.match(lowerInput);
    });

    setFiltered(final);
  };

  return (
    <CountrySelectorWrap small={small}>
      <label htmlFor="countryOfResidence">Country Code</label>

      <FlexibleDiv className="country__code__input__wrap">
        <CountryFormItem
          name={"countryOfResidence"}
          hasError={Boolean(status?.err)}
          hasSuccess={Boolean(status?.success)}
          rules={[
            {
              validator: (rules, value) =>
                validateCountryCode(rules, value, setStatus),
            },
          ]}
          bordered={bordered}
        >
          <CountryCodeSelect
            items="center"
            placeholder="Select Country"
            placement="bottomLeft"
            listHeight={listHeight}
            dropdownRender={(menu) => (
              <React.Fragment>
                <SearchCountryField>
                  <input
                    placeholder="search..."
                    onChange={(e) => {
                      handleFilter(e.target.value);
                    }}
                  />
                </SearchCountryField>
                {menu}
              </React.Fragment>
            )}
            bordered={bordered}
            // onChange={(e) => setSelectedCountryCode(e)}
            suffixIcon={
              <IoChevronDownCircleSharp
                color={
                  Boolean(status?.err)
                    ? "var(--uduxDangerPrimary)"
                    : Boolean(status?.success)
                    ? "var(--successPrimary)"
                    : "var(--pinkPrimary)"
                }
              />
            }
          >
            {filtered.map((country, i) => (
              <Option key={i} value={country.name}>
                <span className="name">{country.name}</span>
              </Option>
            ))}
          </CountryCodeSelect>
        </CountryFormItem>
      </FlexibleDiv>
    </CountrySelectorWrap>
  );
};
