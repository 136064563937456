export const AddToPlaylistIcon = ({
  color = "#D9D9D9",
  width = "18",
  height = "15",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className="fill__player__svg"
    fill="none"
  >
    <path
      d="M0 11.0526H8.57143V12.6316H0V11.0526ZM0 5.52632H17.1429V7.10526H0V5.52632ZM0 0H17.1429V1.57895H0V0ZM13.7143 11.0526V8.68421H15.4286V11.0526H18V12.6316H15.4286V15H13.7143V12.6316H11.1429V11.0526H13.7143Z"
      fill={color}
      fill-opacity="0.9"
    />
  </svg>
);
