import { FlexibleDiv } from "components/lib/Box";
import styled from "styled-components";

export const AddPlaylistCardWrap = styled(FlexibleDiv)`
  width: 100%;
  padding: 0.5rem 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  :not(:last-child) {
    border-bottom: 0.2px solid var(--borderLighter);
  }

  :hover {
    background: var(--backgroundDarkLight);
  }

  p {
    margin-bottom: 0;
  }

  .wrap--layer {
    flex: 1;
  }

  .selectable--wrap {
    justify-content: flex-start;
    flex: 0;
    padding-left: 0.5rem;

    label {
      margin: 0;
    }
  }

  .image--wrap {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info--wrap {
    flex: 2;
    align-items: flex-start;
    flex-direction: column;

    p {
      margin-bottom: 0;
    }

    .title,
    .author {
      text-transform: capitalize;
    }
  }

  .type--wrap {
    justify-content: flex-end;
    padding-right: 0.5rem;

    .type {
      font-size: 12px;
    }

    .solo {
      color: var(--pinkDark);
    }
    .collab {
      color: var(--creamPrimary);
    }
  }

  @media screen and (max-width: 600px) {
    .name__box {
      /* height: 70px; */
    }
  }
`;
