import { instance } from "./axios"

export const getArtistProfile = async artistId => {
  const { data } = await instance.get(`/streaming/artists/${artistId}`)

  return data
}

export const getAllArtist = async artistId => {
  const { data } = await instance.get(`/streaming/artists`)

  return data
}

export const getSimilarArtists = async artistId => {
  const { data } = await instance.get(`/streaming/artists/${artistId}/similar`)

  return data.data
}

export const getArtistPopularTracks = async (artistId, mode = "asc") => {
  const { data } = await instance.get(
    `/streaming/artists/${artistId}/tracks?sortByPopularity=${mode}`
  )

  return data.data
}

export const getArtistRecentTracks = async (artistId, mode = "asc") => {
  const { data } = await instance.get(
    `/streaming/artists/${artistId}/tracks?sortByTimeOfUpload=${mode}`
  )

  return data.data
}

export const followArtist = async artistId => {
  const response = await instance.post(`/social/follow/artist/${artistId}`)

  return response.data
}

export const unFollowArtist = async artistId => {
  const response = await instance.delete(`/social/unfollow/artist/${artistId}`)

  return response.data
}

export const getArtistTopTracks = async (artistId, q) => {
  const { data } = await instance.get(
    `/streaming/artists/${artistId}/tracks?${q}`
  )

  return data.data
}

export const getArtistAlbums = async (artistId, q) => {
  const { data } = await instance.get(
    `/streaming/artists/${artistId}/albums?${q}`
  )

  return data.data
}

export const getAllAlbums = async () => {
  const { data } = await instance.get(`/streaming/albums`)

  return data.data
}

export const getSimilarArtistTracks = async (artistId, q) => {
  const { data } = await instance.get(
    `/streaming/artists/${artistId}/similar-artist-tracks?${q}`
  )

  return data.data
}
