import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/styles";

export const Container = styled(FlexibleDiv)`
  width: 100%;
  padding-bottom: 5%;

  .form__box {
    width: 100%;
  }

  .action_text_box {
    margin-top: 2.5%;
  }

  .action_text,
  .action_text_one {
    color: var(--uduWhiteSecondary);
    font-size: ${({ checkHeight }) => {
      return checkHeight ? "20px" : "15px";
    }};
    font-weight: 600;
    margin-top: 6%;

    :hover {
      color: var(--uduxYellowPrimary);
      transition: all 0.4s;
    }
  }

  .textfield__wrap {
    padding-bottom: 2rem;
  }

  .btn__box {
    margin-top: 5.5%;
  }

  .existing__user {
    margin-top: 1.5rem;
    color: var(--white);
    opacity: 0.7;
    text-decoration: underline;
    font-size: 12px;
  }

  @media screen and (max-width: 600px) {
    .action_text_box {
      margin-top: 2%;
    }

    .action_text,
    .action_text_one {
      margin-top: 12%;
      font-size: ${({ checkHeight }) => {
        return checkHeight ? "15px" : "15px";
      }};
    }
  }

  @media screen and (max-height: 896px) {
    .existing__user {
      margin-top: 0.5rem;
      font-size: 12px;
    }
  }
`;
