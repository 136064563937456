import { LoadingOutlined } from "@ant-design/icons";
import { Form } from "antd";
import galleryImage from "assets/images/Auth/login_page.png";
import { BorderedTextfield, Button, FlexibleDiv } from "components";
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout";
import { Types, useMainContext } from "context";
import { login } from "network";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validateBasicField, validatePassword } from "utils";
import { Container } from "./login.styles";
import { Config } from "config";

const checkHeight = window.innerHeight > 800 ? true : false;

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { dispatch } = useMainContext();
  const { mutate, isLoading } = useMutation(login);

  const handleFormSubmit = async (values) => {
    mutate(
      {
        ...values,
        sourceService: Config.sourceService,
      },
      {
        onSuccess: (res) => {
          if (res) {
            const { user, tokens } = res;

            const pathToReturn = state?.from
              ? `${state.from.pathname}${state.from.hash}${state.from.search}`
              : "/";

            localStorage.setItem("udx__user", JSON.stringify(user));
            localStorage.setItem("udx__tokens", JSON.stringify(tokens));

            dispatch({ type: Types.CURRENT_USER, payload: user });
            dispatch({ type: Types.IS_LOGGED_IN, payload: true });
            navigate(pathToReturn);
          }
        },
        onError: (error) => {
          dispatch({ type: Types.API_RESPONSE_ERROR, payload: error });
        },
      }
    );
  };

  useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined });
  }, [dispatch]);

  return (
    <AuthLayout
      title="Africa’s Music Hub"
      subText="For all the ways music moves you."
      galleryImage={galleryImage}
    >
      <Container checkHeight={checkHeight}>
        <Form form={form} onFinish={handleFormSubmit} className="form__box">
          <FlexibleDiv className="textfield__wrap">
            <BorderedTextfield
              type="text"
              paddingLeft="5px"
              name="emailAddress"
              label="What’s your email/username"
              placeholder="What’s your email/username"
              validator={validateBasicField}
              small
            />
          </FlexibleDiv>

          <FlexibleDiv className="textfield__wrap">
            <BorderedTextfield
              type="password"
              name="password"
              label="Password"
              paddingLeft="5px"
              validator={validatePassword}
              small
            />
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              width="220px"
              height="50px"
              htmlType="submit"
              color="var(--black)"
              contained
              bold
              ltr
            >
              <span>{isLoading ? <LoadingOutlined /> : "Go to uduX"}</span>
            </Button>
          </FlexibleDiv>
        </Form>

        <FlexibleDiv
          flexDir="column"
          className="btn__box"
          justifyContent="center"
        >
          <Link to="/reset-password" className="existing__user">
            Forgot your Password
          </Link>

          <Link to="/signup" className="existing__user">
            Create an Account
          </Link>
        </FlexibleDiv>
      </Container>
    </AuthLayout>
  );
};

export default Login;
