import { FlexibleDiv } from "components"
import styled from "styled-components"

export const TPIWrap = styled(FlexibleDiv)`
  padding: 0.5rem 1rem;

  .timer {
    padding-top: 0.1rem;
  }

  .track__title__control {
    padding: 0.2rem 1rem;

    .icon__wrap {
      width: 30px;
      height: 40px;
      cursor: pointer;
      align-items: center;
    }
    .icon__wrap.left {
      justify-content: flex-start;
    }
    .icon__wrap.right {
      justify-content: flex-end;
    }

    .track-title {
      flex: 3;

      p {
        margin-bottom: 0rem;
        font-family: "Baguede", sans-serif;
        color: var(--textPrimary);
      }

      .artist__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .artist {
        cursor: pointer;
        transition: all 10s ease-in;

        :hover {
          text-decoration: underline;
        }
      }
    }
  }

  .song__progress__bar {
    height: 5px;
    position: relative;

    .track__inner {
      height: 100%;
      position: absolute;
      background-color: var(--yellowPrimary);
    }
  }
`
