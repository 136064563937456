export const FavoriteFilledIcon = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "18"}
    height={height || "18"}
    viewBox="0 0 30 30"
    {...props}
  >
    <g
      id="liked-filled-icon"
      data-name="liked-filled-icon"
      transform="translate(-7420 -2530)"
    >
      <g id="music-lover-2" transform="translate(7422 2537)">
        <path
          id="Path_1201"
          data-name="Path 1201"
          d="M196.487,240.43a1.253,1.253,0,1,0,1.253,1.253A1.254,1.254,0,0,0,196.487,240.43Zm0,0"
          transform="translate(-185.45 -228.405)"
          fill={color || "#fdbe28"}
        />
        <path
          id="Path_1202"
          data-name="Path 1202"
          d="M23.462,2.7a7.525,7.525,0,0,0-10.63,0A7.516,7.516,0,1,0,2.2,13.327l0,0,10.1,9.953a.752.752,0,0,0,1.056,0l10.1-9.953,0,0A7.524,7.524,0,0,0,23.462,2.7ZM17.276,9.835a.752.752,0,0,1-1.042.208l-2.44-1.626v5.361a2.76,2.76,0,1,1-1.5-2.454V7.012a.752.752,0,0,1,1.169-.625l3.608,2.406A.752.752,0,0,1,17.276,9.835Zm0,0"
          transform="translate(0 -0.5)"
          fill={color || "#fdbe28"}
        />
      </g>
    </g>
  </svg>
);
