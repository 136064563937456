import styled from "styled-components"
import { FlexibleDiv } from "components"

export const MobileMusicPlayerWrap = styled(FlexibleDiv)`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  background-color: var(--uduxBlackPrimary);
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  padding: 5px 5px 0 5px;

  .main__content__wrap {
    position: relative;
    justify-content: flex-start;
    height: calc(100dvh - 190px);
    padding: 0 0.5rem;
  }

  .tab__content__mobile {
    height: auto;
  }

  .fsemplayer__container {
    padding: 0 0.5rem;
    position: relative;
    /* margin-bottom: ${({ playerActive }) => (playerActive ? "7rem" : "0")}; */
    max-height: 500px;
    min-height: 200px;
    height: 300px;
  }

  .more__icon__wrap {
    position: absolute;
    right: 0;
    top: 0;
  }

  .tpi__wrap {
    z-index: 99;
    background-color: var(--black);
    position: absolute;
    bottom: 0;

    @media screen and (min-width: 601px) and (max-width: 950px) {
      display: none;
    }
  }

  @media screen and (max-height: 670px) {
    .tab__content__mobile {
      max-height: 500px;
      min-height: 200px;
      height: 300px;
    }
  }
`

export const Spacer = styled(FlexibleDiv)`
  padding-bottom: ${({ pb }) => pb || "2rem"};

  @media screen and (max-width: 600px) and (max-height: 830px) {
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 375px) and (max-height: 667px) {
    padding-bottom: 1rem;
  }
`
