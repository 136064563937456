import { StyledButton } from "./button.styles"

export const Button = ({ loading, children, ...props }) => {
  return (
    <StyledButton {...props}>
      {loading && <div className="loading"></div>}
      {children}
    </StyledButton>
  )
}
