import uduXLogo from "assets/images/general/udux_logo.png";
import { SearchIcon, SoundIcon } from "assets/images/player/SVGExports";
import {
  AddToPlaylistModal,
  FlexibleDiv,
  OverflowScroll,
  Section,
} from "components/lib";
import { useMainContext } from "context";
import { getPlaylistsByQuery } from "network";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { getPlayerSidebarNavItems } from "utils";
import { MobileNav, SidebarWrap } from "./sidebar.styles";
import { useCallback } from "react";

export const PlayerSidebar = ({ isLoggedIn }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const [isScrolled, setIsScrolled] = useState(false);
  const {
    state: { users, player },
  } = useMainContext();
  const { me } = users;

  const { isPlayerActive } = player;

  const { isLoading, data: allPlaylists } = useQuery(
    ["my-playlists"],
    () => getPlaylistsByQuery(`user=${me?.id}`),
    {
      enabled: isLoggedIn,
    }
  );

  const handleScroll = (e) => {
    const contentContainer = e.target;
    setIsScrolled(contentContainer.scrollTop > 0);
  };

  const openCreatePlaylistModal = () => {
    setIsModalOpen(true);
  };

  const closeCreatePlaylistModal = () => {
    setIsModalOpen(false);
  };

  const handlePlaylists = useCallback(() => {
    const playlistObjects = allPlaylists?.map((data) => ({
      id: data?.id,
      img: data?.coverArt?.url,
      text: data?.title,
    }));

    setPlaylist(playlistObjects);
  }, [allPlaylists]);

  const handleSidebarItems = (path, isCreatePlaylist = false) => {
    if (isCreatePlaylist) {
      openCreatePlaylistModal();
    }

    navigate(path);
  };

  useEffect(() => {
    handlePlaylists();
  }, [allPlaylists, handlePlaylists]);

  if (isMobile) {
    return (
      <MobileNav>
        <div style={{ margin: "0 auto" }}>
          <div>
            {getPlayerSidebarNavItems(isLoggedIn, isMobile).map(
              (section, sectionIdx) => (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  {section.sectionItems.map((item, itemIdx) => (
                    <div
                      key={itemIdx}
                      style={{ margin: "20px auto", transform: "scale(1.4)" }}
                      className={
                        "sidebar__nav__sectionitems " +
                        (location.pathname === item.path &&
                          " sidebar__activenav__sectionitems")
                      }
                      onClick={() => navigate(item.path)}
                    >
                      {item.icon}

                      {location.pathname === item.path && !!isPlayerActive && (
                        <div>
                          <SoundIcon width={18} />
                        </div>
                      )}
                    </div>
                  ))}

                  {sectionIdx === 1 && (
                    <React.Fragment>
                      <div>
                        {!!allPlaylists?.data?.length > 10 && (
                          <div
                            onClick={() => setIsSearchOpen(!isSearchOpen)}
                            className="searchicon"
                          >
                            <SearchIcon />
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </MobileNav>
    );
  }

  return (
    <SidebarWrap isPlayerActive={isPlayerActive}>
      <Section flexDir="column" className="sidebar__subwrap">
        <FlexibleDiv
          className="sidebar__userwrap"
          justifyContent="space-evenly"
        >
          <div className="udux__sidebar__logo">
            <img src={uduXLogo} alt="udux" />
          </div>
        </FlexibleDiv>

        <FlexibleDiv
          className="sidebar__bodywrap"
          flexDir="column"
          flexWrap="nowrap"
        >
          <FlexibleDiv className="sidebar__navwrap">
            {getPlayerSidebarNavItems(isLoggedIn).map((section, sectionIdx) => (
              <FlexibleDiv
                className="sidebar__nav__sections"
                key={sectionIdx}
                flexDir="column"
                alignItems="flex-start"
              >
                {section.sectionItems.map((item, itemIdx) => (
                  <FlexibleDiv
                    key={itemIdx}
                    flexWrap="no-wrap"
                    justifyContent="flex-start"
                    className={
                      "sidebar__nav__sectionitems " +
                      (location.pathname === item.path &&
                        " sidebar__activenav__sectionitems")
                    }
                    onClick={() =>
                      handleSidebarItems(item.path, item.isCreatePlaylist)
                    }
                  >
                    {item.icon}
                    <p className="nav__section__content">{item.title}</p>

                    {location.pathname === item.path && !!isPlayerActive && (
                      <FlexibleDiv width="max-content">
                        <SoundIcon width={18} />
                      </FlexibleDiv>
                    )}
                  </FlexibleDiv>
                ))}

                {sectionIdx === 1 && (
                  <React.Fragment>
                    {/* <FlexibleDiv
                      className="searchinput__wrap"
                      height={!!isSearchOpen ? "40px" : "0"}
                      justifyContent="flex-start"
                      // width={!!isSearchOpen ? "100%" : "0"}
                      margin="5px 0 0 0"
                    >
                      <TextField
                        borderWidth="1px"
                        padding="0 10px "
                        hoverColor={
                          isSearchOpen ? "var(--uduxWhiteSecondary)" : "#0F0F0F"
                        }
                        setIsSearchOpen={setIsSearchOpen}
                        margin="0"
                        height="40px"
                        onChange={searchPlaylist}
                      />
                    </FlexibleDiv> */}
                  </React.Fragment>
                )}
              </FlexibleDiv>
            ))}
          </FlexibleDiv>

          {isLoggedIn && (
            <OverflowScroll onScroll={handleScroll}>
              <FlexibleDiv
                className="sidebar__playlistwrap"
                flexWrap="nowrap"
                alignItems="flex-start"
              >
                <FlexibleDiv
                  flexDir="column"
                  // className="sidebar__navwrap"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  {/* {playlist.map((item, index) => (
                     <p key={index} className="playlist__item">
                       {item}
                     </p>
                   ))} */}

                  <FlexibleDiv
                    className="sidebar__nav__sections"
                    flexDir="column"
                    alignItems="flex-start"
                  >
                    {playlist?.map((pl) => (
                      <FlexibleDiv
                        key={pl.id}
                        flexWrap="no-wrap"
                        style={{ marginBottom: "10px" }}
                        onClick={() =>
                          navigate(`/playlist/${pl.id}?t=pl`, {
                            state: { module: "playlist" },
                          })
                        }
                        justifyContent="flex-start"
                        className="sidebar__nav__sectionitems"
                      >
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            objectFit: "cover",
                            transition: "all 0.3s ease",
                          }}
                          src={pl.img}
                          alt=""
                        />
                        <p
                          style={{ marginLeft: "10px" }}
                          className="nav__section__content"
                        >
                          {pl.text}
                        </p>
                      </FlexibleDiv>
                    ))}
                  </FlexibleDiv>
                </FlexibleDiv>
              </FlexibleDiv>
            </OverflowScroll>
          )}
        </FlexibleDiv>
        {isModalOpen && (
          <AddToPlaylistModal
            handleClose={closeCreatePlaylistModal}
            open={isModalOpen}
          />
        )}
      </Section>
    </SidebarWrap>
  );
};
