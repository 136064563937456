import { MoreIcon } from "assets"
import { FlexibleDiv } from "../Box"
import { TabHeaderWrap } from "./tab.styles"
import { MdClose } from "react-icons/md"

export const TabHeader = ({
  tabs,
  activeTab,
  setActiveTab,
  fsemMobileHeader,
  handleClose,
  showCloser,
  handleOpenMore,
  ...props
}) => {
  return (
    <TabHeaderWrap fsemMobileHeader={fsemMobileHeader} {...props}>
      <FlexibleDiv
        className="navigation__routes__web"
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        {tabs.map((route, index) => (
          <span
            key={index}
            className={`tab__links ${
              activeTab === route.hash ? "active__tab" : ""
            }`}
            onClick={() => setActiveTab(route.hash)}
          >
            <p>{route.title}</p>
          </span>
        ))}
      </FlexibleDiv>

      {showCloser && (
        <FlexibleDiv className="more__and__close">
          <MoreIcon onClick={handleOpenMore} />

          <MdClose onClick={handleClose} className="close-icon" />
        </FlexibleDiv>
      )}
    </TabHeaderWrap>
  )
}
