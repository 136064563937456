export const FollowingIcon = ({
  color = "#eaeaea",
  width,
  height,
  ...props
}) => (
  <svg
    id="follow"
    data-name="follow icon"
    xmlns="http://www.w3.org/2000/svg"
    width={width || "30"}
    height={height || "30"}
    viewBox="0 0 50 49"
    {...props}
  >
    <ellipse
      id="Ellipse_16"
      data-name="Ellipse 16"
      cx="25"
      cy="24.5"
      rx="25"
      ry="24.5"
      fill="#fdbe28"
    />
    <g id="add-3" transform="translate(12.375 7.071)">
      <path
        id="Path_1550"
        data-name="Path 1550"
        d="M15.647,2a8.39,8.39,0,0,0-.212,16.777,1.426,1.426,0,0,1,.389,0h.124A8.391,8.391,0,0,0,15.647,2Z"
        transform="translate(-3.203 -2)"
        fill="#fff"
      />
      <path
        id="Path_1551"
        data-name="Path 1551"
        d="M26.387,15.22c-4.932-3.288-12.976-3.288-17.944,0a6.978,6.978,0,0,0-3.483,5.71,6.919,6.919,0,0,0,3.465,5.675A16.334,16.334,0,0,0,17.407,29.1a16.334,16.334,0,0,0,8.981-2.493,6.975,6.975,0,0,0,3.465-5.71,6.961,6.961,0,0,0-3.465-5.675Zm-5.445,7.036h-2.21v2.21a1.326,1.326,0,1,1-2.652,0v-2.21h-2.21a1.326,1.326,0,0,1,0-2.652h2.21v-2.21a1.326,1.326,0,0,1,2.652,0V19.6h2.21a1.326,1.326,0,1,1,0,2.652Z"
        transform="translate(-4.961 6.257)"
        fill="#fff"
      />
    </g>
  </svg>
);
