import { FlexibleDiv } from "components/lib/Box";
import { CheckBox } from "components/lib/CheckBox";
import { forwardRef, memo } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { truncate } from "utils";
import { AddPlaylistCardWrap } from "./add-playlist-card";

const playlistTypes = {
  SOLO: "Solo",
  COLLABORATIVE: "Collab",
};
const AddPlaylistCardRefEl = forwardRef(({ onCheck, ...props }, ref) => {
  const navigate = useNavigate();
  const { id, coverArt, title, editors, tracks, type } = props;
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const __type = playlistTypes[type];

  return (
    <AddPlaylistCardWrap ref={ref}>
      <FlexibleDiv className="selectable--wrap wrap--layer">
        <CheckBox onChange={({ target }) => onCheck(target, id)} />
      </FlexibleDiv>

      <FlexibleDiv className="wrap--layer">
        <div className="image--wrap">
          <img src={coverArt?.url} alt={title} />
        </div>
      </FlexibleDiv>

      <FlexibleDiv className="info--wrap wrap--layer">
        <p className="title">{truncate(title)}</p>
        <small className="author">
          By{" "}
          {editors?.map(({ id, username }, idx) => (
            <span
              className="artist"
              onClick={() => navigate(`/users/${id}`)}
              key={idx}
            >
              {username}
            </span>
          ))}
        </small>
      </FlexibleDiv>

      <FlexibleDiv className="track--wrap wrap--layer">
        <p className="tracks">
          {tracks.length} {tracks.length === 1 ? "song" : "songs"}
        </p>
      </FlexibleDiv>

      <FlexibleDiv className="type--wrap wrap--layer">
        <p className={`type ${__type?.toLowerCase()}`}>{__type}</p>
      </FlexibleDiv>
    </AddPlaylistCardWrap>
  );
});

export const AddPlaylistCard = memo(AddPlaylistCardRefEl);
