import { Loader, OverflowScroll } from "components"
import { Types, useMainContext } from "context"
import {
  getAlbumById,
  getAlbumTracksById,
  getArtistAlbums,
  getSimilarArtists,
} from "network"
import { PlaylistWrap } from "pages/Playlist/playlist.styles"
import React from "react"
import { useQueries } from "react-query"
import { useParams } from "react-router-dom"
import { PlaylistJumbotron } from "../PlaylistJumbotron/PlaylistJumbotron"
import { PlaylistOrganizer } from "../PlaylistOrganizer"

export const AlbumModule = () => {
  const { playlistId } = useParams()
  const {
    state: { playlist },
    dispatch,
  } = useMainContext()

  const albumInfo = useQueries([
    {
      queryKey: ["album", playlistId],
      enabled: !!playlistId,
      queryFn: () => getAlbumById(playlistId),
    },
    {
      queryKey: ["album-tracks"],
      enabled: !!playlistId,
      queryFn: () => getAlbumTracksById(playlistId),
    },
  ])

  const isLoading = albumInfo.some(o => o.isLoading)
  const albumData = albumInfo[0].data
  const tracks = albumInfo[1].data

  const album =
    Boolean(playlist?.currentAlbum?.id) &&
    playlist.currentAlbum.id === playlistId
      ? playlist.currentAlbum
      : albumData
  const coverArt = album?.metadata?.coverArt?.url
  const da = album?.metadata?.displayArtists
  const mainArtist = da?.find(d => d.isMainArtist)

  const extraInfo = useQueries([
    {
      queryKey: ["more-from"],
      enabled: !!mainArtist?.artist?.id,
      queryFn: () => getArtistAlbums(mainArtist?.artist?.id),
    },
    {
      queryKey: ["similar"],
      enabled: !!mainArtist?.artist?.id,
      queryFn: () => getSimilarArtists(mainArtist?.artist?.id),
    },
  ])

  const extraInfoLoading = extraInfo.some(o => o.isLoading)
  const moreFromData = extraInfo[0].data
  const similarFromData = extraInfo[1].data

  React.useEffect(() => {
    dispatch({
      type: Types.UPDATE_CURRENT_ALBUM,
      payload: { ...albumData, tracks },
    })
  }, [albumData, tracks, dispatch])

  return (
    <OverflowScroll>
      {isLoading ? (
        <Loader wrapperHeight="85vh" />
      ) : (
        <PlaylistWrap>
          <PlaylistJumbotron
            entity={album}
            tracks={tracks || []}
            module="album"
          />

          <PlaylistOrganizer
            module="album"
            entity={album}
            tracks={tracks || []}
            coverArt={coverArt}
            mainArtist={mainArtist}
            loading={extraInfoLoading}
            moreFromData={moreFromData}
            similarFromData={similarFromData}
          />
        </PlaylistWrap>
      )}
    </OverflowScroll>
  )
}
