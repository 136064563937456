import { FlexibleDiv } from "components"
import styled from "styled-components"

export const MiniPlayerWrap = styled(FlexibleDiv)`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
  padding: ${({ isActive }) => isActive && "0.5rem 1rem"};
  background: ${({ dominantColors }) =>
    !!dominantColors?.length
      ? `linear-gradient(180deg, ${dominantColors[0]} 2.59%, ${dominantColors[1]} 99.97%, ${dominantColors[2]} 99.98%)`
      : `linear-gradient(
      180deg,
      #39452d 2.59%,
      #232b1a 99.97%,
      #9f6f2d 99.98%,
      #bb8233 99.99%
    )`};
  transition: background 0.3s ease-in;

  .player__home__footer {
    width: 100%;
    position: relative;
  }

  :hover {
    .left__controls__wrap {
      .player__image__wrap {
        .expand__wrap {
          height: 100%;

          svg {
            display: inline-flex;
          }
        }
      }
    }
  }

  .left__controls__wrap {
    column-gap: 10px;
    overflow: hidden;
    justify-content: flex-start;
    min-width: 200px;
    max-width: 350px;
    column-gap: 10px;
    box-sizing: border-box;

    .player__image__wrap {
      width: 65px;
      height: 65px;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .expand__wrap {
        top: 0;
        left: 0;
        height: 0;
        width: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in;
        background-color: var(--overlayMain);

        svg {
          display: none;
        }
      }
    }

    .song__title__wrap {
      display: flex;
      flex-direction: column;

      .track__title {
        line-height: 1;
        cursor: pointer;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        color: var(--textPrimary);
        font-family: "Baguede", sans-serif;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;
      }

      .artist {
        font-family: "Baguede", sans-serif;
        cursor: pointer;
        font-size: 0.65rem;
        transition: all 0.3s ease-in;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;

        :hover {
          text-decoration: underline;
        }
      }
    }
  }

  .center__controls__wrap {
    flex: 3;
  }

  .control__main__wrap {
    width: 70%;

    .controls__main {
      height: 45px;
      column-gap: 20px;
      margin-bottom: 0.2rem;
    }
  }

  .right__controls__wrap {
    flex: 1;
    column-gap: 6%;
  }

  .control__icons {
    height: 100%;
    cursor: pointer;
  }
  .icons__wrap {
    height: 100%;
    cursor: pointer;
  }
  .expand__icon {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    padding: 0.5rem 1rem;

    .right__controls__wrap,
    .left__controls__wrap,
    .song__progress__wrap {
      display: none;
    }

    height: 85px;
  }

  @media screen and (min-width: 601px) and (max-width: 800px) {
    padding: 0.5rem 1rem;

    .left__controls__wrap {
      flex: 0;
      min-width: 180px;
      column-gap: 5px;

      .player__image__wrap {
        width: 50px;
        height: 50px;
      }

      .song__title__wrap {
        .track__title {
          font-size: 0.85rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 120px;
        }

        .artist {
          font-size: 0.65rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 120px;
        }
      }
    }

    .center__controls__wrap {
      width: 400px;
      flex: 3;
      padding: 0 0.25rem;
    }

    .control__main__wrap {
      width: 95%;

      .controls__main {
        height: 40px;
        column-gap: 20px;
        margin-bottom: 0;
      }
    }

    .control__icons {
      svg {
        width: 20px;
      }
    }

    .pause__icon,
    .play__icon {
      svg {
        width: 28px;
      }
    }

    .song__progress__wrap {
      width: 95%;

      small {
        font-size: 0.65rem;
      }
    }

    .right__controls__wrap {
      padding-left: 0.5rem;
      flex: 0;
      min-width: 120px;
      justify-content: space-around;
    }

    .icons__wrap {
      svg {
        width: 18px;
      }
    }

    .more__icon {
      svg {
        width: 12px;
        height: 20px;
      }
    }
  }

  @media screen and (min-width: 801px) and (max-width: 949px) {
    padding: 0.5rem 1rem;

    .left__controls__wrap {
      flex: 1;
      min-width: 200px;
      max-width: 250px;
      column-gap: 10px;
      box-sizing: border-box;

      .player__image__wrap {
        width: 55px;
        height: 55px;
      }

      .song__title__wrap {
        .track__title {
          font-size: 0.95rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 180px;
        }

        .artist {
          font-size: 0.65rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 180px;
        }
      }
    }

    .center__controls__wrap {
      flex: 0;
      padding: 0 0.25rem;
      box-sizing: border-box;
      flex: 2;
      min-width: 280px;
      max-width: 350px;
    }

    .control__main__wrap {
      width: 95%;

      .controls__main {
        height: 40px;
        column-gap: 20px;
        margin-bottom: 0;
      }
    }

    .control__icons {
      svg {
        width: 23px;
      }
    }

    .pause__icon,
    .play__icon {
      svg {
        width: 32px;
      }
    }

    .song__progress__wrap {
      width: 95%;

      small {
        font-size: 0.65rem;
      }
    }

    .right__controls__wrap {
      flex: 0;
      padding-left: 0.5rem;
      column-gap: 5px;
      justify-content: space-around;
      box-sizing: border-box;
      flex: 1;
      min-width: 120px;
      max-width: 180px;
    }

    .icons__wrap {
      svg {
        width: 24px;
      }
    }

    .more__icon {
      svg {
        width: 12px;
        height: 25px;
      }
    }
  }

  @media screen and (min-width: 950px) and (max-width: 1200px) {
    padding: 0.5rem 1rem;

    .left__controls__wrap {
      flex: 1;
      min-width: 200px;
      max-width: 280px;
      column-gap: 10px;
      box-sizing: border-box;

      .player__image__wrap {
        width: 55px;
        height: 55px;
      }

      .song__title__wrap {
        .track__title {
          font-size: 1rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 200px;
        }

        .artist {
          font-size: 0.65rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 180px;
        }
      }
    }

    .center__controls__wrap {
      flex: 0;
      padding: 0 0.25rem;
      box-sizing: border-box;
      flex: 2;
      min-width: 300px;
      max-width: 420px;
    }

    .control__main__wrap {
      width: 95%;

      .controls__main {
        height: 40px;
        column-gap: 20px;
        margin-bottom: 0;
      }
    }

    .control__icons {
      svg {
        width: 23px;
      }
    }

    .pause__icon,
    .play__icon {
      svg {
        width: 32px;
      }
    }

    .song__progress__wrap {
      width: 95%;

      small {
        font-size: 0.65rem;
      }
    }

    .right__controls__wrap {
      flex: 0;
      padding-left: 0.5rem;
      column-gap: 5px;
      justify-content: space-around;
      box-sizing: border-box;
      flex: 1;
      min-width: 120px;
      max-width: 180px;
    }

    .icons__wrap {
      svg {
        width: 24px;
      }
    }

    .more__icon {
      svg {
        width: 12px;
        height: 25px;
      }
    }
  }
`
