import { FlexibleDiv } from "components"
import styled from "styled-components"

export const MSModalWrap = styled(FlexibleDiv)`
  position: absolute;
  z-index: 999;
  height: 250px;
  width: 90%;
  background: ${({ dominantColors }) =>
    !!dominantColors?.length
      ? `linear-gradient(180deg, ${dominantColors[0]} 2.59%, ${dominantColors[1]} 99.97%, ${dominantColors[2]} 99.98%)`
      : `linear-gradient(
      180deg,
      #39452d 2.59%,
      #232b1a 99.97%,
      #9f6f2d 99.98%,
      #bb8233 99.99%
    )`};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 0;

  .top__layer {
    flex: 3;
    height: 150px;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    align-items: flex-start;
  }

  .playlist__cover__art {
    width: 90%;
    justify-content: flex-start;
  }

  .cover__art {
    width: 120px;
    height: 120px;
    position: relative;
    margin-right: 0.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title__and__album {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--textPrimary);

    p {
      margin: 0;
    }

    .title {
      font-size: 1.1rem;
    }

    .desc {
      width: 150px;
      white-space: pre-wrap;
      line-height: 1.2;
      font-size: 0.65rem;
    }
  }

  .close__icon__wrap {
    cursor: pointer;
    width: 20px;
  }

  .share__content {
    flex: 2;

    .link__box {
      border: 2px solid var(--grayLightAlpha);
      color: var(--textPrimary);
      font-family: "Baguede", sans-serif;
      padding: 0.2rem;
      width: 95%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .copy__btn {
      padding: 0.3rem;
      border-radius: 2px;
      border: 1px solid var(--t5);
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  .share__list__layer {
    flex: 1;
    padding-bottom: 0.5rem;
  }

  .share__list {
    height: 100%;
    margin: 0;
    width: 100%;
    padding: 0 1rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .action__item {
      display: flex;
      height: 100%;
      padding: 2% 0;
      list-style: none;
      font-size: 0.75rem;
      align-items: center;
      justify-content: center;
      width: 20%;

      svg {
        font-size: 1.5rem;
        color: var(--t9);
      }
    }

    .whatsapp {
      fill: #075e54;
    }
  }

  @keyframes smmAnimateIn {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    60% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes ssmAnimateOut {
    20% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
`

export const MobilePortalContainer = styled(FlexibleDiv)`
  top: 0;
  z-index: 9999;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  transition: all 0.3s ease;
  background: ${({ dominantColors }) =>
    !!dominantColors?.length
      ? `linear-gradient(180deg, ${dominantColors[0]} 2.59%, ${dominantColors[1]} 99.97%, ${dominantColors[2]} 99.98%)`
      : `linear-gradient(
      180deg,
      #39452d 2.59%,
      #232b1a 99.97%,
      #9f6f2d 99.98%,
      #bb8233 99.99%
    )`};
`
