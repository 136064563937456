import { Form } from "antd"
import {
  BorderedTextarea,
  BorderedTextfield,
  Button,
  FlexibleDiv,
} from "components"
import { Types, useMainContext } from "context"
import { createCollabPlaylist, createSoloPlaylist } from "network"
import { useEffect } from "react"
import { useMutation } from "react-query"
import { validateBasicField } from "utils"
import { CPDWrap } from "./styles"

export const CreatePlaylistDialog = ({
  open,
  type,
  handleOk,
  handleClose,
  setResponseBanner,
  leadText = "Create Playlist",
}) => {
  const [form] = Form.useForm()

  const { dispatch } = useMainContext()

  const { mutate: mutateCreateSoloPlaylist, isLoading: cspLoading } =
    useMutation(createSoloPlaylist)
  const { mutate: mutateCreateCollabPlaylist, isLoading: ccpLoading } =
    useMutation(createCollabPlaylist)

  const isLoading = cspLoading || ccpLoading

  useEffect(() => {
    form.setFieldsValue({
      title: "",
      description: "",
    })
  }, [form])

  return (
    <CPDWrap
      visible={open}
      onOk={handleOk}
      title={leadText}
      onCancel={handleClose}
      footer={null}
    >
      <Form onFinish={handleMutation} form={form}>
        <FlexibleDiv className="textfield__wrap">
          <BorderedTextfield
            type="text"
            paddingLeft="5px"
            name="title"
            label="Playlist Title"
            placeholder="Playlist #1"
            validator={validateBasicField}
            small
          />
        </FlexibleDiv>
        <FlexibleDiv className="textfield__wrap">
          <BorderedTextarea
            paddingLeft="5px"
            name="description"
            label="Playlist Description"
            placeholder="Every playlist is a vibe"
            validator={validateBasicField}
            height="100px"
            small
          />
        </FlexibleDiv>

        <FlexibleDiv className="btn__box">
          <Button loading={isLoading}>
            <span>Create Playlist</span>
          </Button>
        </FlexibleDiv>
      </Form>
    </CPDWrap>
  )

  async function handleMutation(values) {
    if (type === "solo") {
      mutateCreateSoloPlaylist(values, {
        onSuccess: res => {
          form.setFieldsValue({
            title: "",
            description: "",
          })
          setResponseBanner({
            open: true,
            type: "SUCCESS",
            response: res,
          })
          dispatch({ type: Types.CREATING_PLAYLIST, payload: false })
          handleClose && handleClose()
        },
        onError: err =>
          setResponseBanner({
            open: true,
            type: "SUCCESS",
            response: err,
          }),
      })
    }

    if (type === "collab") {
      mutateCreateCollabPlaylist(
        {
          ...values,
          private: true,
        },
        {
          onSuccess: res => {
            form.setFieldsValue({
              title: "",
              description: "",
            })
            setResponseBanner({
              open: true,
              type: "SUCCESS",
              response: res,
            })
            dispatch({ type: Types.CREATING_PLAYLIST, payload: false })
            handleClose && handleClose()
          },
          onError: err =>
            setResponseBanner({
              open: true,
              type: "SUCCESS",
              response: err,
            }),
        }
      )
    }
  }
}
