export const DragIcon = ({ color = "#eaeaea", width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "25"}
    height={height || "25"}
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <g transform={props.transform || "translate(5, 9)"}>
      <g clip-path="url(#clip0_618_3821)">
        <path d="M20 0H0V1.74194H20V0Z" fill="#D9D9D9" />
        <path d="M20 16.2578H0V17.9997H20V16.2578Z" fill="#D9D9D9" />
        <path d="M20 8.12891H0V9.87084H20V8.12891Z" fill="#D9D9D9" />
      </g>
      <defs>
        <clipPath id="clip0_618_3821">
          <rect width="20" height="18" fill="white" />
        </clipPath>
      </defs>
    </g>
  </svg>
);
