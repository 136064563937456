import { take } from "lodash"
import React from "react"
import { truncate } from "."
import { isEmpty } from "./isObjEmpty"

export function getDisplayArtist(trackInfo, navigate) {
  if (!isEmpty(trackInfo)) {
    let da = []

    if (trackInfo?.track?.metadata?.displayArtists) {
      da = trackInfo.track.metadata.displayArtists
    } else if (trackInfo?.metadata?.displayArtists) {
      da = trackInfo.metadata.displayArtists
    }

    const shouldTruncate = checkNamesLength(take(da, 3))

    return da && Array.isArray(da) ? (
      take(da, 3).map((d, idx, list) => (
        <React.Fragment>
          <small
            className="artist"
            key={idx}
            onClick={() => navigate(`/artist/${d.artist ? d.artist.id : d.id}`)}
          >
            {shouldTruncate
              ? idx + 1 < list.length
                ? d.artist.stageName
                : truncate(d.artist.stageName, 5)
              : d.artist.stageName}
            {idx + 1 < list.length ? ", " : da.length > 3 ? ", ..." : ""}{" "}
          </small>{" "}
        </React.Fragment>
      ))
    ) : (
      <small
        className="artist"
        onClick={() => navigate(`/artist/${da.artist ? da.artist.if : da.id}`)}
      >
        {truncate(da.artist.stageName, 30)}
      </small>
    )
  }
}

export function checkNamesLength(list) {
  if (Array.isArray(list)) {
    return list.map(d => d.artist.stageName).join(", ").length > 25
  }

  return false
}
