import dayjs from "dayjs"
import uduXWhiteLogo from "assets/images/general/udux_white_logo.svg"
import { truncate } from "utils"

export function getCoverArt(entity, module) {
  if (module === "album") {
    return entity?.metadata?.coverArt?.url
  }

  if (module === "playlist") {
    return entity?.coverArt?.url
  }
}

export function getTracksLength(tracks, module) {
  if (module === "album") {
    return tracks.length
  }

  if (module === "playlist") {
    return tracks.map(t => t.track).length
  }
}

export function getPlaylistTitle(entity, module) {
  if (module === "album") {
    return truncate(entity?.metadata?.title)
  }

  if (module === "playlist") {
    return truncate(entity?.title)
  }
}

export function getTypeAndYear(entity, module) {
  if (module === "album") {
    return `${entity?.metadata?.entityType}:
              ${dayjs(entity?.metadata?.originalReleaseDate).format("YYYY")}`
  }

  if (module === "playlist") {
    let playlistType = "Public Playlist"

    if (entity?.type?.toLowerCase() === "solo") {
      playlistType = "Solo Playlist"
    }

    if (entity?.type?.toLowerCase() === "collaborative") {
      playlistType = "Collab Playlist"
    }

    if (entity?.type?.toLowerCase() === "managed") {
      playlistType = "Public Playlist"
    }

    return playlistType
  }
}

export function getPlaylistAvatar(entity, mainArtist, module, handleClick) {
  if (module === "album") {
    return (
      <div className="artist__avatar">
        <img
          src={mainArtist?.artist?.profilePicture?.url || getCoverArt(entity)}
          alt="artist name"
        />
      </div>
    )
  }

  if (module === "playlist" && entity?.type === "MANAGED") {
    return (
      <div className="udux__logo">
        <img src={uduXWhiteLogo} alt="udux" />
      </div>
    )
  }

  if (
    module === "playlist" &&
    entity?.type === "SOLO"
    // && entity?.user?.profilePicture?.url
  ) {
    return (
      <div className="playlist__creator">
        <div className="avatar">
          <img
            src={entity.user?.profilePicture?.url}
            alt={entity.user?.username}
          />
        </div>
        <p className="creator__name" onClick={handleClick}>
          {entity?.user?.username}
        </p>
      </div>
    )
  }
}
