import styled from "styled-components";
import { Form } from "antd";

export const FormItemWrap = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "3em"};

  @media only screen and (min-width: 800px) {
    margin-bottom: 3.5rem;
  }

  @media only screen and (min-width: 1020px) {
    margin-bottom: 2.5rem;
  }

  @media only screen and (min-width: 1200px) {
    margin-bottom: 4rem;
  }

  .input_error_text {
    margin: 0;
  }
  .input_error_text p {
    color: var(--uduxDangerPrimary);
    font-size: 0.8rem;
    line-height: 1.4rem;
    text-align: left;
    margin: 0;

    @media only screen and (min-width: 500px) {
      font-size: 1.1rem;
      line-height: 1.6rem;
    }
  }
`;

export const FormItem = styled(Form.Item)`
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;
  background: ${(props) => props.background || "transparent"};
  border: ${(props) => props.border || "1px solid #707070"};
  border-width: ${({ borderWidth }) => borderWidth || "0 0 1px 0"};
  border-radius: ${(props) =>
    props.borderWidth ? "0" : props.radius ? props.radius : "0px"};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 14px;
  border-color: ${(props) => props.borderColor || "var(--uduxWhitePrimary)"};
  text-align: left;
  transition: all 0.3s ease;
  outline: none;
  overflow: hidden;

  div {
    height: 100%;
  }

  :hover,
  :active,
  :focus,
  :visited {
    border-color: ${({ hoverColor }) =>
      hoverColor || "var(--uduxYellowPrimary)"};
    border-width: ${(props) => props.borderWidth || "0 0 1px 0"};
  }

  input,
  textarea {
    width: 100%;
    height: ${(props) => props.height || "100%"};
    background-color: transparent;
    outline: none;
    border: none;
    margin: -3px 0 0 0;
    color: ${(props) => props.color || "var(--uduxWhitePrimary)"};
    font-family: "Bw ModelicaRegular", sans-serif;
    font-size: 14px;
    text-align: left;
    border: none;
    padding: ${(props) => props.padding || "0 0px"};

    ::placeholder {
      color: ${(props) =>
        props.placeholder__color || "var(--uduxWhitePrimary)"};
      /* color: var(--uduxWhitePrimary); */
      font-size: 14px;
      text-align: left;
      font-weight: 400;
      font-family: "Bw ModelicaBold", sans-serif;
      opacity: 0.9;
    }
    :hover,
    :active,
    :focus,
    :visited {
      box-shadow: none;
      background-color: transparent;
      border: none;
    }

    svg {
      color: var(--uduxYellowPrimary);
      margin: -2px 2px 0 0;
    }
    ::before {
      display: none;
    }
    :active,
    :focus {
      ::placeholder {
        color: transparent;
      }
    }
  }
  textarea {
    text-align: start;
    padding: ${({ padding }) => padding || "10px"};
  }

  input:disabled {
    color: var(--uduxGrayPrimary);
    cursor: not-allowed;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: ${({ lightMask }) =>
      lightMask ? `0 0 0 30px ${lightMask} inset` : "0 0 0 30px #0D0E1D inset"};
    box-shadow: ${({ lightMask }) =>
      lightMask ? `0 0 0 30px ${lightMask} inset` : "0 0 0 30px #0D0E1D inset"};
    -webkit-text-fill-color: ${({ lightMask }) =>
      lightMask ? "#fff" : "#fff"};
  }

  .ant-form-item-control-input-content {
    height: ${({ height }) => height || "100%"};
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
`;

export const IconWrap = styled.div`
  width: max-content;
  height: max-content;
  max-width: max-content;
  z-index: 999;
  position: absolute;
  right: 7px;
  bottom: ${(props) => (props.height === "40px" ? "7px" : "10px")};
  svg {
    font-size: 1rem;
    width: 1.2rem;
    height: 1.2rem;
  }

  .check {
    color: #ace0b6;
  }
  .cancel {
    color: #f54b4b;
  }
`;

export const TextFieldWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  margin: 0;
  display: block;

  .password__eye {
    position: absolute;
    font-size: 16px;
    color: var(--uduxYellowPrimary);
    right: ${({ status }) => (!!status ? "30px" : "10px")};
    cursor: pointer;
    bottom: 10px;

    > img {
      width: 20px;
    }
  }
`;
