import {
  AddSongIcon,
  DragIcon,
  FavoriteFilledIcon,
  FavoriteIcon,
  MoreIcon,
} from "assets"
import { CloseOutlineIcon } from "assets/images/player/SVGExports"
import { Dropdown, FlexibleDiv } from "components"
import { forwardRef, memo } from "react"
import { useNavigate } from "react-router-dom"
import { likeItem, unLikeItem } from "utils"
import { QueuedTrackMoreMenu } from "./QueuedTrackMoreMenu"
import { QTIWrap } from "./queue-track-item.styles"
import { Types, useMainContext } from "context"

const QueueItemRefEl = forwardRef(
  (
    { track, position, changeSong, pushToPlayNext, removeFromQueue, isMobile },
    ref
  ) => {
    const navigate = useNavigate()
    const metadata = track?.track?.metadata
    const {
      state: { app },
      dispatch,
    } = useMainContext()

    const { isLoggedIn } = app

    return (
      <QTIWrap
        dominantColors={metadata?.coverArtDominantColors?.map(cadc =>
          cadc.startsWith("#") ? cadc : `#${cadc}`
        )}
        justifyContent="space-between"
        flexWrap="nowrap"
        isMobile={isMobile}
        ref={ref}
      >
        <div
          className="closeicon__wrap"
          onClick={() => {
            removeFromQueue(position, track)
          }}
        >
          <CloseOutlineIcon color="var(--uduxWhitePrimary)" width="10" />
        </div>

        <FlexibleDiv className="song__info__wrap">
          <div
            className="image__wrap"
            // onClick={() => changeSong(idx)}
          >
            <img src={metadata?.coverArt?.url} alt="" />
          </div>

          <FlexibleDiv
            flexDir="column"
            alignItems="flex-start"
            className="text__wrap"
          >
            <p
            // onClick={() => changeSong(idx)}
            >
              {metadata?.title}
            </p>

            <span>
              {metadata?.displayArtists?.map(({ artist }, idx) => (
                <span
                  className="artist"
                  onClick={() => navigate(`/artist/${artist.id}`)}
                  key={idx}
                >
                  {artist?.stageName}
                  {metadata?.displayArtists?.length > 1 &&
                    idx !== metadata?.displayArtists?.length - 1 &&
                    ", "}{" "}
                </span>
              ))}
            </span>
          </FlexibleDiv>
        </FlexibleDiv>

        {isMobile ? (
          <Dropdown
            trigger="click"
            placement="bottomLeft"
            overlay={<QueuedTrackMoreMenu track={track} />}
          >
            <FlexibleDiv
              justifyContent="space-between"
              flexWrap="nowrap"
              className="action__icons__wrap__isMobile"
            >
              <div className="icon__wrap">
                <MoreIcon width={25} height={25} />
              </div>
            </FlexibleDiv>
          </Dropdown>
        ) : (
          <FlexibleDiv
            justifyContent="space-between"
            flexWrap="nowrap"
            className="action__icons__wrap"
          >
            {!!track.isLiked ? (
              <div
                className="icon__wrap show__on__hover"
                onClick={() => {
                  if (!isLoggedIn) {
                    return dispatch({
                      type: Types.SET_PRIVATE_ACTION,
                      payload: true,
                    })
                  }
                  unLikeItem(track.id, "Track")
                }}
              >
                <FavoriteFilledIcon width={25} height={25} />
              </div>
            ) : (
              <div
                className="icon__wrap show__on__hover"
                onClick={() => {
                  if (!isLoggedIn) {
                    return dispatch({
                      type: Types.SET_PRIVATE_ACTION,
                      payload: true,
                    })
                  }
                  likeItem(track.id, "Track")
                }}
              >
                <FavoriteIcon width={25} height={25} />
              </div>
            )}
            <div
              className="icon__wrap show__on__hover"
              onClick={() => {
                if (!isLoggedIn) {
                  return dispatch({
                    type: Types.SET_PRIVATE_ACTION,
                    payload: true,
                  })
                }
              }}
            >
              <AddSongIcon width={25} height={25} />
            </div>
            <div className="icon__wrap">
              <DragIcon width={25} height={25} />
            </div>
          </FlexibleDiv>
        )}
      </QTIWrap>
    )
  }
)

export const QueueTrackItem = memo(QueueItemRefEl)
