import { MyDetailsIcon } from "assets";
import { Dropdown, StyledMenu } from "components/lib";
import { useMainContext } from "context";
import { BsCompass } from "react-icons/bs";
import { GoSettings } from "react-icons/go";
import { HiOutlineUserCircle } from "react-icons/hi";
import { RiArrowDropDownLine, RiCoinsLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { truncate } from "utils";
import { SettingsWidgetWrap } from "./settings-widget.styles";
import { CompassIcon } from "assets/icons/CompassIcon";

export const SettingsWidget = () => {
  const navigate = useNavigate();
  const {
    state: { users },
  } = useMainContext();

  const { me } = users;

  const compassMenu = (
    <StyledMenu
      top="0.5rem"
      style={{
        border: "0.5px solid var(--uduxGrayLightAlpha)",
        width: 260,
      }}
      items={[
        {
          key: "1",
          label: (
            <div className="menu__box__svg" onClick={() => navigate("/me")}>
              <HiOutlineUserCircle
                style={{
                  transform: "scale(1.65)",
                }}
              />
              <p>My Profile</p>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              className="menu_box"
              onClick={() => navigate("/settings#my-details")}
            >
              <MyDetailsIcon
                style={{
                  transform: "scale(1.65)",
                }}
              />
              <p>My Details</p>
            </div>
          ),
        },
        // {
        //   key: "3",
        //   label: (
        //     <div
        //       className="menu__box__svg"
        //       onClick={() => navigate("/settings#my-subscriptions")}
        //     >
        //       <RiCoinsLine width={18} />
        //       <p>Subscription</p>
        //     </div>
        //   ),
        // },
        {
          key: "3",
          label: (
            <div
              className="menu_box"
              onClick={() => navigate("/settings#compass")}
            >
              <CompassIcon
                style={{
                  transform: "scale(1.65)",
                }}
              />
              <p>Compass</p>
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              className="menu_box"
              onClick={() => navigate("/settings#my-settings")}
            >
              <GoSettings
                style={{
                  transform: "scale(1.65)",
                }}
              />
              <p>Settings</p>
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <SettingsWidgetWrap>
      <div className="avatar__wrapped">
        <img src={me?.profile?.profilePicture?.url} alt={me?.username} />
      </div>
      <button>
        <Dropdown
          trigger="hover"
          overlay={compassMenu}
          placement="bottomCenter"
        >
          <span className="user__name">{truncate(me?.username, 15)}</span>
          <RiArrowDropDownLine />
        </Dropdown>
      </button>
    </SettingsWidgetWrap>
  );
};
