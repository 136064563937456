import { FlexibleDiv } from "components/lib";
import styled from "styled-components";

export const SettingsWidgetWrap = styled(FlexibleDiv)`
  width: max-content;
  height: 38px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in;
  justify-content: space-around;
  flex-wrap: nowrap;

  @media screen and (max-width: 500px) {
    margin-right: 12px;
  }

  .avatar__wrapped {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  button {
    flex-grow: 1;
    width: 50%;
    padding-left: 0.5rem;
    outline: 0;
    height: auto;
    border: none;
    display: flex;

    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: var(--highlight);

    span {
      font-size: 1rem;
      opacity: 0.6;
      color: var(--highlight);
      text-transform: capitalize;
      font-family: "Baguede", sans-serif;
    }

    svg {
      font-size: 1.5rem;
    }

    :hover,
    :focus {
      svg {
        color: var(--highlight);
      }
    }
  }

  @media screen and (max-width: 600px) {
    button {
      button {
        width: 50px;
      }
    }
  }
`;