import { Types } from "context/types"

export const playlistReducer = (state, { type, payload }) => {
  switch (type) {
    case Types.UPDATE_CURRENT_ALBUM:
      return {
        ...state,
        currentAlbum: payload,
      }

    case Types.UPDATE_CURRENT_PLAYLIST:
      return {
        ...state,
        current: payload,
      }

    case Types.CREATING_PLAYLIST:
      return {
        ...state,
        creatingPlaylist: payload,
      }

    case Types.UPDATE_RECENTLY_PLAYED:
      return {
        ...state,
        recentlyPlayed: [...state.recentlyPlayed, payload],
      }

    default:
      return state
  }
}
