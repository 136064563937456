import { Section } from "components"
import styled from "styled-components"

export const MLWrap = styled(Section)`
  border: none !important;

  .display__row {
    display: flex;
    flex-wrap: nowrap;
    margin: 2rem auto 5rem;
    overflow: hidden;
    column-gap: 20px;
  }

  .top__tracks__recent__play {
    flex-direction: column;
  }

  .section__display__controls {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .title,
    .see__all {
      margin: 0;
      color: var(--textPrimary);
      font-family: "Baguede", sans-serif;
    }

    .title {
      text-transform: capitalize;
      font-size: 1.2rem;
    }

    .see__all {
      cursor: pointer;
      font-size: 0.65rem;
      text-decoration: underline;
    }
  }

  .explorer__box {
    /* column-gap: 5rem; */
    column-gap: min(4%, 100%);
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .top__artist__display {
  }

  @media screen and (max-width: 600px) {
    margin-top: 2rem;

    .display__row {
      margin: 2rem auto;
      column-gap: 10px;
    }

    .explorer__box__mobile {
      flex-direction: column;
      row-gap: 4rem;
    }

    .section__display__controls {
      margin-bottom: 1rem;
      padding: 0 0.5rem;
      align-items: flex-start;

      .title {
        font-size: 1.05rem;
        max-width: 280px;
      }

      .see__all {
        padding-top: 0.35rem;
        font-size: 0.65rem;
      }
    }
  }
`
