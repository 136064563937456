import { instance } from "./axios";

export const getPlaylistById = async (id) => {
  const { data } = await instance.get(`/streaming/playlists/${id}`);

  return data.data;
};

export const publicGetPlaylistById = async (id) => {
  const { data } = await instance.get(`/streaming/playlists/${id}/public`);

  return data.data;
};

export const getAlbumById = async (id) => {
  const { data } = await instance.get(`/streaming/albums/${id}`);

  return data.data;
};

export const getTrackById = async (id) => {
  const { data } = await instance.get(`/streaming/tracks/${id}`);

  return data.data;
};

export const getAlbumTracksById = async (id) => {
  const { data } = await instance.get(`/streaming/albums/${id}/tracks`);

  return data.data;
};

export const getAlbumAndTracks = async (id) => {
  const [album, tracks] = await Promise.all([
    instance.get(`/streaming/albums/${id}`),
    instance.get(`/streaming/albums/${id}/tracks`),
  ]);

  return {
    album: album.data,
    tracks: tracks.data,
  };
};

export const getPlaylistsByQuery = async (q) => {
  const { data } = await instance.get(`/streaming/playlists?${q}`);

  return data.data;
};

export const createSoloPlaylist = async (data) => {
  const r = await instance.post("/streaming/playlists/solo", data);

  return r.data;
};

export const createCollabPlaylist = async (data) => {
  const r = await instance.post("/streaming/playlists/collab", data);

  return r.data;
};

export const addToMultiplePlaylists = async (data) => {
  const r = await instance.patch(
    "/streaming/playlists/add-tracks-to-multiple-playlists",
    data
  );

  return r.data;
};

export const updatePlaylistInfo = async ({ id, data }) => {
  const r = await instance.patch(`/streaming/playlists/${id}/solo`, data);

  return r.data;
};

export const deletePlaylist = async (id) => {
  const r = await instance.delete(`/streaming/playlists/${id}`);

  return r.data;
};

export const getAllStreamingPlaylistsForUser = async (userId) => {
  const r = await instance.get(`/streaming/playlists?${userId}`);

  return r.data;
};

export const getWidgetPlaylist = async (payload) => {
  const { data } = await instance.get(
    `/streaming/playlists/poprev/artist/${payload}`
  );
  return data;
};
