import styled from "styled-components"
import { FlexibleDiv } from "components"

export const PaymentHeroWrapper = styled(FlexibleDiv)`
  z-index: 1;
  position: relative;
`

export const BackgroundImageWrapper = styled(FlexibleDiv)`
  width: 100%;
  height: 100vh;
  background: var(--uduxBlackPrimary);
  z-index: -1;
  position: absolute;
  top: 0;

  .web__image {
    height: 50%;
    width: 100%;
    object-fit: cover;
  }

  .mobile__image {
    display: none;
  }

  @media (min-width: 601px) and (max-width: 912px) {
    /* height: calc(100vh - 120px); */
  }

  @media (max-width: 600px) {
    height: calc(100vh - 120px);
    .web__image {
      display: none;
    }

    .mobile__image {
      display: block;
      height: 50%;
      width: 100%;
      object-fit: cover;
    }
  }
`

export const PHCWrapper = styled(FlexibleDiv)`
  background: transparent;
  width: 100%;
  height: 100vh;
  padding: 0 3.5%;
  padding-top: 100px;
  z-index: 0;

  .content__section {
    .hero__text__section {
      .hero__text__1 {
        color: var(--uduxWhitePrimary);
        font-family: "Baguede";
        margin: 0;
        font-size: 2.5rem;
        -webkit-text-stroke: 0.2px var(--uduxWhitePrimary);

        @media (min-width: 1920px) {
          font-size: 3rem;
        }
      }

      .hero__text__2 {
        color: #ffa300;
        font-family: "Baguede";
        margin: 0;
        font-size: 2.5rem;
        -webkit-text-stroke: 0.2px #ffa300;

        @media (min-width: 1920px) {
          font-size: 3rem;
        }
      }
    }

    .hero__right__column {
      flex: 1;
      hr {
        width: 130px;
        height: 2.5px;
        border: none;
        background-color: #1ca787;
      }

      .hero__udux__text {
        font-family: "Bw ModelicaMedium";
        color: #fdfeff;
        margin: 0;
        font-size: 12px;
        opacity: 0.8;

        @media (min-width: 1920px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  .homepage__image__wrapper {
    img {
      margin: auto;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  //fix smaller desktop screens
  @media (max-width: 1050px) {
    padding-top: 130px;
    .content__section {
      .hero__right__column {
        hr {
          width: 80px;
        }

        .hero__udux__text {
          font-size: 10px;
        }
      }

      .hero__text__section {
        .hero__text__1,
        .hero__text__2 {
          font-size: 2em;
        }
      }
    }
  }

  //mobile devices screens
  @media (max-width: 600px) {
    height: calc(100vh - 120px);
    padding-top: 75px;
    margin-bottom: 40px;

    .content__section {
      width: 100%;
      flex-direction: column;
      align-items: start;
      gap: 15px;

      @media (max-width: 376px) {
        gap: 10px;
      }

      .hero__right__column {
        height: fit-content;

        hr {
          display: none;
        }

        .hero__udux__text {
          font-size: 15px;
          border-left: 2px solid #15848f;
          padding-left: 13px;
          opacity: 1;

          @media (max-width: 376px) {
            font-size: 11.5px;
          }

          @media (max-width: 281px) {
            font-size: 10px;
          }
        }
      }

      .hero__text__section {
        overflow: visible;
        padding-left: 13px;

        .hero__text__1,
        .hero__text__2 {
          font-size: 2.2em;
        }

        @media (max-width: 376px) {
          margin-left: 0;

          .hero__text__1,
          .hero__text__2 {
            font-size: 1.9em;
          }
        }

        @media (max-width: 281px) {
          .hero__text__1,
          .hero__text__2 {
            font-size: 1.5em;
          }
        }
      }
    }

    .homepage__image__wrapper {
      padding-bottom: 42px;
      padding-top: 25px;

      @media (max-width: 376px) {
        padding-bottom: 15px;
        padding-top: 20px;
      }

      @media (max-width: 360px) {
        padding-bottom: 31px;
      }
    }
  }

  //ipad screens fix
  @media (min-width: 601px) and (max-width: 912px) {
    /* padding-bottom: 120px; */

    .content__section {
      height: 20%;
      width: 100%;
      flex-direction: column;
      align-items: start;

      @media (min-width: 900px) and (max-width: 912px) {
        height: 15%;
      }

      .hero__right__column {
        justify-content: start;

        hr {
          display: none;
        }

        .hero__udux__text {
          font-size: 1.05rem;
          border-left: 2px solid #15848f;
          padding-left: 13px;
          line-height: 2.1em;
          opacity: 1;

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 1.15rem;
          }
        }
      }

      .hero__text__section {
        overflow: visible;
        padding-left: 13px;

        .hero__text__1,
        .hero__text__2 {
          font-size: 1.7rem;

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 1.8rem;
          }
        }
      }
    }

    .homepage__image__wrapper {
      height: 80%;

      @media (min-width: 900px) and (max-width: 912px) {
        height: 85%;
      }
    }
  }
`
