import React, { useState } from "react"
import SignUpImage from "../../../../../assets/images/payment/signUpImage2.png"
import { PaymentRegisterLayout } from "components/layouts/PaymentsLayout/PaymentRegisterLayout/paymentRegisterLayout"
import {
  BorderedTextfield,
  FlexibleDiv,
  Button,
  BorderedSelectField,
  PhoneInput,
  DateSequenceSelector,
  CheckBox,
} from "components"
import { Form, Select } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { signUp } from "network"
import { capitalize, validateBasicField } from "utils"
import { Link, useSearchParams } from "react-router-dom"
import { PaymentRegisterWrapper } from "../../paymentRegister.styles"
import { useMutation } from "react-query"

const checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

export const StepTwo = ({ goBack, registerUser }) => {
  const [signUpForm] = Form.useForm()
  const [params, setParams] = useSearchParams()
  const [dob, setDob] = useState("")
  const plan = params.get("type")
  const [countryCode, setCountryCode] = useState("")

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false)
  const { mutate, isLoading } = useMutation(signUp)

  const setRegistrationData = values => {
    registerUser(
      { ...values, dateOfBirth: dob, acceptedTermsAndConditions },
      mutate
    )
  }

  const goToStepOne = () => {
    goBack()
  }

  return (
    <PaymentRegisterLayout
      checkHeight={checkHeight}
      smallHeight={checkSmallScreenHeight}
      galleryImage={SignUpImage}
      hideCustomBackButton={false}
      customGoBack={goToStepOne}
    >
      <PaymentRegisterWrapper>
        <Form
          form={signUpForm}
          onFinish={setRegistrationData}
          className="form_content"
        >
          <FlexibleDiv className="signup__form__box">
            <FlexibleDiv className="select_box gender__input__wrap">
              <FlexibleDiv
                flexWrap="no-wrap"
                className="select__gender"
                alignItems="center"
              >
                <BorderedSelectField
                  radius="0px"
                  name="gender"
                  type="text"
                  label="Gender"
                  margin="0 0 10px 0"
                  suffixIcon="empty"
                  borderwidth="0.5px"
                  font_family={'"Baguede", sans-serif'}
                  placeholder="What do you identify as?"
                  validator={validateBasicField}
                >
                  {["male", "female", "not-specified"].map((value, idx) => (
                    <Select.Option value={value} key={idx}>
                      {capitalize(value)}
                    </Select.Option>
                  ))}
                </BorderedSelectField>
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv className="phone__input__wrap">
              <PhoneInput
                bordered="true"
                name="phoneNumber"
                onSetCountryCode={code => setCountryCode(code)}
              />
            </FlexibleDiv>

            <FlexibleDiv>
              <DateSequenceSelector
                label="Date of birth"
                handleDateChange={dob => setDob(dob)}
              />
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv
            className="terms__and__conditions"
            flexDir="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <CheckBox
              color="var(--dampWhite)"
              name="acceptedTermsAndConditions"
              checked={acceptedTermsAndConditions}
              onChange={({ target }) =>
                setAcceptedTermsAndConditions(target.checked)
              }
            >
              <span className="tac__text">
                I accept that on occasion I can be contacted by uduX
              </span>
            </CheckBox>

            <p className="tac">
              uduX is a personalised music device. By clicking on the sign up
              button, you agree to our{" "}
              <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions of Use
              </a>
              .
            </p>
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              width="220px"
              height="50px"
              font_size="16px"
              htmlType="submit"
              color="var(--black)"
              disabled={!acceptedTermsAndConditions}
              contained
              bold
              ltr
            >
              <span>
                {isLoading ? (
                  <LoadingOutlined color="#0d0e1d" />
                ) : (
                  "Create Account"
                )}
              </span>
            </Button>
          </FlexibleDiv>
        </Form>
      </PaymentRegisterWrapper>
    </PaymentRegisterLayout>
  )
}

export default StepTwo
