import { AddToQueueIcon, GotoAlbumIcon, GotoArtistPageIcon } from "assets";
import { CloseOutlineIcon, ShareIcon } from "assets/images/player/SVGExports";
import { FlexibleDiv } from "components";
import { Config } from "config";
import { useMainContext } from "context";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {
  addToQueue,
  cleanSongTitle,
  getArtist,
  getDisplayArtist,
  getDominantColors,
  truncate,
  useDelayUnmount,
} from "utils";
import { MobilePortalWrap, MoreModalWrap } from "./more-modal.styles";
import { ShareModalMobile } from "../ShareModalMobile";

export const MoreModal = ({ handleClose, open }) => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(open);
  const [isSharing, setIsSharing] = useState({
    open: false,
    href: "",
  });
  const shouldRenderChild = useDelayUnmount(isMounted, 500);
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const {
    state: { player },
    dispatch,
  } = useMainContext();

  const { currentTrack, playingList } = player;
  const metadata = currentTrack?.metadata || currentTrack?.track?.metadata;

  const mountedStyle = { animation: "inAnimation 500ms ease-in" };
  const unmountedStyle = { animation: "outAnimation 510ms ease-in" };

  const MoreModalContent = (
    <MobilePortalWrap>
      {shouldRenderChild && (
        <MoreModalWrap
          style={isMounted ? mountedStyle : unmountedStyle}
          dominantColors={getDominantColors(currentTrack)}
        >
          <FlexibleDiv className="top__layer">
            <FlexibleDiv className="track__cover__art">
              <div className="cover__art">
                <img src={metadata?.coverArt?.url} alt="cover" />
              </div>
              <div className="title__and__album">
                <p className="title">
                  {truncate(cleanSongTitle(metadata?.title), 30)}
                </p>
                <div className="artist">
                  {getDisplayArtist(currentTrack, navigate)}
                </div>
              </div>
            </FlexibleDiv>

            <div className="close__icon__wrap" onClick={closeModal}>
              <CloseOutlineIcon />
            </div>
          </FlexibleDiv>

          <FlexibleDiv className="action__list__layer">
            <ul className="action__list">
              <li
                className="action__item"
                onClick={() => addToQueue(dispatch, currentTrack)}
              >
                <AddToQueueIcon color="var(--white)" width={18} />
                <span>Add to Queue</span>
              </li>
              <li className="action__item" onClick={handleGoToAlbum}>
                <GotoAlbumIcon color="var(--white)" width={18} />
                <span>Go to Album</span>
              </li>
              <li className="action__item" onClick={handleGoToArtistPage}>
                <GotoArtistPageIcon color="var(--white)" width={18} />
                <span>Go to Artist Page</span>
              </li>
              <li className="action__item" onClick={handleShare}>
                <ShareIcon color="var(--white)" width={18} />
                <span>Share Playlist</span>
              </li>
            </ul>
          </FlexibleDiv>
        </MoreModalWrap>
      )}

      {isSharing.open && (
        <ShareModalMobile
          href={isSharing.href}
          open={isSharing.open}
          handleClose={() => {
            setIsSharing({ open: false, href: "" });
            closeModal();
          }}
        />
      )}
    </MobilePortalWrap>
  );

  return isMobile ? createPortal(MoreModalContent, document.body) : null;

  function closeModal() {
    setIsMounted(false);
    setTimeout(() => {
      handleClose();
    }, 500);
  }

  function handleGoToAlbum() {
    const albumId = currentTrack?.track?.album || currentTrack?.metadata?.album;

    if (albumId) {
      navigate(`/playlist/${albumId}?t=alb`, {
        state: {
          module: "album",
        },
      });
    } else {
      // show notification for no album exists.
      console.log(currentTrack, "ct ");
    }
  }

  function handleGoToArtistPage() {
    const artist = getArtist(currentTrack);

    if (artist?.artist?.id) {
      navigate(`/artist/${artist?.artist?.id}`);
    } else {
      // show notification for no album exists.
      console.log(currentTrack, "ct ");
    }
  }

  function handleShare() {
    const protocol = Config.prod
      ? "https://stage.udux.com/playlist"
      : "http://localhost:3000/playlist";

    const href = `${protocol}/${playingList?.id}?t=pl&sid=`;

    setIsMounted(false);

    setIsSharing({
      open: true,
      href,
    });
  }
};
