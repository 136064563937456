import React, { useState, useEffect } from "react";
import {
  FormItem,
  IconWrap,
  TextFieldWrapper,
  FormItemWrap,
} from "./textfield.styles";
import { FiCheck as Check } from "react-icons/fi";
import { MdCancel as Cancel } from "react-icons/md";
import showIcon from "../../../assets/images/Auth/eye-show.png";
import hideIcon from "../../../assets/images/Auth/eye-hide.png";

export const TextField = ({ ...props }) => {
  const [status, setStatus] = useState();
  const [value, setValue] = useState("");

  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    let numberInput = document.querySelector(".numberInput");

    if (numberInput) {
      numberInput.addEventListener("keypress", function (evt) {
        if (
          (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
          evt.which > 57
        ) {
          evt.preventDefault();
        }
      });
    }
  }, []);

  return (
    <FormItemWrap
      className="form-item-container"
      height={props.height}
      width={props.width}
      {...props}
    >
      <TextFieldWrapper status={status}>
        {!props.validator ? (
          <FormItem
            name={props.name}
            rules={props.rules}
            props={props}
            lightMask={props.lightMask}
            {...props}
          >
            {props.textarea ? (
              <textarea placeholder={props.placeholder} />
            ) : (
              <input
                placeholder={props.placeholder}
                type={
                  props.type === "password"
                    ? passwordType
                    : props.type || "text"
                }
                className={props.type === "number" ? "numberInput" : ""}
                disabled={props.editLocked}
                onChange={props.onChange}
              />
            )}
          </FormItem>
        ) : (
          <FormItem
            name={props.name}
            rules={[
              {
                validator: (rules, value) =>
                  props.validator(rules, value, setStatus),
              },
            ]}
            lightMask={props.lightMask}
            {...props}
          >
            <input
              onChange={(e) =>
                setValue(e.target.value) &&
                props.validator(e, e?.target?.value, setStatus)
              }
              onBlur={(e) => props.validator(e, e?.target?.value, setStatus)}
              placeholder={props.placeholder}
              type={
                props.type === "password" ? passwordType : props.type || "text"
              }
              className={props.type === "number" ? "numberInput" : ""}
            />
          </FormItem>
        )}

        {value && props.type === "password" && !props.noPasswordIcon && (
          <div className="password__eye">
            {passwordType === "password" ? (
              <img
                onClick={() => setPasswordType("type")}
                src={showIcon}
                alt="password show icon"
              />
            ) : (
              <img
                onClick={() => setPasswordType("password")}
                src={hideIcon}
                alt="password hide icon"
              />
            )}
          </div>
        )}

        <IconWrap height={props.height}>
          {status?.err && <Cancel className="cancel" />}
          {status?.success && (
            <Check className="check" color="var(--uduxYellowPrimary)" />
          )}
        </IconWrap>
      </TextFieldWrapper>
      <div className="input_error_text">
        <p className="">{status?.err}</p>
      </div>
    </FormItemWrap>
  );
};
