export const MoreIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "14"}
      height={height || "20"}
      viewBox="0 0 15 30"
      fill="none"
      {...props}
    >
      <g id="more-icon" data-name="More Icon" transform="translate(4 6)">
        <path
          d="M2.15625 1.4375C1.7593 1.4375 1.4375 1.7593 1.4375 2.15625V3.59375C1.4375 3.9907 1.7593 4.3125 2.15625 4.3125H3.59375C3.9907 4.3125 4.3125 3.9907 4.3125 3.59375V2.15625C4.3125 1.7593 3.9907 1.4375 3.59375 1.4375H2.15625ZM2.15625 0H3.59375C4.78461 0 5.75 0.965386 5.75 2.15625V3.59375C5.75 4.78461 4.78461 5.75 3.59375 5.75H2.15625C0.965386 5.75 0 4.78461 0 3.59375V2.15625C0 0.965386 0.965386 0 2.15625 0ZM2.15625 10.0625C1.7593 10.0625 1.4375 10.3843 1.4375 10.7812V12.2188C1.4375 12.6157 1.7593 12.9375 2.15625 12.9375H3.59375C3.9907 12.9375 4.3125 12.6157 4.3125 12.2188V10.7812C4.3125 10.3843 3.9907 10.0625 3.59375 10.0625H2.15625ZM2.15625 8.625H3.59375C4.78461 8.625 5.75 9.59039 5.75 10.7812V12.2188C5.75 13.4096 4.78461 14.375 3.59375 14.375H2.15625C0.965386 14.375 0 13.4096 0 12.2188V10.7812C0 9.59039 0.965386 8.625 2.15625 8.625ZM2.15625 18.6875C1.7593 18.6875 1.4375 19.0093 1.4375 19.4062V20.8438C1.4375 21.2407 1.7593 21.5625 2.15625 21.5625H3.59375C3.9907 21.5625 4.3125 21.2407 4.3125 20.8438V19.4062C4.3125 19.0093 3.9907 18.6875 3.59375 18.6875H2.15625ZM2.15625 17.25H3.59375C4.78461 17.25 5.75 18.2154 5.75 19.4062V20.8438C5.75 22.0346 4.78461 23 3.59375 23H2.15625C0.965386 23 0 22.0346 0 20.8438V19.4062C0 18.2154 0.965386 17.25 2.15625 17.25Z"
          fill={color || "#D9D9D9"}
        />
      </g>
    </svg>
  )
}
