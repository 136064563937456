import {
  EmbeddedPlayerCard,
  EmbeddedPlayerWrapper,
} from "./embeddedPlayer.styles";
import { BsArrowRight as ArrowRight } from "react-icons/bs";
import {
  NextIcon,
  PlayIcon,
  PrevoiusIcon,
  RepeatIcon,
} from "assets/images/player/SVGExports";
import { FlexibleDiv, Loader, ProgressSlider } from "components";
import {
  PauseIcon,
  PauseOutlineIcon,
  PlayOutlineIcon,
  ShuffleIcon,
} from "assets";
import { useCallback, useEffect, useRef, useState } from "react";
import { Types, useMainContext } from "context";
import { useLocation } from "react-router-dom";
import { findIndex, isEmpty } from "lodash";
import { getTrackById, getWidgetPlaylist } from "network";
import {
  changeRange,
  getDisplayArtist,
  pauseAudio,
  playAudio,
  playNextTrack,
  playPreviousTrack,
  repeatTrack,
  shuffleTrack,
  truncate,
  unShuffleTrack,
} from "utils";
import { useQuery } from "react-query";
import { LoaderIcon } from "react-hot-toast";

const EmbeddedPlayer = () => {
  const progressBarRef = useRef();
  const [duration, setDuration] = useState(0);
  const { isLoading: playlistLoading, data: playlist } = useQuery(
    ["playlist"],
    () => getWidgetPlaylist(window.location.search.replace("?artistID=", ""))
  );
  const [currentTrackToPlayId, setTrackToPlayId] = useState(
    playlist?.data?.tracks[0]?.track?.id
  );

  const {
    state: { player, app },
    playerRef,
    dispatch,
  } = useMainContext();

  const { currentTrack, isPlaying, shuffle, currentTime, repeat, playingList } =
    player;
  const shouldGetTrack = isEmpty(currentTrack) && Boolean(currentTrackToPlayId);

  useEffect(() => {
    dispatch({
      type: Types.SET_CURRENT_PLAYING,
      payload: playlist?.data?.tracks[0],
    });
    dispatch({
      type: Types.REPLACE_QUEUED_TRACKS,
      payload: playlist?.data?.tracks,
    });

    dispatch({
      type: Types.SET_PLAYING_PLAYLIST,
      payload: playlist?.data,
    });
  }, [shouldGetTrack, dispatch, playlist]);

  const setMusicToPlay = (track) => {
    dispatch({
      type: Types.SET_CURRENT_PLAYING,
      payload: track,
    });
    playAudio(playerRef, dispatch);
  };

  const playMusic = () => {
    if (!currentTrack?.length) {
      dispatch({
        type: Types.SET_CURRENT_PLAYING,
        payload: playlist.data.tracks[0],
      });
    }
    playAudio(playerRef, dispatch);
  };

  function getUpNext() {
    if (playingList?.tracks?.length > 0) {
      const currentIdx = findIndex(playingList?.tracks, currentTrack);
      return playingList?.tracks[currentIdx + 1];
    } else {
      return currentTrack;
    }
  }

  const upNext = getUpNext();

  useEffect(() => {
    const seconds = Math.floor(playerRef?.current?.duration);
    setDuration(seconds);
  }, [
    playerRef?.current?.loadedmetadata,
    playerRef?.current?.readyState,
    playerRef,
  ]);

  return (
    <EmbeddedPlayerWrapper
      className="section_wrap"
      id="player__section"
      projectColor={playlist?.data?.coverArtDominantColors[0]}
      gradientTopColor={playlist?.data?.coverArtDominantColors[0]}
      gradientMiddleColor={playlist?.data?.coverArtDominantColors[1]}
      gradientBottomColor={playlist?.data?.coverArtDominantColors[2]}
    >
      {playlistLoading ? (
        <FlexibleDiv height="100%">
          <LoaderIcon />
        </FlexibleDiv>
      ) : (
        <>
          {playlist ? (
            <FlexibleDiv className="playersection__wrap">
              <FlexibleDiv className="songcard__wrap">
                {playlist?.data?.tracks?.map((item, idx) => (
                  <EmbeddedPlayerCard
                    key={idx + item.title}
                    margin={idx < playlist?.data?.tracks.length - 1}
                    gradientTopColor={
                      item?.track?.metadata?.coverArtDominantColors[0]
                    }
                    gradientMiddleColor={
                      item?.track?.metadata?.coverArtDominantColors[1]
                    }
                    gradientBottomColor={
                      item?.track?.metadata?.coverArtDominantColors[2]
                    }
                  >
                    <FlexibleDiv className="songcard__top">
                      <h1 className="song__year">{item.year}</h1>
                      <h3 className="song__detail">
                        {item.track.metadata.title}
                      </h3>
                      <h3 className="song__detail">
                        {
                          item?.track?.metadata?.displayArtists[0]?.artist
                            ?.stageName
                        }
                      </h3>
                      {isPlaying &&
                      idx === playlist?.data?.tracks?.indexOf(currentTrack) ? (
                        <FlexibleDiv
                          className="pauseicon mobilecontrol__icons"
                          onClick={() => pauseAudio(playerRef, dispatch)}
                        >
                          <PauseOutlineIcon width={40} height={40} />
                        </FlexibleDiv>
                      ) : (
                        <FlexibleDiv
                          className="playicon mobilecontrol__icons"
                          onClick={() => setMusicToPlay(item)}
                        >
                          <PlayOutlineIcon width={40} height={40} />
                        </FlexibleDiv>
                      )}
                    </FlexibleDiv>
                    <FlexibleDiv
                      className="songcard__bottom"
                      onClick={() => setMusicToPlay(item)}
                    >
                      <FlexibleDiv>
                        <img src={item?.track?.metadata.coverArt?.url} alt="" />
                      </FlexibleDiv>
                    </FlexibleDiv>
                  </EmbeddedPlayerCard>
                ))}
              </FlexibleDiv>

              <FlexibleDiv className="playersection__footer">
                <p className="footer__songtitle">
                  {currentTrack?.track?.metadata.title}
                  <br />
                  {
                    currentTrack?.track?.metadata?.displayArtists[0].artist
                      .stageName
                  }{" "}
                </p>

                <FlexibleDiv
                  className="footer__center__controls__wrap"
                  flexDir="column"
                  width="45%"
                >
                  <FlexibleDiv
                    className="controls__main"
                    width="270px"
                    justifyContent="space-between"
                  >
                    <div
                      className="repeat__icon"
                      onClick={() =>
                        repeatTrack(Types.REPEAT, dispatch, repeat)
                      }
                    >
                      <RepeatIcon
                        color={repeat ? "var(--yellowPrimary)" : "#fff"}
                        height="100%"
                      />
                    </div>
                    <div
                      onClick={() => {
                        playPreviousTrack(dispatch, {
                          track: currentTrack,
                        });
                      }}
                    >
                      <PrevoiusIcon width={24} />
                    </div>

                    {isPlaying ? (
                      <FlexibleDiv
                        className="pauseicon"
                        onClick={() => pauseAudio(playerRef, dispatch)}
                      >
                        <PauseOutlineIcon width={40} height={40} />
                      </FlexibleDiv>
                    ) : (
                      <FlexibleDiv className="playicon" onClick={playMusic}>
                        <PlayOutlineIcon width={40} height={40} />
                      </FlexibleDiv>
                    )}

                    <div
                      onClick={() => {
                        playNextTrack(dispatch, {
                          track: currentTrack,
                        });
                      }}
                    >
                      <NextIcon width={24} />
                    </div>

                    <div
                      className="shuffle__icon"
                      onClick={() =>
                        shuffle
                          ? unShuffleTrack(
                              playlist.data.tracks,
                              dispatch,
                              shuffle
                            )
                          : shuffleTrack(
                              playlist.data.tracks,
                              dispatch,
                              shuffle
                            )
                      }
                    >
                      <ShuffleIcon
                        color={shuffle ? "var(--yellowPrimary)" : "#fff"}
                        height="100%"
                      />
                    </div>
                  </FlexibleDiv>
                  <FlexibleDiv
                    className="songprogress__bar"
                    justifyContent="flex-start"
                  >
                    <ProgressSlider
                      value={(currentTime / duration) * 100}
                      ref={progressBarRef}
                      tooltip={false}
                      onChange={(e) =>
                        changeRange(
                          e,
                          playerRef,
                          Types.CURRENT_PLAYING_TIME,
                          duration,
                          dispatch
                        )
                      }
                      range={true}
                      isMobile
                    />
                    <FlexibleDiv
                      width={`${(currentTime / duration) * 100}%`}
                      className="trackeInner"
                    ></FlexibleDiv>
                  </FlexibleDiv>
                </FlexibleDiv>
                <p className="footer__songtitle songtitle__right">
                  {truncate(upNext?.track?.metadata?.title, 18)}
                  <br />
                  {getDisplayArtist(upNext)}
                </p>
              </FlexibleDiv>
            </FlexibleDiv>
          ) : (
            <FlexibleDiv className="connect__playlist">
              <h1 className="rightsection__title">No Active Playlist</h1>
            </FlexibleDiv>
          )}
        </>
      )}
    </EmbeddedPlayerWrapper>
  );
};

export default EmbeddedPlayer;
