import { isEmpty } from "lodash"

export function getArtist(currentTrack) {
  if (!isEmpty(currentTrack)) {
    return currentTrack?.track
      ? currentTrack?.track?.metadata?.displayArtists?.find(
          da => da.isMainArtist
        )
      : currentTrack?.metadata?.displayArtists?.find(da => da.isMainArtist)
  }
}
