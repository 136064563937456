import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/styles";

export const Container = styled(FlexibleDiv)`
  width: 100%;
  padding-bottom: 5%;

  .form_box {
    width: 100%;
    height: 100%;

    .btn_box {
      margin-top: 4rem;
    }
  }

  .existing__user {
    margin-top: 1.5rem;
    color: var(--white);
    opacity: 0.7;
    text-decoration: underline;
    font-size: 12px;
  }

  @media screen and (max-width: 600px) {
    .form_box {
      .input {
        margin: 10% 0%;
      }
    }
  }
`;
