import styled, { keyframes } from "styled-components";

const rotate = keyframes`
    from{
        transform:rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
`;

export const XLoader = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-width: ${({ size }) => size ?? "1.4rem"};
  max-height: ${({ size }) => size ?? "1.4rem"};
  margin: 1rem;
  border: ${({ color }) => color ?? "var(--uduxYellowPrimary)"}
    ${({ border }) => border ?? "0.1rem"} solid;
  border-bottom-color: rgba(0, 0, 0, 0);

  animation: 1.8s ${rotate} linear forwards infinite;
`;

export const LoaderWrapper = styled.div`
  height: ${({ height }) => (height ? height : "100%")};
  width: 100%;
`;
