export const NextOutlineIcon = ({ color, width, height, ...props }) => (
  <svg
    id="next-outline-icon"
    data-name="Next Outline Icon"
    xmlns="http://www.w3.org/2000/svg"
    width={width || "22"}
    height={height || "22"}
    viewBox="0 0 40 40"
    {...props}
  >
    <g id="next" transform="translate(4 3)">
      <path
        id="Path_1555"
        data-name="Path 1555"
        d="M232.077,149.023l11.742-8.373-11.742-8.374Z"
        transform="translate(-226.56 -123.324)"
        fill={color || "#eaeaea"}
      />
      <g id="Group_9949" data-name="Group 9949" transform="translate(0)">
        <path
          id="Path_1556"
          data-name="Path 1556"
          d="M217.407,135.419a1.064,1.064,0,0,0,.618-.2l11.742-8.374a1.065,1.065,0,0,0,0-1.734l-11.742-8.374a1.065,1.065,0,0,0-1.683.867v16.747a1.065,1.065,0,0,0,1.065,1.065Zm9.908-9.438-8.843,6.306V119.674Z"
          transform="translate(-211.89 -108.654)"
          fill={color || "#eaeaea"}
        />
        <path
          id="Path_1557"
          data-name="Path 1557"
          d="M25.968,7.887a1.065,1.065,0,0,1,1.065,1.065V31.619L48.912,17.326,25.386,1.956A1.065,1.065,0,0,1,26.55.173L51.442,16.434l0,0a1.063,1.063,0,0,1,.1.073l.022.02c.023.02.045.041.066.063l.027.03q.029.033.055.067l.02.027a1.063,1.063,0,0,1,.115.213l.009.026c.011.03.021.059.029.09l.009.034c.007.03.013.06.017.09,0,.011,0,.022.005.033a1.084,1.084,0,0,1,.008.124V31.208h2.13V3.521h-2.13v8.093a1.065,1.065,0,0,1-2.13,0V2.456a1.065,1.065,0,0,1,1.065-1.065h4.259a1.065,1.065,0,0,1,1.065,1.065V32.273a1.065,1.065,0,0,1-1.065,1.065H50.859a1.065,1.065,0,0,1-1.065-1.065V19.293L26.55,34.478a1.065,1.065,0,0,1-1.647-.892V8.952A1.065,1.065,0,0,1,25.968,7.887Z"
          transform="translate(-24.903 0)"
          fill={color || "#eaeaea"}
        />
      </g>
    </g>
  </svg>
);
