import styled from "styled-components";
import { FlexibleDiv } from "components";

export const PaymentLoginWrapper = styled(FlexibleDiv)`
  width: 100%;
  padding-bottom: 5%;
  align-items: center;
  height: 100%;

  .form__box {
    width: 100%;
    max-height: 450px;

    .tac {
      margin-top: 1rem;
      color: #ffffff;
      opacity: 0.7;
      font-size: 0.8rem;

      @media (max-width: 376px) {
        font-size: 0.7rem;
      }

      a {
        /* font-size: 0.8rem; */
        color: var(--dampWhite);
        text-decoration: underline;
      }
    }
  }

  .action_text_box {
    margin-top: 2.5%;
  }

  .action_text,
  .action_text_one {
    color: var(--uduWhiteSecondary);
    font-size: ${({ checkHeight }) => {
      return checkHeight ? "20px" : "15px";
    }};
    font-weight: 600;
    margin-top: 6%;

    :hover {
      color: var(--uduxYellowPrimary);
      transition: all 0.4s;
    }
  }

  .textfield__wrap {
    padding-bottom: 2.5rem;
  }

  .btn__box {
    margin-top: 5%;

    span {
      font-size: 0.8rem;
    }
  }

  .existing__user {
    margin-top: 1.5rem;
    color: var(--white);
    opacity: 0.7;
    text-decoration: underline;
    font-size: 12px;
  }

  .tac__text {
    margin-left: 5px;
    font-size: 10px;
  }

  @media screen and (max-width: 600px) {
    .action_text_box {
      margin-top: 2%;
    }

    .action_text,
    .action_text_one {
      margin-top: 12%;
      font-size: ${({ checkHeight }) => {
        return checkHeight ? "15px" : "15px";
      }};
    }
  }

  @media screen and (max-height: 896px) {
    .existing__user {
      margin-top: 0.5rem;
      font-size: 12px;
    }
  }
`;
