import { instance } from "./axios"

export const getPlayList = async () => {
  const { data } = await instance.get(`/streaming/tracks`)

  return data
}

export const getPlaylistCategories = async category => {
  const { data: categories } = await instance.get("/streaming/categories")

  const singleCategory = categories.data.find(({ name }) => name === category)

  const { data } = await instance.get(
    `/streaming/playlists?category=${singleCategory.id}`
  )

  return data
}

export const getAllUserPlaylists = async () => {
  const { data } = await instance.get(`/streaming/albums`)

  return data
}

export const getArtistPlaylists = async payload => {
  const { data } = await instance.get(
    `/release-resources?displayArtist=${payload.id}`
  )

  return data
}

export const addToFavorites = async payload => {
  const { data } = await instance.post(`/social/reactions`, payload)

  return data
}
export const removeFromFavorites = async payload => {
  const { data } = await instance.delete(`/social/reactions`, {
    data: payload,
  })

  return data
}

export const addReaction = async payload => {
  const { data } = await instance.post(`/social/reactions`, payload)

  return data
}

export const removeReaction = async payload => {
  const { data } = await instance.delete(`/social/reactions`, { data: payload })

  return data
}

export const getFavoriteStatus = async payload => {
  const { data } = await instance.get(
    `/social/reactions?entity=${payload.entity}&entityType=${payload.entityType}&status=ACTIVE`
  )

  return data
}

export const getTopTracks = async payload => {
  const { data } = await instance.get(`/streaming/artists/${payload}/tracks`)

  return data
}

export const getPlaylistFeatArtist = async artistId => {
  const { data } = await instance.get(
    `/streaming/playlists/features-artist/${artistId}`
  )

  return data.data
}
