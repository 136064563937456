export function cleanSongTitle(entity) {
  if (entity) {
    if (entity.includes("(")) {
      const words = entity.split("(");
      return words[0].replace(/[^a-zA-Z ]/g, " ");
    }

    // This should always be the last check
    if (entity.endsWith(".mp3") || entity.endsWith(".mp4")) {
      const words = entity.split(".mp");
      return words[0].replace(/[^a-zA-Z ]/g, " ");
    }

    return entity;
  }
}
