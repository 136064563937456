import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import BackgroundImage from "../../../assets/images/payment/ChoosePlatformImage.png";
import authLogin from "../../../assets/images/payment/authLogin.png";
import authSignUp from "../../../assets/images/payment/signUpImage.png";
import { ChoosePlatform } from "../../../components/layouts/AuthLayout-Platform/ChoosePlatform";
import { ActionBox } from "components/lib/ActionBox/ActionBox";
import { InputIcon, UduXIcon } from "assets/images/Auth";

const PaymentAuthentication = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const plan = params.get("type");
  const mode = params.get("mode");
  const location = useLocation();
  const [activeSlide, setActiveSlide] = React.useState(1);
  const handleActionTileHover = (slideIndex) => {
    setActiveSlide(slideIndex);
  };
  const cardsObjects = [
    {
      redirectURL: () =>
        navigate(`/payment/register?type=${plan}&mode=${mode}`),
      textURL: `/payment/register?type=${plan}&mode=${mode}`,
      boxIcon: <UduXIcon />,
      heroText: "Sign up for",
      pageHoverImage: authLogin,
      spanText: " Free",
    },
    {
      redirectURL: () => navigate(`/payment/login?type=${plan}&mode=${mode}`),
      textURL: `/payment/login?type=${plan}&mode=${mode}`,
      boxIcon: <InputIcon />,
      heroText: "Existing User?",
      pageHoverImage: authSignUp,
      spanText: " Login",
    },
  ];

  return (
    <ChoosePlatform
      title="Never Miss a Beat"
      galleryImages={cardsObjects}
      activeSlide={activeSlide}
      leftColor="#00725E"
      rightImage={BackgroundImage}
      backURL={`/payment/plan?type=${plan}&mode=${mode}`}
    >
      {cardsObjects.map((card, idx) => (
        <ActionBox
          key={idx}
          redirectURL={card.redirectURL}
          setHoverImage={() => handleActionTileHover(idx)}
          textURL={card.textURL}
          boxIcon={card.boxIcon}
          heroText={card.heroText}
          spanText={card.spanText}
        />
      ))}
    </ChoosePlatform>
  );
};

export default PaymentAuthentication;
