import { useState } from "react"
import ReactPhoneInput from "react-phone-input-2"
import { validatePhoneNumber } from "utils"
import { FlexibleDiv } from "../Box"
import { PhoneInput2Wrap, PhoneInputWrap } from "./phoneNumberField.styles"

export const PhoneInput = ({
  name,
  label,
  rules,
  value,
  onChange,
  validator,
  bordered,
  disabled,
  onSetCountryCode,
  ...props
}) => {
  const [status, setStatus] = useState()

  props.hasError = Boolean(status?.err)
  props.hasSuccess = Boolean(status?.success)

  return (
    <PhoneInputWrap {...props}>
      <FlexibleDiv className="label">
        <label htmlFor="countryCode">Phone Number</label>
      </FlexibleDiv>

      <PhoneInput2Wrap disabled={disabled} {...props}>
        <ReactPhoneInput
          country={"ng"}
          enableSearch
          value={value}
          disabled={disabled}
          isValid={checkValid}
          inputProps={{
            name: "phoneNumber",
          }}
          areaCodes={"234"}
          enableAreaCodeStretch
          preferredCountries={["ng"]}
          searchClass="search-input"
          dropdownClass="dropdown-select"
          onChange={(phone, data) => {
            onChange(phone)
            onSetCountryCode(`+${data.dialCode}`)
            validatePhoneNumber(undefined, phone, setStatus)
          }}
          onBlur={({ target }) => {
            validatePhoneNumber(undefined, target.value, setStatus)
          }}
        />
      </PhoneInput2Wrap>
      <p className="input__error__text">{status?.err}</p>
    </PhoneInputWrap>
  )

  function checkValid(value, country) {
    if (value.match(/12345/)) {
      return "Invalid value: " + value + ", " + country.name
    } else if (value.match(/1234/)) {
      return false
    } else {
      return true
    }
  }
}
