import styled from "styled-components"
import { FlexibleDiv } from "components"

export const AboutPaymentWrapper = styled(FlexibleDiv)`
  height: 100vh;
  width: 100%;
  padding: 6% 3.5%;
  /* padding-top: 100px; */

  .left__section__wrapper {
    .about__content__wrapper {
      justify-content: flex-start;
      height: 75%;
      width: 70%;
      flex-wrap: nowrap;

      .header__text {
        color: var(--uduxWhitePrimary);
        width: 100%;
        font-family: "Baguede";
        font-size: 3rem;
        -webkit-text-stroke: 0.7px var(--uduxWhitePrimary);
        margin-bottom: 1rem;
      }

      span {
        color: #ffa300;
        font-family: "Baguede";
        -webkit-text-stroke: 0.7px #ffa300;
      }

      p {
        margin-bottom: 1rem;
        font-family: "Bw ModelicaMedium";
        width: fit-content;
        font-size: 0.85rem;
        color: #fdfeff;
      }
    }
  }

  .right__section__wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    ::after {
      content: "";
      position: absolute;
      bottom: -15px;
      right: 0;
      width: 70%;
      height: 15px;
      background-color: var(--yellowPrimary);
      transition: width 0.3s ease-in-out;
    }

    :hover:after {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    position: relative;
    padding: 0;
    padding-top: 50px;
    padding-bottom: 120px;
    margin: 0;
    align-items: center;

    .left__section__wrapper {
      z-index: 1;
      padding: 0;
      width: 100%;
      height: 100%;
      padding: 0 4%;
      padding-top: 5%;
      justify-content: start;

      @media (max-width: 281px) {
        padding: 0;
      }

      .about__content__wrapper {
        height: fit-content;
        width: 100%;
        padding: 0 9%;

        /* @media (max-width: 376px) {
          height: 60%;
        }

        @media (max-width: 281px) {
          height: 63%;
        } */

        .header__text {
          font-size: 2rem;
          -webkit-text-stroke: 0.4px #fdfeff;

          @media (max-width: 376px) {
            font-size: 1.8rem;
          }

          @media (max-width: 281px) {
            margin: 0;
          }
        }

        p {
          text-align: center;
          font-size: 1rem;
          -webkit-text-stroke: 0.2px #fdfeff;

          @media (max-width: 376px) {
            font-size: 0.75rem;
          }

          @media (max-width: 281px) {
            font-size: 0.65rem;
          }
        }
      }
    }

    .right__section__wrapper {
      z-index: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      position: absolute;
      top: 0px;
      padding-top: 70px;
      padding-bottom: 120px;
      opacity: 0.6;
    }
  }

  @media (min-width: 601px) and (max-width: 950px) {
    position: relative;
    padding: 0;
    padding-top: 100px;
    margin: 0;
    align-items: center;

    .left__section__wrapper {
      z-index: 1;
      padding: 0;
      width: 100%;
      height: 100%;
      padding: 0 4%;
      justify-content: start;

      .about__content__wrapper {
        height: 60%;
        /* padding-top: 5%; */
        width: 80%;
        height: fit-content;
        justify-content: space-evenly;

        p {
          text-align: center;
          font-size: 1.6em;
          line-height: 2.9rem;

          @media (min-width: 700px) and (max-width: 815px) {
            font-size: 1.4em;
          }

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 1.6rem;
          }
        }

        .header__text {
          font-size: 2.8rem;
          height: max-content;
          margin-bottom: 1em;
          -webkit-text-stroke-width: 1.2px;

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 3rem;
          }
        }
      }
    }

    .right__section__wrapper {
      z-index: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      position: absolute;
      top: 0px;
      padding-top: 100px;
      padding-bottom: 120px;
      opacity: 0.6;
    }
  }
`
