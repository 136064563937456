import styled from "styled-components";
import drumsBG from "../../../../assets/images/payment/Drum-bg-pattern.svg";

export const PALWrap = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: opacity(20%);
  padding: 2rem;

  @media only screen and (min-width: 500px) {
    padding: 3%;
  }

  @media only screen and (min-width: 1050px) {
    /* padding: 3%; */
    padding: ${({ checkout }) => (checkout ? "3%" : "0")};
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: url(${({ bg }) => bg ?? drumsBG});
    filter: ${({ checkout }) => (checkout ? "opacity(1)" : "opacity(0.5)")};
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1050px) {
  }

  .image-container {
    /* padding: 4rem 0; */
    display: none;
    width: 35%;

    @media only screen and (min-width: 1050px) {
      display: block;
      height: 100%;
    }

    @media only screen and (min-width: 1200px) {
      width: 50%;
    }

    @media only screen and (min-width: 1200px) {
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .content-container {
    width: 100%;
    height: 100%;
    background: var(--uduxGreenPrimary);
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 2rem;

    ::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (min-width: 1050px) {
      width: ${({ checkout }) => (checkout ? "100%" : "65%")};
      height: ${({ checkout }) => (checkout ? "100%" : "90%")};
    }

    @media only screen and (min-width: 1200px) {
      width: ${({ checkout }) => (checkout ? "100%" : "50%")};
    }

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: ${({ checkout }) => (checkout ? "absolute" : "relative")};
      left: 0;
      top: 0;
      margin-bottom: 5.2rem;
      width: 100%;
      margin-top: 2rem;

      @media only screen and (min-width: 1050px) {
        justify-content: flex-end;
        margin-bottom: ${({ checkout }) => (checkout ? "2rem" : "7rem")};
      }

      .back {
        width: 2rem;
        height: 2rem;
        stroke-width: 0.1rem;
        position: absolute;
        left: 2rem;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;

        @media only screen and (min-width: 800px) {
          width: 2.5rem;
          height: 2.5rem;
        }

        &.mobile {
          color: var(--uduxCreamPrimary);
          display: ${({ checkout }) => (checkout ? "none" : "inline-block")};
          @media only screen and (min-width: 1050px) {
            display: none;
          }
        }
        &.desktop {
          display: none;
          top: 1.5rem;
          left: 3rem;
          color: var(--uduxYellowPrimary);
          transition: all 0.3s ease;

          &:hover,
          &:active,
          &:focus {
            transform: scale(1.1);
          }
          @media only screen and (min-width: 1050px) {
            display: ${({ checkout }) => (checkout ? "none" : "inline-block")};
          }
        }
      }

      .logo {
        width: 5rem;

        @media only screen and (min-width: 1050px) {
          width: 6.5rem;
          position: absolute;
          right: 3rem;
          top: -1rem;
          fill: var(--uduxCyanPrimary);
        }
      }
    }
  }

  .content {
    display: flex;

    flex-direction: column;
    align-items: center;

    /* padding: ${({ checkout }) =>
      checkout ? "6rem 2rem" : "2rem 7%"}; //2rem */
    width: 85%;
    max-width: 45rem;
    /* max-width: ${({ checkout }) => (checkout ? "65rem" : "100%")}; */
    /* flex-grow: ${({ checkout }) => (checkout ? "1" : "0")}; */
    justify-content: flex-start;

    /* @media only screen and (min-width: 500px) {
      padding: ${({ checkout }) =>
      checkout ? "4rem 2rem" : "2rem 10%"}; //2rem 4rem
    }

    @media only screen and (min-width: 800px) {
      padding: ${({ checkout }) =>
      checkout ? "3rem 2rem" : "3rem 12%"}; //3rem 4rem
    } */

    & > h4 {
      margin-bottom: 2rem;
      text-align: center;
      font-weight: 700;
      color: ${({ color }) => color};
      font-size: 2.2rem;

      @media only screen and (min-width: 600px) {
        font-size: 2.4rem;
      }

      @media only screen and (min-width: 1050px) {
        font-size: 2.6rem;
      }
    }

    .title-p {
      text-align: center;
      font-size: 1rem;
      @media only screen and (min-width: 600px) {
        font-size: 1.2rem;
      }

      @media only screen and (min-width: 1050px) {
        font-size: 1.4rem;
      }
    }

    .cyan-p {
      color: var(--uduxCyanPrimary);
    }
  }

  h4,
  h2 {
    color: var(--uduxWhitePrimary);
  }
`;
