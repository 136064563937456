export const RepeatOutlineIcon = ({
  color,
  width,
  height,
  fullScreen,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "25"}
    height={height || "30"}
    viewBox="0 0 30 40"
    {...props}
  >
    {fullScreen ? (
      <g id="repeat-icon" transform="translate(2 4)">
        <path
          id="Path_1598"
          data-name="Path 1598"
          d="M5.883,15.342H16.931a1.7,1.7,0,0,0,1.7-1.7V10.04h2.661v3.605A4.367,4.367,0,0,1,16.931,18H5.883l1.4,1.4L5.406,21.288.79,16.673l4.616-4.616,1.881,1.881ZM15.883,0,14,1.881l1.4,1.4H4.357A4.367,4.367,0,0,0,0,7.643v3.605H2.661V7.643a1.7,1.7,0,0,1,1.7-1.7H15.405L14,7.35l1.881,1.881L20.5,4.616Z"
          fill="#f5cea4"
          fill-rule="evenodd"
        />
        <path
          id="Path_1599"
          data-name="Path 1599"
          d="M38.7,4.616H19.433a4.345,4.345,0,0,1,3.129-1.331H33.609l-1.4-1.4L34.087,0ZM18.994,16.673l4.616,4.616,1.881-1.881-1.4-1.4H35.135a4.345,4.345,0,0,0,3.129-1.331Z"
          transform="translate(-18.204)"
          fill="#fff"
          fill-rule="evenodd"
        />
      </g>
    ) : (
      <g id="repeat" transform="translate(2 8)">
        <path
          id="Path_1596"
          data-name="Path 1596"
          d="M6.84,18.093H19.686a2,2,0,0,0,1.972-2V11.841h3.094v4.252a5.114,5.114,0,0,1-5.066,5.139H6.84l1.632,1.656L6.285,25.106.918,19.663l5.367-5.444,2.188,2.219ZM18.467,0,16.279,2.219l1.632,1.656H5.066A5.114,5.114,0,0,0,0,9.013v4.252H3.094V9.013a2,2,0,0,1,1.972-2H17.912L16.279,8.668l2.188,2.219,5.367-5.444Z"
          transform="translate(0)"
          fill={color || "#fefefe"}
          fill-rule="evenodd"
        />
        <path
          id="Path_1597"
          data-name="Path 1597"
          d="M41.627,5.444H19.5a4.928,4.928,0,0,1,3.593-1.569H35.778L34.166,2.219,36.326,0ZM18.994,19.663l5.3,5.444,2.161-2.219-1.612-1.656H37.53a4.928,4.928,0,0,0,3.593-1.569Z"
          transform="translate(-17.935)"
          fill={color || "#fefefe"}
          fill-rule="evenodd"
        />
      </g>
    )}
  </svg>
)
