import styled from "styled-components";
import ArtBoard from "../../../../assets/images/payment/ChoosePlatformImage.png";

export const PaymentRegisterLayoutWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--uduxYellowDeep);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .bg__wrap__main {
    width: 100%;
    height: 100%;
    background-color: inherit;
    position: absolute;

    .color__box__area,
    .art__box__area {
      height: 100%;
    }

    .color__box__area {
      width: 35%;
      background-color: #00725e;
    }
    .art__box__area {
      width: 65%;
      background-color: transparent;
      background: url(${ArtBoard}) no-repeat center / cover;
    }

    .mobile__color__box__area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mobile__art__box__area {
      width: 90%;
      height: 95%;
      background-color: transparent;
      background: url(${ArtBoard}) no-repeat center / cover;
    }
  }

  .content__box {
    z-index: 1;
    width: 94%;
    height: 90%;

    .gallery__pane,
    .activity__pane {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .gallery__pane {
      width: 38%;
      background: ${({ galleryImage }) =>
        `url(${galleryImage}) no-repeat center / cover`};
      transition: all 1.2s ease-in-out;
    }

    .activity__pane {
      width: 60%;
      padding: 5.5% 4rem;
      background-color: var(--uduxBlackPrimary);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;

      .logo__wrap {
        position: absolute;
        right: 5%;
        top: 5%;
        width: 65px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .go__back__box {
        left: 5%;
        top: 5%;
        outline: 0;
        border: none;
        display: flex;
        cursor: pointer;
        padding: 0px 4px;
        position: absolute;
        width: max-content;
        align-items: center;
        justify-content: space-around;
        background-color: transparent;

        .go__back {
          cursor: pointer;
          width: max-content;

          span {
            opacity: 0.6;
            font-size: 1.35rem;
          }
        }
      }
    }

    .content__box__layout {
      width: 90%;
      height: 100%;
    }

    .content__layout {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .title {
        color: #ff9d00;
        font-size: ${({ checkHeight }) => {
          return checkHeight ? "36px" : "22px";
        }};
        font-weight: 600;
        margin: ${({ checkHeight }) => {
          return checkHeight ? "10px 0px 3px" : "5px 0px";
        }};
        text-align: center;
        letter-spacing: -1.44px;
        font-family: "Baguede", sans-serif;
      }

      .sub__text {
        font-size: ${({ checkHeight }) => {
          return checkHeight ? "18px" : "16px";
        }};
        font-family: "Bw ModelicaBold", sans-serif;
        color: var(--uduxGrayAuth);
        text-align: center;
        opacity: 0.6;
        letter-spacing: -0.88px;
        margin: 0;
      }

      .auth__response__error__main {
        margin: 1.5rem auto 0.5rem;
        width: 100%;
      }

      .form__wrap {
        margin-top: 0rem;
        overflow: auto;
        height: 100%;
        width: 70%;

        ::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }

  @media screen and (max-width: 996px) {
    .content__box {
      height: 92%;

      .activity__pane {
        width: 92%;
        padding: 18% 1rem 0 1rem;
        background-color: var(--uduxBlackPrimary);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        @media (max-width: 500px) {
          padding: 25% 1rem 0 1rem;
        }

        .logo__wrap {
          position: initial;
          width: 65px;
          margin-bottom: 1rem;
        }
      }

      .content__box__layout {
        width: 98%;
      }

      .content__layout {
        .title {
          font-size: ${({ checkHeight }) => {
            return checkHeight ? "20px" : "18px";
          }};
          letter-spacing: -0.65px;
          font-family: "Baguede", sans-serif;
          font-weight: 900;
        }

        .sub__text {
          font-size: 12px;
          font-family: "Bw ModelicaBold", sans-serif;
        }

        .form__wrap {
          margin-top: ${({ checkHeight }) => {
            return checkHeight ? "6%" : "1%";
          }};
        }
      }
    }
  }

  @media screen and (min-width: 650px) and (max-width: 995px) {
    .content__box {
      .content__layout {
        .form__wrap {
          width: 85%;
        }
      }
      .activity__pane {
        padding: 15% 4rem 0 4rem;
      }
    }
  }

  @media screen and (min-width: 997px) and (max-width: 1230px) {
    .content__box {
      .content__layout {
        .form__wrap {
          width: 100%;
        }
      }
      .content__box__layout {
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .content__box {
      .content__layout {
        .form__wrap {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-height: 896px) {
    .content__box {
      .content__layout {
        .form__wrap {
          /* margin-bottom: 30px; */
        }
      }
    }
  }
`;
