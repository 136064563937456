import { FollowIcon, FollowingIcon, UploadCameraIcon } from "assets"
import { Button, Dropdown, FlexibleDiv, Loader } from "components"
import {
  errorResponseHandler,
  followUser,
  successResponseHandler,
  unFollowUser,
} from "network"
import React from "react"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { UPJWrap } from "./user-profile-jumbotron.styles"

export const UserProfileJumbotron = ({
  bg,
  user,
  isOwner,
  isEditMode,
  statistics,
  coverArtMenu,
  profilePicture,
  dominantColors,
  isUploadingCover,
  profileAvatarMenu,
  isUploadingProfile,
  handleClick,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 950 })
  const isTablet = useMediaQuery({ maxWidth: 1201 })
  const isDesktop = useMediaQuery({ maxWidth: 1202 })
  const { mutate: mutateFollowUser } = useMutation(followUser)
  const { mutate: mutateUnFollowUser } = useMutation(unFollowUser)

  const isFollowing = user?.userFollows || user?.profile?.userFollows

  const handleFollowUser = () => {
    mutateFollowUser(user.id, {
      onSuccess: data => successResponseHandler(data),
      onError: error => errorResponseHandler(error),
    })
  }
  const handleUnFollowUser = () => {
    mutateUnFollowUser(user.id, {
      onSuccess: data => {
        // dispatch({type: CURRENT_USER, payload: {}}) // TODO: the user object may not be the current user logged in. re-work the context and save current user as **me**
        successResponseHandler(data)
      },
      onError: error => errorResponseHandler(error),
    })
  }

  return (
    <UPJWrap bg={bg} isMobile={isMobile} dominantColors={dominantColors}>
      {isUploadingCover && (
        <div className="upload__in__progress__cover">
          <Loader variant="purple" width={100} height={120} />
        </div>
      )}

      <div className="img__bg__content">
        {isEditMode && (
          <div className={"camera__icon cover__art__dropdown"}>
            <Dropdown
              trigger="click"
              overlay={coverArtMenu}
              placement="bottomRight"
            >
              <UploadCameraIcon width="25" />
            </Dropdown>
          </div>
        )}
        <FlexibleDiv className="user__info__section">
          <aside className={`user__avatar__wrap ${isEditMode && "is__edit"}`}>
            {isEditMode && (
              <div className="camera__icon profile__avatar__dropdown">
                <Dropdown
                  overlay={profileAvatarMenu}
                  placement="bottomLeft"
                  trigger="click"
                >
                  <UploadCameraIcon width="25" />
                </Dropdown>
              </div>
            )}

            {isUploadingProfile && (
              <div className="upload__in__progress">
                <Loader variant="purple" width={100} height={120} />
              </div>
            )}

            <img
              src={profilePicture || user?.profile?.profilePicture?.url}
              alt={user?.username}
              className="user__avatar"
            />
          </aside>

          <FlexibleDiv className="user__info" flexDir="column">
            {(isMobile || isTablet) && !isOwner && (
              <FlexibleDiv className="follow__section fs__mobile">
                {isFollowing ? (
                  <FollowingIcon
                    width="30"
                    height="45"
                    onClick={handleUnFollowUser}
                  />
                ) : (
                  <FollowIcon
                    width="30"
                    height="45"
                    onClick={handleFollowUser}
                  />
                )}
              </FlexibleDiv>
            )}

            <div className="user__name__bio">
              <h2 className="name">{getName()}</h2>
              <p className="bio">{getBio()}</p>
            </div>

            {isMobile ? (
              <FlexibleDiv className="cta__section">
                <Button
                  onClick={handleClick}
                  width="50%"
                  color="var(--black)"
                  contained
                  bold
                >
                  <span>{getButtonDescription()}</span>
                </Button>
              </FlexibleDiv>
            ) : (
              <React.Fragment>
                <FlexibleDiv className="stats__wrap">
                  <FlexibleDiv flexDir="column" className="stats__wrap__item">
                    <span className="stats__count">
                      {statistics?.followers || 0}
                    </span>
                    <span className="stats__info">Followers</span>
                  </FlexibleDiv>
                  <FlexibleDiv flexDir="column" className="stats__wrap__item">
                    <span className="stats__count">
                      {statistics?.collabPlaylist || 0}
                    </span>
                    <span className="stats__info">Collab Playlist</span>
                  </FlexibleDiv>
                  <FlexibleDiv flexDir="column" className="stats__wrap__item">
                    <span className="stats__count">
                      {statistics?.playlistAdds || 0}
                    </span>
                    <span className="stats__info">Playlist Likes</span>
                  </FlexibleDiv>
                  <FlexibleDiv flexDir="column" className="stats__wrap__item">
                    <span className="stats__count">
                      {statistics?.tuales || 0}
                    </span>
                    <span className="stats__info">Tualés</span>
                  </FlexibleDiv>
                </FlexibleDiv>
              </React.Fragment>
            )}
          </FlexibleDiv>
        </FlexibleDiv>

        {isMobile ? (
          <React.Fragment>
            <FlexibleDiv className="stats__wrap">
              <FlexibleDiv flexDir="column" className="stats__wrap__item">
                <span className="stats__count">
                  {statistics?.followers || 0}
                </span>
                <span className="stats__info">Followers</span>
              </FlexibleDiv>
              <FlexibleDiv flexDir="column" className="stats__wrap__item">
                <span className="stats__count">
                  {statistics?.collabPlaylist || 0}
                </span>
                <span className="stats__info">Collab Playlist</span>
              </FlexibleDiv>
              <FlexibleDiv flexDir="column" className="stats__wrap__item">
                <span className="stats__count">
                  {statistics?.playlistAdds || 0}
                </span>
                <span className="stats__info">Playlist Likes</span>
              </FlexibleDiv>
              <FlexibleDiv flexDir="column" className="stats__wrap__item">
                <span className="stats__count">{statistics?.tuales || 0}</span>
                <span className="stats__info">Tualés</span>
              </FlexibleDiv>
            </FlexibleDiv>
          </React.Fragment>
        ) : (
          <FlexibleDiv className="cta__section cta__section__desktop">
            {!isOwner && !isDesktop && (
              <FlexibleDiv className="follow__section fs__desktop">
                {isFollowing ? (
                  <FlexibleDiv flexDir="column" alignItems="flex-end">
                    <FollowingIcon
                      width="40"
                      height="45"
                      onClick={handleUnFollowUser}
                    />
                    <span>Following</span>
                  </FlexibleDiv>
                ) : (
                  <FlexibleDiv flexDir="column" alignItems="flex-end">
                    <FollowIcon
                      width="40"
                      height="45"
                      onClick={handleFollowUser}
                    />
                    <span>Follow</span>
                  </FlexibleDiv>
                )}
              </FlexibleDiv>
            )}

            <Button
              onClick={handleClick}
              color="var(--black)"
              width="50%"
              contained
              bold
            >
              <span>{getButtonDescription()}</span>
            </Button>
          </FlexibleDiv>
        )}
      </div>
    </UPJWrap>
  )

  function getName() {
    if (Boolean(user?.profile?.fullName?.trim())) {
      return user.profile.fullName
    }

    if (user?.username) return user.username

    return "Hello, there!"
  }

  function getBio() {
    if (user?.profile?.bio) return user.profile.bio

    if (isOwner) {
      return "Write something you like..."
    }
  }

  function getButtonDescription() {
    if (isEditMode && isOwner) {
      return "View Profile"
    }

    if (isOwner) {
      return "Edit Profile"
    }

    return "Collab"
  }
}
