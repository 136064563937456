import React, { useRef } from "react"
import { PaymentInfoWrapper } from "./payment-info.styles"
import { FlexibleDiv } from "components"
import FullPhoneFrame from "assets/images/general/full-phone-frame.png"
import Fade from "react-reveal/Fade"

export const PaymentInfo = () => {
  const uduxFeatures = [
    {
      title: "User Profiles",
      subtitle:
        "Personalise your profile and give other X’ers an insight into who you are and your music tastes.",
    },
    {
      title: "Artist Playlists",
      subtitle:
        "Listen to music curated by your favourite artists, to understand what they listen to.",
    },
    {
      title: "Follow your Faves",
      subtitle:
        "Follow other X’ers, uduX creators, like their profiles, get notifications when they create new playlists and more…",
    },
    {
      title: "Collab Playlists",
      subtitle:
        "Invite friends to collaborate on curating the perfect playlists and compete to see who has the best music tastes.",
    },
  ]

  const rateCardRef = useRef(null)

  const onRateCardsScroll = () => {
    if (rateCardRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = rateCardRef.current
      const totalDocScrollLength = scrollWidth - clientWidth
      const scrollPostion = Math.floor(
        (scrollLeft / totalDocScrollLength) * 100
      )

      if (scrollPostion <= 50) {
        document.querySelector(".left__indicator").style.backgroundColor =
          "#FDA300"
        document.querySelector(".right__indicator").style.backgroundColor =
          "transparent"
      } else {
        document.querySelector(".right__indicator").style.backgroundColor =
          "#FDA300"
        document.querySelector(".left__indicator").style.backgroundColor =
          "transparent"
      }
    }
  }

  return (
    <PaymentInfoWrapper
      flexDir="row"
      width="100%"
      flexWrap="nowrap"
      justifyContent="start"
      alignItems="start"
    >
      <FlexibleDiv
        className="left__section__wrapper"
        width="35%"
        height="100%"
        alignItems="end"
      >
        <Fade left>
          <img
            src={FullPhoneFrame}
            alt="phone_frame"
            className="background__overlay"
          />
        </Fade>
      </FlexibleDiv>
      <FlexibleDiv
        width="65%"
        height="100%"
        className="right__section__wrapper"
        flexDir="column"
        justifyContent="start"
      >
        <FlexibleDiv
          className="top__container"
          width="100%"
          height="45%"
          flexDir="column"
          alignItems="start"
          flexWrap="nowrap"
          justifyContent="space-around"
        >
          <small className="small__udux__text">udux 2.0</small>

          <h1 className="big__subtitle__text">
            Where Music is <span>Social</span>
          </h1>

          <p className="subtitle__paragraph">
            We want to give our users (X’ers) the best music streaming
            experience possible.
          </p>
          <p className="subtitle__paragraph__2__web">
            A premium uduX account takes it one level higher. We’ve layered our
            amazing
            <br /> streaming technology alongside a host of new features that is
            curated to music <br /> lovers and explorers alike.
          </p>
          <p className="subtitle__paragraph__2__mobile">
            A premium uduX account takes it one level higher. We’ve layered our
            amazing streaming technology alongside a host of new features that
            is curated to music lovers and explorers alike.
          </p>
        </FlexibleDiv>
        <FlexibleDiv
          className="bottom__container"
          width="100%"
          height="50%"
          flexWrap="wrap"
          alignItems="start"
          justifyContent="start"
          onScroll={onRateCardsScroll}
          ref={rateCardRef}
        >
          {uduxFeatures.map((value, idx) => (
            <FlexibleDiv
              key={idx}
              width="50%"
              height="35%"
              alignItems="space-between"
              className="box__wrapper"
            >
              <h4 className="container__title">{value.title}</h4>
              <p className="container__subtitle">{value.subtitle}</p>
            </FlexibleDiv>
          ))}
        </FlexibleDiv>
        <FlexibleDiv className="scroll__indicator">
          <FlexibleDiv className="left__indicator"></FlexibleDiv>
          <FlexibleDiv className="right__indicator"></FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </PaymentInfoWrapper>
  )
}
