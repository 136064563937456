import { forwardRef } from "react"

export const VolumeControlIcon = forwardRef(
  ({ color, width, height, ...props }, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "34"}
      height={height || "34"}
      viewBox="0 0 35 35"
      ref={ref}
      {...props}
    >
      <g id="high-volume" transform="translate(6 -10)">
        <g
          id="Group_9972"
          data-name="Group 9972"
          transform="translate(5.36 16.78)"
        >
          <path
            id="Path_1573"
            data-name="Path 1573"
            d="M111.4,36.451l8.241-5.159c.9-.564,1.638-.156,1.638.907V51.527c0,1.063-.737,1.471-1.638.907L111.4,47.275"
            transform="translate(-111.399 -31.038)"
            fill="none"
          />
        </g>
        <g
          id="Group_9975"
          data-name="Group 9975"
          transform="translate(0 16.059)"
        >
          <g
            id="Group_9973"
            data-name="Group 9973"
            transform="translate(0.722 6.134)"
          >
            <path
              id="Path_1574"
              data-name="Path 1574"
              d="M16.933,143.532A1.938,1.938,0,0,0,15,145.465v6.958a1.938,1.938,0,0,0,1.933,1.933h2.706V143.532Z"
              transform="translate(-15 -143.532)"
              fill="#f5cea4"
            />
          </g>
          <g id="Group_9974" data-name="Group 9974" transform="translate(0 0)">
            <path
              id="Path_1575"
              data-name="Path 1575"
              d="M412.646,70.8a.722.722,0,0,0-1.021,1.021,10.887,10.887,0,0,1,0,15.379.722.722,0,0,0,1.021,1.021,12.319,12.319,0,0,0,0-17.421Z"
              transform="translate(-391.617 -67.962)"
              fill={color || "#eaeaea"}
            />
            <path
              id="Path_1576"
              data-name="Path 1576"
              d="M367.2,116.241a.722.722,0,1,0-1.021,1.021,7.782,7.782,0,0,1,0,11.006.722.722,0,1,0,1.021,1.021,9.226,9.226,0,0,0,0-13.047Z"
              transform="translate(-348.358 -111.219)"
              fill={color || "#eaeaea"}
            />
            <path
              id="Path_1577"
              data-name="Path 1577"
              d="M15.108,16.245a1.842,1.842,0,0,0-1.89.176L5.153,21.471h-2.5A2.657,2.657,0,0,0,0,24.125v6.958a2.657,2.657,0,0,0,2.654,2.654h2.5l8.065,5.049a2.187,2.187,0,0,0,1.15.363,1.513,1.513,0,0,0,.74-.187,1.842,1.842,0,0,0,.853-1.7V17.941A1.841,1.841,0,0,0,15.108,16.245ZM4.639,32.294H2.654a1.212,1.212,0,0,1-1.211-1.211V24.125a1.212,1.212,0,0,1,1.211-1.211H4.639Zm9.879,4.974c0,.306-.087.42-.109.432s-.165.025-.424-.137l-7.9-4.947V22.592l7.9-4.947c.259-.162.4-.149.424-.137s.109.127.109.432Z"
              transform="translate(0 -16.059)"
              fill={color || "#eaeaea"}
            />
          </g>
        </g>
      </g>
    </svg>
  )
)
