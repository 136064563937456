import { Button, FlexibleDiv } from "components"
import { PaymentFailedWrapper } from "./verify-payment.styles"
import { useNavigate } from "react-router-dom"

export const PaymentFailed = ({ summary }) => {
  const navigate = useNavigate()

  return (
    <PaymentFailedWrapper height="100%" width="100%" flexDir="column">
      <FlexibleDiv className="top__container" height="25%">
        <h2 className="congratulations__text">We have some bad news</h2>
        <p className="payment__text">
          Unfortunately your subscription to uduX failed
        </p>
      </FlexibleDiv>

      <FlexibleDiv
        className="bottom__container"
        height="70%"
        flexDir="column"
        alignItems="center"
        justifyContent="space-around"
        flexWrap="nowrap"
      >
        <p>This could happen for a number of reasons beyond our control. </p>
        <p>
          We suggest that you try another payment method to get the benefits of
          a premium subscription or contact our{" "}
          <span className="support" onClick={() => navigate("/support")}>
            support team
          </span>
          .
        </p>
        <Button onClick={() => navigate("/payment")}>
          <span>Try again</span>
        </Button>
      </FlexibleDiv>
    </PaymentFailedWrapper>
  )
}
