import styled from "styled-components";
import { FlexibleDiv } from "components";

export const PaymentRegisterWrapper = styled(FlexibleDiv)`
  width: 100%;

  .form_content {
    width: 100%;

    .textfield__wrap {
      padding-bottom: 2.5rem;
    }

    .gender__input__wrap {
      padding-bottom: 1.8rem;
    }

    .select_box {
      width: 100%;
      position: relative;
      align-items: center;
      grid-column-gap: 30px;
      justify-content: flex-start;

      .select {
        margin-top: 13px;
      }

      .select__gender {
        /* border: 0.5px solid var(--dampWhiteLight); */
        width: 50%;
        justify-content: flex-start;
      }

      .input {
        width: calc(100% - 110px);
      }

      .flags_img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        left: 0px;
        top: 23px;
      }

      .country-input {
        display: flex;
      }

      .country-name {
        display: none;
      }
    }
  }

  .btn__box {
    margin-top: 0%;

    @media (max-width: 376px) {
      margin-top: -3px;
    }

    button {
      padding: 0;

      @media (max-width: 376px) {
        width: 160px;
        height: 35px;
      }
    }

    span {
      font-size: 0.8rem;
    }
  }

  .existing__user {
    margin-top: 1rem;
    color: var(--white);
    opacity: 0.7;
    text-decoration: underline;
    font-size: 12px;

    @media (max-width: 376px) {
      margin-top: 0.4rem;
    }
  }

  .terms__and__conditions {
    opacity: 0.8;
    margin: 0.8rem auto;

    label {
      color: var(--dampWhite);
    }

    .tac__text {
      margin-left: 1rem;
    }

    .tac {
      opacity: 0.8;
      margin-top: 0.8rem;
      color: var(--dampWhite);

      a {
        font-size: 0.8rem;
        color: var(--dampWhite);
        text-decoration: underline;
      }
    }
  }

  .phone__input__wrap {
    margin: 2rem auto 3rem;
    height: 45px;

    #country__code__error {
      margin-top: 5px;
      color: var(--uduxDangerPrimary);
      /* font-size: 0.4rem; */
      line-height: 0.55rem;
      text-align: left;
      margin: 0;

      @media only screen and (min-width: 500px) {
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .phone__input__wrap {
      margin: 0.8rem auto;
    }

    .terms__and__conditions {
      opacity: 0.8;
      margin: 0 auto 0.7rem auto;

      label {
        font-size: 0.8rem;
        margin-top: 0px;
      }

      .tac__text {
        margin-left: 5px;
        font-size: 10px;
      }

      .tac {
        margin-top: 1rem;
        font-size: 0.8rem;

        @media (max-width: 376px) {
          font-size: 0.7rem;
        }

        a {
          /* font-size: 0.8rem; */
          color: var(--dampWhite);
          text-decoration: underline;
        }
      }
    }
  }
`;
