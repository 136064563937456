import { Popover } from "antd"
import { FlexibleDiv } from "../Box"
import { VolumeControlIcon, VolumeMuteIcon } from "assets"
import { useMainContext } from "context"
import { VolumeController } from "."

export const VolumeControl = ({ className, ...props }) => {
  const {
    state: { player },
  } = useMainContext()

  const { isPlayerMuted } = player

  return (
    <Popover
      arrow={false}
      placement="top"
      content={<VolumeController />}
      overlayClassName="volume-control-popover"
    >
      <FlexibleDiv className={className} style={{ cursor: "pointer" }}>
        {isPlayerMuted ? (
          <VolumeMuteIcon {...props} />
        ) : (
          <VolumeControlIcon {...props} />
        )}
      </FlexibleDiv>
    </Popover>
  )
}
