// import { AppGuideTour } from "components"
import { useMainContext } from "context"
import { useEffect, useRef } from "react"
import { HomeActivityPane } from "./components"

export const UserHomePage = () => {
  const el = useRef()
  const {
    state: { scrollToBottom },
  } = useMainContext()
  // const [startTour, setStartTour] = useState(true)

  useEffect(() => {
    if (scrollToBottom && el.current) {
      el.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    }
  }, [scrollToBottom])

  return (
    <>
      {/* {startTour && <AppGuideTour setStartTour={setStartTour} />} */}

      <HomeActivityPane />
    </>
  )
}
