import { instance } from "./axios"

export const signUp = async payload => {
  const { data } = await instance.post(`/auth/auth/register`, payload)

  return data
}

export const login = async payload => {
  const emailRegex =
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // const usernameRegex = /^[a-zA-Z0-9_\.]+$/

  let loginData = {}

  if (emailRegex.test(payload.emailAddress)) {
    loginData = {
      ...payload,
      emailAddress: payload.emailAddress,
      password: payload.password,
    }

    const { data } = await instance.post(`/auth/auth/login`, loginData)
    return data.data
  } else {
    loginData = {
      ...payload,
      username: payload.emailAddress,
      password: payload.password,
    }

    const { data } = await instance.post(
      "/auth/auth/login-with-username",
      loginData
    )

    console.log(data?.data)
    return data.data
  }
}

export const resetPassword = async payload => {
  const { data } = await instance.post(
    `/auth/auth/reset-password/request`,
    payload
  )

  return data
}

export const updatePasswordWithToken = async payload => {
  const { data } = await instance.post(
    `/auth/auth/reset-password/update-password`,
    payload
  )

  return data
}

export const loginWithPhone = async payload => {
  const { data } = await instance.post(`/auth/auth/phone/request`, payload)

  return data
}

export const verifyOtp = async payload => {
  const { data } = await instance.post(`/auth/auth/phone/login`, payload)

  return data.data
}

export const OAuth = async (url, payload) => {
  const { data } = await instance.post(url, payload)

  return data
}

export const verifyEmail = async (url, payload) => {
  const { data } = await instance.post(url, payload)

  return data
}
