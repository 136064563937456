export const PauseOutlineIcon = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "22"}
    height={height || "22"}
    viewBox="0 0 45 55"
    {...props}
  >
    <g id="pause-3" transform="translate(-25.267, 6)">
      <path
        id="Path_1565"
        data-name="Path 1565"
        d="M45.086,42.5H30.448a1.181,1.181,0,1,1,0-2.361H43.905V2.361H31.628V36.6a1.181,1.181,0,0,1-2.361,0V1.181A1.18,1.18,0,0,1,30.448,0H45.086a1.18,1.18,0,0,1,1.181,1.181V41.32A1.181,1.181,0,0,1,45.086,42.5Z"
        fill={color || "#eaeaea"}
      />
      <rect
        id="Rectangle_3979"
        data-name="Rectangle 3979"
        width="5.194"
        height="30.694"
        transform="translate(35.17 5.903)"
        fill={color || "#eaeaea"}
      />
      <g id="Group_9971" data-name="Group 9971" transform="translate(33.989)">
        <path
          id="Path_1566"
          data-name="Path 1566"
          d="M92.53,89.945H87.337a1.18,1.18,0,0,1-1.181-1.181V58.07a1.18,1.18,0,0,1,1.181-1.181H92.53a1.18,1.18,0,0,1,1.181,1.181V88.764A1.181,1.181,0,0,1,92.53,89.945Zm-4.013-2.361H91.35V59.25H88.517Z"
          transform="translate(-86.156 -52.167)"
          fill={color || "#eaeaea"}
        />
        <path
          id="Path_1567"
          data-name="Path 1567"
          d="M293.761,42.5H279.123a1.181,1.181,0,1,1,0-2.361H292.58V2.361H280.3V36.6a1.181,1.181,0,0,1-2.361,0V1.181A1.18,1.18,0,0,1,279.123,0h14.638a1.18,1.18,0,0,1,1.181,1.181V41.32A1.181,1.181,0,0,1,293.761,42.5Z"
          transform="translate(-262.022)"
          fill={color || "#eaeaea"}
        />
      </g>
      <rect
        id="Rectangle_3980"
        data-name="Rectangle 3980"
        width="5.194"
        height="30.694"
        transform="translate(55.812 5.903)"
        fill={color || "#eaeaea"}
      />
      <path
        id="Path_1568"
        data-name="Path 1568"
        d="M341.2,89.945h-5.194a1.18,1.18,0,0,1-1.181-1.181V58.07a1.18,1.18,0,0,1,1.181-1.181H341.2a1.18,1.18,0,0,1,1.181,1.181V88.764A1.18,1.18,0,0,1,341.2,89.945Zm-4.013-2.361h2.833V59.25H337.19Z"
        transform="translate(-280.198 -52.167)"
        fill={color || "#eaeaea"}
      />
    </g>
  </svg>
);
