import { Skeleton } from "antd";
import Banner from "assets/images/general/asa-livestream-banner.png";
import EmptyTopTracks from "assets/images/general/empty-top-tracks.svg";
import MobileBanner from "assets/images/general/m-asa-livestream-banner.png";
import {
  ArtistPlaylistCard,
  ArtisteCard,
  Button,
  DataDisplayCard,
  FlexibleDiv,
  OverflowScroll,
  ParticlesBackground,
  PlainImageTextCard,
  PlaylistCard,
  Section,
} from "components";
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import { Types, useMainContext } from "context";
import { capitalize } from "lodash";
import {
  getAllAlbums,
  getAllArtist,
  getPlaylistCategories,
  getUsersToFollow,
  instance,
} from "network";
import React, { useState, useEffect, useRef } from "react";
import { useQueries, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { HomeWrap } from "../home.styles";
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, {
//   Navigation,
//   Pagination,
//   EffectFade,
//   Autoplay,
//   Virtual,
// } from "swiper/core";
import "swiper/css";
import "swiper/swiper-bundle.css";
import { useMediaQuery } from "react-responsive";
import Carousel from "components/lib/Carousel/Carousel";
import { BannerImages } from "utils/data-helpers/bannerImages";




export const HomeActivityPane = () => {
  const el = useRef();
  const { dispatch } = useMainContext();
  const isMobile = useMediaQuery({ maxWidth: 994 });

  const [activeSlide, setActiveSlide] = React.useState(1);
  const [swiper, setSwiper] = useState(null);
  // SwiperCore.use([Navigation, Pagination, Autoplay, Virtual, EffectFade]);

  const navigate = useNavigate();

  const { data: artistsYouLove, isLoading: artistsYouLoveLoading } = useQuery(
    ["artists"],
    getAllArtist
  );

  const { data: albumsYouNeed, isLoading: albumsYouNeedLoading } = useQuery(
    ["albums"],
    getAllAlbums
  );

  React.useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide, 500);
    }
  }, [swiper, activeSlide]);

  const plCategoriesInfo = useQueries([
    {
      queryKey: ["category-one"],
      queryFn: () => getPlaylistCategories("Random 1"),
    },
    {
      queryKey: ["category-two"],
      queryFn: () => getPlaylistCategories("Random 2"),
    },
    {
      queryKey: ["category-three"],
      queryFn: () => getPlaylistCategories("Random 3"),
    },
  ]);

  const isFetching = plCategoriesInfo.some((o) => o.isLoading);
  const categoriesOne = plCategoriesInfo[0].data;
  const categoriesTwo = plCategoriesInfo[1].data;
  const categoriesThree = plCategoriesInfo[2].data;

  const { data: usersToFollow, isLoading: utfLoading } = useQuery(
    ["users-to-follow"],
    () => getUsersToFollow()
  );

  // Fetch and store playlist categories in the global state
  useEffect(() => {
    // if (me?.isMTNRegistration) {
    //   setModal(true)
    // }
    const getCategories = async () => {
      const { data } = await instance.get(`/streaming/categories`);
      dispatch({ type: Types.GET_CATEGORIES, payload: data?.data });
    };

    getCategories();
  }, [dispatch]);

  return (
    <PlayerLayout>
      <HomeWrap>
        <OverflowScroll>
          <Section
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            ref={el}
          >
            <FlexibleDiv className="banner__wrap" justifyContent="flex-start">
              <Carousel imagesArray={BannerImages}/>
            </FlexibleDiv>

            <FlexibleDiv
              flexDir="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="playerhome__bodysection"
            >
              {/* spotlight x section */}
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <h4 className="mini__subheader">Spotlight X</h4>

                {isFetching ? (
                  <FlexibleDiv minHeight="300px">
                    <Skeleton active={isFetching} />
                  </FlexibleDiv>
                ) : (
                  <ArtistPlaylistCard
                    title="Simi's Top Tracks"
                    playlist={categoriesOne?.data[0]}
                    cardData={categoriesOne?.data[0].tracks.map((t) => t.track)}
                  />
                )}
              </FlexibleDiv>

              {/* Artists You'll Love */}
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">
                    Artists we think you’ll love!
                  </h4>
                  <Link to="#" className="see__more">
                    See All
                  </Link>
                </FlexibleDiv>

                <FlexibleDiv className="cards__carousel">
                  {artistsYouLoveLoading ? (
                    <FlexibleDiv minHeight="300px">
                      <Skeleton active={artistsYouLoveLoading} />
                    </FlexibleDiv>
                  ) : (
                    <>
                      {artistsYouLove?.data.slice(0, 9).map((item, idx) => {
                        return (
                          <FlexibleDiv
                            className="playercard"
                            width="max-content"
                            key={idx}
                            margin={idx !== 0 ? "0 0 0 20px" : "0"}
                          >
                            <ArtisteCard {...item} />
                          </FlexibleDiv>
                        );
                      })}
                    </>
                  )}
                </FlexibleDiv>
              </FlexibleDiv>

              {/* Albums that you need in your Life RN!*/}
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">
                    Albums that you need in your Life RN!
                  </h4>
                  <Link to="#" className="see__more">
                    Explore More{" "}
                  </Link>
                </FlexibleDiv>

                <FlexibleDiv className="cards__carousel">
                  {albumsYouNeedLoading ? (
                    <FlexibleDiv minHeight="300px">
                      <Skeleton active={albumsYouNeedLoading} />
                    </FlexibleDiv>
                  ) : (
                    <>
                      {albumsYouNeed?.map((item, idx) => {
                        return (
                          <FlexibleDiv
                            className="playercard"
                            width="max-content"
                            key={idx}
                            margin={idx !== 0 ? "0 0 0 30px" : "0"}
                          >
                            <PlainImageTextCard
                              {...item}
                              coverArt={item?.metadata?.coverArt?.url}
                              title={item?.metadata?.title}
                              subTitle={
                                item?.metadata?.displayArtists[0]?.artist
                                  ?.stageName
                              }
                              handleClick={() =>
                                navigate(`/playlist/${item.id}?t=alb`, {
                                  state: {
                                    module: "ablum",
                                  },
                                })
                              }
                            />
                          </FlexibleDiv>
                        );
                      })}
                    </>
                  )}
                </FlexibleDiv>
              </FlexibleDiv>

              {/* Playlists to get you going!*/}
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">
                    Playlists to get you going!
                  </h4>
                  <Link to="#" className="see__more">
                    Explore More{" "}
                  </Link>
                </FlexibleDiv>

                <FlexibleDiv className="cards__carousel data__card__carousel">
                  {isFetching ? (
                    <FlexibleDiv minHeight="300px">
                      <Skeleton active={isFetching} />
                    </FlexibleDiv>
                  ) : (
                    <>
                      {categoriesOne?.data.map((pfa, idx) => {
                        return (
                          <DataDisplayCard
                            key={`${idx}-${pfa.id}`}
                            emptyDisplayIcon={EmptyTopTracks}
                            emptyText="Please wait while we curate this content for you."
                            cardData={pfa.tracks.map((t) => t.track)}
                            playlist={pfa}
                            isPlaylist
                            isLarge
                          />
                        );
                      })}
                    </>
                  )}
                </FlexibleDiv>
              </FlexibleDiv>

              {/* X’ers that you need to follow!*/}
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">
                    X’ers that you need to follow!{" "}
                  </h4>
                  <Link to="#" className="see__more">
                    Explore More{" "}
                  </Link>
                </FlexibleDiv>

                <FlexibleDiv className="cards__carousel">
                  {utfLoading ? (
                    <FlexibleDiv minHeight="300px">
                      <Skeleton active={utfLoading} />
                    </FlexibleDiv>
                  ) : (
                    <>
                      {usersToFollow?.map(({ username, profile, id }, idx) => {
                        return (
                          <FlexibleDiv
                            className="playercard"
                            width="max-content"
                            key={idx}
                            margin={idx !== 0 ? "0 0 0 30px" : "0"}
                          >
                            <PlainImageTextCard
                              coverArt={profile?.profilePicture?.url}
                              title={capitalize(username)}
                              handleClick={() => navigate(`/user/${id}`)}
                            />
                          </FlexibleDiv>
                        );
                      })}
                    </>
                  )}
                </FlexibleDiv>
              </FlexibleDiv>

              {/*The Wild Side - Gem’s you’ve never heard of but need!*/}
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">
                    The Wild Side - Gem’s you’ve never heard of but need!{" "}
                  </h4>
                  <Link to="#" className="see__more">
                    Explore More{" "}
                  </Link>
                </FlexibleDiv>

                <FlexibleDiv className="cards__carousel">
                  {isFetching ? (
                    <FlexibleDiv minHeight="300px">
                      <Skeleton active={isFetching} />
                    </FlexibleDiv>
                  ) : (
                    <>
                      {categoriesThree?.data.map((item, idx) => {
                        return (
                          <FlexibleDiv
                            className="playercard"
                            width="max-content"
                            key={idx}
                            margin={idx !== 0 ? "0 0 0 20px" : "0"}
                          >
                            <PlaylistCard {...item} />
                          </FlexibleDiv>
                        );
                      })}
                    </>
                  )}
                </FlexibleDiv>
              </FlexibleDiv>

              {/* Outside Territory.*/}
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">Outside Territory.</h4>
                  <Link to="#" className="see__more">
                    Explore More{" "}
                  </Link>
                </FlexibleDiv>

                <FlexibleDiv className="cards__carousel data__card__carousel">
                  {isFetching ? (
                    <FlexibleDiv minHeight="300px">
                      <Skeleton active={isFetching} />
                    </FlexibleDiv>
                  ) : (
                    <>
                      {categoriesTwo?.data?.map((pfa, idx) => (
                        <DataDisplayCard
                          key={`${idx}-${pfa.id}`}
                          emptyDisplayIcon={EmptyTopTracks}
                          emptyText="Please wait while we curate this content for you."
                          cardData={pfa.tracks.map((t) => t.track)}
                          playlist={pfa}
                          isPlaylist
                          isLarge
                        />
                      ))}
                    </>
                  )}
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>
          </Section>
        </OverflowScroll>
      </HomeWrap>
    </PlayerLayout>
  );
};
