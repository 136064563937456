import { last, uniqBy } from "lodash";
import { Types } from "context/types";

export const playerReducer = (state, { type, payload }) => {
  switch (type) {
    case Types.IS_PLAYER_ACTIVE:
      return {
        ...state,
        isPlayerActive: payload,
      };

    case Types.PLAYING_LIST_ACTIVE_TRACKS:
      return {
        ...state,
        playingList: {
          ...state.playingList,
          tracks: uniqBy(payload, "track.id"),
        },
      };

    case Types.SET_PLAYING_PLAYLIST:
      return {
        ...state,
        playingList: payload,
      };

    case Types.PLAY_NEXT_TRACK:
      const pnQueuedTracks = Array.from(state.queuedTracks);
      const pnPrevTracks = Array.from(state.prevTracks);

      const curTrackIdx = pnQueuedTracks.findIndex(
        (pnt) => pnt?.track?.id === payload?.track?.id || pnt.id === payload?.id
      );
      let curTrack = {};
      console.log(pnQueuedTracks);
      if (pnQueuedTracks?.length > 1) {
        curTrack = pnQueuedTracks[curTrackIdx + 1];
        pnPrevTracks.push(payload);
      } else if (pnQueuedTracks?.length === 1) {
        curTrack = pnQueuedTracks[0];
        pnPrevTracks.push(payload);
      } else {
        curTrack = payload;
      }

      return {
        ...state,
        currentTrack: curTrack,
        prevTracks: pnPrevTracks,
        queuedTracks: pnQueuedTracks.slice(1),
      };

    case Types.PLAY_PREV_TRACK:
      const ppPrevTracks = Array.from(state.prevTracks);
      const prevTrackToPlay = last(ppPrevTracks);
      const shouldNotUpdatePrevTrack = prevTrackToPlay === undefined;

      const filteredPrevTracks = ppPrevTracks.filter(
        (pt) => pt.id !== payload.id && pt.id !== prevTrackToPlay?.id
      );
      const ppQueuedTracks = Array.from(state.queuedTracks);
      ppQueuedTracks.unshift(payload);

      return {
        ...state,
        currentTrack: shouldNotUpdatePrevTrack ? payload : prevTrackToPlay,
        prevTracks: filteredPrevTracks,
        queuedTracks: shouldNotUpdatePrevTrack
          ? state.queuedTracks
          : ppQueuedTracks,
      };

    case Types.IS_PLAYING:
      return {
        ...state,
        isPlaying: payload,
      };

    case Types.REPEAT:
      return {
        ...state,
        repeat: payload,
      };

    case Types.CURRENT_PLAYING_TIME:
      return {
        ...state,
        currentTime: payload,
      };

    case Types.SHUFFLE:
      return {
        ...state,
        shuffle: payload,
      };

    case Types.CURRENT_VOLUME:
      return {
        ...state,
        volume: payload,
      };

    case Types.MUTE_PLAYER:
      return {
        ...state,
        isPlayerMuted: payload,
      };

    case Types.SET_CURRENT_PLAYING:
      return {
        ...state,
        currentTrack: payload,
      };

    case Types.SET_PLAY_TRACK:
      return {
        ...state,
        isPlaying: true,
      };

    case Types.SET_PAUSE_TRACK:
      return {
        ...state,
        isPlaying: false,
        isPaused: true,
      };

    case Types.SET_STOP_TRACK:
      return {
        ...state,
        currentTime: 0,
        isPlaying: false,
        isPaused: false,
      };

    case Types.SET_FSEM:
      return {
        ...state,
        fsem: payload,
      };

    case Types.ADD_TO_QUEUE:
      let atq = Array.isArray(payload) ? payload : [payload];
      const qTracks = Array.from(state.queuedTracks);

      return {
        ...state,
        queuedTracks: qTracks.concat(atq),
      };

    case Types.REPLACE_QUEUED_TRACKS:
      return {
        ...state,
        queuedTracks: payload,
      };

    case Types.REPLACE_PREV_TRACKS:
      return {
        ...state,
        prevTracks: payload,
      };

    default:
      return state;
  }
};
