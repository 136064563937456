import { instance } from "./axios"

export const sendStreamData = async data => {
  const response = await instance.post(`/data/logs/stream`, data)

  return response.data
}

export const getStreams = async () => {
  const response = await instance.get(`/data/logs/stream`)

  return response.data
}

export const getTopStreamedTracks = async userId => {
  const url = `/data/logs/stream/top-tracks${userId ? `/${userId}` : ""}`
  const response = await instance.get(url)

  return response.data
}

export const getRecentlyPlayedTracks = async userId => {
  const url = `/data/logs/stream/recently-played${userId ? `/${userId}` : ""}`
  const response = await instance.get(url)

  return response.data
}
