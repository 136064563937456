import { Form, Select } from "antd"
import {
  BorderedTextfield,
  Button,
  FlexibleDiv,
  BorderedSelectField,
  DateSequenceSelector,
  ParticlesBackground,
} from "components"
import { useMainContext, Types } from "context"
import React, { useState } from "react"
import { createPortal } from "react-dom"
import { useNavigate } from "react-router-dom"
import { useDelayUnmount, validateBasicField, capitalize } from "utils"
import {
  UPPortalWrap,
  UpdateProfileWrap,
  UPMPrintBackground,
} from "./update-profile-modal.styles"
import dayjs from "dayjs"
import { pickBy } from "lodash"
import { LoadingOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import {
  updateUserProfile,
  errorResponseHandler,
  successResponseHandler,
} from "network"
import { Fragment } from "react"

export const UpdateProfileModal = ({ handleClose, open }) => {
  const navigate = useNavigate()
  const [isMounted, setIsMounted] = useState(open)
  const shouldRenderChild = useDelayUnmount(isMounted, 500)
  const [dob, setDob] = React.useState("")
  const [form] = Form.useForm()
  const { isLoading, mutate } = useMutation(updateUserProfile, {})

  const {
    dispatch,
    state: {
      users: { me },
    },
  } = useMainContext()

  const mountedStyle = { animation: "inAnimation 500ms ease-in" }
  const unmountedStyle = { animation: "outAnimation 510ms ease-in" }

  const UpdateProfileModalContent = (
    <UPPortalWrap>
      {shouldRenderChild && (
        <UpdateProfileWrap style={isMounted ? mountedStyle : unmountedStyle}>
          <div className="update-header">
            <h3>Follow the Rhythm You're one step closer to the beat.</h3>
            <p>Update your information to unlock your X.</p>
          </div>

          <Form form={form} onFinish={handleSubmit}>
            <FlexibleDiv
              flexDir="column"
              className="form__section"
              margin="20px 0px 0px 0"
            >
              <FlexibleDiv margin="5px 0px 15px 0" className="input">
                <BorderedTextfield
                  small
                  nullable
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                  validator={validateBasicField}
                />
              </FlexibleDiv>

              <FlexibleDiv margin="10px 0px 15px 0" className="input">
                <BorderedTextfield
                  small
                  nullable
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  validator={validateBasicField}
                />
              </FlexibleDiv>

              <FlexibleDiv margin="10px 0px 15px 0" className="input">
                <BorderedTextfield
                  small
                  editLocked={false}
                  name="username"
                  label="Username"
                  placeholder="Username"
                  validator={validateBasicField}
                />
              </FlexibleDiv>

              <FlexibleDiv margin="10px 0px 15px 0" className="input">
                <BorderedTextfield
                  small
                  editLocked={false}
                  name="emailAddress"
                  label="Email Address"
                  placeholder="Email Address"
                />
              </FlexibleDiv>

              <FlexibleDiv margin="10px 0px 15px 0" className="input">
                <BorderedSelectField
                  nullable
                  name="gender"
                  radius="0"
                  text_align="left"
                  label="Gender"
                  placeholder="Gender"
                  validator={validateBasicField}
                  small
                >
                  {["male", "female", "not-specified"].map((value, idx) => (
                    <Select.Option value={value} key={idx}>
                      {capitalize(value)}
                    </Select.Option>
                  ))}
                </BorderedSelectField>
              </FlexibleDiv>
              <FlexibleDiv margin="10px 0px 15px 0" className="input">
                <DateSequenceSelector
                  nullable
                  mb="1rem"
                  mt="0.5rem"
                  name="dateOfBirth"
                  padding="8px 2px"
                  format="DD/MM/YYYY"
                  label="Date of birth"
                  defaultValue={dob}
                  handleDateChange={dob =>
                    setDob(dayjs(dob).format("DD/MM/YYYY"))
                  }
                  validator={validateBasicField}
                  small
                />
              </FlexibleDiv>
              <FlexibleDiv margin="10px 0px 0px 0" className="btn__wrap">
                <Button
                  background="var(--pinkPrimary)"
                  color="var(--black)"
                  htmlType="submit"
                  radius="0"
                  bold
                >
                  <span>
                    {isLoading ? <LoadingOutlined /> : "Save Changes"}
                  </span>
                </Button>
              </FlexibleDiv>
            </FlexibleDiv>
          </Form>
        </UpdateProfileWrap>
      )}

      <UPMPrintBackground />

      <ParticlesBackground />
    </UPPortalWrap>
  )

  return createPortal(UpdateProfileModalContent, document.body)

  function handleSubmit(payload) {
    payload = {
      ...payload,
      dateOfBirth: dob,
    }

    mutate(pickBy(payload), {
      onSuccess: async data => {
        await dispatch({
          type: Types.CURRENT_USER,
          payload: {
            ...me,
            isTempAccount: false,
            profile: { ...me.profile, ...data.data },
          },
        })
        successResponseHandler(data)
      },
      onError: error => errorResponseHandler(error),
    })
  }
}
