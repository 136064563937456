import { LoadingOutlined } from "@ant-design/icons"
import { Form, Select } from "antd"
import {
  BorderedSelectField,
  BorderedTextfield,
  Button,
  CountrySelector,
  DateSequenceSelector,
  FlexibleDiv,
  PhoneInput,
} from "components"
import { useMainContext, Types } from "context"
import dayjs from "dayjs"
import { pickBy } from "lodash"
import {
  errorResponseHandler,
  successResponseHandler,
  updateUserProfile,
} from "network"
import React from "react"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { capitalize, validateBasicField } from "utils"
import { MyInformationWrap } from "./my-information.styles"

export const MyInformation = () => {
  const [form] = Form.useForm()
  const [dob, setDob] = React.useState("")
  const [countryCode, setCountryCode] = React.useState("")
  const [phoneNumber, setPhoneNumber] = React.useState("")
  const {
    state: { users },
    dispatch,
  } = useMainContext()
  const { me } = users
  const isMobile = useMediaQuery({ maxWidth: 740 })

  const { isLoading, mutate } = useMutation(updateUserProfile, {})

  React.useEffect(() => {
    form.setFieldsValue({
      ...me,
      ...me.profile,
    })

    setDob(me?.profile?.dateOfBirth)
    setPhoneNumber(me?.phoneNumber)
  }, [form])

  return (
    <MyInformationWrap>
      <Form form={form} onFinish={handleSubmit}>
        <FlexibleDiv className="main__wrap top__layer">
          <FlexibleDiv className="section__info">
            <h2 className="title">Your Details</h2>

            {!isMobile && (
              <React.Fragment>
                <FlexibleDiv className="textarea">
                  <BorderedTextfield
                    small
                    name="bio"
                    textarea
                    height="10em"
                    padding="2px"
                    label="Your Bio"
                    placeholder="Tell us something about you"
                  />
                </FlexibleDiv>

                <FlexibleDiv className="btn__wrap">
                  <Button
                    background="var(--pinkPrimary)"
                    color="var(--black)"
                    htmlType="submit"
                    radius="0"
                    bold
                  >
                    <span>
                      {isLoading ? <LoadingOutlined /> : "Save Changes"}
                    </span>
                  </Button>
                </FlexibleDiv>
              </React.Fragment>
            )}
          </FlexibleDiv>

          <FlexibleDiv flexDir="column" className="form__section">
            <FlexibleDiv className="input">
              <BorderedTextfield
                small
                nullable
                name="firstName"
                label="First Name"
                placeholder="First Name"
                validator={validateBasicField}
              />
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <BorderedTextfield
                small
                nullable
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                validator={validateBasicField}
              />
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <BorderedTextfield
                small
                editLocked
                name="username"
                label="Username"
                placeholder="Username"
                validator={validateBasicField}
              />
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <BorderedTextfield
                small
                editLocked
                name="emailAddress"
                label="Email Address"
                placeholder="Email Address"
              />
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <BorderedSelectField
                nullable
                name="gender"
                radius="0"
                text_align="left"
                label="Gender"
                placeholder="Gender"
                validator={validateBasicField}
                small
              >
                {["male", "female", "not-specified"].map((value, idx) => (
                  <Select.Option value={value} key={idx}>
                    {capitalize(value)}
                  </Select.Option>
                ))}
              </BorderedSelectField>
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <DateSequenceSelector
                nullable
                mb="1rem"
                mt="0.5rem"
                name="dateOfBirth"
                padding="8px 2px"
                format="DD/MM/YYYY"
                label="Date of birth"
                defaultValue={dob}
                handleDateChange={dob =>
                  setDob(dayjs(dob).format("DD/MM/YYYY"))
                }
                validator={validateBasicField}
                small
              />
            </FlexibleDiv>

            <FlexibleDiv>
              <CountrySelector nullable listHeight={200} small />
            </FlexibleDiv>

            <FlexibleDiv className="">
              <PhoneInput
                value={phoneNumber}
                onChange={e => {
                  setPhoneNumber(e)
                }}
                onSetCountryCode={code => setCountryCode(code)}
                nullable
                disabled
                small
              />
            </FlexibleDiv>

            {isMobile && (
              <React.Fragment>
                <FlexibleDiv className="textarea">
                  <BorderedTextfield
                    small
                    name="bio"
                    textarea
                    height="8em"
                    padding="2px"
                    label="Your Bio"
                    placeholder="Tell us something about you"
                  />
                </FlexibleDiv>

                <FlexibleDiv
                  className="btn__wrap__mobile"
                  margin="4rem auto 0"
                  width="50%"
                >
                  <Button
                    background="var(--pinkPrimary)"
                    color="var(--black)"
                    htmlType="submit"
                    width="100%"
                    radius="0"
                    bold
                  >
                    <span>
                      {isLoading ? <LoadingOutlined /> : "Save Changes"}
                    </span>
                  </Button>
                </FlexibleDiv>
              </React.Fragment>
            )}
          </FlexibleDiv>
        </FlexibleDiv>
      </Form>
    </MyInformationWrap>
  )

  function handleSubmit(payload) {
    payload = {
      ...payload,
      phoneNumber,
      countryCode,
      dateOfBirth: dob,
    }

    mutate(pickBy(payload), {
      onSuccess: async data => {
        await dispatch({
          type: Types.CURRENT_USER,
          payload: { ...me, profile: { ...me.profile, ...data.data } },
        })
        successResponseHandler(data)
      },
      onError: error => errorResponseHandler(error),
    })
  }
}
