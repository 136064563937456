import { notification } from "antd"

export function errorResponseHandler(error) {
  // check for errorHandle config
  if (
    error.config.hasOwnProperty("errorHandle") &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error)
  }

  // if has response show the error
  if (error?.response?.data?.message) {
    notification.error({
      message: "Error",
      description: error.response.data.message,
      // icon: <SmileOutlined style={{ color: "red" }} rotate="180" />,
    })
  } else if (error?.code === "ERR_NETWORK") {
    notification.error({
      message: "Error",
      description: error.message,
      // icon: <SmileOutlined style={{ color: "red" }} rotate="180" />,
    })
  } else {
    notification.error({
      message: "Error",
      description:
        "There may be an issue with your network, please try again later",
      // icon: <SmileOutlined style={{ color: "red" }} rotate="180" />,
    })
  }
}
