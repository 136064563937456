import { useMainContext } from "context"
import { Fragment, useRef } from "react"
import { useLocation } from "react-router-dom"

export const Guardian = ({ children, fromPaymentPage }) => {
  const location = useLocation()
  const userObject = useRef(JSON.parse(localStorage.getItem("udx__user"))?.data)
  const {
    state: { users },
    dispatch,
  } = useMainContext()

  const isLoggedIn =
    Boolean(users?.me?.id) || Boolean(userObject?.current?.user?.id)

  // console.log({ isLoggedIn, location })

  // useEffect(() => {
  //   if (isEmpty(users.me) && !isEmpty(userObject?.current)) {
  //     dispatch({
  //       type: CURRENT_USER,
  //       payload: userObject?.current?.user,
  //     })
  //   }
  // }, [])

  return <Fragment>{children}</Fragment>
}
