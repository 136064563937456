import { FlexibleDiv } from "components"
import styled from "styled-components"

export const PJWrap = styled(FlexibleDiv)`
  height: 380px;
  position: relative;
  align-items: flex-start;
  background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
      : ""};

  .album__art,
  .album__stats {
    padding: 0 5rem;
  }

  .album__art {
    flex: 1;
    display: flex;
    position: relative;
    justify-content: space-evenly;
    flex-direction: column;

    .hero__details {
      padding: 6rem 0 2rem;
      height: 100%;
      display: flex;
      position: relative;
      justify-content: space-between;
      flex-direction: column;
    }

    .type__and__year {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .title {
      color: white;
      line-height: 1;
      font-size: 2.55rem;
      text-transform: capitalize;
      font-family: "Baguede", sans-serif;
    }

    .artist {
      margin-bottom: 3rem;
      display: flex;
      align-items: center;

      .artist__avatar {
        width: 60px;
        height: 60px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .artist__names {
        display: flex;
        column-gap: 2px;
        margin-bottom: 0;
        margin-left: 1rem;
        justify-content: flex-start;
      }

      .artist__name {
        color: white;
        font-size: 1rem;
        cursor: pointer;
        width: max-content;
        text-transform: capitalize;

        :hover {
          text-decoration: underline;
        }
      }

      .udux__logo {
        width: 60px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .album__art__playlist {
    margin-left: 410px;
    flex: 1;
    display: flex;
    position: relative;
    justify-content: space-evenly;
    flex-direction: column;

    .hero__details {
      padding: 6rem 0 2rem;
      height: 100%;
      display: flex;
      position: relative;
      justify-content: space-between;
      flex-direction: column;
    }

    .type__and__year {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .title {
      color: white;
      font-size: 2.5rem;
      text-transform: capitalize;
      font-family: "Baguede", sans-serif;
    }

    .artist {
      margin-bottom: 3rem;
      display: flex;
      align-items: center;

      .artist__avatar {
        width: 60px;
        height: 60px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .artist__names {
        display: flex;
        column-gap: 2px;
        margin-bottom: 0;
        margin-left: 1rem;
        justify-content: flex-start;
      }

      .artist__name {
        color: white;
        font-size: 1rem;
        cursor: pointer;
        width: max-content;
        text-transform: capitalize;

        :hover {
          text-decoration: underline;
        }
      }

      .udux__logo {
        width: 60px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .cover__art {
    width: 340px;
    height: 350px;
    position: absolute;
    right: 3%;
    bottom: 0;
    z-index: 99;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cover__art__playlist {
    width: 340px;
    height: 350px;
    position: absolute;
    left: 3%;
    bottom: 0;
    z-index: 99;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .album__stats {
    height: 65px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    border: 0.2px solid var(--borderLight);
    border-top: none;
    position: absolute;
    bottom: 0;
  }

  .album__stats__playlist {
    padding: 0 5rem 0 400px;
    height: 65px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    border: 0.2px solid var(--borderLight);
    border-top: none;
    position: absolute;
    right: 0;
    bottom: 0;

    .stats__wrap {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .stats__wrap {
    width: 75%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .player__controls {
    display: flex;
    height: 100%;
    column-gap: 20px;
    align-items: center;
    justify-content: space-around;

    .hover__fill {
      :hover {
        svg {
          path {
            fill: var(--uduxYellowPrimary);
          }
        }
      }
    }
    .hover__stroke {
      :hover {
        svg {
          path {
            stroke: var(--uduxYellowPrimary);
          }
        }
      }
    }

    .icon__wrap {
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        path {
          transition: all 0.5s ease;
        }
      }
    }
  }

  .stats__display {
    display: flex;
    align-items: center;
    column-gap: 13px;
    padding-right: 2rem;

    .statistics {
      margin: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    span {
      font-family: "Baguede", sans-serif;
    }

    .number {
      font-size: 17px;
    }

    .info {
      opacity: 0.8;
      font-size: 12px;
    }
  }

  .mobile__cover__art__view,
  .mobile__cover__art__view__playlist {
    width: 200px;
    height: 200px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .mobile__cover__art__view__playlist {
    position: absolute;
    left: -10%;
  }

  @media screen and (max-width: 1679px) {
    flex-direction: row;

    .album__art {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: calc(100% - 65px);
    }

    .stats__wrap {
      width: 100%;
    }

    .cover__art__playlist {
    }

    .album__art__playlist {
      margin-left: 300px;
    }

    .album__stats__playlist {
      width: 100%;
      padding: 0 4rem;
    }

    .mobile__cover__art__view__playlist {
      left: -230px;
    }
  }

  @media screen and (max-width: 1132px) {
    .album__art {
      .hero__details {
      }

      .type__and__year {
        font-size: 1rem;
      }

      .title {
        font-size: 2rem;
      }

      .artist {
        margin-bottom: 5rem;

        .artist__avatar {
          width: 45px;
          height: 45px;
        }

        .artist__names {
        }

        .artist__name {
          font-size: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 739px) {
    height: 230px;
    margin-top: 2.5rem;

    .album__art,
    .album__art__playlist {
      padding: 0 1rem;

      .hero__details {
        justify-content: center;
        padding: 2rem 0;
      }

      .type__and__year {
        font-size: 1rem;
        margin-bottom: 0;
      }

      .title {
        font-size: 1.5rem;
      }

      .artist {
        margin-top: 1rem;
        margin-bottom: 0;

        .artist__avatar {
          width: 25px;
          height: 25px;
        }

        .artist__names {
          width: 140px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .artist__name {
          font-size: 0.85rem;
        }
      }
    }

    .mobile__cover__art__view,
    .mobile__cover__art__view__playlist {
      width: 100px;
      height: 100px;
    }

    .album__stats,
    .album__stats__playlist {
      padding: 1rem;

      .stats__wrap {
        flex-direction: row-reverse;
      }

      .player__controls,
      .stats__display {
        width: 48%;
        column-gap: 8px;
      }

      .player__controls {
        .icon__wrap {
          height: 25px;
          width: 25px;
        }
      }

      .player__controls {
        max-width: 200px;
      }

      .stats__display {
        padding-right: 0;

        .number {
          font-size: 12px;
        }

        .info {
          font-size: 8px;
        }
      }
    }

    .album__stats__playlist {
      height: 60px;
    }

    .album__art__playlist {
      margin-left: 120px;
    }

    .mobile__cover__art__view__playlist {
      left: -100px;
    }
  }
`
