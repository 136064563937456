export const CollabPlaylistIcon = ({
  color = "#eaeaea",
  width,
  height,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 25 25"
    className="fill__player__svg"
    fill="none"
    {...props}
  >
    <g id="versus" transform="translate(-1 -0.972)">
      <path
        id="Path_1687"
        data-name="Path 1687"
        d="M1.327,47.507H5.908a.327.327,0,0,0,.327-.327V44.235A1.638,1.638,0,0,0,4.6,42.6H4.532a1.472,1.472,0,1,0-1.828,0H2.636A1.638,1.638,0,0,0,1,44.235V47.18A.327.327,0,0,0,1.327,47.507Zm2.29-6.871a.818.818,0,1,1-.818.818A.818.818,0,0,1,3.618,40.636Zm-1.963,3.6a.982.982,0,0,1,.982-.982H4.6a.982.982,0,0,1,.982.982v2.618H1.654Z"
        transform="translate(0 -26.239)"
        fill={color}
      />
      <path
        id="Path_1688"
        data-name="Path 1688"
        d="M50.6,3.6h-.067a1.472,1.472,0,1,0-1.828,0h-.067A1.638,1.638,0,0,0,47,5.235V8.18a.327.327,0,0,0,.327.327h4.581a.327.327,0,0,0,.327-.327V5.235A1.638,1.638,0,0,0,50.6,3.6Zm-.982-1.963a.818.818,0,1,1-.818.818A.818.818,0,0,1,49.618,1.636Zm1.963,6.217H47.654V5.235a.982.982,0,0,1,.982-.982H50.6a.982.982,0,0,1,.982.982Z"
        transform="translate(-30.948)"
        fill={color}
      />
      <path
        id="Path_1689"
        data-name="Path 1689"
        d="M23.254,29.507A4.254,4.254,0,1,0,19,25.254,4.254,4.254,0,0,0,23.254,29.507Zm0-7.853a3.6,3.6,0,1,1-3.6,3.6,3.6,3.6,0,0,1,3.6-3.6Z"
        transform="translate(-12.11 -13.474)"
        fill={color}
      />
      <path
        id="Path_1690"
        data-name="Path 1690"
        d="M1,33H6.235v.654H1Z"
        transform="translate(0 -21.548)"
        fill={color}
      />
      <path
        id="Path_1691"
        data-name="Path 1691"
        d="M47,33h5.235v.654H47Z"
        transform="translate(-30.948 -21.548)"
        fill={color}
      />
      <path
        id="Path_1692"
        data-name="Path 1692"
        d="M39,9h1.963v.654H39Z"
        transform="translate(-25.566 -5.401)"
        fill={color}
      />
      <path
        id="Path_1693"
        data-name="Path 1693"
        d="M35,13h1.963v.654H35Z"
        transform="translate(-22.875 -8.092)"
        fill={color}
      />
      <path
        id="Path_1694"
        data-name="Path 1694"
        d="M39,17h1.963v.654H39Z"
        transform="translate(-25.566 -10.783)"
        fill={color}
      />
      <path
        id="Path_1695"
        data-name="Path 1695"
        d="M19,57h1.963v.654H19Z"
        transform="translate(-12.11 -37.695)"
        fill={color}
      />
      <path
        id="Path_1696"
        data-name="Path 1696"
        d="M22,53h2.29v.654H22Z"
        transform="translate(-14.129 -35.004)"
        fill={color}
      />
      <path
        id="Path_1697"
        data-name="Path 1697"
        d="M19,49h1.963v.654H19Z"
        transform="translate(-12.11 -32.312)"
        fill={color}
      />
      <path
        id="Path_1698"
        data-name="Path 1698"
        d="M35.373,31.79a.982.982,0,0,0-.982-.982.327.327,0,1,1,0-.654h.982V29.5h-.982a.982.982,0,0,0,0,1.963.327.327,0,0,1,0,.654H33.41v.654h.982A.982.982,0,0,0,35.373,31.79Z"
        transform="translate(-21.805 -19.193)"
        fill={color}
      />
      <path
        id="Path_1699"
        data-name="Path 1699"
        d="M25.513,32.772h.654a.327.327,0,0,0,.321-.262l.6-3.01h-.668L25.9,32.118H25.78L25.257,29.5H24.59l.6,3.01A.327.327,0,0,0,25.513,32.772Z"
        transform="translate(-15.871 -19.193)"
        fill={color}
      />
    </g>
  </svg>
)
