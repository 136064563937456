import styled, { css } from "styled-components";
import { Form, Input } from "antd";

export const BaseInput = css`
  background: none;
  font-family: "Bw Modelica", sans-serif;

  @media only screen and (min-width: 768px) {
    line-height: 24px;
  }

  input {
    background: none;
    color: var(--uduxWhitePrimary);
  }

  &.ant-input-affix-wrapper,
  &.ant-input-affix-wrapper {
    border: none;
    border-bottom: solid 0.1rem var(--uduxWhitePrimary);
    padding: 1rem 0;
    box-shadow: none;

    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
      border-color: var(--uduxYellowPrimary);
    }
  }

  &.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border-color: var(--uduxYellowPrimary);
    background: none;
    box-shadow: none;
  }
`;

export const CustomForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-grow: 0.35;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .form-item-container,
  & > .ant-form-item {
    // margin-bottom: ${({ login }) => (login ? "5rem" : "")};
    margin-bottom: 2.5rem;

    @media only screen and (min-width: 500px) {
      margin-bottom: 3rem;
    }
  }
`;

export const TextInputView = styled(Input)`
  ${BaseInput};
`;

export const PasswordInput = styled(Input.Password)`
  ${BaseInput};
  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 6rem;
  }
  .password-icon {
    color: var(--uduxYellowPrimary);

    padding-right: 3rem;
  }
`;
