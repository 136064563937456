import { FlexibleDiv } from "components"
import styled from "styled-components"

export const GeneralSearchBarWrap = styled(FlexibleDiv)`
  margin-left: 60px;
  min-width: 150px;
  position: relative;

  .searchinput__wrap {
    height: 45px;
    overflow: hidden;
    position: relative;
    align-items: center;
    padding: 0 0.5rem;
    transition: all 0.3s ease;
    justify-content: flex-start;
    transition: all 0.3s ease;
    border: ${({ active }) =>
      active ? "0.5px solid white" : "0.5px solid var(--uduxGrayLightAlpha)"};

    :hover {
      border: 0.5px solid var(--uduxYellowPrimary);
    }
  }

  .searchicon__wrap {
    cursor: pointer;
    transition: all 0.3s ease;
    width: max-content;
    margin-right: 0.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      transform: scale(1.1);
    }
  }

  .search__input {
    flex: 1;
    outline: 0;
    height: 100%;
    border: none;
    font-size: 0.85rem;
    padding-left: 0.5rem;
    color: var(--textPrimary);
    background-color: transparent;
    font-family: "Baguede", sans-serif;
  }

  .clear__search {
    position: absolute;
    cursor: pointer;
    right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  @media (max-width: 950px) {
    width: 80%;
    margin-left: 50px;
  }
`
