import { SearchIcon } from "assets/images/player/SVGExports";
import { FlexibleDiv } from "components";
import { useMainContext } from "context/mainContext";
import React from "react";
import { CiCircleRemove as ClearIcon } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import { GeneralSearchBarWrap } from "./general-search-bar.styles";

export const GeneralSearchBar = () => {
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const { setSearchQuery, searchQuery, clearSearchQuery } = useMainContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleChange = ({ target }) => {
    setSearchQuery(target.value);
  };

  React.useEffect(() => {
    setIsSearchOpen(pathname.includes("/search"));
  }, [pathname]);

  return (
    <GeneralSearchBarWrap
      width="420px"
      justifyContent="flex-start"
      className="search__wrap"
      alignItems="center"
      active={isSearchOpen}
    >
      <FlexibleDiv
        className="searchinput__wrap"
        onClick={() => {
          pathname !== "/search" && navigate("/search");
        }}
      >
        <div className="searchicon__wrap">
          <SearchIcon
            color={!!isSearchOpen ? "var(--uduxYellowPrimary)" : "#D9D9D9"}
          />
        </div>

        <input
          name="searchQuery"
          value={searchQuery}
          onChange={handleChange}
          placeholder="What do you want to listen to?"
          className="search__input"
          autoComplete="off"
        />

        {searchQuery && (
          <div className="clear__search" onClick={clearSearchQuery}>
            <ClearIcon color="white" />
          </div>
        )}
      </FlexibleDiv>
    </GeneralSearchBarWrap>
  );
};
