import { Types } from "context/types"

export const artistReducer = (state, { type, payload }) => {
  switch (type) {
    case Types.ACTIVE_ARTIST_PROFILE:
      const { artist } = state
      return {
        ...state,
        artist: {
          ...artist,
          ...payload,
        },
      }

    default:
      return state
  }
}
