import styled from "styled-components"
import { FlexibleDiv } from "components"

export const PaymentHomeWrapper = styled(FlexibleDiv)`
  position: relative;

  /* .react-reveal {
    width: 100vw;
  } */

  .subscribe__btn__mobile {
    display: none;
  }

  @media (max-width: 1000px) {
    .subscribe__btn__mobile {
      z-index: 100;
      display: flex;
      background: var(--uduxBlackPrimary);
      padding: 20px;
      position: fixed;
      height: 120px;
      bottom: 0;

      button {
        width: fit-content;
        background: #ffa800;
        border: 1px solid #ffa800;
        border-radius: 0;
        padding: 8px 12px;
        transition: background-color 0.5s;

        :hover {
          background-color: #12a24b;
          cursor: pointer;
          border: 1px solid #12a24b;
        }

        span {
          color: var(--uduxBackgroundBlack);
          font-family: "Baguede";
          font-weight: bolder;
          font-size: 1rem;
          -webkit-text-stroke: 0.7px var(--uduxBackgroundBlack);
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 912px) {
    .subscribe__btn__mobile {
      height: 130px;

      @media (min-width: 812px) and (max-width: 899px) {
        height: 170px;
      }

      @media (min-width: 900px) and (max-width: 1000px) {
        height: 170px;
      }

      button {
        padding: 14px 20px;

        @media (min-width: 812px) and (max-width: 899px) {
          height: 16px 22px;
        }

        @media (min-width: 900px) and (max-width: 1000px) {
          padding: 16px 22px;
        }

        span {
          font-size: 1.2rem;
          -webkit-text-stroke: 1px var(--uduxBackgroundBlack);

          @media (min-width: 900px) and (max-width: 1000px) {
            /* max-height: 100%; */
            font-size: 1.4rem;
          }
        }
      }
    }
  }
`
