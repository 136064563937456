import { LoadingOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { Button, FlexibleDiv, PhoneInput } from "components";
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout";
import { Types, useMainContext } from "context";
import { loginWithPhone } from "network";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Container } from "./phone.styles";
import galleryImage from "assets/images/Auth/continue_with_phone.png";

const LoginWithPhoneNumber = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const { mutate, isLoading } = useMutation(loginWithPhone);
  const { dispatch } = useMainContext();

  useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined });
  }, [dispatch]);

  return (
    <AuthLayout
      title="Follow the Rhythm"
      subText="Lets start with a phone number"
      galleryImage={galleryImage}
    >
      <Container>
        <Form onFinish={handleFormSubmit} form={form} className="form_box">
          <FlexibleDiv className="input">
            <PhoneInput
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e);
              }}
              onSetCountryCode={(code) => setCountryCode(code)}
              bordered
              small
            />
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              width="220px"
              height="50px"
              font_size="16px"
              htmlType="submit"
              color="var(--black)"
              contained
              bold
              ltr
            >
              <span>
                {isLoading ? (
                  <LoadingOutlined color="var(--uduxYellowPrimary)" />
                ) : (
                  "Continue"
                )}
              </span>
            </Button>
          </FlexibleDiv>
        </Form>
      </Container>
    </AuthLayout>
  );

  async function handleFormSubmit() {
    const payload = {
      phoneNumber: `+${phoneNumber}`,
    };

    mutate(payload, {
      onSuccess: () => {
        navigate("/login/verify-otp", { state: payload });
      },
      onError: (error) => {
        dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload: error,
        });
      },
    });
  }
};

export default LoginWithPhoneNumber;
