export const CompassIcon = ({}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.6"
    height="14"
    viewBox="0 0 18.895 18.895"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="path504"
          d="M18.895-682.665H0v18.895H18.895Z"
          transform="translate(0 682.665)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g id="g474" transform="translate(0 682.665)">
      <g id="g476" transform="translate(7.712 -678.385)">
        <path
          id="path478"
          d="M1.168,0H0"
          transform="translate(0)"
          fill="rgba(0,0,0,0)"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.75"
        />
      </g>
      <g id="g480" transform="translate(7.712 -679.441)">
        <path
          id="path482"
          d="M-42.2,0h1.168"
          transform="translate(42.196)"
          fill="rgba(0,0,0,0)"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.75"
        />
      </g>
      <g id="g484" transform="translate(12.255 -679.441)">
        <path
          id="path486"
          d="M-41.867,0h1.159"
          transform="translate(41.867)"
          fill="rgba(0,0,0,0)"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.75"
        />
      </g>
      <g id="g488" transform="translate(12.255 -678.385)">
        <path
          id="path490"
          d="M1.159,0H0"
          fill="rgba(0,0,0,0)"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.75"
        />
      </g>
      <g id="g492" transform="translate(9.988 -678.385)">
        <path
          id="path494"
          d="M1.159,0H0"
          fill="rgba(0,0,0,0)"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.75"
        />
      </g>
      <g id="g496" transform="translate(9.988 -679.441)">
        <path
          id="path498"
          d="M-41.865,0h1.159"
          transform="translate(41.865)"
          fill="rgba(0,0,0,0)"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.75"
        />
      </g>
      <g id="g500" transform="translate(0 -682.665)">
        <g id="g502" clip-path="url(#clip-path)">
          <g id="g508" transform="translate(3.314 3.224)">
            <path
              id="path510"
              d="M1.022-37.084H.335A.335.335,0,0,1,0-37.419v-.386a.335.335,0,0,1,.335-.335h.687"
              transform="translate(0 38.14)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g512" transform="translate(5.445 4.28)">
            <path
              id="path514"
              d="M1.159,0H0"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g516" transform="translate(5.445 3.224)">
            <path
              id="path518"
              d="M-41.867,0h1.159"
              transform="translate(41.867)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g520" transform="translate(14.522 3.224)">
            <path
              id="path522"
              d="M-36.928,0h.687a.335.335,0,0,1,.335.335V.721a.335.335,0,0,1-.335.335h-.687"
              transform="translate(36.928)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g524" transform="translate(8.879 1.939)">
            <path
              id="path526"
              d="M-31.576,0h.652A.228.228,0,0,1-30.7.228V8.085a.554.554,0,0,1-.554.554.554.554,0,0,1-.554-.554V.228A.228.228,0,0,1-31.576,0Z"
              transform="translate(31.804)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g528" transform="translate(11.146 1.939)">
            <path
              id="path530"
              d="M-31.577,0h.652A.228.228,0,0,1-30.7.228V7.117a.554.554,0,0,1-.554.554.554.554,0,0,1-.554-.554V.228A.228.228,0,0,1-31.577,0Z"
              transform="translate(31.805)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g532" transform="translate(13.413 1.939)">
            <path
              id="path534"
              d="M-31.577,0h.652A.228.228,0,0,1-30.7.228V5.869a.554.554,0,0,1-.554.554.554.554,0,0,1-.554-.554V.228A.228.228,0,0,1-31.577,0Z"
              transform="translate(31.805)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g536" transform="translate(6.603 1.939)">
            <path
              id="path538"
              d="M-7.354,0h-.652a.228.228,0,0,0-.228.228V7.117a.554.554,0,0,0,.554.554.554.554,0,0,0,.554-.554V.228A.228.228,0,0,0-7.354,0Z"
              transform="translate(8.235)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g540" transform="translate(4.336 1.939)">
            <path
              id="path542"
              d="M-7.354,0h-.652a.228.228,0,0,0-.228.228V5.869a.554.554,0,0,0,.554.554.554.554,0,0,0,.554-.554V.228A.228.228,0,0,0-7.354,0Z"
              transform="translate(8.235)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g544" transform="translate(6.686 11.676)">
            <path
              id="path546"
              d="M-165.625-24.957a2.751,2.751,0,0,1,1.849-.71,2.761,2.761,0,0,1,2.761,2.761,2.761,2.761,0,0,1-2.761,2.761,2.761,2.761,0,0,1-2.761-2.761,2.751,2.751,0,0,1,.223-1.087"
              transform="translate(166.537 25.668)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g548" transform="translate(7.794 12.783)">
            <path
              id="path550"
              d="M-58.1-116.195a1.656,1.656,0,0,0,1.654-1.654A1.656,1.656,0,0,0-58.1-119.5a1.656,1.656,0,0,0-1.654,1.654A1.656,1.656,0,0,0-58.1-116.195Z"
              transform="translate(59.751 119.502)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g552" transform="translate(5.48 0.281)">
            <path
              id="path554"
              d="M-409.756,0h9.8a1.544,1.544,0,0,1,1.544,1.544V12.936"
              transform="translate(409.756)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
          <g id="g556" transform="translate(2.073 0.281)">
            <path
              id="path558"
              d="M14.748-496.4v2.661a1.545,1.545,0,0,1-1.544,1.546H1.544A1.545,1.545,0,0,1,0-493.738v-15.246a1.545,1.545,0,0,1,1.544-1.546h.683"
              transform="translate(0 510.529)"
              fill="rgba(0,0,0,0)"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.75"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
