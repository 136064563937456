import styled from "styled-components"
import { FlexibleDiv } from "../Box"
import cardIconBG from "assets/images/Auth/login_type_artwork.png"
import cardIconBGHover from "assets/images/Auth/login_type_artwork_hover_2.png"

export const ActionBoxWrapper = styled(FlexibleDiv)`
  border: 0.75px solid #eaeaeafc;
  height: 70px;
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
  justify-content: stretch;

  @media (max-height: 680px) {
    height: 54px;
  }

  @media (min-height: 681px) and (max-height: 799px) {
    height: 62px;
  }

  :not(:first-child) {
    margin-top: 25px;
  }

  :hover {
    border: 0.75px solid var(--uduxYellowPrimary);
    transition: all 0.5s ease;
  }

  :hover .card__icon__box {
    background: url(${cardIconBGHover}) no-repeat center;
    background-size: cover;
  }

  .pad__content {
    padding: 0.5rem;
    display: flex;
    height: 100%;
    width: max-content;
    border-right: 0.25px solid #eaeaea80;

    svg {
      width: 60%;
      height: 60%;
    }
  }

  .card__icon__box {
    width: 120px;
    height: 100%;
    border: 0.2px solid #eaeaea80;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${cardIconBG}) no-repeat center;
    background-size: cover;

    .white_logo {
      width: 50px;
      height: auto;
    }

    .exist_icon {
      width: 33px;
      height: auto;
    }
  }

  .card__text__box {
    padding: 1rem 0 1rem 1rem;
    width: 70%;
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;

    .text {
      font-size: 16px;
      color: white;
      margin: 0;
      letter-spacing: -0.99px;
      font-family: "Baguede", sans-serif;
      line-height: 1.25;
    }

    .udux__yellow {
      color: var(--uduxYellowPrimary);
    }

    &--loading {
      display: flex;
      justify-content: center;
      width: 65%;

      svg {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 1800px) {
    .card__icon__box {
      width: 100px;
    }

    .card__text__box {
      .text {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .card__icon__box {
      width: 65px;
    }
  }

  @media screen and (max-width: 600px) {
    height: 40px;

    .pad__content {
      padding: 0.5rem;
      display: flex;
      width: 80px;
      border-right: 0.25px solid #eaeaea80;

      svg {
        width: 50%;
        height: 50%;
      }
    }

    .card__icon__box {
      width: 120px;
      height: 100%;
      border: 0.2px solid #eaeaea80;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url(${cardIconBG}) no-repeat center;
      background-size: cover;

      .white_logo {
        width: 50px;
        height: auto;
      }

      .exist_icon {
        width: 33px;
        height: auto;
      }
    }

    .card__text__box {
      padding: 1rem;

      .text {
        font-size: 13px;
      }
    }
  }
`
