import styled from "styled-components"
import ArtBoard from "assets/images/payment/actionBoxTextImage.png"

export const ChoosePlatformWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ leftColor }) =>
    leftColor ? leftColor : `var(--uduxYellowDeep)`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .bg__wrap__main {
    width: 100%;
    height: 100%;
    background-color: inherit;
    position: absolute;

    .color__box__area,
    .art__box__area {
      height: 100%;
    }

    .color__box__area {
      width: 35%;
      background-color: ${({ leftColor }) =>
        leftColor ? leftColor : `var(--uduxYellowDeep)`};
    }
    .art__box__area {
      width: 65%;
      background-color: transparent;
      background: ${({ rightImage }) =>
        rightImage
          ? `url(${rightImage}) no-repeat center / cover`
          : `url(${ArtBoard}) no-repeat center / cover`};
    }

    .mobile__color__box__area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mobile__art__box__area {
      width: 90%;
      height: 95%;
      background-color: transparent;
      background: ${({ rightImage }) =>
        rightImage
          ? `url(${rightImage}) no-repeat center / cover`
          : `url(${ArtBoard}) no-repeat center / cover`};
    }
  }

  .content__box {
    z-index: 1;
    width: 94%;
    height: 90%;

    .gallery__pane,
    .activity__pane {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

      .gallery__pane {
      width: 38%;
      .swiper{
        width: 100%;
        height: 100%;

        .slider__slide {
         position: relative;
         width: 100%;
        }

        .gallery_pane_img{
          width: 100%;
          height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        }
      }


    }

    .activity__pane {
      width: 60%;
      padding: 7% 4rem 0 4rem;
      background-color: var(--uduxBlackPrimary);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;

      .logo__wrap {
        position: absolute;
        right: 5%;
        top: 5%;
        width: 65px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .go__back__box {
        left: 5%;
        top: 5%;
        outline: 0;
        border: none;
        display: flex;
        cursor: pointer;
        padding: 0px 4px;
        position: absolute;
        width: max-content;
        align-items: center;
        justify-content: space-around;
        background-color: transparent;

        .go__back {
          cursor: pointer;
          width: max-content;

          span {
            opacity: 0.6;
            font-size: 1.35rem;
          }
        }
      }
    }

    .content__box__layout {
      width: 90%;
      height: 100%;
    }

    .content__layout {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .title {
        color: #ff9d00;
        font-size: ${({ checkHeight }) => {
          return checkHeight ? "28px" : "22px"
        }};
        font-weight: 600;
        margin: ${({ checkHeight }) => {
          return checkHeight ? "10px 0px 3px" : "5px 0px"
        }};
        text-align: center;
        letter-spacing: -1.44px;
        font-family: "Baguede", sans-serif;
      }

      .subscription__details__wrapper {
        margin-top: 1rem;

        @media (max-width: 1900px) {
          margin-top: 0.8rem;
          width: 75%;
        }

        @media (max-width: 1400px) {
          margin-top: 0.6rem;
          width: 95%;
        }

        @media (max-width: 1100px) {
          margin-top: 0.8rem;
          width: 100%;
        }

        @media (max-width: 1000px) {
          margin-top: 0.8rem;
          width: 100%;
          max-height: fit-content;
        }

        @media (max-width: 550px) {
          flex-direction: row;
        }

        @media (max-width: 376px) {
          margin-top: 0;
        }

        p {
          height: fit-content;
          margin: 0px;
        }

        .left__wrapper {
          background: url(${ArtBoard}) no-repeat center / cover;

          @media (max-width: 550px) {
            width: 25%;
            height: auto;
            box-sizing: border-box;
            background-repeat: no-repeat;
            background-position: center;
            flex-wrap: nowrap;
          }

          .type__text {
            font-size: 1rem;
            font-family: "Bw ModelicaRegular";
            color: var(--uduxWhitePrimary);
            -webkit-text-stroke: 0.5px var(--uduxWhitePrimary);

            @media (max-width: 1100px) {
              font-size: 0.8rem;
            }

            @media (max-width: 1400px) {
              font-size: 0.9rem;
            }
          }

          .price__text {
            font-size: 1.4rem;
            font-family: "Baguede";
            color: var(--uduxWhitePrimary);
            -webkit-text-stroke: 1px var(--uduxWhitePrimary);

            @media (max-width: 1100px) {
              font-size: 1.2rem;
            }

            @media (max-width: 1400px) {
              font-size: 1.3rem;
            }
          }
        }

        .right__wrapper {
          padding: 0.5rem 1rem;
          background-color: #15848f;

          @media (max-width: 550px) {
            width: 70%;
            height: auto;
          }

          .content__text {
            font-size: 0.8rem;
            font-family: "Bw ModelicaMedium";
            color: var(--uduxWhitePrimary);
            line-height: 1.2;

            @media (max-width: 1100px) {
              font-size: 0.7rem;
            }

            @media (max-width: 550px) {
              font-size: 0.55rem;
              padding: 0;
            }

            @media (max-width: 280px) {
              font-size: 0.5rem;
              padding: 0;
            }
          }
        }
      }

      .auth__response__error__main {
        margin: 2rem auto 1.3rem;
        width: 100%;

        @media (max-width: 376px) {
          margin: 1rem auto 1rem;
        }
      }

      .form__wrap {
        margin-top: 0rem;
        overflow: auto;
        height: 100%;
        width: 85%;

        ::-webkit-scrollbar {
          display: none;
        }

        @media (max-width: 1200px) {
          width: 100%;
        }

        @media (min-width: 1400px) {
          width: 75%;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }

  @media screen and (max-width: 996px) {
    .content__box {
      height: 92%;

      .activity__pane {
        width: 92%;
        padding: 15% 1rem 0 1rem;
        background-color: var(--uduxBlackPrimary);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        @media (max-width: 500px) {
          padding: 22% 1rem 0 1rem;
        }

        @media (max-width: 375px) {
          padding: 22% 1rem 0 1rem;
        }

        .logo__wrap {
          position: initial;
          width: 65px;
          margin-bottom: 1.5rem;

          @media (max-width: 500px) {
            margin-bottom: 1rem;
          }

          @media (max-width: 376px) {
            margin-bottom: 0rem;
          }
        }
      }

      .content__box__layout {
        width: 98%;
      }

      .content__layout {
        .title {
          font-size: ${({ checkHeight }) => {
            return checkHeight ? "20px" : "18px"
          }};
          letter-spacing: -0.65px;
          font-family: "Baguede", sans-serif;
          font-weight: 900;
        }

        .sub__text {
          font-size: 12px;
          font-family: "Bw ModelicaBold", sans-serif;
        }

        .form__wrap {
          margin-top: ${({ checkHeight }) => {
            return checkHeight ? "2%" : "1%"
          }};
        }
      }
    }
  }

  @media screen and (min-width: 650px) and (max-width: 995px) {
    .content__box {
      .content__layout {
        .form__wrap {
          width: 85%;
        }
      }
      .activity__pane {
        padding: 10% 4rem;
      }
    }
  }

  @media screen and (min-width: 997px) and (max-width: 1230px) {
    .content__box {
      .content__layout {
        .form__wrap {
          width: 100%;
        }
      }
      .content__box__layout {
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .content__box {
      .content__layout {
        @media (max-width: 550px) {
          justify-content: start !important;
          margin-top: 2%;
        }
        .form__wrap {
          width: 100%;
          /* max-height: 230px; */
        }
      }
    }
  }

  @media screen and (max-height: 896px) {
    .content__box {
      .content__layout {
        @media (max-width: 376px) {
          justify-content: start;
          margin-top: 2%;
        }

        .form__wrap {
          /* margin-bottom: 30px; */
        }
      }
    }
  }
`
