export const redirectRoutes = [
  "/",
  "/login",
  "/signup",
  "/verify-email",
  "/reset-password",
  "/forgot-password",
];

export const settingsTabRoutes = [
  {
    title: "My Details",
    path: "/settings#my-details",
  },
  {
    title: "My Subscriptions",
    path: "/settings#my-subscriptions",
  },
  {
    title: "Compass",
    path: "/settings#compass",
  },
  {
    title: "Settings",
    path: "/settings#my-settings",
  },
];

export const playerTabRoutes = [
  {
    title: "Artist Catalogue",
    hash: "#artist-catalogue",
  },
  {
    title: "Recommended",
    hash: "#recommended",
  },
  {
    title: "Add To Playlist",
    hash: "#add-to-playlist",
  },
  {
    title: "Manage Queue",
    hash: "#manage-queue",
  },
];

export const controlPanelRoutes = [];
