import styled from "styled-components";
import SidebarBg from "../../../../assets/images/player/player-sidebar-drums-bg.png";
import SidebarBG from "assets/images/general/sidebar_bng.png";

export const MobileNav = styled.div`
  position: fixed;
  // border: 2px solid red;
  z-index: 10 !important;
  bottom: 0;
  background: linear-gradient(89.79deg, #000000ce -100%, #000000ef 130.38%),
    url(${SidebarBG}) no-repeat center / cover;
  // background-color: black;
  width: 100vw;
  height: 70px;
`;

export const SidebarWrap = styled.div`
  width: var(--sidebar-width-expanded);
  height: 100vh;
  overflow: hidden;
  position: relative;
  position: fixed;
  left: 0;
  top: 0;
  background: linear-gradient(89.79deg, #000000ce -100%, #000000ef 130.38%),
    url(${SidebarBG}) no-repeat center / cover;
  z-index: 1;
  transition: all 300ms cubic-bezier(0.155, 0.33, 0.885, 0.27);

  .udux__sidebar__logo {
    width: 80px;
    height: 80px;
    transition: all 200ms cubic-bezier(0.155, 0.33, 0.885, 0.27);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 950px) {
    display: none;
  }

  .sidebar__subwrap .sidebar__userwrap {
    padding: 0 2rem;
  }

  .sidebar__nav__sectionitems,
  .sidebar__activenav__sectionitems {
    padding: 3px 6px;
  }

  .nav__section__content {
    display: block;
    padding-top: 2px;
  }



  .sidebar__subwrap {
    height: 100%;
    justify-content: flex-start;

    .sidebar__userwrap {
      min-height: 90px;

      .user-profile-info {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .user__image {
        border-radius: 100%;
        width: 60px;
        height: 60px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }

      .greeting_text {
        margin-left: 1.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--uduxWhiteSecondary);
        text-transform: capitalize;
      }
    }
    .sidebar__nav__sectionitems {
      @media screen and (min-width: 960px) {
        height: 35px !important;
        width: 250px !important;
      }

      margin: 5px 0 6px 0;
      cursor: pointer;
      box-sizing: border-box;
      background: transparent;
      border: 1px solid transparent;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
      }

      svg {
        color: var(--uduxWhiteSecondary);
      }
  
      .nav__section__content {
        color: var(--uduxWhiteSecondary);
        // border: 2px solid red !important;
        // display: none;
        margin: 0;
        margin: 0 14px;
        font-family: "Baguede", sans-serif;
        font-size: 1rem;
      }

      &:hover {
        /* border: 2px solid red; */
        box-shadow: inset 500px 500px 0 2000px #63543a94;
      }
    }

    .sidebar__bodywrap {
      height: ${({ isPlayerActive }) =>
        !!isPlayerActive ? "calc(100vh - 200px)" : "calc(100vh - 90px)"};
      justify-content: flex-start;
      padding-bottom: 20px;

      .sidebar__navwrap {
        padding: 25px;

        .searchinput__wrap {
          transition: all 0.3s ease;
          overflow: hidden;
        }
        .searchicon {
          margin-bottom: -10px;
        }

        .sidebar__nav__sections {
          border-bottom: 0.5px solid #282828;
          padding: 20px 0;

          .sidebar__activenav__sectionitems {
            position: relative;
            margin: 5px 0 14px 0;

            /* @media screen and (min-width: 960px) {
              height: 30px;
              width: 210px;
            } */

            /* ::after {
              content: "";
              width: 3px;
              height: 100px;
              background-color: var(--uduxYellowPrimary);
              right: -25px;
              position: absolute;
              z-index: 9;
            } */
            .nav__section__content {
              color: var(--uduxYellowPrimary);
            }
            .fill__player__svg {
              path {
                fill: var(--uduxYellowPrimary);
              }
            }
            .stroke__player__svg {
              path {
                stroke: var(--uduxYellowPrimary);
              }
            }
            :hover {
              .nav__section__content,
              svg {
                opacity: 1;
              }
            }
          }
        }
      }

      .sidebar__playlistwrap {
        padding: 0 25px;
        margin-top: -10px;
        height: ${({ isPlayerActive }) =>
          !!isPlayerActive ? "calc(100vh - 560px)" : "calc(100vh - 460px)"};
        justify-content: flex-start;
        overflow-y: auto;

        .playlist__item {
          white-space: nowrap;
          width: 100%;
          overflow-x: hidden;
          text-overflow: ellipsis;
          color: var(--uduxWhiteSecondary);
          cursor: pointer;
          transition: all 0.3s ease;
          :hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
`;
