import { FlexibleDiv } from "components";
import styled from "styled-components";

export const RegisterContent = styled(FlexibleDiv)`
  max-height: 270px;

  .confirm__text {
    font-size: 0.85rem;
    font-family: "Bw ModelicaBold";
    font-weight: bold;
    text-align: center;
    width: 100% !important;
    margin: 0;

    @media (max-width: 376px) {
      font-size: 0.7rem;
    }
  }

  .change__method__text {
    font-size: 0.65rem;
    text-decoration: none;
    color: var(--uduxWhitePrimary);
    font-family: "Bw ModelicaRegular";
    opacity: 0.7;
  }

  .subscribe__btn {
    width: fit-content;
    background: #ffa300;
    text-transform: none;
    border-radius: 0;
    transition: transform 1s;

    @media (max-width: 376px) {
      font-size: 0.7rem;
    }

    span {
      font-size: 0.75rem;
      color: var(--uduxBlackPrimary);
      font-family: "Baguede";
      -webkit-text-stroke-width: 0.8px;

      @media (max-width: 376px) {
        font-size: 0.65rem;
      }
    }

    :hover {
      transform: scale(1.1);
    }
  }

  .logout__class {
    font-size: 0.65rem;
    text-decoration: none;
    color: var(--uduxWhitePrimary);
    font-family: "Bw ModelicaRegular";
    opacity: 0.7;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
