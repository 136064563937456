import { Types, useMainContext } from "context"
import { Fragment, useEffect, useRef } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { isEmpty } from "utils"

export const ProtectedRoute = ({ children, fromPaymentPage }) => {
  const location = useLocation()
  const userObject = useRef(JSON.parse(localStorage.getItem("udx__user")))
  const {
    state: { users },
    dispatch,
  } = useMainContext()

  const isLoggedIn = Boolean(users?.me?.id) || Boolean(userObject?.current?.id)

  useEffect(() => {
    if (isEmpty(users.me) && !isEmpty(userObject?.current)) {
      dispatch({
        type: Types.CURRENT_USER,
        payload: userObject?.current,
      })
    }
  }, [])

  return (
    <Fragment>
      {isLoggedIn ? (
        children
      ) : (
        <Navigate
          to={`${fromPaymentPage ? "/payment" : "/login"}`}
          replace={true}
          state={{ from: location }}
        />
      )}
    </Fragment>
  )
}
