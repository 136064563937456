import emptyBg from "assets/images/general/empty-collab.svg";
import { FlexibleDiv } from "components/lib/Box";
import { Button } from "components/lib/Button";
import { NDDWrap } from "./no-data-display.styles";

export const NoCollabPlaylistDisplay = ({ isOwner, ...props }) => {
  return (
    <NDDWrap {...props}>
      <div className="no__display__image">
        <img src={emptyBg} />
      </div>
      <p className="text__content">Find other Music Lovers to Collab with</p>

      <FlexibleDiv className="button__wrap">
        <Button contained>
          <span>{isOwner ? "Find X'ers" : "Invite to Collaborate"}</span>
        </Button>
      </FlexibleDiv>
    </NDDWrap>
  );
};
