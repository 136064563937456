import styled from "styled-components";

export const ListWrap = styled.div`
  width: 80%;
  max-width: 100%;
  overflow-x: auto;
  padding-left:5px;

  .custom-row {
    background-color: transparent;
   transition: all 0.3s ease-in-out;
  }

  .custom-row:hover {
     background-color: var(--backgroundDarkLight);
  }

  .custom-row:hover td, .ant-table-tbody tr td .ant-table-cell-row-hover {
    background-color: transparent !important;
  }

  .ant-table {
    background-color: transparent !important;
  }

  /* .ant-table tr{
  } */

  .ant-table-tbody tr td {
    color: var(--uduxWhitePrimary) !important;
    border: none;
    font-family: "Baguede";
    padding: 10px 0px;
  }

  .ant-table-tbody {
    color: var(--uduxWhitePrimary) !important;
    border: none;
    font-family: "Baguede";
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    .ant-table-tbody tr td {
      padding-right: 20px;
      white-space: nowrap;
  }
  }
`;
