export const GotoAlbumIcon = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "14"}
    height={height || "17"}
    viewBox="0 0 20 20"
    {...props}
  >
    <g id="music-4" transform="translate(-48 -8.005)">
      <path
        id="Path_3929"
        data-name="Path 3929"
        d="M117.145,152a5.145,5.145,0,1,0,5.145,5.145A5.151,5.151,0,0,0,117.145,152Zm0,9.718a4.573,4.573,0,1,1,4.573-4.573,4.573,4.573,0,0,1-4.573,4.573Z"
        transform="translate(-61.713 -138.851)"
        fill="#fff"
      />
      <path
        id="Path_3930"
        data-name="Path 3930"
        d="M62.577,10.863H60.862V8.29a.286.286,0,0,0-.35-.278L48.221,10.87a.285.285,0,0,0-.221.278V25.439a.286.286,0,0,0,.286.286H62.577a.286.286,0,0,0,.286-.286V11.148A.286.286,0,0,0,62.577,10.863ZM60.29,8.65v2.213H50.777Zm2,16.5H48.572V11.434H62.291Z"
        transform="translate(0 0)"
        fill="#fff"
      />
      <path
        id="Path_3931"
        data-name="Path 3931"
        d="M181.615,208.07a.286.286,0,0,0-.232-.064l-3.43.572a.286.286,0,0,0-.239.282v3.3a1.143,1.143,0,1,0,.572.989v-2.9l2.858-.477v1.817a1.143,1.143,0,1,0,.572.989v-4.287A.286.286,0,0,0,181.615,208.07Zm-4.472,5.648a.572.572,0,1,1,.572-.572A.572.572,0,0,1,177.143,213.719Zm3.43-.572a.572.572,0,1,1,.572-.572A.572.572,0,0,1,180.573,213.147Zm.572-3.958-2.858.477V209.1l2.858-.477Z"
        transform="translate(-123.427 -192.852)"
        fill="#fff"
      />
      <path
        id="Path_3932"
        data-name="Path 3932"
        d="M82.572,121.715v-1.429a.286.286,0,0,0-.286-.286h-2a.286.286,0,0,0-.286.286v1.429a.286.286,0,0,0,.286.286h2A.286.286,0,0,0,82.572,121.715ZM82,121.429H80.572v-.857H82Z"
        transform="translate(-30.857 -107.994)"
        fill="#fff"
      />
      <path
        id="Path_3933"
        data-name="Path 3933"
        d="M360.286,432a.286.286,0,0,0,0,.572h2a.286.286,0,0,0,0-.572Z"
        transform="translate(-300.853 -408.847)"
        fill="#fff"
      />
      <path
        id="Path_3934"
        data-name="Path 3934"
        d="M393.143,456h-.857a.286.286,0,0,0,0,.572h.857a.286.286,0,1,0,0-.572Z"
        transform="translate(-331.71 -431.99)"
        fill="#fff"
      />
    </g>
  </svg>
)
