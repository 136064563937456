import { instance } from "./axios";

export const getSearchHistory = async (userId) => {
  const response = await instance.get(`/data/search/history?user=${userId}`);

  return response.data;
};

export const generalSearch = async (category = "combined", query) => {
  // const response = await instance.get(`/data/search/${category}?q=${query}`);
  const response = await instance.get(`/data/search/combined?q=${query}`);

  return response.data;
};
