export const ArtistCatalogueIcon = ({
  color = "#D9D9D9",
  width = "14",
  height = "14",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className="fill__player__svg"
    fill="none"
  >
    <path
      d="M6.97873 0C3.12355 0 0 3.12355 0 6.97873C0 10.8339 3.12355 13.9575 6.97873 13.9575C10.8339 13.9575 13.9575 10.8339 13.9575 6.97873C13.9575 3.12355 10.8339 0 6.97873 0ZM2.47632 6.97873H1.57584C1.57584 3.9987 3.9987 1.57584 6.97873 1.57584V2.47632C4.49678 2.47632 2.47632 4.49678 2.47632 6.97873ZM6.97873 9.68018C5.48731 9.68018 4.27729 8.47016 4.27729 6.97873C4.27729 5.48731 5.48731 4.27729 6.97873 4.27729C8.47016 4.27729 9.68018 5.48731 9.68018 6.97873C9.68018 8.47016 8.47016 9.68018 6.97873 9.68018ZM6.97873 6.07825C6.48065 6.07825 6.07825 6.48065 6.07825 6.97873C6.07825 7.47681 6.48065 7.87921 6.97873 7.87921C7.47681 7.87921 7.87921 7.47681 7.87921 6.97873C7.87921 6.48065 7.47681 6.07825 6.97873 6.07825Z"
      fill={color}
    />
  </svg>
);
