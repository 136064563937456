import styled from "styled-components"
import { FlexibleDiv } from "components"

export const PaymentInfoWrapper = styled(FlexibleDiv)`
  position: relative;
  height: 100vh;
  width: 100%;
  padding: 0 3.5%;
  padding-top: 100px;

  .left__section__wrapper {
    position: relative;
    .background__overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      bottom: 0;
      z-index: 0;
    }

    /* .phone__frame {
      width: fit-content;
      height: inherit;
      position: absolute;
      object-fit: contain;
      bottom: 0;
      z-index: 1;
    } */
  }

  .right__section__wrapper {
    padding-left: 3%;
    padding-top: 1%;

    .top__container {
      .small__udux__text {
        font-size: 0.9rem;
        font-family: "Bw ModelicaRegular";
        color: #fed573;
        font-weight: bolder;
        margin: 0;
        width: 100%;

        @media (min-width: 1920px) {
          font-size: 1.3rem;
        }
      }

      .big__subtitle__text,
      .big__subtitle__text span {
        color: var(--uduxWhitePrimary);
        font-family: "Baguede";
        margin: 0;
        font-size: 2.7rem;
        -webkit-text-stroke: 0.6px var(--uduxWhitePrimary);

        @media (min-width: 1920px) {
          font-size: 3.3rem;
        }
      }

      .big__subtitle__text span {
        color: #ffa300;
        font-weight: bold;
        font-size: 1em;
        -webkit-text-stroke: 0.6px #ffa300;
      }

      .subtitle__paragraph,
      .subtitle__paragraph__2__web,
      .subtitle__paragraph__2__mobile {
        color: var(--uduxGrayAuth);
        margin: 0;
        font-family: "Bw ModelicaMedium";
        font-size: 0.9rem;
        width: 100%;

        @media (min-width: 1920px) {
          font-size: 1.5rem;
        }
      }

      .subtitle__paragraph__2__mobile {
        display: none;
      }
    }

    .bottom__container {
      .box__wrapper {
        padding: 10px 15px 10px 0;
        .container__title {
          color: #00aa85;
          font-family: "BaguedeBold";
          text-align: left;
          margin: 0;
          width: 100%;
          font-size: 1.3rem;
          -webkit-text-stroke: 0.4px #00aa85;

          @media (min-width: 1920px) {
            font-size: 1.8rem;
          }
        }

        .container__subtitle {
          text-align: left;
          margin: 0;
          width: 100%;
          font-family: "Bw ModelicaMedium";
          font-size: 0.9rem;

          @media (min-width: 1920px) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 912px) {
    padding: 0;
    /* padding-bottom: 120px;
    padding-top: 70px; */

    .left__section__wrapper {
      padding: 0;
      opacity: 0.3;
      width: 100%;
      z-index: 1;
      position: absolute;
      /* bottom: 120px; */
      left: 0;
      right: 0;

      .background__overlay {
        width: 100%;
        height: 60%;
        object-fit: cover;
      }

      /* .phone__frame {
        width: 100%;
        object-fit: contain;
        height: 45%;
      } */
    }

    .right__section__wrapper {
      width: 100%;
      height: 100%;
      z-index: 2;
      padding: 0;
      justify-content: space-evenly;

      .top__container {
        padding: 0 5%;
        height: 44%;

        .small__udux__text,
        .subtitle__paragraph,
        .subtitle__paragraph__2__mobile,
        .big__subtitle__text {
          text-align: center;
        }

        .small__udux__text {
          font-size: 1rem;
        }

        .big__subtitle__text {
          font-size: 2.6rem;
          width: 100%;
        }

        .subtitle__paragraph {
          font-size: 1.2rem;
        }

        .subtitle__paragraph__2__mobile {
          display: block;
          font-size: 1.2rem;
          line-height: 2rem;
        }

        .subtitle__paragraph__2__web {
          display: none;
        }
      }

      .bottom__container {
        width: 100%;
        height: 40%;
        padding: 0 4%;

        .box__wrapper {
          align-items: space-evenly;
          width: 49%;
          padding: 0;
          padding: 2%;

          @media (min-width: 900px) and (max-width: 912px) {
            align-items: start;
          }
          .container__title {
            font-size: 1.8em;
            height: fit-content;
            text-align: center;
            padding-top: 2px;
            color: var(--uduxBlackPrimary);
            -webkit-text-stroke: 1px var(--uduxBlackPrimary);

            @media (min-width: 900px) and (max-width: 912px) {
              font-size: 2rem;
            }
          }

          .container__subtitle {
            font-size: 1.2rem;
            text-align: center;
            height: fit-content;
            color: var(--uduxBlackPrimary);
            -webkit-text-stroke: 0.4px var(--uduxBlackPrimary);

            @media (min-width: 601px) and (max-width: 768px) {
              font-size: 1rem;
            }

            @media (min-width: 769px) and (max-width: 900px) {
              font-size: 1rem;
            }
          }
        }

        .box__wrapper:nth-of-type(odd) {
          background: #1ca787;
          margin-right: 2%;
        }

        .box__wrapper:nth-of-type(even) {
          background: #fed573;
        }
      }

      .scroll__indicator {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0;
    /* margin-bottom: 120px; */
    /* padding-bottom: 120px; */

    .left__section__wrapper {
      padding: 0;
      opacity: 0.3;
      width: 100%;
      z-index: 1;
      position: absolute;
      /* bottom: 120px; */
      left: 0;
      right: 0;

      .background__overlay {
        width: 100%;
        height: 57%;
        object-fit: cover;
      }

      /* .phone__frame {
        width: 100%;
        object-fit: contain;
        height: 40%;
      } */
    }

    .right__section__wrapper {
      width: 100%;
      height: 100%;
      z-index: 2;
      padding: 0;

      .top__container {
        padding: 0 4%;

        .small__udux__text,
        .subtitle__paragraph,
        .subtitle__paragraph__2__mobile,
        .big__subtitle__text {
          text-align: center;
        }

        .big__subtitle__text {
          font-size: 2rem;
          width: 100%;

          @media (max-width: 376px) {
            font-size: 1.9rem;
          }

          @media (max-width: 280px) {
            font-size: 1.5rem;
          }
        }

        .subtitle__paragraph {
          @media (max-width: 376px) {
            font-size: 0.9em;
          }

          @media (max-width: 376px) {
            font-size: 0.8em;
          }
        }

        .subtitle__paragraph__2__mobile {
          display: block;

          @media (max-width: 376px) {
            font-size: 0.9em;
          }
          @media (max-width: 376px) {
            font-size: 0.8em;
          }
        }

        .subtitle__paragraph__2__web {
          display: none;
        }
      }

      .bottom__container {
        width: 100%;
        padding: 0 4%;
        height: 40%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 15px;
        overflow-x: scroll;

        @media (max-width: 376px) {
          padding: 0 6.5%;
          margin-bottom: 10px;
        }

        ::-webkit-scrollbar {
          display: none;
        }

        .box__wrapper {
          width: 98%;
          padding: 0;
          padding: 12px;
          flex-direction: column;
          justify-content: space-evenly;

          .container__title {
            color: var(--uduxBackgroundBlack);
            -webkit-text-stroke: 0.9px var(--uduxBackgroundBlack);
            text-align: center;
            font-weight: bold;
            line-height: normal;
            height: fit-content;

            @media (max-width: 376px) {
              font-size: 1.1rem;
            }
          }

          .container__subtitle {
            color: var(--uduxBackgroundBlack);
            text-align: center;
            line-height: normal;
            margin-bottom: -3px;
            height: fit-content;

            @media (max-width: 376px) {
              font-size: 0.85em;
            }

            @media (max-width: 376px) {
              font-size: 0.75em;
            }
          }
        }

        .box__wrapper:nth-of-type(odd) {
          background: #1ca787;
        }

        .box__wrapper:nth-of-type(even) {
          background: #fed573;
        }
      }

      .scroll__indicator {
        display: flex;
        gap: 7px;

        .left__indicator,
        .right__indicator {
          width: 40px;
          height: 4px;
          border: 1px solid #707070;
        }

        .left__indicator {
          background: #fda300;
        }
      }
    }
  }
`
