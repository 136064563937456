import { FlexibleDiv } from "components";
import styled from "styled-components";

export const PreloaderWrap = styled(FlexibleDiv)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9 !important;
  height: 100%;
  bottom: 0;
  left: 0;
  background: rgba(15, 15, 15, 0.95);
  padding: 10px;
  transition: transform 0.5s ease-in-out;
  transform: ${({ showPreloader }) =>
    showPreloader ? "translateX(0)" : "translateX(-120vw)"};
  overflow: hidden;

  & > div {
    border: 0.5px solid rgba(217, 217, 217, 0.6);
    padding: 15px;
    transition: all 0.7s ease-in-out;

    .swipe__wrap {
      padding: 0 3%;
      h4 {
        color: var(--uduxYellowPrimary);
        font-size: 14px;
        max-width: 180px;
      }
      small {
        font-size: 10px;
        max-width: 170px;
      }

      .swipeup__wrap {
        h4,
        small {
          text-align: center;
        }
      }
      .swiperight__wrap {
        svg {
          margin: 0 0 -3px 7px;
        }
      }
      .swipeleft__wrap {
        svg {
          margin: 0 7px -3px 0;
        }
      }
    }
  }
`;
