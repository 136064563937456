import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { PaymentAuthenticationLayout } from "components/layouts/PaymentsLayout/PaymentAuthenticationLayout/PaymentAuthenticationLayout"
import { CustomForm } from "components/lib/Form/form.styles"
import { validateBasicField, validatePhoneNumber } from "utils"
import { Button, PhoneInput, TextField } from "components"
import { signUp } from "network"
import img from "assets/images/payment/dancingPeople.png"
import { RegisterContent } from "./paymentRegisterComplete.styles"
import { useMainContext } from "context"

const PaymentRegister = () => {
  const navigate = useNavigate()
  const {
    state: {
      app: { paymentMethod },
    },
  } = useMainContext()
  const [loading, setLoading] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const onFinish = async value => {
    const register = JSON.parse(localStorage.getItem("register"))

    const data = {
      ...register,
      firstName: value.firstname,
      lastName: value.lastname,
      countryCode: value.number.countryCode,
      phoneNumber: `${value.number.countryCode}${value.number.number}`,
    }

    setLoading(true)
    try {
      const response = await signUp(data)

      localStorage.removeItem("register")
      toast.success("Sign up complete. Please confirm your email.")
      navigate(
        `/payment/login?type=${paymentMethod.plan}&mode=${paymentMethod.mode}`
      )
    } catch (err) {
      toast.error(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }
  return (
    <PaymentAuthenticationLayout img={img} alt="" lazyImg={img}>
      <RegisterContent className="content">
        <h4>You’ll need an uduX account</h4>
        <p className="cyan-p title-p">
          Once you create an uduX account, we’ll send confirmation details and a
          link to get started
        </p>
        <CustomForm onFinish={value => onFinish(value)}>
          <TextField
            placeholder="Firstname"
            name="firstname"
            validator={validateBasicField}
            lightMask="var(--uduxGreenPrimary)"
          />
          <TextField
            placeholder="Lastname"
            name="lastname"
            validator={validateBasicField}
            lightMask="var(--uduxGreenPrimary)"
          />
          <PhoneInput
            phone={true}
            validator={validatePhoneNumber}
            onSetCountryCode={code => setCountryCode(code)}
          />
          <Button
            backgroundColor="var(--uduxCyanPrimary)"
            borderColor="var(--uduxCyanPrimary)"
            width="220px"
            height="50px"
            margin="20px 0px 0px 0px"
            color="var(--uduxBlackPrimary)"
            htmlType="submit"
            disabled={loading}
          >
            <span>Create an Account</span>
          </Button>
        </CustomForm>
      </RegisterContent>
    </PaymentAuthenticationLayout>
  )
}

export default PaymentRegister
