import React, { forwardRef, memo } from "react";
import { TabContentWrap } from "./tab.styles";

const TabContentRefComponent = forwardRef(({ children, ...props }, ref) => {
  return (
    <TabContentWrap ref={ref} {...props}>
      {children}
    </TabContentWrap>
  );
});

export const TabContent = memo(TabContentRefComponent);
