import { appReducer } from "./app"
import { artistReducer } from "./artist"
import { playerReducer } from "./player"
import { playlistReducer } from "./playlist"
import { searchReducer } from "./search"
import { userReducer } from "./user"

export const initialState = {
  users: {
    me: {},
    xer: {},
  },
  artist: {},

  search: {
    searchQuery: "",
    searchTracks: [],
    filterSearch: "combined",
    filterActiveSearch: "combined",
  },

  player: {
    volume: 1,
    fsem: false,
    repeat: false,
    shuffle: false,
    currentTime: 0,
    prevTracks: [],
    playingList: {},
    isPaused: false,
    queuedTracks: [],
    currentTrack: {},
    isPlaying: false,
    isPlayerMuted: false,
    isPlayerActive: false,
  },

  playlist: {
    current: {},
    currentAlbum: {},
    recentlyPlayed: [],
    creatingPlaylist: false,
  },

  app: {
    msisdn: "",
    isMtn: false,
    categories: "",
    billingPlans: [],
    paymentMethod: {},
    isEnriched: false,
    isLoggedIn: false,
    signUpFormStep: 0,
    headerEnrichment: {},
    scrollToBottom: false,
    apiResponseError: undefined,
    authStreamingWarning: false,
    msisdnUserHasAccount: false,
  },
}

export const mainReducer = (state, action) => {
  const { users, playlist, artist, player, app, search } = state

  return {
    app: appReducer(app, action),
    users: userReducer(users, action),
    artist: artistReducer(artist, action),
    search: searchReducer(search, action),
    player: playerReducer(player, action),
    playlist: playlistReducer(playlist, action),
  }
}
