import React from "react";
import { HeaderWrapper } from "./header.styles";
import { Button, FlexibleDiv } from "components";
import { Link } from "react-router-dom";
import UduxLogo from "assets/images/general/udux_logo.png";
import { Hamburger } from "assets/images/general/SVGExports";

export const Header = () => {
  const menuLinks = [
    { name: "FAQ's", linkUrl: "#" },
    { name: "About Us", linkUrl: "#" },
    { name: "Artists", linkUrl: "#" },
    { name: "Labels & CP's", linkUrl: "#" },
  ];

  const scrollToSubscribe = () => {
    const subscribeSection = document.getElementById("subscribe__section");
    subscribeSection.scrollIntoView({ behavior: "smooth" }, true);
  };

  return (
    <HeaderWrapper className="header__wrapper">
      <FlexibleDiv
        className="header__section"
        flexDir="row"
        justifyContent="space-between"
        width="100%"
      >
        <Link to="/">
          <img src={UduxLogo} className="logo__wrapper" alt="udux-logo" />
        </Link>

        <FlexibleDiv
          flexDir="row"
          width="70%"
          justifyContent="space-between"
          className="actions__wrapper"
        >
          <FlexibleDiv
            width="60%"
            justifyContent="space-around"
            className="tablet__wrapper"
          >
            {menuLinks.map((value, idx) => (
              <Link className="link__text" key={idx} to={value.linkUrl}>
                {value.name}
              </Link>
            ))}
          </FlexibleDiv>

          <Button
            className="subscribe__btn"
            color="var(--black)"
            onClick={scrollToSubscribe}
            contained
            bold
            ltr
          >
            <span>Subscribe Now</span>
          </Button>
        </FlexibleDiv>
        <Hamburger
          className="hamburger__icon"
          onClick={() =>
            (document.querySelector("#toggle-menu").checked = true)
          }
          stroke="#1CA787"
          for="toggle-menu"
        />

        {/* Mobile Menu Container */}
      </FlexibleDiv>
      <input type="checkbox" id="toggle-menu" />
      <FlexibleDiv className="mobile__overlay">
        <FlexibleDiv
          width="100%"
          height="100%"
          className="mobile__menu__wrapper"
          flexDir="column"
        >
          <div
            className="close-button"
            onClick={() =>
              (document.querySelector("#toggle-menu").checked = false)
            }
          >
            <span>&times;</span>
          </div>

          {menuLinks.map((value, idx) => (
            <FlexibleDiv
              flexDir="column"
              className="link__background"
              key={idx}
            >
              <Link to={value.linkUrl}>{value.name}</Link>
            </FlexibleDiv>
          ))}
        </FlexibleDiv>
      </FlexibleDiv>
    </HeaderWrapper>
  );
};
