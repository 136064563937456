import { FlexibleDiv } from "components"
import React from "react"
import { AboutPaymentWrapper } from "./about-payment.styles"
import David from "assets/images/general/david.png"
import Fade from "react-reveal/Fade"

export const AboutPayment = () => {
  return (
    <AboutPaymentWrapper>
      <FlexibleDiv
        className="left__section__wrapper"
        width="50%"
        height="100%"
        flexDir="column"
      >
        <FlexibleDiv className="about__content__wrapper" flexDir="column">
          <Fade right>
            <p className="header__text">
              This is <span className="udux__text">uduX</span>
            </p>
            <p>
              We are Africa’s first and indigenous subscription-based music
              store and streaming service.
            </p>
            <p>
              We believe in the potential of Africa’s sound and use technology
              to amplify this belief.
            </p>
            <p>
              We combine high-quality audio and high-definition video to
              innovate what we call experiential technology.
            </p>
            <p>
              We’ve brought you Davido + Wizkid live from the o2, we’re bringing
              collaborative playlists and promise to continue to push the
              boundaries - ensuring that Africa retains it’s deserved spot on
              the global music stage
            </p>
          </Fade>
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv
        className="right__section__wrapper"
        width="50%"
        height="100%"
        style={{ backgroundImage: `url(${David})` }}
      ></FlexibleDiv>
    </AboutPaymentWrapper>
  )
}
