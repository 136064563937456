import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout";
import { OAuth } from "network";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import galleryImage from "assets/images/Auth/verification_starting_page.png"

const Index = () => {
  const [searchParams] = useSearchParams();
  const accessId = searchParams.get("accessId");
  const provider = searchParams.get("provider");

  const payload = {
    agent: "UDUX_WEB_AGENT",
    accessID: `${accessId}`,
  };

  useEffect(() => {
    const authenticateUser = () => {
      if (provider === "facebook") {
        return OAuth("/auth/facebook/validate-from-web", payload);
      } else if (provider === "google") {
        return OAuth("/auth/google/validate-from-web", payload);
      } else if (provider === "apple") {
        return OAuth("/auth/apple/validate", payload);
      } else {
        return false;
      }
    };

    authenticateUser();
  }, []);

  return (
    <AuthLayout
      title="Authentication"
      subText="You will be redirected shortly"
      galleryImage={galleryImage}
    ></AuthLayout>
  );
};

export default Index;
