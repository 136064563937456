import { isEmpty } from "lodash"

export function getImageDisplay(entity) {
  if (!isEmpty(entity)) {
    if (entity.track?.metadata) {
      return entity.track.metadata?.coverArt?.url
    }

    if (entity?.metadata) {
      return entity.metadata?.coverArt?.url
    }

    if (entity?.coverArt) {
      return entity.coverArt?.url
    }
  }
}
