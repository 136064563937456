export const  formatDuration = (startDateStr, endDateStr)=> {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the duration in milliseconds
  const durationInMilliseconds = endDate - startDate;

  // Calculate the number of days
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const durationInDays = Math.floor(durationInMilliseconds / millisecondsInADay);

  return durationInDays+" Days";
}
export const calculateExpiryDate = (startDateStr, endDateStr)=> {
   const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  if (isNaN(startDate) || isNaN(endDate)) {
    throw new Error("Invalid date format. Please provide valid date strings.");
  }

  const currentDate = new Date();

  // If the current date is greater than the ending date, set the expiry date to the ending date
  if (currentDate > endDate) {
    return endDate.toISOString().split("T")[0];
  }


  // Calculate the total milliseconds for the duration
  const durationInMilliseconds = endDate - startDate;


  // Calculate the expiry date in milliseconds
  const expiryDateInMilliseconds = startDate.getTime() + durationInMilliseconds;
  // Create a new Date object for the expiry date
  const expiryDate = new Date(expiryDateInMilliseconds);
  // Format the expiry date
  const formattedExpiryDate = expiryDate.toISOString().split("T")[0];
  return formattedExpiryDate;
}
