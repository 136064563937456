import { Types, useMainContext } from "context"
import { FSEMFooter, MiniPlayer } from "pages/Player"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { FooterWrap } from "./player-footer.styles"

export const PlayerFooter = React.memo(({ location }) => {
  const { pathname, hash } = location
  const params = useParams()
  const {
    state: { player },
    dispatch,
  } = useMainContext()
  const canShowFsem = `${pathname}${hash}` === `/player/${params.playId}${hash}`

  const { fsem } = player

  useEffect(() => {
    dispatch({
      type: Types.SET_FSEM,
      payload: canShowFsem,
    })
  }, [canShowFsem, dispatch])

  return <FooterWrap>{fsem ? <FSEMFooter /> : <MiniPlayer />}</FooterWrap>
})
