import {
  FavoriteFilledIcon,
  FavoriteIcon,
  NextOutlineIcon,
  PauseOutlineIcon,
  PlayOutlineIcon,
  PrevOutlineIcon,
  RepeatOutlineIcon,
  ShuffleOutlineIcon,
} from "assets"
import { FlexibleDiv, TrackProgressControl, VolumeControl } from "components"
import { Types, useMainContext } from "context"
import { isEmpty } from "lodash"
import { getTrackById } from "network"
import { useCallback, useEffect } from "react"
import { useLocation } from "react-router-dom"
import {
  getDisplayArtist,
  getImageDisplay,
  getTrackTitle,
  pauseAudio,
  playAudio,
  playNextTrack,
  playPreviousTrack,
  repeatTrack,
} from "utils"
import { FSEMPlayerWrap } from "./fsem-player.styles"

export const FSEMPlayer = ({ width, isMobile, ...props }) => {
  const { pathname, state: rState } = useLocation()
  const trackId = pathname.split("/player/")[1]

  const {
    state: { player, app },
    playerRef,
    dispatch,
  } = useMainContext()

  const { isLoggedIn } = app
  const { currentTrack, isPlaying, shuffle, repeat, isPlayerMuted } = player
  const shouldGetTrack = isEmpty(currentTrack) && Boolean(trackId)

  const getCurrentTrackById = useCallback(async () => {
    const res = await getTrackById(trackId)
    dispatch({ type: Types.SET_CURRENT_PLAYING, payload: res })
  }, [trackId, dispatch])

  useEffect(() => {
    if (shouldGetTrack) {
      getCurrentTrackById()

      dispatch({
        type: Types.REPLACE_QUEUED_TRACKS,
        payload: rState?.queuedTracks,
      })
      dispatch({
        type: Types.REPLACE_PREV_TRACKS,
        payload: rState?.prevTracks,
      })
      dispatch({
        type: Types.SET_PLAYING_PLAYLIST,
        payload: rState?.playingList,
      })
    }
  }, [shouldGetTrack, dispatch, rState, getCurrentTrackById])

  return (
    <FSEMPlayerWrap width={width} isMobile={isMobile}>
      <FlexibleDiv className="fsem__wrap">
        <FlexibleDiv className="track__image__wrap">
          <img
            src={getImageDisplay(currentTrack)}
            alt={`${getTrackTitle(currentTrack, true)} cover`}
          />
        </FlexibleDiv>

        <FlexibleDiv className="track__title__control">
          <FlexibleDiv className="icon__wrap left">
            {currentTrack?.userLiked ? (
              <FavoriteFilledIcon
                onClick={() => {
                  if (!isLoggedIn) {
                    return dispatch({
                      type: Types.SET_PRIVATE_ACTION,
                      payload: true,
                    })
                  }
                }}
                width="25"
                height="25"
              />
            ) : (
              <FavoriteIcon
                onClick={() => {
                  if (!isLoggedIn) {
                    return dispatch({
                      type: Types.SET_PRIVATE_ACTION,
                      payload: true,
                    })
                  }
                }}
                width="25"
                height="25"
              />
            )}
          </FlexibleDiv>

          <FlexibleDiv flexDir="column" className="track-title">
            <p className="title">{getTrackTitle(currentTrack, true)}</p>
            <p className="artist__wrap">{getDisplayArtist(currentTrack)}</p>
          </FlexibleDiv>

          <VolumeControl className={"icon__wrap right"} />
        </FlexibleDiv>

        <FlexibleDiv className="track__controls__wrap" flexDir="column">
          <FlexibleDiv
            flexWrap="nowrap"
            justifyContent="center"
            className="controls__main"
          >
            <div
              className="control__icons repeat__icon"
              onClick={() => repeatTrack(Types.REPEAT, dispatch, repeat)}
            >
              <RepeatOutlineIcon
                color={repeat ? "var(--yellowPrimary)" : "#fff"}
                height="100%"
              />
            </div>
            <div
              className="control__icons"
              onClick={() =>
                playPreviousTrack(dispatch, {
                  track: currentTrack,
                })
              }
            >
              <PrevOutlineIcon width={28} height="100%" />
            </div>

            {isPlaying ? (
              <div
                className="control__icons pause__icon"
                onClick={() => pauseAudio(playerRef, dispatch)}
              >
                <PauseOutlineIcon width={40} height="100%" />
              </div>
            ) : (
              <div
                className="control__icons play__icon"
                onClick={() => playAudio(playerRef, dispatch)}
              >
                <PlayOutlineIcon width={40} height={40} />
              </div>
            )}
            <div
              className="control__icons"
              onClick={() => {
                playNextTrack(dispatch, {
                  track: currentTrack,
                })
              }}
            >
              <NextOutlineIcon width={28} height="100%" />
            </div>

            {shuffle ? (
              <div
                // onClick={() =>
                //   unShuffleTrack(
                //     tracks,
                //     dispatch,
                //     ACTIVE_TRACKS,
                //     SHUFFLE,

                //     tracks?.currentTrack
                //   )
                // }
                className="control__icons shuffle__icon"
              >
                <ShuffleOutlineIcon
                  height="100%"
                  color="var(--yellowPrimary)"
                />
              </div>
            ) : (
              <div
                // onClick={() =>
                //   shuffleTrack(tracks, dispatch, ACTIVE_TRACKS, SHUFFLE)
                // }
                className="control__icons shuffle__icon"
              >
                <ShuffleOutlineIcon height="100%" />
              </div>
            )}
          </FlexibleDiv>

          <FlexibleDiv className="song__progress__wrap" flexDir="column">
            <TrackProgressControl />
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </FSEMPlayerWrap>
  )
}
