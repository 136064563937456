import { FlexibleDiv } from "components"
import styled from "styled-components"

export const WebMusicPlayerWrap = styled(FlexibleDiv)`
  width: 100%;
  height: 100%;
  padding: 2% 3% 1%;
  column-gap: 2.5%;
  align-items: flex-start;

  .left__wrap,
  .right__wrap {
    width: 48%;
    justify-content: center;
  }

  .left__wrap {
    align-items: center;
    justify-content: center;
    min-width: 450px;
    max-width: 550dvw;
  }

  .right__wrap {
    overflow: hidden;
    position: relative;
    padding-left: 0.5rem;
    justify-content: flex-start;
    min-height: calc(100vh - 240px);
    min-width: 450px;
    max-width: 650dvw;
  }

  @media screen and (min-width: 951px) and (max-width: 1221px) {
    flex-direction: column-reverse;
    align-items: center;

    .right__wrap {
      min-width: 85%;
      min-height: auto;
    }

    .close__btn__wrap {
      justify-content: flex-end;
      padding-top: 0.5rem;

      .close-icon {
        cursor: pointer;
        font-size: 1.5rem;
      }
    }
  }
`
