import { AddToQueueIcon, GotoAlbumIcon, GotoArtistPageIcon } from "assets"
import { ShareIcon } from "assets/images/player/SVGExports"
import { StyledMenu } from "components"

export const TrackItemMoreMenu = ({
  handleShare,
  handleGotoAlbum,
  handleAddToQueue,
  handleGotoArtistPage,
  ...props
}) => {
  let items = [
    {
      label: (
        <div className="menu_box" onClick={handleAddToQueue}>
          <AddToQueueIcon width={18} />
          <p>Add to Queue</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleGotoAlbum}>
          <GotoAlbumIcon width={18} />
          <p>Go to Album</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleGotoArtistPage}>
          <GotoArtistPageIcon width={18} />
          <p>Go to Artist Page</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleShare}>
          <ShareIcon width={18} />
          <p>Share Song</p>
        </div>
      ),
    },
  ]

  return (
    <StyledMenu
      top={props.top || "1rem"}
      items={items.map((menuItem, idx) => ({ ...menuItem, key: idx }))}
      {...props}
    />
  )
}
