export function getTrackDuration(entity) {
  if (entity?.entityType?.toLowerCase() === "track") {
    if (entity.duration) {
      const matches = entity.duration.match(/\d+/g);
      const mins = matches[0];
      const secs = matches[1] < 10 ? `0${matches[1]}` : matches[1];

      return `${mins}:${secs}`;
    } else if (entity.durationMS) {
      const minutes = Math.floor(entity.durationMS / 60000);
      const seconds = ((entity.durationMS % 60000) / 1000).toFixed(0);
      const secs = seconds < 10 ? `0${seconds}` : seconds;

      return `${minutes}:${secs}`;
    }
  }
}
