import { FlexibleDiv, TabContent, TabHeader } from "components"
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import { Types, useMainContext } from "context"
import { isEmpty } from "lodash"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSwipeable } from "react-swipeable"
import { getArtist, playerTabRoutes } from "utils"
import { AddToPlaylist } from "../AddToPlaylist"
import { ArtistCatalogue } from "../ArtistCatalogue"
import { FSEMPlayer } from "../FSEMPlayer"
import { ManageQueue } from "../ManageQueue"
import { Recommended } from "../Recommended"
import { Preloader } from "./Preloader"
import { TrackProgressIndicator } from "./TrackProgressIndicator"
import { MobileMusicPlayerWrap, Spacer } from "./mobile-player.styles"
import { MoreIcon } from "assets"
import { MoreModal } from "./MoreModal"

export const MobilePlayer = () => {
  const tabContentRef = useRef()
  const navigate = useNavigate()
  const [fsemMobileHeader, setFsemMobileHeader] = useState(true)
  const [activeTab, setActiveTab] = useState("")
  const [showCloser, setShowCloser] = useState(false)
  const [showMoreMenu, setShowMoreMenu] = useState(false)

  const {
    state: { player },
    dispatch,
  } = useMainContext()

  const { currentTrack, isPlayerActive } = player

  const artist = getArtist(currentTrack)

  const handlers = useSwipeable({
    // onSwipedUp: () => handleActiveTab("Artist Catalogue"),
  })

  useEffect(() => {
    if (isEmpty(currentTrack)) {
      dispatch({ type: Types.IS_PLAYING, payload: false })
      dispatch({ type: Types.IS_PLAYER_ACTIVE, payload: false })
      navigate("/")
    }
  }, [currentTrack, navigate, dispatch])

  return (
    <PlayerLayout
      footerOnly
      isPlayerActive={isPlayerActive}
      fsemMobileHeader={fsemMobileHeader}
    >
      <MobileMusicPlayerWrap
        flexWrap="nowrap"
        {...handlers}
        playerActive={isPlayerActive}
      >
        {/* <Preloader /> */}

        <FlexibleDiv flexDir="column" className="main__content__wrap">
          <TabHeader
            width="100%"
            margin="0 auto"
            pad_link="0.8rem"
            activeTab={activeTab}
            tabs={playerTabRoutes}
            padding="0.2rem 0.4rem"
            showCloser={showCloser}
            fsemMobileHeader={fsemMobileHeader}
            setActiveTab={tab => {
              setActiveTab(tab)
              setShowCloser(true)
              dispatch({ type: Types.SET_FSEM, payload: false })
            }}
            handleClose={() => {
              setActiveTab("")
              setShowCloser(false)
              dispatch({ type: Types.SET_FSEM, payload: true })
            }}
            handleOpenMore={() => setShowMoreMenu(true)}
          />

          <Spacer pb={showCloser ? "0.5rem" : "2rem"} />

          {activeTab === "" ? (
            <FlexibleDiv className="fsemplayer__container">
              <div
                className="more__icon__wrap"
                onClick={() => setShowMoreMenu(true)}
              >
                <MoreIcon />
              </div>
              <FSEMPlayer isMobile />
            </FlexibleDiv>
          ) : (
            <TabContent className="tab__content__mobile" ref={tabContentRef}>
              {activeTab === "#artist-catalogue" && (
                <ArtistCatalogue artist={artist} isMobile />
              )}
              {activeTab === "#recommended" && (
                <Recommended artist={artist} isMobile />
              )}
              {activeTab === "#add-to-playlist" && <AddToPlaylist isMobile />}
              {activeTab === "#manage-queue" && <ManageQueue isMobile />}
            </TabContent>
          )}

          {activeTab !== "" && (
            <FlexibleDiv className="tpi__wrap">
              <TrackProgressIndicator />
            </FlexibleDiv>
          )}
        </FlexibleDiv>
      </MobileMusicPlayerWrap>

      {showMoreMenu && (
        <MoreModal
          open={showMoreMenu}
          handleClose={() => setShowMoreMenu(false)}
        />
      )}
    </PlayerLayout>
  )
}
