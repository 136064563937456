import { LoadingOutlined } from "@ant-design/icons"
import { Button, FlexibleDiv } from "components"
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout"
import { Types, useMainContext } from "context"
import {
  confirmEmailWithToken,
  resendEmailConfirmationCode,
  successResponseHandler,
} from "network"
import { useEffect, useState } from "react"
import OtpInput from "react-otp-input"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { useLocation, useNavigate } from "react-router-dom"
import { ConfirmEmailWrap } from "./confirm-email.styles"
import { Config } from 'config';
import galleryImage from "assets/images/Auth/enter_otp.jpg"

const ConfirmEmail = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { dispatch } = useMainContext()
  const [otpValue, setOtpValue] = useState("")
  const { mutate, isLoading } = useMutation(confirmEmailWithToken)
  const { mutate: mutateResendCode } = useMutation(resendEmailConfirmationCode)
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get("token")
  const emailAddress = queryParams.get("emailAddress")

  const handleChange = e => setOtpValue(e)

  const handleFormSubmit = async () => {
    if (otpValue < 5) {
      return false
    }

    const payload = {
      emailAddress,
      confirmEmailToken: token,
      sourceService: Config.sourceService,
    }

    mutate(payload, {
      onSuccess: () => {
        navigate("/")
      },
      onError: error => {
        setOtpValue("")
        dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload: error,
        })
      },
    })
  }

  const handleResendCode = async () => {
    const payload = {
      emailAddress,
    }

    mutateResendCode(payload, {
      onSuccess: res => {
        successResponseHandler(res)
      },
      onError: error => {
        dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload: error,
        })
      },
    })
  }

  useEffect(() => {
    if (token) {
      setOtpValue(token)
    }
  }, [token])

  useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined })
  }, [])

  return (
    <AuthLayout
      title="Follow the Rhythm."
      subText="We need the OTP sent to your email"
      galleryImage={galleryImage}
    >
      <ConfirmEmailWrap>
        <FlexibleDiv className="form__box">
          <OtpInput
            numInputs={6}
            value={otpValue}
            onChange={handleChange}
            inputStyle="otp__input"
            separator={
              <span style={{ margin: isMobile ? "0 5px" : "0px 10px" }}></span>
            }
          />
        </FlexibleDiv>

        <FlexibleDiv flexDir="column" className="btn__box">
          <Button
            width="220px"
            height="50px"
            font_size="16px"
            color="var(--black)"
            onClick={handleFormSubmit}
            contained
            bold
            ltr
          >
            <span> {isLoading ? <LoadingOutlined /> : "Continue"}</span>
          </Button>

          <span className="resend__code" onClick={handleResendCode}>
            Resend Confirmation Code
          </span>
        </FlexibleDiv>
      </ConfirmEmailWrap>
    </AuthLayout>
  )
}

export default ConfirmEmail
