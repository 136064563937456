import { Types } from "context/types"

export const userReducer = (state, { type, payload }) => {
  switch (type) {
    case Types.CURRENT_USER:
      return {
        ...state,
        me: payload,
      }

    case Types.UPDATE_MY_PROFILE:
      const { me } = state

      return {
        ...state,
        me: {
          ...me,
          profile: {
            ...me.profile,
            ...payload,
          },
        },
      }

    case Types.ACTIVE_USER_PROFILE:
      const { xer } = state

      return {
        ...state,
        xer: {
          ...xer,
          ...payload,
        },
      }

    default:
      return state
  }
}
