import BackgroundImage from "assets/images/payment/ChoosePlatformImage.png"
import PaymentSuccessImg from "assets/images/payment/paymentSuccess.png"
import { Loader } from "components"
import { ChoosePlatform } from "components/layouts/AuthLayout-Platform/ChoosePlatform"
import { Types, useMainContext } from "context"
import { pollSubscriptionStatus, verifyPayment } from "network"
import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { PaymentFailed } from "./PaymentFailed"
import { PaymentSuccess } from "./PaymentSuccess"

export const VerifyPayment = () => {
  const { dispatch } = useMainContext()
  const [params] = useSearchParams()
  const reference = params.get("reference")
  const lStore = localStorage.getItem("udx_pay")
  const provider = JSON.parse(lStore.replace(/\\/g, ""))
  const { data } = useQuery(["user-subscription-status"], () =>
    // FIXME: Api doesn't return user subscription data
    pollSubscriptionStatus()
  )
  const { data: pvData, isLoading } = useQuery(
    ["verify-payment", reference, provider.method],
    () => verifyPayment(provider.method, reference),
    {
      enabled: !!reference && !!provider.method,
      onError: error => {
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    }
  )

  const summary = pvData?.data
  const trxSuccessful = summary?.transactionStatus === "SUCCESS"

  return (
    <ChoosePlatform
      title="You choose..."
      leftColor="#00725E"
      showBackButton={false}
      rightImage={BackgroundImage}
      galleryImage={PaymentSuccessImg}
      displaySubscriptionBox={false}
    >
      {isLoading ? (
        <Loader variant="green" />
      ) : trxSuccessful ? (
        <PaymentSuccess summary={summary} />
      ) : (
        <PaymentFailed summary={summary} />
      )}
    </ChoosePlatform>
  )
}
