import { IoMdPlay } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { nowPlaying, pauseAudio, playAudio, truncate } from "utils";
import { FlexibleDiv } from "../../Box/styles";
import { PlaylistCardWrap } from "./playlist-card.styles";
import { useMainContext } from "context";
import { memo } from "react";
import { PausePlayingIcon } from "assets";

export const PlaylistCard = memo(({ ...props }) => {
  const {
    id,
    coverArt,
    coverArtDominantColors,
    featuredArtists,
    description,
    title,
    type,
  } = props;
  const {
    state: { player },
    playerRef,
    dispatch,
  } = useMainContext();

  const { playingList, isPlaying, isPaused } = player;
  const showPausePlay = isPlaying && playingList.id === id;

  const navigate = useNavigate();

  return (
    <PlaylistCardWrap
      dominantColors={coverArtDominantColors?.map((cadc) =>
        cadc.startsWith("#") ? cadc : `#${cadc}`
      )}
    >
      <FlexibleDiv
        className="card__main"
        id={
          type === "MANAGED" ? "udux__playlist__card" : "user__playlist__card"
        }
      >
        <FlexibleDiv
          className="card__innerbox__wrap"
          flexDir="column"
          justifyContent="space-between"
        >
          <div className="card__image__wrap">
            {showPausePlay ? (
              <FlexibleDiv
                className="is__playing"
                onClick={() => pauseAudio(playerRef, dispatch)}
              >
                <PausePlayingIcon
                  height={60}
                  width={60}
                  color="var(--yellowPrimary)"
                />
              </FlexibleDiv>
            ) : (
              <FlexibleDiv className="card-play-btn">
                {isPaused && playingList.id === id ? (
                  <IoMdPlay
                    onClick={() => playAudio(playerRef, dispatch)}
                    size={45}
                    color="var(--yellowPrimary)"
                  />
                ) : (
                  <IoMdPlay
                    onClick={() => nowPlaying(props, dispatch, playerRef)}
                    size={45}
                    color="var(--yellowPrimary)"
                  />
                )}
              </FlexibleDiv>
            )}
            <img src={coverArt?.url} alt="playlist cover" />
          </div>

          <FlexibleDiv
            className="text__content"
            alignItems="flex-start"
            justifyContent="flex-start"
            onClick={() =>
              navigate(`/playlist/${id}?t=pl`, {
                state: { module: "playlist" },
              })
            }
          >
            <p className="title">{truncate(title)}</p>

            <FlexibleDiv justifyContent="flex-start" className="artist">
              {description ? (
                <small>{truncate(description, 25)}</small>
              ) : (
                featuredArtists?.map((fa, idx) => (
                  <small key={idx}>
                    {fa}
                    {featuredArtists.length > 1 &&
                      idx !== featuredArtists.length - 1 &&
                      ","}
                  </small>
                ))
              )}
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </PlaylistCardWrap>
  );
});
