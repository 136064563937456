import { instance } from "./axios"

/* Auth services */
export const changePassword = async data => {
  const response = await instance.post("/auth/auth/change-password", data)

  return response.data
}

export const confirmResetPasswordToken = async data => {
  const response = await instance.post(
    "/auth/auth/reset-password/confirm-token",
    data
  )

  return response.data
}

export const completeForgotPassword = async data => {
  const response = await instance.post(
    "/auth/auth/reset-password/update-password",
    data
  )

  return response.data
}

export const confirmEmail = async data => {
  const response = await instance.post("/user/check-email-exist", data)

  return response.data
}

export const confirmEmailWithToken = async data => {
  const response = await instance.post(
    "/auth/auth/confirm-email/update-status",
    data
  )

  return response.data
}

export const resendEmailConfirmationCode = async data => {
  const response = await instance.post("/auth/auth/confirm-email/request", data)

  return response.data
}

/* Profile Services */
export const getUserProfile = async () =>
  await instance.get("/core/user/profile")
export const getUserProfileWithID = async userId => {
  const response = await instance.get(`/core/user/profile/${userId}`)

  return response.data
}

export const updateUserProfile = async data => {
  const response = await instance.patch("/core/user/profile", data)

  return response.data
}

export const getPreSignedUrl = async data => {
  const response = await instance.post("/core/uploader/presigned-url", data)

  return response.data
}

export const uploadToPresignedURL = async (url, data) => {
  const response = await instance.put(url, data)

  return response.data
}

export const fetchImage = async url => {
  // const response = await axios.get(url);
  const response = await instance.get(url)
  return response.data
}

export const followUser = async userId => {
  const response = await instance.post(`/social/follow/user/${userId}`)

  return response.data
}

export const unFollowUser = async userId => {
  const response = await instance.delete(`/social/unfollow/user/${userId}`)

  return response.data
}

export const checkAvailableUsername = async username => {
  const response = await instance.get(
    `/core/user/check-username-exist/${username}`
  )

  return response.data
}

export const checkAvailableEmail = async emailAddress => {
  const response = await instance.get(
    `/core/user/check-email-exist/${emailAddress}`
  )

  return response.data
}

export const checkAvailablePhone = async phoneNumber => {
  const { data } = await instance.get(
    `/core/user/check-phone-exist/${phoneNumber}`
  )

  return data.data
}

export const userLibraryData = async () => {
  const response = await instance.get(`/core/user/library`)
  return response.data
}

export const getLikedPlaylists = async () => {
  const { data } = await instance.get(`/core/user/liked/playlists`)
  return data.data
}

export const fetchArtistsIFollow = async userId => {
  const url = `/social/follow/followed-artists${userId ? `/${userId}` : ""}`
  const response = await instance.get(url)

  return response.data
}

export const getUsersToFollow = async () => {
  const { data } = await instance.get(`/core/user/random`)

  return data.data
}
