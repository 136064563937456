import { Button, FlexibleDiv } from "components"
import { forwardRef, memo, useState } from "react"
import { EPNWrap } from "./styles"
import { IoClose } from "react-icons/io5"
import { useMutation } from "react-query"
import {
  createCollabPlaylist,
  createSoloPlaylist,
  errorResponseHandler,
  successResponseHandler,
} from "network"
import { Types, useMainContext } from "context"
import { capitalize } from "lodash"
import { HiInformationCircle } from "react-icons/hi2"

const EnterPlaylistNameRefEl = forwardRef(
  ({ placeholder, onComplete, onClose, type, bng, ...props }, ref) => {
    const [value, setValue] = useState("")
    const {
      state: { player },
      dispatch,
    } = useMainContext()
    const { mutate: mutateCreateSoloPlaylist, isLoading: cspLoading } =
      useMutation(createSoloPlaylist)
    const { mutate: mutateCreateCollabPlaylist, isLoading: ccpLoading } =
      useMutation(createCollabPlaylist)

    const { currentTrack } = player

    return (
      <EPNWrap bng={bng}>
        <FlexibleDiv className="title__wrap" justifyContent="space-between">
          <header>
            <p>You are going {capitalize(type)}</p>
            <small className="subtext">
              Every epic soundtrack deserves a Title
            </small>
          </header>
          <IoClose onClick={onClose} />
        </FlexibleDiv>

        <FlexibleDiv className="input__wrap">
          <input
            value={value}
            placeholder={placeholder || `${type} playlist #1`}
            onChange={({ target }) => setValue(target.value)}
          />

          <FlexibleDiv className="btn__wrap">
            <Button onClick={handleMutation} loading={cspLoading || ccpLoading}>
              <span>Create Playlist</span>
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>

        {type === "collab" && (
          <FlexibleDiv
            className="collab__warn__wrap"
            justifyContent="flex-start"
            alignItems="center"
          >
            <p>
              <HiInformationCircle />
              <small>
                You may only add this song to a collab playlist after inviting
                your friends.
              </small>
            </p>
          </FlexibleDiv>
        )}
      </EPNWrap>
    )

    function handleMutation() {
      if (type === "solo") {
        mutateCreateSoloPlaylist(
          {
            title: value,
            tracks: [
              {
                sequence: 0,
                track: currentTrack?.track?.id,
              },
            ],
          },
          {
            onSuccess: res => {
              successResponseHandler(res)
              onClose && onClose()
              dispatch({ type: Types.CREATING_PLAYLIST, payload: false })
            },
            onError: err => errorResponseHandler(err),
          }
        )
      }

      if (type === "collab") {
        mutateCreateCollabPlaylist(
          {
            title: value,
            private: true,
          },
          {
            onSuccess: res => {
              successResponseHandler(res)
              onClose && onClose()
              dispatch({ type: Types.CREATING_PLAYLIST, payload: false })
            },
            onError: err => errorResponseHandler(err),
          }
        )
      }
    }
  }
)

export const EnterPlaylistName = memo(EnterPlaylistNameRefEl)
