import {
  ArtisteCard,
  DataDisplayCard,
  FlexibleDiv,
  NoArtistDisplay,
  NoCollabPlaylistDisplay,
  NoSoloPlaylistDisplay,
} from "components";
import { MLWrap } from "./metrics-layout.styles";
import NoTopTracksImage from "assets/images/general/top-tracks-image.png";
import NoRecentlyPlayed from "assets/images/general/recent-display-image.png";
import EmptyTopTracks from "assets/images/general/empty-top-tracks.svg";
import EmptyRecentlyPlayed from "assets/images/general/empty-recently-played.svg";
import { Skeleton } from "antd";
import React from "react";
import { capitalize } from "lodash";

export const MetricsLayout = ({
  isOwner,
  user,
  following,
  ddcLoading,
  usrPlLoading,
  topTracks,
  recentlyPlayed,
  soloPlaylist,
  collabPlaylist,
}) => {
  console.log(following);
  return (
    <MLWrap>
      <FlexibleDiv
        justifyContent="flex-start"
        alignItems="flex-start"
        className="display__row top__tracks__recent__play"
      >
        <FlexibleDiv
          flexWrap="nowrap"
          alignItems="center"
          className="explorer__box explorer__box__mobile"
          justifyContent="flex-start"
        >
          {ddcLoading ? (
            <FlexibleDiv minHeight="300px" style={{ columnGap: "5%" }}>
              <Skeleton active={ddcLoading} style={{ width: "45%" }} />
              <Skeleton active={ddcLoading} style={{ width: "45%" }} />
            </FlexibleDiv>
          ) : (
            <React.Fragment>
              <DataDisplayCard
                emptyBg={getBgImage()}
                emptyDisplayIcon={EmptyTopTracks}
                emptyText="Like songs for them to appear here"
                header={
                  <FlexibleDiv className="section__display__controls">
                    <h1 className="title">{getTitle("Top Tracks")}</h1>

                    <span className="see__all">See All</span>
                  </FlexibleDiv>
                }
                isEmpty={topTracks?.length === 0}
                cardData={topTracks}
                isLarge={true}
              />
              <DataDisplayCard
                emptyBg={getRPImage()}
                emptyDisplayIcon={EmptyRecentlyPlayed}
                emptyText="You haven’t played anything recently"
                header={
                  <FlexibleDiv className="section__display__controls">
                    <h1 className="title">{getTitle("Recently Played")}</h1>

                    <span className="see__all">See All</span>
                  </FlexibleDiv>
                }
                isEmpty={recentlyPlayed?.length === 0}
                cardData={recentlyPlayed?.map((rp) => rp.track)}
                isLarge={true}
              />
            </React.Fragment>
          )}
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        className="display__row"
      >
        <FlexibleDiv className="section__display__controls">
          <h1 className="title">{getTitle("Top Artists")}</h1>

          <span className="see__all">See All</span>
        </FlexibleDiv>

        {usrPlLoading ? (
          <FlexibleDiv minHeight="300px" style={{ columnGap: "5%" }}>
            <Skeleton active={usrPlLoading} />
          </FlexibleDiv>
        ) : (
          <FlexibleDiv
            flexWrap="nowrap"
            alignItems="center"
            className="explorer__box"
            justifyContent="flex-start"
          >
            {!!following?.length ? (
              following.map((f, idx) => (
                <ArtisteCard key={`${idx}-${f.id}`} {...f.followedArtist} />
              ))
            ) : (
              <FlexibleDiv
                className="top__artist__display"
                justifyContent="flex-start"
                flexWrap="nowrap"
              >
                <NoArtistDisplay
                  message={`${capitalize(
                    user?.username
                  )} is not following any artists yet.`}
                  height="280px"
                />
              </FlexibleDiv>
            )}
          </FlexibleDiv>
        )}
      </FlexibleDiv>

      <FlexibleDiv
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        className="display__row"
      >
        <FlexibleDiv className="section__display__controls">
          <h1 className="title">{getTitle("Solo Playlist")}</h1>

          <span className="see__all">See All</span>
        </FlexibleDiv>

        {usrPlLoading ? (
          <FlexibleDiv minHeight="300px" style={{ columnGap: "5%" }}>
            <Skeleton active={usrPlLoading} style={{ width: "45%" }} />
            <Skeleton active={usrPlLoading} style={{ width: "45%" }} />
          </FlexibleDiv>
        ) : (
          <FlexibleDiv
            flexWrap="nowrap"
            alignItems="center"
            className="explorer__box"
            justifyContent="flex-start"
          >
            {!!soloPlaylist?.length ? (
              soloPlaylist.map((sp, idx) => (
                <DataDisplayCard
                  key={`${idx}-${sp.id}`}
                  emptyDisplayIcon={EmptyRecentlyPlayed}
                  emptyText="This playlist has no songs in it yet."
                  isEmpty={sp.tracks?.length === 0}
                  cardData={sp.tracks?.map((sp) => sp.track)}
                  isLarge
                  isPlaylist
                  playlist={sp}
                />
              ))
            ) : (
              <FlexibleDiv
                className="top__artist__display"
                justifyContent="flex-start"
                flexWrap="nowrap"
              >
                <NoSoloPlaylistDisplay isOwner={isOwner} />
              </FlexibleDiv>
            )}
          </FlexibleDiv>
        )}
      </FlexibleDiv>

      <FlexibleDiv
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        className="display__row"
      >
        <FlexibleDiv className="section__display__controls">
          <h1 className="title">{getTitle("Collab Playlist")}</h1>

          <span className="see__all">See All</span>
        </FlexibleDiv>

        {usrPlLoading ? (
          <FlexibleDiv minHeight="300px" style={{ columnGap: "5%" }}>
            <Skeleton active={usrPlLoading} style={{ width: "45%" }} />
            <Skeleton active={usrPlLoading} style={{ width: "45%" }} />
          </FlexibleDiv>
        ) : (
          <FlexibleDiv
            flexWrap="nowrap"
            alignItems="center"
            className="explorer__box"
            justifyContent="flex-start"
          >
            {!!collabPlaylist?.length ? (
              collabPlaylist.map((cp, idx) => (
                <DataDisplayCard
                  key={`${idx}-${cp.id}`}
                  emptyText="This collaborative playlist has no songs in it yet."
                  cardData={cp.tracks?.map((cp) => cp.track)}
                  emptyDisplayIcon={EmptyRecentlyPlayed}
                  isEmpty={cp.tracks?.length === 0}
                  playlist={cp}
                  isPlaylist
                  isLarge
                />
              ))
            ) : (
              <FlexibleDiv
                className="top__artist__display"
                justifyContent="flex-start"
                flexWrap="nowrap"
              >
                <NoCollabPlaylistDisplay isOwner={isOwner} />
              </FlexibleDiv>
            )}
          </FlexibleDiv>
        )}
      </FlexibleDiv>
    </MLWrap>
  );

  function getBgImage() {
    if (user?.profile?.coverArt?.url) {
      return user.profile.coverArt.url;
    } else {
      return NoTopTracksImage;
    }
  }

  function getRPImage() {
    if (user?.profile?.profilePicture?.url) {
      return user.profile.profilePicture.url;
    } else {
      return NoRecentlyPlayed;
    }
  }

  function getTitle(text) {
    if (isOwner) {
      return `Your ${text}`;
    } else if (user?.username) {
      return `${user.username}'s ${text}`;
    } else return text;
  }
};
