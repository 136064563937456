import emptyBg from "assets/images/general/empty-top-artist.svg"
import { NDDWrap } from "./no-data-display.styles"

export const NoArtistDisplay = ({
  message = "Listen, Like or Follow Artists to fill out this section",
  ...props
}) => {
  return (
    <NDDWrap {...props}>
      <div className="no__display__image">
        <img src={emptyBg} alt="no artist" />
      </div>
      <p className="text__content">{message}</p>
    </NDDWrap>
  )
}
