import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import { FeedWrap } from "./feed.styles"
import { FeedData, FeedJumbotron } from "./components"

export const Feed = () => {
  return (
    <PlayerLayout>
      <FeedWrap>
        <FeedJumbotron />
        <FeedData />
      </FeedWrap>
    </PlayerLayout>
  )
}
