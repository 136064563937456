import styled from "styled-components";
import { Dropdown } from "antd";

export const DropdownWrap = styled(Dropdown)`
  background-color: transparent;
  border: none;
  height: max-content;
  padding: 0;


  :hover,
  :focus,
  :active {
    box-shadow: none;
    outline: none;
    transform: translateX(0px);
    background-color: transparent;
  }
`;
