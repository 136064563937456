import { capitalize } from "lodash"
import { useNavigate } from "react-router-dom"
import { PaymentSuccessWrapper } from "./verify-payment.styles"
import { Button, FlexibleDiv } from "components"
import { useMainContext } from "context"
import moment from "moment"

export const PaymentSuccess = ({ summary }) => {
  const {
    state: {
      users: { me },
    },
  } = useMainContext()
  const navigate = useNavigate()

  return (
    <PaymentSuccessWrapper width="100%" flexDir="column">
      <FlexibleDiv height="100%" width="100%" className="payment__container">
        <FlexibleDiv className="top__container">
          <h2 className="congratulations__text">
            Congratulations {capitalize(me?.username)}
          </h2>

          <FlexibleDiv className="button__wrap">
            <Button
              font_size="0.85rem"
              radius="0"
              color="var(--black)"
              contained
              onClick={() => navigate("/")}
            >
              <span>Continue to uduX</span>
            </Button>
          </FlexibleDiv>
          <small className="payment__text">
            Your payment has successfully been received. We will email a
            confirmation of your receipt shortly.
          </small>
        </FlexibleDiv>

        <FlexibleDiv
          className="bottom__container"
          flexDir="column"
          alignItems="flex-start"
          flexWrap="nowrap"
        >
          <p className="summary__text">Summary</p>
          <FlexibleDiv className="receipt__content__wrapper">
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
            >
              <p className="title">Plan</p>
              <p className="content">{summary?.plan}</p>
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
            >
              <p className="title">Amount</p>
              <p className="content">
                {summary?.currency} {summary?.amount}
              </p>
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
            >
              <p className="title">Date of Purchase</p>
              <p className="content">
                {moment(summary?.startDate).format("ddd, do MMM YYYY")}
              </p>
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
              id="break-flex"
            >
              <p className="title">Date of Expiry</p>
              <p className="content">
                {moment(summary?.endDate).format("ddd, do MMM YYYY")}
              </p>
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
              id="break-flex"
            >
              <p className="title">Payment Method</p>
              <p className="content">
                {summary?.paymentProvider?.toLowerCase()}
              </p>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </PaymentSuccessWrapper>
  )
}
