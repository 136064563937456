import { LoadingOutlined } from "@ant-design/icons"
import { Form } from "antd"
import MTNPagePicture from "assets/images/payment/mtnPaymentPicture.png"
import { Button, CheckBox, FlexibleDiv, PhoneInput } from "components"
import { PaymentRegisterLayout } from "components/layouts/PaymentsLayout/PaymentRegisterLayout/paymentRegisterLayout"
import { Types, useMainContext } from "context"
import { checkUserPhoneNumber } from "network"
import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { PaymentLoginWrapper } from "./paymentMTNPage.styles"

const checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

const PaymentLogin = () => {
  const [params] = useSearchParams()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const {
    dispatch,
    state: { app },
  } = useMainContext()
  const navigate = useNavigate()
  const plan = params.get("type")
  const mode = params.get("mode")
  const [form] = Form.useForm()
  const { mutate, isLoading } = useMutation(checkUserPhoneNumber)
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false)

  const isMtnUser = Boolean(app?.isMtn)
  const udx__pay__esn = localStorage.getItem("udx__pay__esn")

  const handleFormSubmit = async values => {
    const fullPhoneNumber = `+${values}`

    console.log({ fullPhoneNumber, values })
    mutate(fullPhoneNumber, {
      onSuccess: res => {
        const { data } = res
        const { exists } = data
        localStorage.setItem("phoneNumber", fullPhoneNumber)
        localStorage.setItem("user-exist", JSON.stringify(exists))
        navigate(`/payment/mtn/confirm?type=${plan}&mode=${mode}`)
      },
      onError: error => {
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    })
  }

  useEffect(() => {
    setPhoneNumber(app?.msisdn)
  }, [isMtnUser, app?.msisdn])

  return (
    <PaymentRegisterLayout
      checkHeight={checkHeight}
      smallHeight={checkSmallScreenHeight}
      galleryImage={MTNPagePicture}
      title="Y’ello"
      subText="Please Confirm your MTN Number"
    >
      <PaymentLoginWrapper checkHeight={checkHeight}>
        <Form form={form} className="form__box">
          <FlexibleDiv className="input">
            <PhoneInput
              onChange={e => {
                setPhoneNumber(e)
              }}
              onSetCountryCode={code => setCountryCode(code)}
              value={isMtnUser ? app?.msisdn : udx__pay__esn?.msisdn || ""}
              bordered
              small
            />
          </FlexibleDiv>

          <CheckBox
            color="var(--dampWhite)"
            name="acceptedTermsAndConditions"
            checked={acceptedTermsAndConditions}
            onChange={({ target }) =>
              setAcceptedTermsAndConditions(target.checked)
            }
          >
            <span className="tac__text">
              I accept that on occasion I can be contacted by uduX
            </span>
          </CheckBox>

          <p className="tac">
            uduX is a personalised music device. By clicking on the sign up
            button, you agree to our{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions of Use
            </a>
            .
          </p>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              width="150px"
              height="50px"
              font_size="16px"
              htmlType="submit"
              color="var(--black)"
              contained
              disabled={!acceptedTermsAndConditions}
              bold
              ltr
              onClick={() => handleFormSubmit(phoneNumber)}
            >
              <span>
                {isLoading ? (
                  <LoadingOutlined color="var(--uduxYellowPrimary)" />
                ) : (
                  "Continue"
                )}
              </span>
            </Button>
          </FlexibleDiv>
        </Form>

        {/* <FlexibleDiv
          flexDir="column"
          className="btn__box"
          justifyContent="center"
        >
          <Link to="/reset-password" className="existing__user">
            Forgot your Password
          </Link>

          <Link to="/signup" className="existing__user">
            Create an Account
          </Link>
        </FlexibleDiv> */}
      </PaymentLoginWrapper>
    </PaymentRegisterLayout>
  )
}

export default PaymentLogin
