import UduXGrayLogo from "assets/images/Auth/udux_logo_gray.svg"
import defaultGalleryImage from "assets/images/Auth/verification_starting_page.png"
import { FlexibleDiv, Loader } from "components/lib"
import { Types, useMainContext } from "context"
import { instance } from "network"
import { AuthResponseError } from "pages/Auth/AuthResponseError"
import React, { useCallback, useState } from "react"
import { IoIosArrowRoundBack } from "react-icons/io"
import { useMediaQuery } from "react-responsive"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { topSlideInFrom } from "utils/data-helpers/animations"
import { ChoosePlatformWrapper } from "./choose-platform.styles"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
// import SwiperCore, { EffectFade } from "swiper";


const checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

export const ChoosePlatform = ({
  children,
  title = "You choose...",
  galleryImages,
  leftColor,
  rightImage,
  activeSlide,
  displaySubscriptionBox = true,
  backURL,
}) => {
  const { pathname, search, hash } = useLocation()
  const navigate = useNavigate()
  const apiResponseErrorRef = React.useRef(null)
  const isMobile = useMediaQuery({ maxWidth: 996 })
  const {
    state: { app },
    dispatch,
  } = useMainContext()
  const { apiResponseError, billingPlans } = app
  const showBackButton = pathname !== "/login" && pathname !== "/oauth"
  const [searchParams] = useSearchParams()
  const [subscriptionObj, setSubscriptionObj] = useState({})
  const mode = searchParams.get("mode")
  const type = searchParams.get("type")
  const [swiper, setSwiper] = React.useState(null);
  // SwiperCore.use([EffectFade]);

  const [nextBillingDate, setNextBillingDate] = useState("")
  const [fetchBilling, setFetchBilling] = useState(false)
  const [billingPrice, setBillingPrice] = useState(0)

  // http://localhost:3000/payment/complete?reference=ee05cfdf-0e2f-4af0-820f-b46cf4b5a224&trxref=ee05cfdf-0e2f-4af0-820f-b46cf4b5a224&reference=ee05cfdf-0e2f-4af0-820f-b46cf4b5a224

  React.useEffect(() => {
    topSlideInFrom(apiResponseErrorRef, { y: 30 })
  }, [apiResponseError])

  const subInterval = subscriptionObj?.interval?.toLowerCase()
  const subAmount = subscriptionObj?.amount
  const setBillingInfo = useCallback(
    plans => {
      const activePlan = plans?.find(
        singlePlan =>
          singlePlan?.interval?.toLowerCase() === type?.toLowerCase() &&
          singlePlan?.provider?.toLowerCase() === mode?.toLowerCase()
      )

      setSubscriptionObj(activePlan)

      //update the billing price
      setBillingPrice(parseInt(subAmount / 100))

      //update content
      let currentDate = new Date()

      if (subInterval === "daily") {
        calculateNextBillingDate(currentDate, 1, billingPrice)
      } else if (subInterval === "weekly") {
        calculateNextBillingDate(currentDate, 7, billingPrice)
      } else {
        calculateNextBillingDate(currentDate, 30, billingPrice)
      }
    },
    [billingPrice, mode, type, subInterval, subAmount]
  )

  //if the mode or type does not exist
  React.useEffect(() => {
    const reference = searchParams.get("reference")
    const trxRef = searchParams.get("trxref")

    if (reference || trxRef) return

    if (!mode || !type) {
      navigate("/payment")
    }
  }, [mode, type, navigate, searchParams])

    React.useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide, 500);
    }
  }, [swiper, activeSlide]);

  React.useEffect(() => {
    const store = JSON.parse(localStorage.getItem("udx__billing__plans"))
    const shouldFetchBilling =
      billingPlans?.length < 1 && !Array.isArray(store) && !!store?.length < 1

    if (shouldFetchBilling) {
      setFetchBilling(true)
      async function fetchBilling() {
        try {
          const { data } = await instance.get("/core/billing-plan")

          dispatch({ type: Types.SET_BILLING_PLANS, payload: data?.data })
          localStorage.setItem(
            "udx__billing__plans",
            JSON.stringify(data?.data)
          )

          setFetchBilling(false)
        } catch (error) {
          dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
          setFetchBilling(false)
        }
      }

      fetchBilling()
    } else {
      setBillingInfo(store)

      if (billingPlans.length === 0) {
        dispatch({ type: Types.SET_BILLING_PLANS, payload: store })
      }
    }
  }, [billingPlans, dispatch, setBillingInfo])

  const calculateNextBillingDate = (date, days, price) => {
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
    let result = new Date(date)
    result.setDate(result.getDate() + days)
    let formattedDate = result.toLocaleDateString("en-US", options)

    //update the next billing date
    setNextBillingDate(
      `Subscription will automatically renew at ₦${
        price ? price : "0"
      } daily, starting from ${formattedDate}, unless you cancel before the day of your next renewal.`
    )
  }

  const handleGoBack = () => {
    if (backURL) {
      return navigate(backURL)
    }

    return navigate(-1, { hash, search })
  }

  return (
    <>
      {fetchBilling ? (
        <Loader variant="green" height="100vh" />
      ) : (
        <ChoosePlatformWrapper
          checkHeight={checkHeight}
          location={pathname}
          smallHeight={checkSmallScreenHeight}
          leftColor={leftColor}
          rightImage={rightImage}
        >
          <FlexibleDiv className="bg__wrap__main">
            {isMobile ? (
              <div className="mobile__color__box__area">
                <div className="mobile__art__box__area"></div>
              </div>
            ) : (
              <React.Fragment>
                <div className="color__box__area"></div>
                <div className="art__box__area"></div>
              </React.Fragment>
            )}
          </FlexibleDiv>
          <FlexibleDiv className="content__box">
              {!isMobile && (
          <div className="gallery__pane" id="gallery__pane">
            <Swiper
              className="swiper"
              effect="fade"
              slidesPerView={1}
              initialSlide={activeSlide}
              onSwiper={setSwiper}
              allowTouchMove={false}
            >
              {galleryImages && galleryImages?.map((image, index) => (
                <SwiperSlide className="slider__slide " key={index}>
                  <div
                    className="gallery_pane_img"
                    style={{
                      backgroundImage: `url(${image?.pageHoverImage})`,
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
            <div className="activity__pane">
              {showBackButton && displaySubscriptionBox && (
                <button
                  className="go__back__box"
                  justifyContent="flex-start"
                  onClick={handleGoBack}
                >
                  <IoIosArrowRoundBack
                    color="var(--uduxWhitePrimary)"
                    size={30}
                  />{" "}
                  <span>Back</span>
                </button>
              )}
              <div className="logo__wrap">
                <img src={UduXGrayLogo} alt="uduX" />
              </div>

              <div className="content__box__layout">
                <div className="content__layout">
                  {displaySubscriptionBox && (
                    <>
                      <h2 className="title">{title}</h2>

                      <FlexibleDiv className="subscription__details__wrapper">
                        <FlexibleDiv
                          className="left__wrapper"
                          flexDir="column"
                          width="35%"
                          height="100%"
                          justifyContent="center"
                        >
                          <p className="type__text">
                            {`${subscriptionObj?.interval?.[0].toUpperCase()}${subscriptionObj?.interval?.slice(
                              1
                            )}`}
                          </p>
                          <p className="price__text">
                            ₦{billingPrice ? billingPrice : "0"}
                          </p>
                        </FlexibleDiv>

                        <FlexibleDiv
                          className="right__wrapper"
                          width="65%"
                          height="100%"
                        >
                          <p className="content__text">{nextBillingDate}</p>
                        </FlexibleDiv>
                      </FlexibleDiv>
                    </>
                  )}
                  <div className="auth__response__error__main">
                    {apiResponseError && (
                      <AuthResponseError
                        ref={apiResponseErrorRef}
                        error={apiResponseError}
                      />
                    )}
                  </div>

                  <div className="form__wrap">{children}</div>
                </div>
              </div>
            </div>
          </FlexibleDiv>
        </ChoosePlatformWrapper>
      )}
    </>
  )
}
