import { AREModalWrap } from "./auth-response-error.styles";

export const ReadMoreModal = ({ open, content, handleCancel, handleOk }) => {
  return (
    <AREModalWrap
      visible={open}
      onOk={handleOk}
      onCancel={handleCancel}
      title="We encountered some errors"
    >
      <ul className="list__errors">
        {content.map((e, idx) => (
          <li key={idx}>{e}</li>
        ))}
      </ul>
    </AREModalWrap>
  );
};
