import Card2 from "assets/images/general/card-bg-2.png";
import Card3 from "assets/images/general/card-bg-3.png";
import { FlexibleDiv, Loader } from "components";
import { Header } from "components/layouts/PaymentsLayout/PaymentLandingLayout/Header/Header";
import { Types, useMainContext } from "context";
import { getBillingPlans } from "network";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { PaymentHomeWrapper } from "./payment-home.styles";
import {
  AboutPayment,
  PaymentHero,
  PaymentInfo,
  SubscriptionPlans,
} from "./Components";

export const PaymentHome = () => {
  const [mtnPlans, setMTNPlans] = useState([]);
  const [uduxPlans, setUduxPlans] = useState([]);
  const {
    dispatch,
    state: { users },
  } = useMainContext();
  const { search } = useLocation();

  const { me } = users;

  const scrollToSubscribe = () => {
    const subscribeSection = document.getElementById("subscribe__section");
    subscribeSection.scrollIntoView({ behavior: "smooth" }, true);
  };

  const { data } = useQuery("billing-plans", async () => getBillingPlans());
  const [billingPlans, setBillingPlans] = useState([]);

  const filterUduxPlans = (plans) => {
    const tempArray = plans?.filter(
      (singlePlan) =>
        singlePlan["currency"] === "NGN" &&
        singlePlan["provider"]?.toUpperCase() === "UDUX"
    );

    const uduxSubscriptions = [
      {
        ...tempArray[0],
        borderColor: "#FE8A00",
        cardBackground: Card2,
      },
      {
        ...tempArray[2],
        borderColor: "#9F9D53",
        cardBackground: Card2,
      },
      {
        ...tempArray[1],
        borderColor: "#F3A01E",
        cardBackground: Card3,
      },
    ];

    setUduxPlans(uduxSubscriptions);
  };

  const filterMTNPlans = (plans) => {
    const tempArray = plans?.filter(
      (singlePlan) =>
        singlePlan["currency"] === "NGN" && singlePlan["provider"] === "MTN"
    );

    const mtnSubscriptions = [
      {
        ...tempArray[0],
        borderColor: "#FE8A00",
        cardBackground: Card2,
      },
      {
        ...tempArray[2],
        borderColor: "#9F9D53",
        cardBackground: Card2,
      },
      {
        ...tempArray[1],
        borderColor: "#F3A01E",
        cardBackground: Card3,
      },
    ];

    setMTNPlans(mtnSubscriptions);
  };

  console.log(window.location.search);

  useEffect(() => {
    const subscribeSection = document.getElementById("subscribe__section");
  

    if (subscribeSection && window.location.search.includes("isMtn")) {
      subscribeSection.scrollIntoView({ behavior: "smooth" });
    }
  }, [window.location]);
  

  useEffect(() => {
    const isEnriched = search.includes("isMtn") || search.includes("msisdn");

    if (me.isEnriched) {
      return;
    }

    if (isEnriched) {
      localStorage.removeItem("udx__pay__esn");

      // update context
      const qp = new URLSearchParams(search);
      dispatch({
        type: Types.HE_SERVICE,
        payload: {
          isEnriched: true,
          msisdn: qp.get("msisdn"),
          isMtn: qp.get("isMtn") === "true",
        },
      });
      localStorage.setItem(
        "udx__pay__esn",
        JSON.stringify({
          isEnriched: true,
          msisdn: qp.get("msisdn"),
          isMtn: qp.get("isMtn") === "true",
        })
      );
      //include the stream pass id
    } else {
      // redirect to HE-Service
      const protocol =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/payment"
          : "udux.com/payment";
      window.location.href = `http://mtn-service.udux.com/he?redirectUrl=${protocol}`;
    }
  }, [search, dispatch, me]);

  useEffect(() => {
    if (data && data.data) {
      setBillingPlans(data.data);
      filterUduxPlans(billingPlans);
      filterMTNPlans(billingPlans);

      dispatch({
        type: Types.SET_BILLING_PLANS,
        payload: data.data,
      });

      //store the data in localStorage
      localStorage.setItem("udx__billing__plans", JSON.stringify(data.data));
    }
  }, [data, billingPlans, dispatch]);

  return (
    <>
      {!data ? (
        <Loader variant="green" height="100vh" />
      ) : (
        <PaymentHomeWrapper>
          {/* imported the header here to make editing easier */}
          <Header />
          <PaymentHero />
          <PaymentInfo />

          <FlexibleDiv id="subscribe__section">
            <SubscriptionPlans mtnPlans={mtnPlans} uduxPlans={uduxPlans} />
          </FlexibleDiv>

          <AboutPayment />
          {/* <Footer /> */}

          {/* <--------> */}
          <FlexibleDiv className="subscribe__btn__mobile">
            <button onClick={scrollToSubscribe}>
              <span>Subscribe Now</span>
            </button>
          </FlexibleDiv>
        </PaymentHomeWrapper>
      )}
    </>
  );
};
