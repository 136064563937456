import appTypes from "./app/app.types"
import artistTypes from "./artist/artist.types"
import playerTypes from "./player/player.types"
import playlistTypes from "./playlist/playlist.types"
import searchTypes from "./search/search.types"
import userTypes from "./user/user.types"

export const Types = {
  ...appTypes,
  ...artistTypes,
  ...playerTypes,
  ...playlistTypes,
  ...searchTypes,
  ...userTypes,
}
