import { FlexibleDiv } from "components/lib/Box";
import styled from "styled-components";

export const SongCardWrap = styled(FlexibleDiv)`
  max-width: 200px;
  min-width: 200px;
  height: 280px;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 0.5px solid var(--uduxGrayLightAlpha);

  ::before,
  ::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 20%;
  }

  ::before {
    right: 0;
    top: 0;
    background: ${({ dominantColors }) =>
      dominantColors?.length
        ? `linear-gradient(${dominantColors[1]}, ${dominantColors[0]})`
        : "var(--gradientPrimary)"};
  }
  ::after {
    left: 0;
    bottom: 0;
    background: ${({ dominantColors }) =>
      dominantColors?.length
        ? `linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : "var(--gradientPrimary)"};
  }

  :hover .card__play__btn {
    height: 100%;

    svg {
      display: inline-flex;
    }
  }

  .inner__wrap {
    width: 90%;
    z-index: 9;
    height: 90%;
    margin: 0 auto;
  }

  .picture__box {
    width: 100%;
    height: 75%;
    display: flex;
    border-bottom: 0;
    position: relative;
    align-items: center;
    background-color: black;
    border: 0.5px solid var(--uduxGrayLightAlpha);
  }

  .card__play__btn {
    top: 0;
    height: 0;
    z-index: 11;
    overflow: hidden;
    position: absolute;
    transition: all 0.3s ease-in;
    background-color: rgba(0, 0, 0, 0.75);

    svg {
      display: none;
    }
  }

  .image__wrap {
    width: 95%;
    height: 95%;
    margin: 0 auto;

    img {
      float: left;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .name__box {
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: space-between;
    border: ${({ dominantColors }) =>
      dominantColors?.length
        ? `0.5px solid linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : "0.5px solid var(--gradientPrimary)"};
    background: ${({ dominantColors }) =>
      dominantColors?.length
        ? `linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : "var(--gradientPrimary)"};

    p {
      margin: 0;
      font-size: 13px;
      font-family: "Baguede", sans-serif;
    }

    .title__and__artists {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .name {
      font-size: 15px;
      text-transform: capitalize;
    }

    .artist__names {
      display: flex;
      column-gap: 2px;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      position: relative;

      .duration {
        position: absolute;
        right: 0;
        font-size: 10px;
      }
    }

    .artist {
      font-size: 10px;
      text-transform: capitalize;
      width: max-content;

      :hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .name__box {
      /* height: 70px; */
    }
  }
`;
