import React from "react"
import { FlexibleDiv } from "components"
import HomePagePicture from "assets/images/general/sisters-standing.jpg"
import HomePageBG from "assets/images/general/homepage-bg.png"
import HomePageBGMobile from "assets/images/general/homepage-bg-mobile.png"
import {
  PaymentHeroWrapper,
  BackgroundImageWrapper,
  PHCWrapper,
} from "./payment-hero.styles"

export const PaymentHero = () => {
  return (
    <PaymentHeroWrapper>
      <FlexibleDiv className="landing__page__container">
        <BackgroundImageWrapper flexDir="column" justifyContent="end">
          <img src={HomePageBG} className="web__image" alt="background pic" />
          <img
            src={HomePageBGMobile}
            className="mobile__image"
            alt="background pic"
          />
        </BackgroundImageWrapper>
        <PHCWrapper
          flexDir="column"
          justifyContent="start"
          alignItems="start"
          flexWrap="nowrap"
        >
          <FlexibleDiv
            className="content__section"
            height="25%"
            flexDir="row"
            width="90%"
            justifyContent="start"
            flexWrap="nowrap"
          >
            <FlexibleDiv
              className="hero__text__section"
              justifyContent="start"
              width="fit-content"
            >
              <h1 className="hero__text__1">Follow Your</h1>
              <h1 className="hero__text__2">&nbsp; Rhythm</h1>
            </FlexibleDiv>
            <FlexibleDiv
              height="100%"
              flexDir="row"
              flexWrap="nowrap"
              justifyContent="space-evenly"
              className="hero__right__column"
            >
              <hr />

              <p className="hero__udux__text">
                uduX gives you payment options that work for you and your music.
                <br />
                Daily, Monthly and Weekly plans that start from just ₦30
              </p>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv
            className="homepage__image__wrapper"
            width="100%"
            height="75%"
          >
            <img src={HomePagePicture} alt="homepage design" />
          </FlexibleDiv>
        </PHCWrapper>
      </FlexibleDiv>
    </PaymentHeroWrapper>
  )
}
