import classNames from "classnames"
import { Types, useMainContext } from "context"
import React from "react"
import { activeCategory, filterCategory, filterList } from "./data-helper"
import { SearchFilterWrap } from "./search-filter.styles"

export const SearchFilter = ({ activeSearch }) => {
  const [active, setActive] = React.useState("")
  const {
    state: { search },
    dispatch,
  } = useMainContext()
  const { filterSearch, filterActiveSearch } = search

  React.useEffect(() => {
    if (activeSearch) {
      setActive(filterActiveSearch ? activeCategory[filterActiveSearch] : "all")
    } else {
      setActive(filterSearch ? activeCategory[filterSearch] : "all")
    }
  }, [])

  React.useEffect(() => {
    const payload = filterCategory[active]
    if (activeSearch) {
      dispatch({ type: Types.FILTER_ACTIVE_SEARCH, payload })
    } else {
      dispatch({ type: Types.FILTER_SEARCH, payload })
    }
  }, [active])

  return (
    <SearchFilterWrap>
      <ul className="filter__list">
        {filterList.map((fl, idx) => (
          <li
            className={classNames("filter__list__item", {
              ["active__item"]: active === fl.label,
            })}
            onClick={() => setActive(fl.label)}
            key={`${idx}__${fl.label}`}
          >
            {fl.name}
          </li>
        ))}
      </ul>
    </SearchFilterWrap>
  )
}
