export const FeedIcon = ({
  color = "#eaeaea",
  width = "22.338",
  height = "22.337",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className="fill__player__svg"
  >
    <g id="earth-day" transform="translate(0 -0.001)">
      <path
        id="Path_1189"
        data-name="Path 1189"
        d="M20.282,14a6.282,6.282,0,1,0,6.282,6.282A6.29,6.29,0,0,0,20.282,14Zm5.567,5.933h-.331v-.7a.348.348,0,0,0-.1-.247l-.7-.7a.35.35,0,0,0-.247-.1H24.11a.349.349,0,0,0-.349.349v.7h-.687a.349.349,0,0,0-.312.193l-.252.505H21.24a.349.349,0,0,0-.306.517l.574,1.047a.35.35,0,0,0,.306.181h.912v.482l-1.2.6a.348.348,0,0,0-.193.312v.86l-.8.536H20.1l-.52-.671v-.455a.35.35,0,0,0-.117-.261l-.581-.515v-.541a.349.349,0,0,0-.349-.349h-.743a.346.346,0,0,0-.164.041l-.471.252-.738-.482V19.831l.6-.75,1.445-.179.845.617a.351.351,0,0,0,.206.067h2.162a.349.349,0,0,0,.349-.349V18.188a.349.349,0,0,0-.349-.349H20l-1.065-.647a.348.348,0,0,0-.181-.051H17.729l.021-.053,1.219-.295a.349.349,0,0,0,.267-.339v-.447l.905-.262h1.482a.349.349,0,0,0,.2-.066l.7-.506a5.588,5.588,0,0,1,3.321,4.76Zm-5.567,5.933a5.584,5.584,0,1,1,1.44-10.973l-.213.154H20.091a.338.338,0,0,0-.1.014l-1.2.349a.349.349,0,0,0-.252.335v.435l-1.129.273a.349.349,0,0,0-.243.213l-.27.7a.349.349,0,0,0,.325.475h1.433l1.065.647a.347.347,0,0,0,.181.051h1.43v.349h-1.7l-.865-.631a.345.345,0,0,0-.249-.065l-1.723.214a.349.349,0,0,0-.23.128l-.765.957a.348.348,0,0,0-.076.218v1.97a.348.348,0,0,0,.158.292l1.069.7a.349.349,0,0,0,.355.015l.576-.307h.306v.349a.35.35,0,0,0,.117.261l.581.515v.417a.351.351,0,0,0,.073.214l.7.9a.351.351,0,0,0,.276.135h.7a.351.351,0,0,0,.194-.059l1.047-.7a.349.349,0,0,0,.155-.29V23.29l1.2-.6a.348.348,0,0,0,.193-.312V21.33a.349.349,0,0,0-.349-.349H22.02l-.191-.349h.9a.349.349,0,0,0,.312-.193l.252-.505h.82a.349.349,0,0,0,.349-.349v-.565l.361.361v.9a.349.349,0,0,0,.349.349h.68A5.587,5.587,0,0,1,20.282,25.867Z"
        transform="translate(-9.114 -9.113)"
        fill={color}
      />
      <path
        id="Path_1190"
        data-name="Path 1190"
        d="M23.629,51.368l-1.4,1.4a1.047,1.047,0,0,0-1-1.359H18.436a1.035,1.035,0,0,0-.735.3l-1.593,1.526a.349.349,0,0,0-.005.5l1.745,1.745a.349.349,0,0,0,.494,0l.6-.6h3.695a1.04,1.04,0,0,0,.74-.306l1.739-1.731a1.048,1.048,0,0,0,0-1.481A1.072,1.072,0,0,0,23.629,51.368Zm.988.987-1.738,1.731a.353.353,0,0,1-.247.1H18.792a.348.348,0,0,0-.247.1l-.451.451L16.848,53.5l1.341-1.285a.352.352,0,0,1,.247-.1h2.792a.349.349,0,1,1,0,.7h-.683a.349.349,0,0,0,0,.7h1.79a.35.35,0,0,0,.247-.1l1.541-1.541a.358.358,0,0,1,.494,0,.349.349,0,0,1,0,.493Z"
        transform="translate(-10.416 -33.246)"
        fill={color}
      />
      <path
        id="Path_1191"
        data-name="Path 1191"
        d="M22.069,4.538a1.039,1.039,0,0,0,.74-.307l1.4-1.4a1.035,1.035,0,0,0-.047.312A1.048,1.048,0,0,0,25.21,4.189H28a1.037,1.037,0,0,0,.736-.3l1.593-1.541a.348.348,0,0,0,0-.5L28.591.1A.349.349,0,0,0,28.1.1l-.6.6H23.814a1.04,1.04,0,0,0-.74.307L21.329,2.75a1.047,1.047,0,0,0,.74,1.787Zm-.247-1.294L23.567,1.5a.352.352,0,0,1,.247-.1h3.832a.348.348,0,0,0,.247-.1l.451-.451L29.592,2.09l-1.342,1.3a.352.352,0,0,1-.247.1H25.21a.349.349,0,1,1,0-.7h.683a.349.349,0,1,0,0-.7H24.1a.35.35,0,0,0-.247.1L22.316,3.738a.357.357,0,0,1-.493,0,.349.349,0,0,1,0-.494Z"
        transform="translate(-13.685 0)"
        fill={color}
      />
      <path
        id="Path_1192"
        data-name="Path 1192"
        d="M55.463,28.115l-.6-.6V23.825a1.04,1.04,0,0,0-.306-.74L52.83,21.347a1.072,1.072,0,0,0-1.481,0,1.047,1.047,0,0,0,0,1.481l1.4,1.4a1.047,1.047,0,0,0-1.359,1v2.792a1.035,1.035,0,0,0,.3.735l1.526,1.593a.349.349,0,0,0,.248.107h0a.35.35,0,0,0,.247-.1l1.745-1.745a.349.349,0,0,0,0-.494Zm-1.986,1.493-1.285-1.341a.352.352,0,0,1-.1-.247V25.228a.349.349,0,0,1,.7,0v.683a.349.349,0,1,0,.7,0v-1.79a.349.349,0,0,0-.1-.247l-1.541-1.541a.349.349,0,0,1,0-.494.358.358,0,0,1,.493,0l1.731,1.738a.354.354,0,0,1,.1.247v3.839a.349.349,0,0,0,.1.247l.451.451Z"
        transform="translate(-33.228 -13.702)"
        fill={color}
      />
      <path
        id="Path_1193"
        data-name="Path 1193"
        d="M2.829,22.225a1.034,1.034,0,0,0,.312.047,1.048,1.048,0,0,0,1.047-1.047V18.433a1.037,1.037,0,0,0-.3-.736L2.345,16.1A.349.349,0,0,0,2.1,16a.3.3,0,0,0-.25.1L.1,17.845a.349.349,0,0,0,0,.494l.6.6v3.687a1.04,1.04,0,0,0,.307.74L2.75,25.107A1.047,1.047,0,1,0,4.23,23.626Zm.907,2.388a.357.357,0,0,1-.494,0L1.5,22.868a.352.352,0,0,1-.1-.247V18.789a.348.348,0,0,0-.1-.247l-.451-.451L2.09,16.844l1.3,1.342a.352.352,0,0,1,.1.247v2.792a.349.349,0,1,1-.7,0v-.683a.349.349,0,1,0-.7,0v1.79a.348.348,0,0,0,.1.247L3.737,24.12a.349.349,0,0,1,0,.494Z"
        transform="translate(0 -10.412)"
        fill={color}
      />
    </g>
  </svg>
);
