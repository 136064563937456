import { FlexibleDiv, ProgressSlider } from "components"
import { Types, useMainContext } from "context"
import { useState } from "react"
import { useRef } from "react"
import { memo } from "react"
import {
  calculateTime,
  changeRange,
  getDisplayArtist,
  getTrackTitle,
} from "utils"
import { TPIWrap } from "./track-progress-indicator.styles"
import { AddSongIcon, FavoriteFilledIcon, FavoriteIcon } from "assets"
import { useEffect } from "react"

export const TrackProgressIndicator = memo(() => {
  const progressBarRef = useRef()
  const [duration, setDuration] = useState(0)
  const {
    state: { player },
    playerRef,
    dispatch,
  } = useMainContext()

  const { currentTrack, isPlaying, currentTime } = player

  useEffect(() => {
    const seconds = Math.floor(playerRef?.current?.duration)
    setDuration(seconds)
  }, [
    playerRef?.current?.loadedmetadata,
    playerRef?.current?.readyState,
    playerRef,
  ])

  return (
    <TPIWrap className="song__progress__wrap" flexDir="column">
      <FlexibleDiv className="song__progress__bar" justifyContent="flex-start">
        <ProgressSlider
          value={(currentTime / duration) * 100}
          ref={progressBarRef}
          tooltip={false}
          onChange={e =>
            changeRange(
              e,
              playerRef,
              Types.CURRENT_PLAYING_TIME,
              duration,
              dispatch
            )
          }
          range={true}
          isMobile
        />
        <FlexibleDiv
          width={`${(currentTime / duration) * 100}%`}
          className="track__inner"
        ></FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv className="timer" justifyContent="space-between">
        <small>{calculateTime(currentTime || 0)}</small>
        {currentTime > duration ? (
          <small>0:00</small>
        ) : (
          <small>
            {isPlaying && "-"}
            {calculateTime(duration - currentTime || 0)}
          </small>
        )}
      </FlexibleDiv>

      <FlexibleDiv className="track__title__control">
        <FlexibleDiv className="icon__wrap left">
          {currentTrack?.userLiked ? (
            <FavoriteFilledIcon width="25" height="25" />
          ) : (
            <FavoriteIcon width="25" height="25" />
          )}
        </FlexibleDiv>

        <FlexibleDiv flexDir="column" className="track-title">
          <p className="title">{getTrackTitle(currentTrack, true)}</p>
          <p className="artist__wrap">{getDisplayArtist(currentTrack)}</p>
        </FlexibleDiv>

        <FlexibleDiv className="icon__wrap right">
          <AddSongIcon width="25" height="25" />
        </FlexibleDiv>
      </FlexibleDiv>
    </TPIWrap>
  )
})
