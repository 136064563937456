import BackgroundImage from "assets/images/payment/ChoosePlatformImage.png"
import GalleryImage from "assets/images/payment/confirmPayment.png"
import { Button, FlexibleDiv, PhoneInput } from "components"
import { ChoosePlatform } from "components/layouts/AuthLayout-Platform/ChoosePlatform"
import { Types, useMainContext } from "context"
import { checkAvailablePhone, subscribeMTNPhone } from "network"
import { useEffect, useState, Fragment } from "react"
import { useMutation, useQuery } from "react-query"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { subscriptionPlans } from "utils"
import { RegisterContent } from "./PaymentMTNConfirmationPage.styles"
import { getBillingPlans } from "network"

const PaymentMTNConfirmationPage = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [, setCountryCode] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const { data: cpeData, refetch } = useQuery(
    ["check-phone-exists", phoneNumber],
    async () => {
      console.log({ phoneNumber }, "pn")
      return checkAvailablePhone(phoneNumber)
    },
    {
      enabled: !!phoneNumber,
    }
  )
  const {
    dispatch,
    state: { app, users },
  } = useMainContext()
  const { mutate, isLoading } = useMutation(subscribeMTNPhone)
  const [params] = useSearchParams()
  const mode = params.get("mode") || state?.mode
  const plan = params.get("type") || state?.plan

  const { me } = users
  const isMtnUser = Boolean(app?.isMtn)
  const lStore = localStorage.getItem("udx__pay__esn")
  const udx__pay__esn = JSON.parse(lStore.replace(/\\/g, ""))
  const msisdn = isMtnUser ? app.msisdn : udx__pay__esn?.msisdn || ""

  useEffect(() => {
    console.log({ msisdn, app, udx__pay__esn }, "msi")
    setPhoneNumber(msisdn.startsWith("+") ? msisdn : `+${msisdn}`)
  }, [msisdn])

  const fetchPlanId = async () => {
    try {
      const res = await getBillingPlans()
      const billingPlans = res.data

      let planSelected = billingPlans.find(
        singlePlan =>
          singlePlan["interval"] === plan &&
          singlePlan["provider"] === mode.toUpperCase()
      )

      return planSelected?.mtnPlanInfo?.planId
    } catch (err) {
      console.log("err: ", err)
    }
  }

  const subscribeMTNNumber = async value => {
    const planId = await fetchPlanId()

    const payload = {
      planId,
      msisdn: value.startsWith("+") ? value.substr(1) : value,
    }

    console.log({ payload })

    if (phoneNumber && !cpeData?.hasOwnProperty("exist")) {
      await refetch()
    }

    if (value === me?.phoneNumber) {
      dispatch({ type: Types.MSISDN_USER_HAS_ACCOUNT, payload: cpeData.exists })

      mutate(payload, {
        onSuccess: () => {
          navigate(`/payment/mtn/check-phone?type=${plan}&mode=${mode}`, {
            state: {
              plan,
              mode,
            },
          })
        },
        onError: error => {
          dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
        },
      })
    } else {
      // The user has a different phone number attached to their account from what they've provided or browsing with
      return dispatch({
        type: Types.API_RESPONSE_ERROR,
        payload:
          "Sorry, we cannot proceed with this subscription for this account!",
      })
    }
  }

  return (
    <Fragment>
      {Object.keys(subscriptionPlans).includes(plan) && (
        <ChoosePlatform
          title="Y’ello"
          galleryImage={GalleryImage}
          leftColor="#00725E"
          rightImage={BackgroundImage}
          subscriptionObj={{
            type: `${plan[0].toUpperCase()}${plan.substring(1)}`,
            ...subscriptionPlans[`${plan}`],
          }}
          backURL={`/payment`}
        >
          <RegisterContent
            width="100%"
            height="100%"
            flexDir="column"
            justifyContent="space-between"
          >
            <p className="confirm__text">
              Please Confirm this is your MTN Number
            </p>

            <FlexibleDiv
              width="90%"
              height="fit-content"
              className="payment__method__wrapper"
            >
              <PhoneInput
                value={phoneNumber}
                onChange={e => {
                  setPhoneNumber(e)
                }}
                onSetCountryCode={code => setCountryCode(code)}
                bordered
                small
              />
            </FlexibleDiv>

            <Button
              loading={isLoading}
              className="subscribe__btn"
              onClick={() => subscribeMTNNumber(phoneNumber)}
            >
              <span>Subscribe me to Udux</span>
            </Button>
            <Link
              className="change__method__text"
              to={`/payment/plan?type=${plan}&mode=${mode}`}
            >
              Change Payment Method
            </Link>
          </RegisterContent>
        </ChoosePlatform>
      )}
    </Fragment>
  )
}

export default PaymentMTNConfirmationPage
