import classNames from "classnames"
import { memo, useEffect, useState } from "react"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { MdCancel as Cancel } from "react-icons/md"
import hideIcon from "assets/images/Auth/eye-hide.png"
import showIcon from "assets/images/Auth/eye-show.png"
import {
  BorderedFormItem,
  BorderedFormItemWrap,
  BorderedIconWrap,
} from "./bordered-textfield.styles"

export const BorderedTextfield = memo(
  ({
    name,
    type,
    label,
    rules,
    rows,
    cols,
    textarea,
    onChange,
    validator,
    placeholder,
    hasError,
    nullable,
    hasErrorMessage,
    checkAvailability,
    ...props
  }) => {
    const [status, setStatus] = useState()
    const [value, setValue] = useState("")

    const [passwordType, setPasswordType] = useState("password")

    useEffect(() => {
      let numberInput = document.querySelector(".numberInput")

      if (numberInput) {
        numberInput.addEventListener("keypress", function (evt) {
          if (
            (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
            evt.which > 57
          ) {
            evt.preventDefault()
          }
        })
      }
    }, [])

    props.hasError = checkAvailability ? hasError : Boolean(status?.err)
    props.hasSuccess = checkAvailability
      ? props.hasSuccess
      : Boolean(status?.success)

    useEffect(() => {
      if (hasError) {
        setStatus({ err: hasErrorMessage })
      }
    }, [hasError])

    const handleBlur = e => {
      if (hasError && hasErrorMessage) {
        return setStatus({ err: hasErrorMessage })
      } else {
        validator(e, e?.target?.value, setStatus)
      }
    }

    return (
      <BorderedFormItemWrap
        className="form-item-container"
        height={props.height}
        width={props.width}
        {...props}
      >
        {label && <label htmlFor={name}>{label}</label>}

        <div className="input__wrapper">
          <BorderedFormItem
            name={name}
            rules={
              validator
                ? [
                    {
                      validator: (rules, value) =>
                        validator(rules, value, setStatus, nullable),
                    },
                  ]
                : rules
            }
            lightMask={props.lightMask}
            {...props}
          >
            {textarea ? (
              <textarea placeholder={placeholder} rows={rows} cols={cols} />
            ) : (
              <input
                disabled={props.editLocked}
                placeholder={placeholder}
                className={classNames("", {
                  ["numberInput"]: type === "number",
                })}
                onChange={e => {
                  if (onChange) {
                    return onChange(e)
                  }

                  setValue(e.target.value)

                  if (checkAvailability) {
                    return checkAvailability(e?.target?.value)
                  }

                  validator && validator(e, e?.target?.value, setStatus)
                }}
                type={type === "password" ? passwordType : type || "text"}
                onBlur={handleBlur}
              />
            )}
          </BorderedFormItem>

          {type === "password" && !props.noPasswordIcon && (
            <div className="password__eye">
              {passwordType === "password" ? (
                <img
                  onClick={() => setPasswordType("type")}
                  src={showIcon}
                  alt="password show icon"
                />
              ) : (
                <img
                  onClick={() => setPasswordType("password")}
                  src={hideIcon}
                  alt="password hide icon"
                />
              )}
            </div>
          )}

          <BorderedIconWrap height={props.height}>
            {status?.err && <Cancel className="cancel" />}
            {status?.success && (
              <BsFillCheckCircleFill
                className="check"
                color="var(--successPrimary)"
              />
            )}
          </BorderedIconWrap>
        </div>

        <p className="input__error__text">{status?.err}</p>
      </BorderedFormItemWrap>
    )
  }
)
