export const ShuffleOutlineIcon = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "25"}
    height={height || "30"}
    viewBox="0 0 30 40"
    {...props}
  >
    <g id="shuffle-icon" transform="translate(2 6)">
      <path
        id="Path_1558"
        data-name="Path 1558"
        d="M0,29.94H2.786c1.588,0,2.618,1.1,3.424,2.355l.416.65L8.65,29.786A7.075,7.075,0,0,0,2.786,26.5H0Zm21.675,5.878-2.431,2.431,1.685,1.685H17.1c-1.569,0-2.63-1.115-3.424-2.355l-.416-.65-2.022,3.157A7.254,7.254,0,0,0,17.1,43.373h3.834l-1.685,1.685,2.431,2.431,5.836-5.836ZM0,39.934H2.786c1.588,0,2.618-1.1,3.424-2.355l4.573-7.138c1.439-2.246,3.534-3.94,6.312-3.94h3.834l-1.685-1.685,2.431-2.431,5.836,5.836-5.836,5.836-2.431-2.431,1.685-1.685H17.1c-1.569,0-2.63,1.115-3.424,2.355L9.1,39.433c-1.468,2.292-3.45,3.94-6.312,3.94H0Z"
        transform="translate(0 -18.384)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_1559"
        data-name="Path 1559"
        d="M7.248,28.22A6.519,6.519,0,0,0,2.786,26.5H0V28.22ZM0,41.654H7.248a6.52,6.52,0,0,1-4.462,1.719H0Zm12.665,0H27.512L21.675,47.49l-2.431-2.431,1.685-1.685H17.1A6.633,6.633,0,0,1,12.665,41.654ZM27.512,28.22H12.665A6.634,6.634,0,0,1,17.1,26.5h3.834l-1.685-1.685,2.431-2.431Z"
        transform="translate(0 -18.384)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);
