import { FlexibleDiv } from "components";
import styled from "styled-components";

export const SearchHistoryWrap = styled(FlexibleDiv)`
  padding: 1rem 4rem;
  height: auto;
  padding-bottom: ${({ playerActive }) => (playerActive ? "2rem" : "1rem")};

  .make__sticky {
    position: sticky;
    top: 0;
  }

  .search__header {
    padding: 0.5rem 0;

    h2 {
      color: var(--uduxWhitePrimary);
      font-size: 22px;
      font-family: "Bw Modelica" sans-serif;
    }
  }

  .search__filter__wrap {
    width: 100%;
    justify-content: flex-end;

    &__content {
      width: 50%;
    }
  }

  .result__main {
    padding-top: 4rem;

    gap: 4rem 3%;
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    height: 100%;
  }

  .result__main__tracks {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  @media screen and (min-width: 1921px) {
    .search__filter__wrap {
      &__content {
        width: 40%;
      }
    }
  }

  @media screen and (min-width: 951px) and (max-width: 1493px) {
    padding: 1.5rem;
    padding-bottom: ${({ playerActive }) => (playerActive ? "2rem" : "1.5rem")};

    .search__filter__wrap {
      justify-content: flex-end;

      &__content {
        width: 68%;
      }
    }

    .result__main {
      gap: 3rem 5%;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 950px) {
    padding: 1.5rem;
    padding-bottom: ${({ playerActive }) => (playerActive ? "2rem" : "1.5rem")};

    .search__filter__wrap {
      justify-content: flex-end;

      &__content {
        width: 75%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 1.5rem;
    padding-bottom: ${({ playerActive }) => (playerActive ? "2rem" : "1.5rem")};

    .search__filter__wrap {
      justify-content: center;
      position: sticky;
      top: 0;
      z-index: 999;

      &__content {
        width: 100%;
      }
    }

    .result__main {
      padding: 2rem 3rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px;
    }

    .result__main__tracks {
      padding: 2rem 0rem;
    }
  }

  @media screen and (max-width: 420px) {
    .result__main {
      justify-content: center;
    }
  }
`;
