const playerTypes = {
  REPEAT: "REPEAT",
  SHUFFLE: "SHUFFLE",
  SET_FSEM: "SET_FSEM",
  IS_PLAYING: "IS_PLAYING",
  MUTE_PLAYER: "MUTE_PLAYER",
  ADD_TO_QUEUE: "ADD_TO_QUEUE",
  ACTIVE_TRACKS: "ACTIVE_TRACKS",
  CURRENT_VOLUME: "CURRENT_VOLUME",
  SET_PLAY_TRACK: "SET_PLAY_TRACK",
  SET_STOP_TRACK: "SET_STOP_TRACK",
  PLAY_NEXT_TRACK: "PLAY_NEXT_TRACK",
  PLAY_PREV_TRACK: "PLAY_PREV_TRACK",
  SET_PAUSE_TRACK: "SET_PAUSE_TRACK",
  IS_PLAYER_ACTIVE: "IS_PLAYER_ACTIVE",
  UPDATE_PREV_TRACKS: "UPDATE_PREV_TRACKS",
  SET_CURRENT_PLAYING: "SET_CURRENT_PLAYING",
  REPLACE_PREV_TRACKS: "REPLACE_PREV_TRACKS",
  SET_PLAYING_PLAYLIST: "SET_PLAYING_PLAYLIST",
  CURRENT_PLAYING_TIME: "CURRENT_PLAYING_TIME",
  REPLACE_QUEUED_TRACKS: "REPLACE_QUEUED_TRACKS",
  PLAYING_LIST_ACTIVE_TRACKS: "PLAYING_LIST_ACTIVE_TRACKS",
}

export default playerTypes
