import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import { useMainContext } from "context";
import { ActiveSearch, SearchHistory } from "./components";

export const Search = () => {
  const { searchQuery } = useMainContext();

  return <PlayerLayout>{getRenderView()}</PlayerLayout>;

  function getRenderView() {
    if (searchQuery) {
      return <ActiveSearch />;
    } else {
      return <SearchHistory />;
    }
  }
};
