import styled from "styled-components"
import { FlexibleDiv } from "components"

export const PaymentSuccessWrapper = styled(FlexibleDiv)`
  background: #00745d;
  padding: 10px;
  justify-content: space-between;

  .payment__container {
    .top__container {
      height: 130px;

      .congratulations__text {
        color: var(--uduxWhitePrimary);
        margin-bottom: 0.5rem;
        font-family: "Baguede";
        text-align: center;
        width: 100%;
        font-size: 1.75rem;
      }

      .payment__text {
        margin: 0.6rem auto 0.5rem;
        text-align: center;
        width: 70%;
        font-size: 0.65rem;
        font-family: "Bw ModelicaRegular";
        color: var(--uduxWhitePrimary);
        font-weight: bold;
      }

      .button__wrap {
        width: 70%;
      }
    }

    .bottom__container {
      background: var(--uduxBackgroundBlack);
      padding: 30px;
      height: 300px;
      margin-top: 1rem;

      .summary__text {
        color: #ffa300;
        margin-bottom: 0;
        font-size: 1rem;
        font-family: "Baguede";
        -webkit-text-stroke-width: 0.4px;
      }

      .receipt__content__wrapper {
        flex: 1;

        .receipt__content {
          p {
            color: #fcfcfc;
            opacity: 0.8;
            letter-spacing: normal;
            margin-bottom: 0;
          }

          .title {
            font-weight: bold;
            font-family: "Bw ModelicaExtraBold";
            font-size: 0.7rem;
            margin: 0;
          }

          .content {
            font-family: "Baguede";
            font-size: 0.75rem;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  //query for media screens
  @media (max-width: 550px) {
    align-items: flex-start;

    @media (max-width: 376px) {
      height: 100%;
    }

    .payment__container {
      .top__container {
        .congratulations__text {
          font-size: 1.5rem;

          @media (max-width: 280px) {
            font-size: 0.9rem;
          }
        }

        .payment__text {
          width: 88%;

          @media (max-width: 376px) {
            font-size: 0.6rem;
          }

          @media (max-width: 280px) {
            font-size: 0.5rem;
          }
        }

        .button__wrap {
          width: 80%;

          button {
            padding: 0.5rem;
          }
        }
      }

      .bottom__container {
        padding: 1rem 0.5rem;

        /* #break-flex {
          flex-direction: column;
          align-items: flex-start;
        } */
      }
    }
  }

  @media (min-width: 768px) and (max-width: 920px) {
    max-height: 450px;
  }
`

export const PaymentFailedWrapper = styled(FlexibleDiv)`
  background: #740016;
  padding: 10px;
  justify-content: space-between;
  max-height: 450px;

  .top__container {
    .congratulations__text {
      color: var(--uduxWhitePrimary);
      margin: 0;
      font-family: "Baguede";

      @media (max-width: 280px) {
        font-size: 0.9rem;
      }
    }

    .payment__text {
      margin: 0;
      text-align: center;
      width: 70%;
      font-size: 0.7rem;
      font-family: "Bw ModelicaRegular";
      color: var(--uduxWhitePrimary);
      font-weight: bold;

      @media (max-width: 280px) {
        font-size: 0.6rem;
      }
    }
  }

  .bottom__container {
    background: var(--uduxBackgroundBlack);
    padding: 30px;

    @media (max-width: 550px) {
      padding: 20px;
    }

    p {
      font-family: "Baguede";
      font-size: 0.8rem;
      color: #fcfcfc;
      opacity: 0.8;
      text-align: center;
      margin: 0;

      @media (max-width: 280px) {
        font-size: 0.6rem;
      }
    }

    button {
      width: fit-content;
      border-radius: 0;
      background: #ffa800;
      padding: 0;
      padding: 6px 22px;

      span {
        font-size: 0.7rem;
        -webkit-text-stroke-width: 0.4px;
        color: var(--uduxBackgroundBlack);
        padding: 0;
      }
    }
  }

  .support {
    text-decoration: underline;
    cursor: pointer;
    color: var(--white);
  }
`
