import React, { useEffect, useState } from "react";
import {
  BorderedSelectFormItem,
  BorderedSelectItemWrap,
  BorderedUduxSelect,
} from "./bordered-select.styles";
import { IoChevronDownCircleSharp } from "react-icons/io5";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdCancel as Cancel } from "react-icons/md";

export const BorderedSelectField = ({
  name,
  width,
  color,
  label,
  border,
  radius,
  height,
  onBlur,
  margin,
  padding,
  onClick,
  onchange,
  children,
  validator,
  suffixIcon,
  showSearch,
  background,
  text_align,
  placeholder,
  borderColor,
  font_family,
  placeholder_color,
  ...props
}) => {
  const [status, setStatus] = useState("");

  return (
    <BorderedSelectItemWrap className="select__item__container" {...props}>
      {label && <label htmlFor={name}>{label}</label>}

      <BorderedSelectFormItem
        name={name}
        width={width}
        color={color}
        height={height}
        radius={radius}
        border={border}
        margin={margin}
        text_align={text_align}
        background={background}
        borderColor={borderColor}
        hasError={Boolean(status?.err)}
        hasSuccess={Boolean(status?.success)}
        rules={[
          {
            validator: (rules, value) => validator(rules, value, setStatus),
          },
        ]}
      >
        <BorderedUduxSelect
          small={props.small}
          onBlur={onBlur}
          padding={padding}
          onClick={onClick}
          onChange={onchange}
          showSearch={showSearch}
          placeholder={placeholder}
          font_family={font_family}
          suffixIcon={
            status?.success ? (
              <div className="check">
                <BsFillCheckCircleFill
                  className="check"
                  color="var(--successPrimary)"
                />
              </div>
            ) : status?.err ? (
              <div className="cancel">
                <Cancel />
              </div>
            ) : (
              <IoChevronDownCircleSharp color="var(--pinkPrimary)" />
            )
          }
          onFocus={() => setStatus(status)}
          placeholder_color={placeholder_color}
        >
          {children}
        </BorderedUduxSelect>
      </BorderedSelectFormItem>

      <p className="input__error__text">{status?.err}</p>
    </BorderedSelectItemWrap>
  );
};
