import styled from "styled-components"

export const HeaderWrap = styled.div`
  // width: calc(100% - 240px);
  height: 90px;
  overflow: hidden;
  position: fixed;
  right: 0;
  padding: 0 10px;
  z-index: 999;
  border-bottom: 0.2px solid #44444499;
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  -ms-backdrop: blur(25px);

  .routechange__wrap {
    grid-column-gap: 10px;
    & > div {
      display: flex;
      padding: 0.5rem;
      cursor: pointer;
      font-size: 1.35rem;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      :hover {
        background-color: var(--backgroundDarkLight);
        transform: scale(1.1);
      }
    }

    img {
      width: 13px;
    }
  }

  .web__header {
    position: relative;
  }

  .compass_wrap {
    max-width: 180px;
    position: absolute;
    right: 5%;
  }

  .auth__btn__wrap {
    margin-right: 1rem;
  }

  .mobile__header {
    .user__image {
      border-radius: 100%;
      width: 49px;
      height: 49px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    @media (min-width: 950px) {
      display: none !important;
    }
  }
  @media (max-width: 950px) {
    width: 100%;
    height: 83px;
    padding: 0 15px;

    .web__header {
      display: none;
    }

    .mobile__header {
      .image__wrap {
        width: 30%;
        justify-content: flex-start;

        .udux__logo__wrap {
          width: 50px;
          height: 50px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    display: ${({ footerOnly }) => (footerOnly ? "none" : "block")};
  }

  @media (max-width: 940px) {
    width: 100% !important;
  }

  @media (max-width: 600px) {
    height: 65px;
    background-color: var(--black);

    width: 100% !important;
    margin-left: auto !important;

    .mobile__header {
      padding: 0 1rem;

      .image__wrap {
        width: 30%;
        justify-content: flex-start;

        .udux__logo__wrap {
          width: 40px;
          height: 40px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .compass_wrap {
        width: 30%;
      }
    }
  }
`
