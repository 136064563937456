import { FlexibleDiv, TabContent, TabHeader } from "components"
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import { Types, useMainContext } from "context"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getArtist, isEmpty, playerTabRoutes } from "utils"
import { AddToPlaylist } from "../AddToPlaylist"
import { ArtistCatalogue } from "../ArtistCatalogue"
import { FSEMPlayer } from "../FSEMPlayer"
import { ManageQueue } from "../ManageQueue"
import { Recommended } from "../Recommended"
import { WebMusicPlayerWrap } from "./web-music-player.styles"
import { useMediaQuery } from "react-responsive"
import { MdClose } from "react-icons/md"

export const WebPlayer = () => {
  const { playId } = useParams()
  const navigate = useNavigate()
  const tabContentRef = useRef()
  const [activeTab, setActiveTab] = useState("#artist-catalogue")
  const {
    state: { player },
    dispatch,
  } = useMainContext()
  const { currentTrack } = player
  const inTabMode = useMediaQuery({ minWidth: 951, maxWidth: 1221 })

  const artist = getArtist(currentTrack)

  useEffect(() => {
    if (isEmpty(currentTrack)) {
      dispatch({ type: Types.IS_PLAYING, payload: false })
      dispatch({ type: Types.IS_PLAYER_ACTIVE, payload: false })
      navigate("/")
    }
  }, [currentTrack, navigate, dispatch])

  useEffect(() => {
    if (inTabMode) {
      setActiveTab("")
    } else {
      setActiveTab("#artist-catalogue")
    }
  }, [inTabMode])

  return (
    <PlayerLayout>
      <WebMusicPlayerWrap>
        <FlexibleDiv className="left__wrap">
          {!inTabMode && <FSEMPlayer />}
          {inTabMode && !activeTab && <FSEMPlayer />}
        </FlexibleDiv>

        <FlexibleDiv flexDir="column" className="right__wrap">
          <TabHeader
            width="100%"
            margin="0 auto"
            activeTab={activeTab}
            tabs={playerTabRoutes}
            padding="0.2rem"
            setActiveTab={tab => setActiveTab(tab)}
            noGap
          />

          {inTabMode && activeTab !== "" && (
            <FlexibleDiv className="close__btn__wrap">
              <MdClose
                onClick={() => setActiveTab("")}
                className="close-icon"
              />
            </FlexibleDiv>
          )}

          <TabContent ref={tabContentRef}>
            {activeTab === "#artist-catalogue" && (
              <ArtistCatalogue artist={artist} />
            )}
            {activeTab === "#recommended" && <Recommended artist={artist} />}
            {activeTab === "#add-to-playlist" && <AddToPlaylist />}
            {activeTab === "#manage-queue" && <ManageQueue />}
          </TabContent>
        </FlexibleDiv>
      </WebMusicPlayerWrap>
    </PlayerLayout>
  )
}
