export const PrevOutlineIcon = ({ color, width, height, ...props }) => (
  <svg
    id="Group_9969"
    data-name="Group 9969"
    xmlns="http://www.w3.org/2000/svg"
    width={width || "22"}
    height={height || "22"}
    viewBox="0 0 40 40"
    {...props}
  >
    <g id="backward" transform="translate(4 3)">
      <path
        id="Path_1555"
        data-name="Path 1555"
        d="M243.82,149.023l-11.743-8.373,11.743-8.374Z"
        transform="translate(-218.056 -123.324)"
        fill={color || "#eaeaea"}
      />
      <g id="Group_9949" data-name="Group 9949">
        <path
          id="Path_1556"
          data-name="Path 1556"
          d="M229.15,135.419a1.064,1.064,0,0,1-.618-.2l-11.743-8.374a1.065,1.065,0,0,1,0-1.734l11.743-8.374a1.065,1.065,0,0,1,1.683.867v16.747a1.065,1.065,0,0,1-1.065,1.065Zm-9.909-9.438,8.844,6.306V119.674Z"
          transform="translate(-203.385 -108.654)"
          fill={color || "#eaeaea"}
        />
        <path
          id="Path_1557"
          data-name="Path 1557"
          d="M55.119,7.887a1.065,1.065,0,0,0-1.065,1.065V31.619L32.175,17.326,55.7,1.956A1.065,1.065,0,1,0,54.537.173L29.645,16.434l0,0a1.063,1.063,0,0,0-.1.073l-.022.02c-.023.02-.045.041-.066.063l-.027.03q-.029.033-.055.067l-.02.027a1.063,1.063,0,0,0-.115.213l-.009.026c-.011.03-.021.059-.029.09l-.009.034c-.007.03-.013.06-.017.09,0,.011,0,.022-.005.033a1.084,1.084,0,0,0-.008.124V31.208h-2.13V3.521h2.13v8.093a1.065,1.065,0,0,0,2.13,0V2.456a1.065,1.065,0,0,0-1.065-1.065h-4.26A1.065,1.065,0,0,0,24.9,2.456V32.273a1.065,1.065,0,0,0,1.065,1.065h4.26a1.065,1.065,0,0,0,1.065-1.065V19.293L54.537,34.478a1.065,1.065,0,0,0,1.647-.892V8.952A1.065,1.065,0,0,0,55.119,7.887Z"
          transform="translate(-24.903 0)"
          fill={color || "#eaeaea"}
        />
      </g>
    </g>
  </svg>
);
