import { Modal } from "antd"
import styled from "styled-components"

export const ATPMWrap = styled(Modal)`
  top: 25%;
  /* position: relative; */

  .ant-modal-content {
    color: var(--dampWhiteLight);
    background: var(--modalMain);

    .ant-modal-close-x {
      color: var(--dampWhiteLight);
    }
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .top__layer {
    flex: 3;
    height: 150px;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    align-items: flex-start;
  }

  .title__section {
    width: 100%;
    justify-content: flex-start;
  }

  .title {
    font-size: 1.1rem;
    margin: 0;
    color: var(--textPrimary);
  }

  .select__playlist__content {
    height: 270px;
    overflow-y: scroll;
    margin-bottom: 1rem;
    align-items: flex-start;
  }

  .creating__playlist {
  }

  .btn__wrap {
    max-width: 200px;
    margin: 0 auto;
  }

  .input__wrap {
    height: 60px;

    input {
      outline: 0;
      border: 0;
      border-bottom: 0.2px solid var(--borderLighter);
      width: 100%;
      padding-left: 0.5rem;
      height: 45px;
      font-size: large;
      font-family: "Baguede", sans-serif;
      color: var(--textPrimary);
      background-color: transparent;
    }
  }

  .radio__group__wrap {
    margin-top: 1rem;
    label {
      color: var(--textPrimary);
    }
  }

  .cancel__create__playlist {
    margin-top: 1rem;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 600px) {
    width: 80%;

    .title__and__album {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--textPrimary);

      p {
        margin: 0;
      }

      .title {
        font-size: 1.1rem;
      }
    }
  }
`
