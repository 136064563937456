import { Button, FlexibleDiv } from "components"
import { ActionBox } from "components/lib/ActionBox"
import { Types, useMainContext } from "context"
import { getBillingPlans } from "network"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useQuery } from "react-query"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import BackgroundImage from "../../../assets/images/payment/ChoosePlatformImage.png"
import { PaystackSVG, StripeSVG } from "assets/images/payment/SVGExports"
import GalleryImage from "assets/images/payment/confirmPayment.png"
import { ChoosePlatform } from "components/layouts/AuthLayout-Platform/ChoosePlatform"
import { initiatePayment } from "network"
import { RegisterContent } from "./payment-confirmation.styles"
import { MtnIcon } from "assets/images/Auth"
import { useCallback } from "react"
import { capitalize } from "lodash"
import PayWithPaystack from "assets/images/payment/payWithPaystack.png";
import PayWithStripe from "assets/images/payment/payWithStripe.png";

export const PaymentConfirmation = () => {
  const {
    state: { users, app },
    dispatch,
  } = useMainContext()
  const [link, setLink] = useState()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const plan = params.get("type")
  const mode = params.get("mode")
  const lStore = localStorage.getItem("udx_pay")
  const [activeSlide, setActiveSlide] = useState(0);

  const provider = JSON.parse(lStore.replace(/\\/g), "")

  const { me } = users
  const { isLoggedIn, billingPlans } = app

  const generateLink = useCallback(async () => {
    if (mode === "mtn") {
      return
    }

    if (link) return

    if (!!billingPlans.length) {
      let currentPlan = billingPlans?.find(
        singlePlan =>
          singlePlan?.interval?.toLowerCase() === plan.toLowerCase() &&
          singlePlan?.provider?.toLowerCase() === mode.toLowerCase()
      )

      const successUrl = `${window.location.origin}/payment/complete${window.location.search}`
      const failedUrl = `${window.location.origin}/payment/failed`

      const data = {
        failedUrl,
        successUrl,
        emailAddress: me.emailAddress,
        billingPlanId: currentPlan.id,
      }

      // FIXME: api does not work for stripe payment initiation - throws a 500 error
      const response = await initiatePayment(provider.method, data)
      setLink(response.data.authorizationUrl)
    }
  }, [link, billingPlans, mode, plan, provider, me?.emailAddress])

  useEffect(() => {
    if (me?.id && isLoggedIn) {
      welcomeUserToast()
    }

    generateLink()
  }, [me, link, isLoggedIn, generateLink])

  const welcomeUserToast = () => {
    toast.success("You are logged in", {
      duration: 3000,
      position: "top-right",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#0a0",
        secondary: "#fff",
      },
      id: "loggedIn1",
      // styling
      style: {
        border: "1px solid #713200",
        padding: "5px 10px",
        color: "#713200",
        // minWidth: '300px'
      },
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    })
  }

  const galleryImages = [
    {
      pageHoverImage: PayWithPaystack
    },
    {
      pageHoverImage: PayWithStripe
    }
  ]

  const cardsObjects = {
    mtn: {
      boxIcon: <MtnIcon ill={"white"} />,
      heroText: "Go Premium with",
      spanText: " MTN",
    },
    paystack: {
      boxIcon: <PaystackSVG fill={"white"} />,
      heroText: "Go Premium with",
      spanText: " PayStack",
    },
    stripe: {
      boxIcon: <StripeSVG fill={"white"} />,
      heroText: "Go Premium with",
      spanText: " Stripe",
    },
  }

  const logoutUser = () => {
    localStorage.removeItem("udx__user")
    localStorage.removeItem("udx__tokens")
    navigate(`/payment/authentication?type=${plan}&mode=${mode}`)
  }

  // console.log({ link })
  return (
    <>
      {provider?.method && (
        <ChoosePlatform
          title={`Hey ${capitalize(me.username)}`}
          galleryImages={galleryImages}
          activeSlide={activeSlide}
          leftColor="#00725E"
          rightImage={BackgroundImage}
          backURL={`/payment/plan?type=${plan}&mode=${mode}`}
        >
          <RegisterContent
            width="100%"
            height="100%"
            flexDir="column"
            justifyContent="space-between"
          >
            <p className="confirm__text">
              Please confirm that you would like to subscribe to uduX via
            </p>

            <FlexibleDiv
              height="fit-content"
              width="90%"
              className="payment__method__wrapper"
            >
              <ActionBox
                boxIcon={cardsObjects[`${[provider?.method]}`].boxIcon}
                heroText={cardsObjects[`${[provider?.method]}`].heroText}
                spanText={cardsObjects[`${[provider?.method]}`].spanText}
              />
            </FlexibleDiv>
            <Link
              className="change__method__text"
              to={`/payment/plan?type=${plan}&mode=${mode}`}
            >
              Change Payment Method
            </Link>
            <Button
              className="subscribe__btn"
              onClick={() => {
                console.log({ link }, "btn")

                if (provider.method === "stripe") {
                  return dispatch({
                    type: Types.API_RESPONSE_ERROR,
                    payload:
                      "Sorry we could not communicate with this provider. Please use another payment option.",
                  })
                } else if (mode === "mtn") {
                  return navigate(`/payment/mtn?type=${plan}&mode=${mode}`)
                } else if (link) {
                  return (window.location.href = `${link}`)
                } else
                  return navigate(
                    `/payment/authentication?type=${plan}&mode=${mode}`
                  )
              }}
            >
              <span>Subscribe me to Udux</span>
            </Button>
            <p className="logout__class">
              Not this account? <span onClick={logoutUser}>Logout</span>
            </p>
          </RegisterContent>
        </ChoosePlatform>
      )}
    </>
  )
}
