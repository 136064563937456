import { Types, useMainContext } from "context"
import { FlexibleDiv } from "../Box"
import { ProgressSlider } from "../ProgressSlider"
import { TPCStyles } from "./track-progress-control.styles"
import { useRef } from "react"
import { useState } from "react"
import { calculateTime, changeRange } from "utils"
import { useEffect } from "react"

export const TrackProgressControl = () => {
  const {
    state: { player },
    playerRef,
    dispatch,
  } = useMainContext()
  const progressBar = useRef()
  const [duration, setDuration] = useState(0)

  const { isPlaying, currentTime } = player

  useEffect(() => {
    const seconds = Math.floor(playerRef?.current?.duration)
    setDuration(seconds)
  }, [
    playerRef?.current?.loadedmetadata,
    playerRef?.current?.readyState,
    playerRef,
  ])

  return (
    <TPCStyles>
      <FlexibleDiv justifyContent="space-between">
        <small>{calculateTime(currentTime || 0)}</small>
        {currentTime > duration ? (
          <small>0:00</small>
        ) : (
          <small>
            {isPlaying && "-"}
            {calculateTime(duration - currentTime || 0)}
          </small>
        )}
      </FlexibleDiv>

      <FlexibleDiv className="song__progress__bar" justifyContent="flex-start">
        <ProgressSlider
          value={(currentTime / duration) * 100}
          ref={progressBar}
          tooltip={false}
          onChange={e =>
            changeRange(
              e,
              playerRef,
              Types.CURRENT_PLAYING_TIME,
              duration,
              dispatch
            )
          }
          range={true}
          isMobile
        />
        <FlexibleDiv
          width={`${(currentTime / duration) * 100}%`}
          className="track__inner"
        ></FlexibleDiv>
      </FlexibleDiv>
    </TPCStyles>
  )
}
