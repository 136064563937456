import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout";
import { verifyEmail } from "network";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import galleryImage from "assets/images/Auth/verification_starting_page.png"

const Index = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const emailAddress = searchParams.get("emailAddress");

  const payload = {
    confirmEmailToken: token,
    emailAddress: emailAddress,
  };

  useEffect(() => {
    const authenticateUser = () => {
      verifyEmail("/core/auth/confirm-email/update-status", payload);
    };

    authenticateUser();
  }, []);

  return (
    <AuthLayout
      title="Email Confirmation"
      subText="You will be redirected shortly"
      galleryImage={galleryImage}
    ></AuthLayout>
  );
};

export default Index;
