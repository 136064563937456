import { Skeleton } from "antd"
import { AlbumCard, FlexibleDiv, NoDataDisplay } from "components"
import { take } from "lodash"
import { getArtistPopularTracks, getArtistRecentTracks } from "network"
import { memo } from "react"
import { useQueries } from "react-query"
import { ExplorerBox, FSEMWrap } from "../shared/styles"

export const ArtistCatalogue = memo(({ artist, isMobile }) => {
  const artistId = artist?.artist?.id
  const artistName = artist?.artist?.stageName

  const artistTrackInfo = useQueries([
    {
      enabled: !!artistId,
      queryKey: ["artist-popular-tracks", artistId],
      queryFn: () => getArtistPopularTracks(artistId),
    },
    {
      enabled: !!artistId,
      queryKey: ["artist-recent-tracks", artistId],
      queryFn: () => getArtistRecentTracks(artistId, "asc"),
    },
  ])

  const isFetching = artistTrackInfo.some(o => o.isLoading)
  const popularTracks = artistTrackInfo[0].data
  const mostRecentTracks = artistTrackInfo[1].data

  return (
    <FSEMWrap isMobile={isMobile}>
      {isFetching ? (
        <Skeleton active={isFetching} />
      ) : (
        <FlexibleDiv
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="display__row"
        >
          <FlexibleDiv className="section__display__controls">
            <h1 className="title">{artistName}'s popular tracks </h1>
          </FlexibleDiv>

          <ExplorerBox
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="flex-start"
            isMobile={isMobile}
          >
            {!!popularTracks?.length ? (
              take(popularTracks, 10).map(pt => (
                <AlbumCard key={pt.id} {...pt} />
              ))
            ) : (
              <NoDataDisplay
                text="Please wait while we curate this content for you."
                height="280px"
              />
            )}
          </ExplorerBox>
        </FlexibleDiv>
      )}

      {isFetching ? (
        <Skeleton active={isFetching} />
      ) : (
        <FlexibleDiv
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="display__row"
        >
          <FlexibleDiv className="section__display__controls">
            <h1 className="title">{artistName}'s most recent releases </h1>
          </FlexibleDiv>

          <ExplorerBox
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="flex-start"
            isMobile={isMobile}
          >
            {!!mostRecentTracks?.length ? (
              take(mostRecentTracks, 10).map(pt => (
                <AlbumCard key={pt.id} {...pt} />
              ))
            ) : (
              <NoDataDisplay
                text="Please wait while we curate this content for you."
                height="280px"
              />
            )}
          </ExplorerBox>
        </FlexibleDiv>
      )}
    </FSEMWrap>
  )
})
