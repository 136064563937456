import styled from "styled-components";
import { FlexibleDiv } from "components";

export const EmbeddedPlayerWrapper = styled(FlexibleDiv)`
  height: 100vh;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;

  .connect__playlist {
    height: 100%;
    h1 {
      color: #fff;
      font-size: 2rem;
      text-align: center;
    }
  }

  .playersection__wrap {
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;

    .songcard__wrap {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: auto;
      height: calc(100% - 110px);
      padding: 50px 30px 0 30px;
    }

    .playersection__footer {
      height: 70px;
      min-height: 70px;
      justify-content: space-between;
      padding: 0 20px;
      flex-wrap: nowrap;
      background: ${({
        gradientTopColor,
        gradientMiddleColor,
        gradientBottomColor,
      }) => `transparent
        linear-gradient(
          180deg,
          ${gradientMiddleColor} 0%,
          ${gradientBottomColor} 100%,
          ${gradientTopColor} 100%,
          transparent 100%
          )
        0% 0% no-repeat padding-box`};

      .footer__songtitle {
        margin: 0;
        color: var(--poprevWhite);
        font-size: 0.8em;
        white-space: nowrap;
      }
      .songtitle__right {
        text-align: right;
      }
      .footer__center__controls__wrap {
        min-width: 300px;
        margin: 0 10%;
        .controls__main {
          & > div {
            transition: all 0.3s ease;
            cursor: pointer;
            :hover {
              /* transform: scale(1.07); */
            }
          }

          .playicon,
          .pauseicon {
            position: relative;
            width: max-content;
            background-color: blue;
            svg {
              position: absolute;
              top: -26px;
            }
          }
          .pauseicon {
            svg {
              top: -19px;
            }
          }
        }

        .songprogress__bar {
          margin-top: 5px;
          position: relative;
          border: 1px solid #fff;
          height: 5px;

          .trackeInner {
            height: 5px;
            margin: -1px 0 0 -1px;
            position: absolute;
            background-color: var(--yellowPrimary);
            top: 0;
          }
        }
        @media (max-width: 1050px) {
          margin: 0 7%;
        }
      }

      @media (max-width: 950px) and (max-height: 740px) {
        display: none;
      }
    }
  }

  @media (max-width: 950px) {
    .playersection__wrap {
      .songcard__wrap {
        /* height: 100%;
        padding: 0; */
      }
    }
  }

  @media (max-width: 950px) {
    .playersection__wrap {
      .songcard__wrap {
        height: 100%;
        padding: 0;
      }
      .playersection__footer {
        display: none;
      }
    }

    .connect__playlist {
      h1 {
        color: #fff;
        font-size: 2rem;
      }
    }
  }
`;

export const EmbeddedPlayerCard = styled(FlexibleDiv)`
  height: 100%;
  margin-right: ${({ margin }) => (margin ? "30px" : 0)};
  width: 300px;
  min-width: 300px;
  border: 1px solid #707070;
  padding: 5px;
  align-items: flex-start;
  cursor: pointer;
  overflow: hidden;

  .mobilecontrol__icons {
    display: none;
    width: max-content;
    position: absolute;
    right: 5px;
    bottom: 0;
    svg {
      width: 20px;
    }
  }

  .songcard__top {
    height: 85px;
    position: relative;
    background: ${({
      gradientTopColor,
      gradientMiddleColor,
      gradientBottomColor,
    }) => `transparent
        linear-gradient(
          180deg,
          #${gradientMiddleColor} 0%,
          #${gradientBottomColor} 100%,
          #${gradientTopColor} 100%,
          transparent 100%
          )
        0% 0% no-repeat padding-box`};
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    flex-wrap: nowrap;

    .song__detail {
      color: var(--poprevWhite);
      margin: 0;
      font-family: "MadeOuterMedium", sans-serif;
    }
    .song__year {
      color: var(--poprevWhiteTwo);
      font-size: 1.2em;
      width: 100%;
      text-align: end;
      margin: 0 0 5% 0;
    }
  }

  .songcard__bottom {
    height: calc(100% - 100px);
    padding: 5px;

    & > div {
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @media (max-width: 950px) and (max-height: 740px) {
    .mobilecontrol__icons {
      display: flex;
    }
  }

  @media (max-width: 950px) {
    width: 400px;
    min-width: 400px;
    margin-right: ${({ margin }) => (margin ? "20px" : 0)};

    .songcard__top {
      height: 80px;

      .song__detail {
        font-size: 0.8em;
      }
      .song__year {
        font-size: 0.8em;
      }
    }
    .songcard__bottom {
      height: calc(100% - 80px);
    }
  }

  @media (max-height: 740px) {
    margin-right: ${({ margin }) => (margin ? "20px" : 0)};

    .songcard__top {
      min-height: 80px;

      .song__detail {
        font-size: 0.8em;
      }
      .song__year {
        font-size: 0.8em;
      }
    }

    .songcard__bottom {
      height: calc(100% - 80px);
    }
  }

  @media (max-width: 600px) {
    .mobilecontrol__icons {
      display: flex;
    }
  }

  @media (max-width: 500px) {
    width: 90%;
    min-width: 220px;
    max-width: 250px;
  }
`;
