import styled from "styled-components"
import { FlexibleDiv } from "components"

export const SubscriptionPlansWrapper = styled(FlexibleDiv)`
  height: 100vh;
  width: 100%;
  background: #ffd573;
  padding: 0 3.5%;

  .top__section {
    .vibe__text {
      font-size: 2.2rem;
      color: var(--uduxBackgroundBlack);
      font-family: "Baguede";
      -webkit-text-stroke: 1px var(--uduxBackgroundBlack);
      margin: 0;
    }

    .vibe__subtitle__text {
      color: var(--uduxBackgroundBlack);
      font-family: "Bw ModelicaMedium";
      text-align: center;
      font-size: 0.8rem;
      -webkit-text-stroke: 0.6px var(--uduxBackgroundBlack);
      margin: 0;
    }

    .subscription__mode__selector {
      width: 28%;
      height: 19%;
      max-height: 90px;
      max-width: 280px;
      background-color: var(--uduxBackgroundBlack);
      overflow: hidden;

      .mode__text__bg {
        height: 100%;
        width: 50%;
        cursor: pointer;

        p {
          text-align: center;
          margin: 0;
          font-family: "Baguede";
          -webkit-text-stroke: 0.2px var(--uduxWhitePrimary);
        }
      }

      .active__tab {
        background-color: var(--yellowPrimary);
      }
    }
  }

  @media (min-width: 601px) and (max-width: 912px) {
    padding: 0 5%;

    .top__section {
      height: 25%;
      .vibe__text {
        font-size: 3em;
        width: 60%;
        text-align: center;
        line-height: 3rem;
        -webkit-text-stroke: 1.5px var(--uduxBlackPrimary);

        @media (min-width: 900px) and (max-width: 915px) {
          font-size: 3.3em;
          line-height: 4.4rem;
        }
      }

      .vibe__subtitle__text {
        font-size: 1em;

        @media (min-width: 900px) and (max-width: 915px) {
          font-size: 1.2em;
          line-height: 2.1rem;
        }
      }

      .subscription__mode__selector {
        max-height: 50px;
        max-width: 350px;
      }
    }

    .bottom__section {
      height: 60%;
      overflow-x: scroll;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 30px;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0 5%;
    /* padding-bottom: 120px;
    padding-top: 90px; */

    .top__section {
      height: 35%;

      @media (max-width: 376px) {
        height: 32%;
      }

      .vibe__text {
        font-size: 2.3em;
        width: 80%;
        text-align: center;
        line-height: 1.3em;
        -webkit-text-stroke: 2px #000;

        @media (max-width: 376px) {
          font-size: 1.8em;
        }

        @media (max-width: 281px) {
          font-size: 1.4em;
        }
      }

      .vibe__subtitle__text {
        font-size: 1em;

        @media (max-width: 376px) {
          font-size: 0.8em;
        }

        @media (max-width: 281px) {
          font-size: 0.7em;
        }
      }

      .subscription__mode__selector {
        width: 50%;
        max-width: 370px;
        height: 19%;
        max-height: 50px;

        @media (min-width: 390px) and (max-width: 450px) {
          max-height: 40px;
        }
      }
    }

    .bottom__section {
      width: 100%;
      padding: 0;
      height: 50%;
      overflow-x: scroll;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 15px;

      @media (max-width: 376px) {
        height: 60%;
      }

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

export const PricingPlanWrap = styled(FlexibleDiv)`
  background: var(--uduxBackgroundBlack);
  padding: 6px;

  .price__wrapper {
    margin-bottom: 1.5%;
    position: relative;

    .card__background {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background: ${({ bng }) => `url(${bng})`};
      position: relative;

      ::after {
        position: absolute;
        content: "";
        bottom: 0;
        top: ${({ flipDir }) => (flipDir ? 0 : "none")};
        bottom: ${({ flipDir }) => (flipDir ? "100%" : "none")};
        left: 0;
        width: 100%;
        height: 0;
        transition: all 0.3s ease-in-out;
      }
    }

    .text__container {
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 3;
      transition: 1s ease;

      .price__text,
      .duration__text {
        margin: 0;
        color: var(--textPrimary);
      }

      .duration__text {
        font-family: "Bw ModelicaBold";
        font-size: 1.2rem;
      }

      .price__text {
        font-family: "Baguede";
        text-decoration: none;
        font-size: 1.7rem;
        -webkit-text-stroke: var(--w700);
      }
    }
  }

  .subscription__features {
    border: 1px solid rgba(112, 112, 112, 0.5);
    gap: 15px;
    padding: 0 3%;

    @media (max-width: 1400px) {
      /* gap: 15px; */
      padding: 0 1%;
    }

    .features__container {
      align-items: start;
      justify-content: center;
      gap: 10px;

      .icon__wrapper {
        height: 100%;
      }

      .features__text {
        font-family: "Bw ModelicaMedium";
        font-size: 0.9em;
        width: 70%;
        color: #fdfeff;
        margin: 0;

        @media (max-width: 1400px) {
          font-size: 0.8em;
        }

        svg {
          font-size: 15px;
        }
      }
    }
  }

  .upgrade__wrapper {
    position: relative;
    border-top: ${({ borderColor }) => `5px solid ${borderColor}`};

    .upgrade__text {
      font-size: 1.1rem;
      color: var(--white);
      text-decoration: none;
      font-family: "BaguedeBold";
      transition: transform 1s, color 0.2s;
      -webkit-text-stroke: 2px var(--uduxBackgroundWhite);

      @media (max-width: 1400px) {
        font-size: 0.9rem;
      }
    }

    ::before {
      top: 0;
      left: 0;
      height: 0;
      content: "";
      width: 100%;
      position: absolute;
      transition: all 0.3s ease-in-out;
    }
  }

  :hover {
    .price__wrapper {
      position: relative;

      .card__background {
        ::after {
          background-color: var(--overlayMain);
          height: 100%;
        }
      }
    }

    .upgrade__wrapper {
      .upgrade__text {
        color: var(--black);
        transform: scale(1.1);
        -webkit-text-stroke: 0.5px var(--black);
      }

      ::before {
        height: 100%;
        background-color: ${({ borderColor }) => borderColor};
      }
    }
  }

  @media (min-width: 601px) and (max-width: 912px) {
    width: 70%;
    height: 90%;
    padding: 10px;

    .price__wrapper {
      .text__container {
        .duration__text {
          font-size: 1.8rem;
          font-family: "Bw ModelicaRegular";
          -webkit-text-stroke: 0.8px var(--uduxWhitePrimary);

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 1.9rem;
          }
        }

        .price__text {
          font-size: 2.2rem;
          -webkit-text-stroke: 1px var(--uduxWhitePrimary);

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 2.4rem;
          }
        }
      }
    }

    .subscription__features {
      gap: 12px;
      .features__container {
        gap: 18px;
        .icon__wrapper {
          padding-top: 11px;
          svg {
            font-size: 18px;
          }
        }

        .features__text {
          font-size: 1.35em;
          line-height: 2.8rem;

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 1.7em;
          }
        }
      }
    }

    .upgrade__wrapper {
      .upgrade__text {
        font-size: 1.5rem;

        @media (min-width: 900px) and (max-width: 912px) {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 80%;
    height: 90%;

    @media (max-width: 376px) {
      height: 90%;
    }

    .price__wrapper {
      height: 23.5%;

      .text__container {
        .duration__text {
          @media (max-width: 281px) {
            font-size: 1.2em;
          }
        }

        .price__text {
          @media (max-width: 281px) {
            font-size: 1.4em;
          }
        }
      }
    }

    .subscription__features {
      height: 65%;
      gap: 15px;
      padding: 0;

      @media (max-width: 281px) {
        gap: 7px;
      }

      .features__container {
        gap: 12px;

        @media (max-width: 281px) {
          align-items: start;
        }

        .features__text {
          font-size: 0.85em;

          @media (max-width: 281px) {
            font-size: 0.6em;
          }
        }

        .icon__wrapper {
          padding-top: 0px;

          @media (max-width: 281px) {
            height: fit-content;
            margin-top: -3.5px;
          }

          svg {
            @media (max-width: 281px) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .upgrade__wrapper {
      .upgrade__text {
        @media (max-width: 281px) {
          padding-top: 3px;
          font-size: 1.3rem;
        }
      }
    }
  }
`
