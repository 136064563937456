import { FlexibleDiv } from "components/lib/Box"
import { memo } from "react"
import { PITWrap } from "./image-text-card.styles"

const ImageTextCard = ({ handleClick, ...props }) => {
  const { coverArt, title, subTitle } = props

  return (
    <PITWrap>
      <FlexibleDiv className="image__wrap">
        <img src={coverArt} alt="" />
      </FlexibleDiv>
      <h3 className="title" onClick={handleClick}>
        {title}
      </h3>
      {subTitle && <h4 className="subtitle">{subTitle}</h4>}
    </PITWrap>
  )
}

export const PlainImageTextCard = memo(ImageTextCard)
