import { Button, FlexibleDiv } from "components"
import { useNavigate } from "react-router-dom"
import NotFoundImage from "../../assets/images/general/404.svg"
import { UduXGreenLogo } from "../../assets/images/general/udux_green_logo"
import { NotFoundWrap } from "./not-found.styles"

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <NotFoundWrap>
      <aside className="border-line">
        <FlexibleDiv flexDir="column">
          <FlexibleDiv className="svg-wrap">
            <img src={NotFoundImage} alt="404" />
          </FlexibleDiv>

          <p className="title">
            <span className="you-are">You’re </span>
            Ahead of Your Time
          </p>

          <p className="extra-info">We’ll help you find your way back home</p>
        </FlexibleDiv>

        <FlexibleDiv className="btn_wrap" margin="2rem auto">
          <Button color="#000" onClick={() => navigate("/")} contained bold>
            <span>Back to uduX</span>
          </Button>
        </FlexibleDiv>

        <FlexibleDiv className="udux_green_logo_wrap">
          <UduXGreenLogo />
        </FlexibleDiv>
      </aside>
    </NotFoundWrap>
  )
}
