export const UduXIcon = () => (
  <svg
    id="Group_3405"
    data-name="Group 3405"
    xmlns="http://www.w3.org/2000/svg"
    width="65.847"
    height="21.577"
    viewBox="0 0 65.847 21.577"
  >
    <g id="Text">
      <path
        id="XMLID_146_"
        d="M191,261.6l6.731-10.775h-4.469c-.32.532-.692,1.117-1.091,1.729-.4.639-.772,1.25-1.171,1.888s-.771,1.25-1.144,1.862-.745,1.171-1.064,1.676l-4.443-7.182h-4.523c1.091,1.756,2.208,3.538,3.379,5.375s2.288,3.618,3.352,5.348L179.8,272.377h4.443l4.576-7.237,4.576,7.237h4.416Z"
        transform="translate(-131.964 -250.8)"
        fill="#fff"
      />
      <path
        id="XMLID_143_"
        d="M73.843,268.521a7.077,7.077,0,1,0,0,11.706v.878h3.113V263.2H73.843Zm-3.991,9.471a3.618,3.618,0,1,1,3.618-3.618A3.618,3.618,0,0,1,69.852,277.992Z"
        transform="translate(-46.094 -259.901)"
        fill="#fff"
      />
      <path
        id="XMLID_142_"
        d="M11.946,281h0v5.481h0a4.243,4.243,0,0,1-8.46.186H3.459V281H0v5.667H0a7.7,7.7,0,0,0,15.378,0h0V281Z"
        transform="translate(0 -272.965)"
        fill="#fff"
      />
      <path
        id="XMLID_141_"
        d="M133.446,282h0v5.481h0a4.243,4.243,0,0,1-8.46.186h-.027V282H121.5v5.667h0a7.7,7.7,0,0,0,15.378,0h0V282Z"
        transform="translate(-89.175 -273.699)"
        fill="#fff"
      />
    </g>
  </svg>
);
