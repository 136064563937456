import styled from "styled-components";
import ArtBoard from "assets/images/Auth/artboard_6@2x.png";

export const AuthLayoutWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--uduxYellowDeep);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .bg__wrap__main {
    width: 100%;
    height: 100%;
    background-color: inherit;
    position: absolute;

    .color__box__area,
    .art__box__area {
      height: 100%;
    }

    .color__box__area {
      width: 35%;
      background-color: var(--uduxYellowDeep);
    }
    .art__box__area {
      width: 65%;
      background-color: transparent;
      background: url(${ArtBoard}) no-repeat center / cover;
    }

    .mobile__color__box__area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mobile__art__box__area {
      width: 90%;
      height: 95%;
      background-color: transparent;
      background: url(${ArtBoard}) no-repeat center / cover;
    }
  }

  .content__box {
    z-index: 1;
    width: 94%;
    height: 90%;

    .gallery__pane,
    .activity__pane {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .gallery__pane {
      width: 38%;
      .swiper{
        width: 100%;
        height: 100%;

        .slider__slide {
         position: relative;
         width: 100%;
        }

        .gallery_pane_img{
          width: 100%;
          height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        }
      }


    }

    .activity__pane {
      width: 60%;
      padding: 5.5% 4rem;
      background-color: var(--uduxBlackPrimary);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;

      .logo__wrap {
        position: absolute;
        right: 5%;
        top: 5%;
        width: 65px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .go__back__box {
        left: 5%;
        top: 5%;
        outline: 0;
        border: none;
        display: flex;
        cursor: pointer;
        padding: 0px 4px;
        position: absolute;
        width: max-content;
        align-items: center;
        justify-content: space-around;
        background-color: transparent;

        .go__back {
          cursor: pointer;
          width: max-content;

          span {
            opacity: 0.6;
            font-size: 1.35rem;
          }
        }
      }
    }

    .content__box__layout {
      width: 90%;
      height: auto;
    }

    .content__layout {
      width: 100%;
      padding-top: 3%;
      height: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .title {
        color: var(--uduxPinkYellowAuth);
        font-size: 30px;
        font-weight: 600;
        margin: ${({ checkHeight }) => {
          return checkHeight ? "10px 0px 3px" : "5px 0px";
        }};
        text-align: center;
        letter-spacing: -1.44px;
        font-family: "Baguede", sans-serif;
      }

      .sub__text {
        font-size: 16px;
        font-family: "Bw ModelicaBold", sans-serif;
        color: var(--uduxGrayAuth);
        text-align: center;
        opacity: 0.6;
        letter-spacing: -0.88px;
        margin: 0;
      }

      .auth__response__error__main {
        margin: 3% auto 2%;
        width: 100%;
      }

      .form__wrap {
        margin-top: ${({ hasError, pt }) => (hasError ? "0" : pt || "5%")};
        height: 100%;
        width: 70%;
      }
    }
  }

  @media screen and (min-width: 995px) and (max-width: 1920px) {
    .content__box {
      z-index: 1;
      width: 94%;
      height: 90%;

      .activity__pane {
        padding: 5% 4%;
      }

      .content__layout {
        .title {
          margin: 5px auto;
        }

        .form__wrap {
          min-width: 60%;
          max-width: 70%;
        }
      }
    }
  }

  @media screen and (min-width: 650px) and (max-width: 994px) {
    .content__box {
      .activity__pane {
        width: 90%;
        padding: 4rem 1rem;
      }

      .content__layout {
        .title {
          font-size: 25px;
          letter-spacing: -0.65px;
        }

        .sub__text {
          font-size: 12px;
          font-family: "Bw ModelicaBold", sans-serif;
        }
      }
    }
  }

  @media screen and (max-width: 649px) {
    .content__box {
      .activity__pane {
        width: 90%;
        padding: 4rem 1rem;
        .go__back__box {
          span {
            display: none;
          }
        }
      }

      .content__layout {
        .title {
          font-size: 24px;
          letter-spacing: -0.65px;
        }

        .sub__text {
          font-size: 12px;
          font-family: "Bw ModelicaBold", sans-serif;
        }

        .form__wrap {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .content__box {
      .activity__pane {
        width: 90%;
        padding: 3.5rem 1rem;

        .go__back__box {
          top: 3%;

          span {
            display: none;
          }
        }

        .logo__wrap {
          top: 3%;
          width: 45px;
        }
      }

      .content__layout {
        .title {
          font-size: 20px;
          letter-spacing: -0.65px;
        }

        .sub__text {
          font-size: 0.75rem;
          font-family: "Bw ModelicaBold", sans-serif;
        }

        .form__wrap {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-height: 896px) {
    .content__box {
      .activity__pane {
        overflow: auto;
        padding-bottom: 2%;
      }

      .content__layout {
        .form__wrap {
          margin-bottom: 3%;
        }
      }
    }
  }
`;
