import React, { useEffect } from "react"
import {
  PricingPlanWrap,
  SubscriptionPlansWrapper,
} from "./subscription-plans.styles"
import { FlexibleDiv } from "components"
import { Link } from "react-router-dom"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { useMainContext } from "context"
import { useState } from "react"

export const SubscriptionPlans = ({ mtnPlans, uduxPlans }) => {
  const tabHeader = [
    { label: "uduX", value: "udux" },
    { label: "MTN", value: "mtn" },
  ]
  const [mode, setMode] = useState("")
  const [plans, setPlans] = useState([])

  const {
    state: { app },
  } = useMainContext()

  const isMTNUser = Boolean(app?.isMtn)

  useEffect(() => {
    setMode(isMTNUser ? "mtn" : "udux")
  }, [isMTNUser])

  useEffect(() => {
    if (mode === "mtn") {
      setPlans(mtnPlans)
    } else if (mode === "udux") {
      setPlans(uduxPlans)
    }
  }, [mode, uduxPlans, mtnPlans])

  return (
    <SubscriptionPlansWrapper flexDir="column">
      <FlexibleDiv
        className="top__section"
        height="25%"
        width="100%"
        flexDir="column"
        flexWrap="nowrap"
        justifyContent="space-around"
      >
        <h3 className="vibe__text">Whats your Vibe? Find your X</h3>
        <p className="vibe__subtitle__text">
          uduX gives you payment options that work for you and your music.
          <br />
          Daily, Monthly and Weekly plans that start from just ₦30
        </p>

        <FlexibleDiv className="subscription__mode__selector" flexWrap="nowrap">
          {tabHeader.map((th, idx) => (
            <FlexibleDiv
              key={idx}
              className={`mode__text__bg ${
                mode === th.value ? "active__tab" : ""
              }`}
              onClick={() => setMode(th.value)}
              id={`mode__${idx}`}
            >
              <p>{th.label}</p>
            </FlexibleDiv>
          ))}
        </FlexibleDiv>
      </FlexibleDiv>
      <FlexibleDiv
        className="bottom__section"
        height="65%"
        width="100%"
        justifyContent="space-evenly"
      >
        {plans.map((value, idx) => (
          <PricingPlanWrap
            height="90%"
            width="25%"
            key={idx}
            bng={value.cardBackground}
            borderColor={value.borderColor}
            flipDir={value.provider !== "MTN"}
          >
            <FlexibleDiv
              className="price__wrapper"
              width="100%"
              height="22.5%"
              flexDir="column"
              flexWrap="nowrap"
              justifyContent="space-evenly"
            >
              <FlexibleDiv className="card__background">
                <FlexibleDiv className="text__container" flexDir="column">
                  <p className="duration__text">
                    {value.interval?.toString()[0].toUpperCase()}
                    {value.interval?.toString().substring(1)}
                  </p>
                  <p className="price__text">
                    ₦{value.amount ? value.amount / 100 : 0}
                  </p>
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>
            <FlexibleDiv
              width="100%"
              className="subscription__features"
              style={{ borderBottom: `0` }}
              height="65%"
              flexDir="column"
              justifyContent="center"
            >
              {value["features"]?.map((value, idx) => (
                <FlexibleDiv
                  key={idx}
                  flexDir="row"
                  className="features__container"
                >
                  <div className="icon__wrapper">
                    <BsFillCheckCircleFill
                      color="#FED573"
                      className="check__icon"
                    />
                  </div>

                  <p className="features__text"> {value}</p>
                </FlexibleDiv>
              ))}
            </FlexibleDiv>
            <FlexibleDiv className="upgrade__wrapper" height="10%">
              {mode === "mtn" && (
                <Link
                  to={`/payment/plan?type=${value.interval
                    ?.toString()
                    .toLowerCase()}&mode=${mode}`}
                  // to={`/payment/mtn/confirm?type=${value.interval
                  //   ?.toString()
                  //   .toLowerCase()}&mode=${mode}`}
                  state={{
                    mode,
                    plan: value.interval?.toString(),
                  }}
                  className="upgrade__text"
                >
                  Upgrade Now
                </Link>
              )}
              {mode === "udux" && (
                <Link
                  to={`/payment/plan?type=${value.interval
                    ?.toString()
                    .toLowerCase()}&mode=${mode}`}
                  state={{
                    mode,
                    plan: value.interval?.toString(),
                  }}
                  className="upgrade__text"
                >
                  Upgrade Now
                </Link>
              )}
            </FlexibleDiv>
          </PricingPlanWrap>
        ))}
      </FlexibleDiv>
    </SubscriptionPlansWrapper>
  )
}
