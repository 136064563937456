import { FlexibleDiv } from "components/lib/Box"
import { useMainContext } from "context"
import { useState } from "react"
import { AiFillTwitterCircle } from "react-icons/ai"
import { FaFacebook, FaInstagramSquare } from "react-icons/fa"
import { RiWhatsappFill } from "react-icons/ri"
import { useMediaQuery } from "react-responsive"
import {
  getDominantColors,
  getImageDisplay,
  getTrackDesc,
  getTrackTitle,
  truncate,
} from "utils"
import { ShareModalWrap } from "./share-modal"

export const ShareModal = ({ href, handleClose, open }) => {
  const [copied, setCopied] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const {
    state: { player },
  } = useMainContext()

  const { playingList } = player

  return (
    <ShareModalWrap
      title={null}
      footer={null}
      visible={open}
      onCancel={handleClose}
      dominantColors={getDominantColors(playingList, "playlist")}
    >
      <FlexibleDiv className="top__layer">
        <FlexibleDiv className="playlist__cover__art">
          <div className="cover__art">
            <img src={getImageDisplay(playingList)} alt="cover" />
          </div>
          <div className="title__and__album">
            <p className="title">{truncate(getTrackTitle(playingList), 50)}</p>
            <p className="desc">{truncate(getTrackDesc(playingList), 80)}</p>
          </div>
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv className="share__content">
        <div className="link__box">
          {truncate(href, isMobile ? 39 : 55)}

          <span
            className="copy__btn"
            onClick={() => {
              navigator.clipboard.writeText(href)
              setCopied(true)
            }}
          >
            {copied ? "copied" : "copy"}
          </span>
        </div>
      </FlexibleDiv>

      <FlexibleDiv className="share__list__layer">
        <ul className="share__list">
          <li className="action__item">
            <RiWhatsappFill />
          </li>
          <li className="action__item">
            <FaFacebook />
          </li>
          <li className="action__item">
            <AiFillTwitterCircle />
          </li>
          <li className="action__item">
            <FaInstagramSquare />
          </li>
        </ul>
      </FlexibleDiv>
    </ShareModalWrap>
  )
}
