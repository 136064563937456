const appTypes = {
  GET_CATEGORIES: "GET_CATEGORIES",
  SCROLL_TO_BOTTOM: "SCROLL_TO_BOTTOM",
  SIGN_UP_FORM_STEP: "SIGN_UP_FORM_STEP",
  API_RESPONSE_ERROR: "API_RESPONSE_ERROR",
  HE_SERVICE: "HE_SERVICE",
  SET_BILLING_PLANS: "SET_BILLING_PLANS",
  SET_PAYMENT_METHOD: "SET_PAYMENT_METHOD",
  HEADER_ENRICHMENT: "HEADER_ENRICHMENT",
  MSISDN_USER_HAS_ACCOUNT: "MSISDN_USER_HAS_ACCOUNT",
  AUTH_STREAMING_WARNING: "AUTH_STREAMING_WARNING",
  SET_PRIVATE_ACTION: "SET_PRIVATE_ACTION",
  IS_LOGGED_IN: "IS_LOGGED_IN",
}

export default appTypes
