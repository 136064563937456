import CollabImage from "assets/images/player/collab-playlist-cover.jpg"
import SoloImage from "assets/images/player/solo-playlist-cover.jpg"
import { FlexibleDiv } from "components"
import { Types, useMainContext } from "context"
import React, { forwardRef, memo } from "react"
import { GoCheck } from "react-icons/go"
import { IoClose } from "react-icons/io5"
import useImageColor from "use-image-color"
import { EnterPlaylistName } from "./EnterPlaylistName"
const { CPTWrap } = require("./styles")

const ChoosePlaylistTypeRefEl = forwardRef(({ ...props }, ref) => {
  const { dispatch } = useMainContext()
  const [selected, setSelected] = React.useState("")
  const { colors: collabColors } = useImageColor(CollabImage, {
    colors: 3,
  })
  const { colors: soloColors } = useImageColor(SoloImage, {
    colors: 3,
  })

  return (
    <CPTWrap
      bng={
        Boolean(selected)
          ? selected === "collab"
            ? CollabImage
            : SoloImage
          : ""
      }
      collabColors={collabColors}
      soloColors={soloColors}
    >
      <FlexibleDiv className="title__wrap" justifyContent="space-between">
        <p>Choose a playlist type</p>

        <IoClose
          onClick={() => {
            if (Boolean(selected)) {
              setSelected("")
            }
            dispatch({ type: Types.CREATING_PLAYLIST, payload: false })
          }}
        />
      </FlexibleDiv>

      <FlexibleDiv className="options__wrap">
        {(selected === "collab" || selected === "") && (
          <FlexibleDiv
            className={`pl__option ${selected === "collab" ? "selected" : ""}`}
            onClick={() => setSelected("collab")}
          >
            <FlexibleDiv className="top__content">
              <div className="vert__tagline collab tagline__content">
                <span className="tagline">collab</span>
                <span className="tagline">collab</span>
                <span className="tagline">collab</span>
                <span className="tagline">collab</span>
              </div>

              <div className="art__and__info">
                <div
                  className={`check__selected ${
                    selected === "collab" ? "active" : ""
                  }`}
                >
                  <GoCheck />
                </div>
                <div className="image__wrap">
                  <img src={CollabImage} alt="option art" />
                </div>
                <div className="text__content">
                  <p className="text">
                    Put your music taste to the test. Collab, Co-create with up
                    to 5 friends.
                  </p>
                </div>
              </div>
            </FlexibleDiv>

            <div className="bottom__content collab__color tagline__content">
              <span className="tagline">collab</span>
              <span className="tagline">collab</span>
              <span className="tagline">collab</span>
            </div>
          </FlexibleDiv>
        )}

        {(selected === "solo" || selected === "") && (
          <FlexibleDiv
            className={`pl__option ${selected === "solo" ? "selected" : ""}`}
            onClick={() => setSelected("solo")}
          >
            <FlexibleDiv className="top__content">
              <div className="vert__tagline solo tagline__content">
                <span className="tagline">solo</span>
                <span className="tagline">solo</span>
                <span className="tagline">solo</span>
              </div>

              <div className="art__and__info">
                <div
                  className={`check__selected ${
                    selected === "solo" ? "active" : ""
                  }`}
                >
                  <GoCheck />
                </div>
                <div className="image__wrap">
                  <img src={SoloImage} alt="option art" />
                </div>
                <div className="text__content">
                  <p className="text">
                    Music curated by you, for you and maybe to share…
                  </p>
                </div>
              </div>
            </FlexibleDiv>

            <div className="bottom__content solo__color tagline__content">
              <span className="tagline">solo</span>
              <span className="tagline">solo</span>
              <span className="tagline">solo</span>
              <span className="tagline">solo</span>
            </div>
          </FlexibleDiv>
        )}
      </FlexibleDiv>

      {Boolean(selected) && (
        <EnterPlaylistName
          onClose={() => setSelected("")}
          type={selected}
          bng={selected === "collab" ? CollabImage : SoloImage}
        />
      )}
    </CPTWrap>
  )
})

export const ChoosePlaylistType = memo(ChoosePlaylistTypeRefEl)
