import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Dropdown } from "antd"
import {
  AddSongIcon,
  FavoriteFilledIcon,
  FavoriteIcon,
  MoreIcon,
  PausePlayingIcon,
  PlayIcon,
} from "assets"
import { FlexibleDiv } from "components/lib/Box"
import { SpinLoader } from "components/lib/Loader"
import { Types, useMainContext } from "context"
import {
  addReaction,
  errorResponseHandler,
  removeReaction,
  successResponseHandler,
  updatePlaylistInfo,
} from "network"
import React, { Fragment } from "react"
import { IoIosAdd } from "react-icons/io"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import {
  cleanSongTitle,
  getTrackDuration,
  pauseAudio,
  playAudio,
  truncate,
} from "utils"
import { MiniTrackListItem } from "./MiniTrackListItem"
import { TrackItemMoreMenu } from "./TrackItemMoreMenu"
import { TLIWrap } from "./track-list-item.styles"

export const TrackListItem = ({
  idx,
  fullView,
  minimize,
  playlistId,
  addToPlaylist,
  handlePlayTrack,
  handleApiResponse,
  ...props
}) => {
  const { metadata, id, userLiked } = props
  const [liked, setLiked] = React.useState(userLiked)
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const { mutate: mutateLike } = useMutation(addReaction)
  const { mutate: mutateUnlike } = useMutation(removeReaction)
  const { mutate: mutateAddToPlaylist, isLoading: atpLoading } =
    useMutation(updatePlaylistInfo)

  const {
    state: { playlist, player, app },
    dispatch,
    playerRef,
  } = useMainContext()

  const { isLoggedIn } = app
  const { current } = playlist
  const { currentTrack, isPlaying, isPaused } = player

  const isPlayingTrack =
    (currentTrack?.track?.id === id || currentTrack?.id === id) && isPlaying

  const isAdded = current?.tracks?.some(t => addToPlaylist && t.track.id === id)
  const { invitedByName, playlistName, dateOfInvite, collab, collabCover } =
    props
  return (
    <TLIWrap minimize={minimize} {...props}>
      {minimize ? (
        <MiniTrackListItem handlePlayTrack={handlePlayTrack} {...props} />
      ) : (
        <React.Fragment>
          {!collab && <div className="number__idx">{`${idx + 1}`}</div>}

          {isMobile ? (
            <React.Fragment>
              <div className="mobile__display__only">
                <div className="name__and__cover">
                  <div className="cover__art">
                    <div className="player__icon__wrap">
                      {isPlayingTrack ? (
                        <div className="is__playing__track">
                          <PausePlayingIcon
                            width="20px"
                            height="20px"
                            color="var(--uduxYellowPrimary)"
                            onClick={() => pauseAudio(playerRef, dispatch)}
                          />
                        </div>
                      ) : (
                        <div className="player__icon__wrap">
                          {isPaused && currentTrack?.track?.id === id ? (
                            <PlayIcon
                              width="25px"
                              height="25px"
                              color="var(--white)"
                              onClick={() => playAudio(playerRef, dispatch)}
                            />
                          ) : (
                            <PlayIcon
                              width="25px"
                              height="25px"
                              color="var(--uduxYellowPrimary)"
                              onClick={() => handlePlayTrack(props)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <img
                      src={metadata?.coverArt?.url || collabCover}
                      alt="cover"
                    />
                  </div>
                </div>
                {!collab && (
                  <div className="mobile__title__and__album">
                    <p className="mobile__title">
                      {truncate(cleanSongTitle(metadata?.title), 30)}
                    </p>
                    <p className="mobile__artist">{getDisplayArtists()}</p>
                  </div>
                )}
                {/* mobile view for collab playlist */}
                {collab && (
                  <div className="mobile__title__and__album">
                    <p className="mobile__title">{playlistName}</p>
                    <p className="mobile__artist">{invitedByName}</p>
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="name__and__cover">
                <div className="cover__art">
                  {isPlayingTrack ? (
                    <div className="is__playing__track">
                      <PausePlayingIcon
                        width="20px"
                        height="20px"
                        color="var(--uduxYellowPrimary)"
                        onClick={() => pauseAudio(playerRef, dispatch)}
                      />
                    </div>
                  ) : (
                    <div className="player__icon__wrap">
                      {isPaused && currentTrack?.track?.id === id ? (
                        <PlayIcon
                          width="25px"
                          height="25px"
                          color="var(--white)"
                          onClick={() => playAudio(playerRef, dispatch)}
                        />
                      ) : (
                        <PlayIcon
                          width="25px"
                          height="25px"
                          color="var(--uduxYellowPrimary)"
                          onClick={() => handlePlayTrack(props)}
                        />
                      )}
                    </div>
                  )}
                  <img
                    src={metadata?.coverArt?.url || collabCover}
                    alt="cover"
                  />
                </div>
                <div className="title__and__album">
                  <p className="title">
                    {truncate(cleanSongTitle(metadata?.title), 30) ||
                      playlistName}
                  </p>
                  {/* {fullView && <p className="album">{album?.metadata?.title}</p>} */}
                </div>
              </div>
              <div className="artist">
                {getDisplayArtists() || invitedByName}
              </div>
              {collab && <div className="artist">{dateOfInvite}</div>}
            </React.Fragment>
          )}

          <div className="duration">{getTrackDuration(metadata)}</div>

          {addToPlaylist && !collab ? (
            <FlexibleDiv className="actions">
              {isAdded ? (
                <div className="add__action added">Added</div>
              ) : (
                <div className="add__action add" onClick={handleAddToPlaylist}>
                  {atpLoading ? (
                    <SpinLoader />
                  ) : (
                    <Fragment>
                      Add <IoIosAdd />
                    </Fragment>
                  )}
                </div>
              )}
            </FlexibleDiv>
          ) : (
            <>
              {!collab && (
                <FlexibleDiv className="actions">
                  {liked ? (
                    <div
                      className="icons__wrap"
                      onClick={() => toggleLike(id, "Track")}
                    >
                      <FavoriteFilledIcon width={21} height={20} />
                    </div>
                  ) : (
                    <div
                      className="icons__wrap"
                      onClick={() => toggleLike(id, "Track")}
                    >
                      <FavoriteIcon width={21} height={20} />
                    </div>
                  )}
                  <div
                    className="icons__wrap"
                    onClick={() => {
                      if (!isLoggedIn) {
                        return dispatch({
                          type: Types.SET_PRIVATE_ACTION,
                          payload: true,
                        })
                      }
                    }}
                  >
                    <AddSongIcon
                      className="hover__fill"
                      width={20}
                      height={20}
                    />
                  </div>
                  <Dropdown
                    top="0"
                    trigger="click"
                    overlay={<TrackItemMoreMenu />}
                    placement="bottomRight"
                  >
                    <div className="icons__wrap">
                      <MoreIcon className="hover__fill" height="18" />
                    </div>
                  </Dropdown>
                </FlexibleDiv>
              )}
              {/* If collab re-uses this component */}
              {collab && (
                <FlexibleDiv className="actions">
                  <div className="icons__wrap">
                    <div className="collab_action_icon">
                      <CheckOutlined className="collab_icon" />
                    </div>
                  </div>
                  <div className="icons__wrap">
                    <div className="collab_action_icon red_collab_action_icon">
                      <CloseOutlined className="collab_icon" />
                    </div>
                  </div>
                </FlexibleDiv>
              )}
            </>
          )}
        </React.Fragment>
      )}
    </TLIWrap>
  )

  function getDisplayArtists() {
    const da = metadata?.displayArtists

    if (da && Array.isArray(da)) {
      const threeArtistsPlus = da.length > 2
      const twoArtists = da.length === 2

      let names = da.map(d => d.artist.stageName).join(", ")

      if (twoArtists) {
        names = names.split(",").join(", ")
      } else if (threeArtistsPlus) {
        names = [...names.split(",").slice(0, 2), " & others"].join(", ")
      }

      return isMobile ? truncate(names, 24) : truncate(names, 40)
    }

    return da
  }

  function toggleLike(entity, entityType) {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true })
    }

    const payload = { entity, entityType }

    if (liked) {
      setLiked(false)
      mutateUnlike(payload, {
        onSuccess: () => {
          successResponseHandler({
            message: `${entityType} un-liked successfully.`,
          })
        },
        onError: error => errorResponseHandler(error),
      })
    } else {
      setLiked(true)
      mutateLike(
        { ...payload, type: "LIKE" },
        {
          onSuccess: () => {
            successResponseHandler({
              message: `${entityType} liked successfully.`,
            })
          },
          onError: error => errorResponseHandler(error),
        }
      )
    }
  }

  function handleAddToPlaylist() {
    const payload = {
      id: current.id,
      data: {
        tracks: [
          {
            track: id,
            sequence: current?.tracks?.length + 1,
          },
        ],
      },
    }

    mutateAddToPlaylist(payload, {
      onSuccess: res => {
        handleApiResponse(res, "SUCCESS")
        dispatch({ type: Types.UPDATE_CURRENT_PLAYLIST, payload: res.data })
      },
      onError: err => {
        handleApiResponse(err, "ERROR")
      },
    })
  }
}
