import { AddSongIcon, FavoriteFilledIcon, FavoriteIcon } from "assets"
import { StyledMenu } from "components"
import { likeItem, unLikeItem } from "utils"

export const QueuedTrackMoreMenu = ({ track }) => {
  let items = [
    {
      label: track?.isLiked ? (
        <div className="menu_box" onClick={() => unLikeItem(track.id, "Track")}>
          <FavoriteFilledIcon width={25} height={25} />
          <p>Like</p>
        </div>
      ) : (
        <div className="menu_box" onClick={() => likeItem(track.id, "Track")}>
          <FavoriteIcon width={25} height={25} />
          <p>Like</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box">
          <AddSongIcon width={25} height={25} />
          <p>Add to playlist</p>
        </div>
      ),
    },
  ]

  return (
    <StyledMenu
      top="1rem"
      items={items.map((menuItem, idx) => ({ ...menuItem, key: idx }))}
    />
  )
}
