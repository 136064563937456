import styled from "styled-components";
import { Checkbox } from "antd";

export const CheckBox = styled(Checkbox)`
  margin: 10px 0 0 0;
  width: max-content;

  .ant-checkbox {
    height: max-content;
    margin: 0;
    padding: 0;
    line-height: 0;
    input {
      height: 0;
    }
  }

  .ant-checkbox-inner {
    background: transparent;
    border-color: ${({ color }) => color || "var(--uduxWhitePrimary)"};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after,
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ color }) => color || "var(--uduxYellowPrimary)"};
  }

  span {
    color: ${({ color }) => color || "var(--textPrimary)"};
    font-family: "Bw ModelicaRegular", sans-serif;
    font-size: 12px;

    a {
      color: ${({ color }) => color || "var(--textPrimary)"};
      text-decoration: underline;
    }
  }
  :hover {
    .ant-checkbox-inner,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${({ color }) => color || "var(--uduxYellowPrimary)"};
    }
  }
`;
