import styled from "styled-components";
import { Select, Form } from "antd";

export const BorderedUduxSelect = styled(Select)`
  height: 100%;
  width: 100%;

  .ant-select-selector {
    height: 100% !important;
    background: transparent !important;
    border: none !important;
    border-radius: 0;
    font-size: ${({ small }) => (small ? "10px" : "14px")};
    color: #ffffff;
    width: 100%;
    box-shadow: none !important;
    padding: ${({ padding }) =>
      padding ? `${padding} !important` : "0.5rem !important"};

    .ant-form-item-control-input {
      height: 100% !important;
    }
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    color: ${({ color }) => color || "var(--textPrimary)"};
    font-size: ${({ small }) => (small ? "10px" : "14px")};
    height: 100%;
    display: flex;
    align-items: center;
    font-family: ${({ font_family }) => font_family || `"Baguede", sans-serif`};
  }

  .ant-select-item-option-content {
    font-size: ${({ small }) => (small ? "10px" : "14px")};
    font-family: ${({ font_family }) => font_family || `"Baguede", sans-serif`};
  }

  .ant-select-selection-search-input {
    color: ${({ color }) => color || "var(--textPrimary)"};
    font-family: "Baguede", sans-serif;
  }

  .ant-select-arrow {
    right: 3px !important;

    svg {
      color: ${({ color }) => color || "var(--uduxWhitePrimary)"};
      font-size: 12px;
    }
  }

  .cancel {
    svg {
      color: var(--uduXDangerPrimary);
      font-size: 15px;
      margin-top: -10px;
    }
  }
  .check {
    margin-left: 10px !important;
    svg {
      color: var(--uduxYellowPrimary);
    }
  }
  .edit__icon {
    display: none;
  }

  input {
    box-shadow: none;
    height: 100% !important;
    width: 100% !important;
    font-size: ${({ small }) => (small ? "10px" : "14px")};
    font-family: ${({ font_family }) => font_family || '"Baguede", sans-serif'};

    ::placeholder {
      color: var(--dampWhiteLight);
      font-size: ${({ small }) => (small ? "10px" : "14px")};
      text-align: left;
      font-weight: 400;
      font-family: ${({ font_family }) =>
        font_family || '"Baguede", sans-serif'};
      opacity: 0.9;
    }
  }
`;

export const BorderedSelectFormItem = styled(Form.Item)`
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin || "0 0 5px 0"};
  height: ${({ height }) => height || "100%"};
  padding: ${({ padding, borderwidth }) =>
    borderwidth ? "0" : padding || "0"};
  background: ${({ background }) => background || "transparent"};
  border-width: ${({ borderwidth }) => borderwidth || "0.5px"};
  border-radius: ${({ radius }) => radius || "4px"};
  border: ${({ border }) => border || "0.5px solid var(--dampWhiteLight)"};
  border-color: ${({ borderColor, hasError, hasSuccess }) => {
    return hasSuccess
      ? "var(--successPrimary)"
      : hasError
      ? "var(--uduxDangerPrimary)"
      : borderColor || "var(--dampWhiteLight)";
  }};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 0.86rem;
  text-align: ${({ text_align }) => text_align || "center"};
  transition: all 0.3s ease;
  outline: none;
  overflow: hidden;

  :hover,
  :active,
  :focus,
  :visited {
    border-color: var(--uduxYellowPrimary);
  }

  .ant-form-item-control-input-content {
    height: ${({ height }) => height || "100%"};
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }

  & div {
    height: 100% !important;
  }
`;

export const BorderedSelectItemWrap = styled.div`
  width: 100%;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "3em"};
  position: relative;

  .input__error__text {
    color: var(--uduxDangerPrimary);
    font-size: 0.75rem;
    line-height: 1.2;
    text-align: left;
    margin: 0;
  }

  label {
    color: var(--dampWhiteLight);
    margin-bottom: 0.5rem;
    display: inline-flex;
    font-size: ${({ small }) => (small ? "10px" : "14px")};
    font-family: "Baguede", sans-serif;

    @media (max-width: 376px) {
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 380px) {
    height: 2.5em;
  }
`;
