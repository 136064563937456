import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { DroppableWrap } from "./drag-and-drop.styles";

export const DragAndDrop = ({
  withDroppable,
  droppableId,
  children,
  onDragEnd,
  onDragStart,
  onDragUpdate,
  ...props
}) => {
  return withDroppable ? (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      <Droppable droppableId={droppableId} style={{ width: "100%" }}>
        {(provided) => (
          <DroppableWrap
            className="droppable"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}
          </DroppableWrap>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      {children}
    </DragDropContext>
  );
};
