import { NoSearchResultIcon } from "assets/images/general/NoSearchResultIcon";
import { FlexibleDiv } from "components";
import { NoSearchHistoryWrap } from "../NoSearchHistory/no-search-history.styles";

export const NoSearchResult = () => {
  return (
    <NoSearchHistoryWrap flexDir="column">
      <FlexibleDiv className="icon__wrap">
        <NoSearchResultIcon />
      </FlexibleDiv>

      <FlexibleDiv flexDir="column">
        <p className="headline">We can’t find what you’re looking for</p>
        <p className="text">
          Please try another keyword, artist name, project/song title, or
          username.
        </p>
      </FlexibleDiv>
    </NoSearchHistoryWrap>
  );
};
