import { FlexibleDiv } from "components"
import styled from "styled-components"

export const PlaylistWrap = styled(FlexibleDiv)`
  padding: 2rem;
  padding-bottom: 0;
  position: relative;

  .filter__playlist__box {
    margin: 3rem auto;
    padding: 1rem 5rem;

    .search__input {
      width: 50%;
      max-width: 380px;
      padding: 10px 5px;
      flex-wrap: nowrap;
      flex-direction: row;
      border: 0.5px solid var(--borderMain);

      > input {
        border: none;
        outline: none;
        width: 90%;
        padding-left: 5px;
        background-color: transparent;
        font-family: "Baguede", sans-serif;
      }
    }

    .organize__by {
      cursor: pointer;
      border: 0.5px solid var(--borderLight);
      padding: 10px 5px;
      width: 30%;
      max-width: 150px;

      > p {
        margin: 0;
        font-family: "Baguede", sans-serif;
        color: rgba(217, 217, 217, 0.7);
      }

      > img {
        width: 18px;
        margin-right: 15px;
      }
    }
  }

  .playlist--search {
    width: 50%;

    .curate__playlist {
      color: var(--textPrimary);
    }

    .search__input {
      width: 70%;
      max-width: 380px;
      padding: 10px 5px;
      flex-wrap: nowrap;
      flex-direction: row;
      border: 0.5px solid var(--borderMain);

      > input {
        border: none;
        outline: none;
        width: 90%;
        padding-left: 5px;
        background-color: transparent;
        font-family: "Baguede", sans-serif;
      }
    }
  }

  .mapped__content {
    width: 100%;
    column-gap: 30px;
    overflow-y: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .display__result {
    justify-content: flex-start;
    overflow: hidden;
    padding: 1rem 0 2rem;

    :not(:first-child) {
      margin: 1rem auto 3rem;
    }

    .result__title {
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 1rem;
      flex-direction: column;
      align-items: flex-start;

      h2 {
        color: var(--textPrimary);
        font-size: 1.35rem;
        text-align: left;
      }
    }

    .heading__text {
      color: white;
      text-transform: capitalize;
      font-size: 1.2rem;
    }
  }

  .atp__display__result {
    justify-content: space-between;
    overflow: hidden;
    padding: 1rem 0 2rem;

    :not(:first-child) {
      margin: 1rem auto 2rem;
    }

    .result__title {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1rem;

      h2 {
        color: var(--textPrimary);
        font-size: 1.35rem;
        text-align: left;
      }
    }
  }

  .category__filter {
    width: 100%;
    margin-bottom: 4rem;
    justify-content: space-between;

    &__content {
      width: 140px;
    }
  }

  .response__banner__wrap {
    padding: 1rem;
    height: 80px;
    position: relative;
    align-items: center;
    margin-top: -1.5rem;
  }

  @media screen and (max-width: 1244px) {
    .filter__playlist__box {
      padding: 1rem;
    }

    .playlist--search {
      .curate__playlist {
        font-size: 90%;
        color: var(--textPrimary);
      }

      .search__input {
        width: 80%;

        > input {
          padding-left: 5px;
          font-size: 90%;
        }
      }
    }
  }

  @media screen and (max-width: 739px) {
    padding: 1rem;

    .filter__playlist__box {
      margin: 1rem auto;
      padding: 1rem;

      .search__input {
        max-width: 280px;
        padding: 5px;

        > input {
          font-size: 0.85rem;
        }
      }

      .organize__by {
        padding: 7px;
        max-width: 150px;

        > p {
          margin: 0;
          font-size: 0.65rem;
          font-family: "Baguede", sans-serif;
          color: rgba(217, 217, 217, 0.7);
        }

        > img {
          width: 13px;
          margin-right: 5px;
        }
      }
    }

    .display__result {
      padding: 1rem 0 2rem;

      :not(:first-child) {
        margin: 1rem auto 2rem;
      }

      .result__title {
        margin-bottom: 1rem;
      }

      .heading__text {
        font-size: 1rem;
      }
    }
  }
`

export const TrackListWrap = styled(FlexibleDiv)`
  display: flex;
  padding-top: ${({ pt }) => pt || "4rem"};
  margin-bottom: 4rem;
  flex-direction: column;
  width: 100%;
  background: ${({ bgImg }) =>
    bgImg
      ? `linear-gradient(0deg, rgba(13, 8, 8, 0.86), rgba(0, 0, 0, 0.96)), url(${bgImg}) no-repeat center / contain`
      : "transparent"};

  .sorry__message {
    color: var(--uduxGrayLight);
    opacity: 0.8;
    font-family: "Baguede", sans-serif;
  }

  @media screen and (max-width: 739px) {
    padding-top: 2rem;
  }
`
