import { FlexibleDiv } from "components"
import styled from "styled-components"

export const HomeWrap = styled(FlexibleDiv)`
  width: 100%;
  height: 100%;
  // margin:0 auto;
  // padding-left: 10px;
  position: relative;
  // border: 2px solid red;

  .update-modal-wrap {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: var(--uduxBlackPrimary);

    .update-header {
      width: 50%;
      margin: 0 auto;

      h3 {
        margin-top: 100px;
        font-size: 1.8rem;
      }

      p {
        font-size: 1rem;
        margin: 0 auto;
      }
      h3,
      p {
        text-align: center;
        width: 100%;
        color: var(--uduxWhitePrimary);
      }
    }
  }

  section {
    min-height: calc(100vh - 90px);
    padding: 5px;
    overflow-y: auto;
    overflow-x: hidden;

  
  
    max-width: 1500px;
    margin: 0 auto;


    .banner__wrap {
      // position: relative;
      height: 350px;
      /* width:100%; */
      // margin:0 auto;
      // max-height: 350px;
      // overflow: auto;
      // display: flex;
      // align-items: center;
      // justify-content: flex-start;
      // border: 2px solid red;

      img {
        width: 100%;
        height: 100%;
        // object-fit:cover;
        // border:2px solid yellow;
      }

      // .stream__now__btn__wrap {
      //   position: absolute;
      //   width: 200px;
      //   bottom: 5%;
      //   right: 2%;
      // }

      banner_wrap {
      }
      .banner-img__wrap {
        // width: 100%;
        // height: 100%;
        border: 2px solid red;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .web__banner {
          @media (max-width: 500px) {
            display: none;
          }
        }

        .mobile__banner {
          @media (min-width: 500px) {
            display: none;
          }
        }
      }

      @media (max-width: 500px) {
        display: flex;
        align-items: center;
        justify-content: center;

        .gallery__pane .gallery_pane_img {
          min-height: 300px !important;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          border: 2px solid red;
        }
      }
    }

    .playerhome__bodysection {
      .mini__subheader {
        /* font-size: 1.4em; */
        flex-basis: 50%;
        color: var(--uduxWhitePrimary);
        margin: 60px 0 0 0;
        font-family: "Bw ModelicaSS01Bold", sans-serif;
      }

      .header__withlink {
        margin-bottom: 20px;
        .see__more {
          color: #d9d9d9e5;
          font-size: 12px;
          text-decoration: underline;
        }

        @media (min-width: 950px) {
          width: 95%;
        }
      }

      .cards__carousel {
        justify-content: flex-start;
        overflow-x: auto;
        flex-wrap: nowrap;
        &::-webkit-scrollbar {
          height: 0;
        }
      }
      .data__card__carousel {
        column-gap: 3%;
      }

      .playerhome__cardswrap {
        width: 95%;
        overflow-x: auto;
        justify-content: flex-start;
        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0 !important;
        }
        &::-webkit-scrollbar-button {
          width: 0 !important;
          height: 0 !important;
        }
      }
    }
  }

  @media (max-width: 950px) {
    width: 100%;
    // padding: 0 15px 0 0;
    section {
      padding: 0;

      .playerhome__bodysection {
        .playerhome__cardswrap {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 502px) {
    section {
      .banner__wrap {
        margin-left: 2px;
        max-height: 180px;
      }
      .playerhome__bodysection {
        .welcomeback__text {
          font-size: 20px;
          margin-bottom: 10px;
        }
        .cheers__text {
          font-size: 16px;
        }
        g .mini__subheader {
          margin: 40px 0 0 0;
          font-size: 1em;
        }

        .header__withlink {
          margin: 0 20px 16px 5px;
          width: 95% !important;
          h4 {
            font-size: 10px;
          }
          .see__more {
            font-size: 12px;
          }
        }

        .data__card__carousel {
          column-gap: 8%;
        }
      }
    }
  }
`
