import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/styles";

export const Container = styled(FlexibleDiv)`
  width: 100%;
  padding-bottom: 5%;

  .form_box {
    margin: 10% 0 7%;
    width: 100%;
    height: 100%;
  }

  .btn__box {
    margin-top: 3rem;
  }

  @media screen and (max-width: 600px) {
    .action_text_box {
      margin-top: 2%;
    }

    .action_text,
    .action_text_one {
      margin-top: 12%;
      font-size: ${({ checkHeight }) => {
        return checkHeight ? "15px" : "15px";
      }};
    }
  }
`;
