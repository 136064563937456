export const HomeIcon = ({
  color = "#eaeaea",
  width = "22.338",
  height = "23.004",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className="fill__player__svg"
  >
    <g id="hut-2" transform="translate(-7.394)">
      <path
        id="Path_1166"
        data-name="Path 1166"
        d="M122.5,436h.675v.675H122.5Z"
        transform="translate(-109.924 -416.373)"
        fill={color}
      />
      <path
        id="Path_1167"
        data-name="Path 1167"
        d="M373.5,436h.675v.675H373.5Z"
        transform="translate(-349.625 -416.373)"
        fill={color}
      />
      <path
        id="Path_1168"
        data-name="Path 1168"
        d="M27.031,10.579h.9l-2.7-2.7h1l-3.151-2.7h1.051L19.646,2.013,20.4,0H16.725l.755,2.013L13,5.177h1.051L10.9,7.878h1l-2.7,2.7h.9l-2.7,2.7H9.672V23H27.454V13.28h2.278ZM26.3,9.9h-2.26L22.489,7.878h1.787ZM16.583,7.2l1.351-2.026h1.259L20.543,7.2Zm2.588.675L20.251,9.9H16.875l1.08-2.026Zm-1.981,0L16.11,9.9H13.935l1.553-2.026Zm-2.876,2.7h2.218L15.518,12.6H12.693Zm2.973,0H19.84L20.853,12.6h-4.58Zm3.309,0h2.218L24.433,12.6H21.608Zm.422-.675-1.08-2.026h1.7L23.191,9.9ZM24.4,7.2H21.355L20,5.177h2.035ZM19.426.675,19,1.8h-.883L17.7.675Zm-1.431,1.8h1.136L22,4.5H15.125Zm-2.908,2.7h2.035L15.771,7.2H12.723Zm-2.236,2.7h1.787L13.084,9.9h-2.26ZM9.024,12.6l2.026-2.026h2.4L11.828,12.6Zm6.5,9.724H10.347v-.675h5.177Zm0-1.351H10.347V18.952h5.177Zm0-3.714v1.013H10.347v-1.27l1.193-.7,1.531.9,1.53-.9.95.559A3.047,3.047,0,0,0,15.524,17.264Zm5.4,5.064H16.2V17.264a2.363,2.363,0,0,1,4.727,0Zm5.852,0H21.6v-.675h5.177Zm0-1.351H21.6V18.952h5.177Zm0-2.7H21.6V17.264a3.053,3.053,0,0,0-.027-.4l.95-.559,1.53.9,1.531-.9,1.193.7Zm0-2.053-1.193-.7-1.531.9-1.53-.9-1.123.66a3.038,3.038,0,0,0-5.678,0l-1.123-.66-1.53.9-1.531-.9-1.193.7V13.28H26.779ZM25.3,12.6l-1.621-2.026h2.4L28.1,12.6Z"
        fill={color}
      />
      <path
        id="Path_1169"
        data-name="Path 1169"
        d="M92.5,436h.675v.675H92.5Z"
        transform="translate(-81.275 -416.373)"
        fill={color}
      />
      <path
        id="Path_1170"
        data-name="Path 1170"
        d="M152.5,436h.675v.675H152.5Z"
        transform="translate(-138.574 -416.373)"
        fill={color}
      />
      <path
        id="Path_1171"
        data-name="Path 1171"
        d="M403.5,436h.675v.675H403.5Z"
        transform="translate(-378.275 -416.373)"
        fill={color}
      />
      <path
        id="Path_1172"
        data-name="Path 1172"
        d="M343.5,436h.675v.675H343.5Z"
        transform="translate(-320.976 -416.373)"
        fill={color}
      />
    </g>
  </svg>
);
