export const GotoArtistPageIcon = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "17"}
    height={height || "17"}
    viewBox="0 0 20 20"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="path1221"
          d="M0-682.665H17v17H0Z"
          transform="translate(0 682.665)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g id="g1215" transform="translate(0 682.665)">
      <g id="g1217" transform="translate(0 -682.665)">
        <g id="g1219" clip-path="url(#clip-path)">
          <g id="g1225" transform="translate(0.249 0.249)">
            <path
              id="path1227"
              d="M-593.586-362a5.735,5.735,0,0,0,1.343-3.47c0-3.811-3.007-5.775-6.574-5.775a6.4,6.4,0,0,0-1.064.084s.835.811.835,1.354a22.611,22.611,0,0,0-.631,3.226,4.374,4.374,0,0,0,.51,1.939m2.314,2.838a12.812,12.812,0,0,0,2.183,1.533m-12.582,1.568v-2.721l.876.471m-1.717,2.136c-.413.078-.654.325-.654.646a.583.583,0,0,0,.747.5.947.947,0,0,0,.747-1.036A1.614,1.614,0,0,0-608.092-358.812Zm7.28-12.093a4.088,4.088,0,0,0-2.456,2.891,1.6,1.6,0,0,1-.764,1.139c-1.707.771-1.924.852-2.016,1.207s.223.716.712,1.236l-.214.572a.5.5,0,0,0,.3.644,9.664,9.664,0,0,0,1.693.356c.913.14.979.875.709,1.659a4.283,4.283,0,0,1-1.339,1.778.5.5,0,0,0-.082.656c.665.972,1.266,1.4,1.668,1.451a4.97,4.97,0,0,0,2.345-.744v1.66m5.162-1.222-5.162,1.3a1,1,0,0,0-.753.966v.618l2.862-.72m.907-.228,2.226-.56v-2.46a4.387,4.387,0,0,1,.877-2.5m-7-.352a.883.883,0,0,0,.2,1.244.893.893,0,0,0,1.232-.206l4.206-5.7a1.365,1.365,0,0,1,1.466-.682.5.5,0,0,1,.339.563,1.365,1.365,0,0,1-1.288.977l-3.473.522a1.893,1.893,0,0,0-1.061.536,1.908,1.908,0,0,0-.023,2.688,1.75,1.75,0,0,0,2.439,0,1.281,1.281,0,0,0,0-1.811,1.039,1.039,0,0,0-1.548-.029.82.82,0,0,0,.179,1.179m-4.407-3.785a1.5,1.5,0,0,0,1.278.1m.055-1.836a2.3,2.3,0,0,0-1.877-.595"
              transform="translate(608.745 371.24)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="0.75"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
