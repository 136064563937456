import { Space } from "antd"
import styled from "styled-components"

export const VolumeControllerStyles = styled(Space)`
  background-color: transparent;
  flex-direction: column;

  .volume__bar {
    height: 6rem;
    width: 2px;
    margin: 0 auto;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  .ant-space-item {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .volume__divider {
    margin: 0.5rem 0 0;
    background-color: rgba(74, 74, 74, 0.5);
  }

  .icons__wrap {
    height: 100%;
    cursor: pointer;
  }
`
