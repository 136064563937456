// import Particles from "react-particles"
import { useCallback, useMemo } from "react"
import Particles from "react-tsparticles"
import { loadFull } from "tsparticles"
import { ParticleConfig } from "./particle.config"

export const ParticlesBackground = () => {
  const options = useMemo(() => {
    return ParticleConfig
  }, [])

  const particlesInit = useCallback(engine => {
    loadFull(engine)
  }, [])

  return <Particles id="particles-bg" init={particlesInit} options={options} />
}
