import { FlexibleDiv } from "components"
import styled from "styled-components"

export const CPTWrap = styled(FlexibleDiv)`
  ::before {
    position: ${({ bng }) => (bng ? "absolute" : "relative")};
    content: "";
    height: 70%;
    width: 100%;
    background: ${({ bng }) =>
      bng
        ? `linear-gradient(0deg, rgba(13, 8, 8, 0.9), rgba(0, 0, 0, 0.96)), url(${bng}) repeat center / contain`
        : "linear-gradient(0deg, rgba(13, 8, 8, 0.86), rgba(0, 0, 0, 0.96))"};
  }

  .title__wrap {
    margin: 1rem auto 0;

    p {
      margin-bottom: 0;
    }

    svg {
      cursor: pointer;
    }
  }

  .options__wrap {
    width: 100%;
    margin-top: 1rem;
    column-gap: 10%;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .pl__option {
    width: 45%;
    height: 238px;
    cursor: pointer;
    position: relative;

    :hover {
      .check__selected {
        opacity: 1;
      }
    }
  }

  .selected {
    width: 255px;
  }

  .check__selected {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    width: 30px;
    height: 30px;
    background-color: var(--yellowPrimary);
    transition: all 0.3s ease-in-out;

    svg {
      font-size: 1.2rem;
      color: var(--white);
    }
  }

  .active {
    opacity: 1;
  }

  .top__content {
    height: 80%;
    position: relative;
  }
  .bottom__content {
    height: 20%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 0.5rem;
    overflow: hidden;

    & .tagline::after {
      position: absolute;
      content: "|";
      top: -5%;
    }
    &.solo__color .tagline::after {
      right: -20%;
    }
    &.collab__color .tagline::after {
      right: -15%;
    }
  }

  .vert__tagline {
    width: 45px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    row-gap: 10%;
    padding: 1rem 0.5rem;

    span.tagline {
      top: 0;
      left: 0;
      position: relative;
      display: inline-flex;
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }

    & .tagline::after {
      position: absolute;
      content: "|";
      left: 1px;
    }
    &.solo .tagline::after {
      top: -16%;
    }
    &.collab .tagline::after {
      top: -14%;
    }

    &.solo {
      background-color: #fdca03;
    }
    &.collab {
      background-color: #00b9d7;
    }
  }

  span.tagline {
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 2px;
    -webkit-text-stroke: 2px;
    position: relative;
  }

  .vert__tagline span.tagline {
    font-size: 1rem;
  }

  .art__and__info {
    flex: 1;
    height: 100%;
    width: 80%;
    border-right: 0.2px solid var(--borderLight);

    .image__wrap {
      width: 100%;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text__content {
      width: 100%;
      display: flex;
      padding: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      p.text {
        width: 90%;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 1.2;
        font-family: "Bw Modelica", sans-serif;
      }
    }
  }

  .collab__color {
    background: ${({ collabColors }) =>
      !!collabColors?.length
        ? `linear-gradient(90deg, ${collabColors[0]} 0%, ${collabColors[1]} 100%)`
        : `#fdca03`};
  }

  .solo__color {
    background-image: ${({ soloColors }) =>
      !!soloColors?.length
        ? `linear-gradient(90deg, ${soloColors[0]} 0%, ${soloColors[1]} 100%)`
        : `#00b9d7`};
  }

  .tagline__content {
    overflow: hidden;
    column-gap: 10%;
  }

  @media screen and (max-width: 1120px) {
    .art__and__info {
      flex: 1;
      height: 100%;
      width: 80%;

      .text__content {
        p.text {
          width: 90%;
          margin-bottom: 0;
          font-size: 10px;
          font-family: "Bw Modelica", sans-serif;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .title__wrap {
      margin: 0;
    }

    .options__wrap {
      width: 100%;
      padding-top: 1rem;
      flex-direction: column;
    }

    .pl__option {
      width: 60%;
      height: 190px;

      &:first-child {
        margin-bottom: 2rem;
      }
    }
  }
`

export const EPNWrap = styled(FlexibleDiv)`
  flex-direction: column;
  justify-content: flex-start;
  height: 260px;
  padding-top: 2rem;
  position: relative;

  & > div {
    z-index: 1;
  }

  .title__wrap {
    margin: 1rem auto;
    position: relative;

    p {
      margin-bottom: 0;
    }

    .subtext {
      color: var(--grayPrimary);
    }

    svg {
      cursor: pointer;
    }
  }

  .input__wrap {
    flex: 1;
    flex-direction: row;
    column-gap: 2%;
    align-items: flex-start;
    padding-top: 3%;

    input {
      outline: 0;
      border: 0;
      border-bottom: 0.2px solid var(--borderLighter);
      width: 60%;
      padding-left: 0.5rem;
      height: 45px;
      font-size: large;
      font-family: "Baguede", sans-serif;
      color: var(--textPrimary);
      background-color: transparent;
    }
  }

  .btn__wrap {
    flex: 1;

    button {
      width: 100%;
    }
  }

  .collab__warn__wrap {
    p {
      display: flex;
      align-items: center;

      small {
        margin-left: 0.3rem;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding-top: 0;
  }
`
