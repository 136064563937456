import styled from "styled-components"
import { FlexibleDiv } from "../../components/lib/Box/styles"

export const SupportWrap = styled(FlexibleDiv)`
  height: 100vh;

  .border-line {
    width: 96%;
    height: 92%;
    border: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .svg-wrap {
    width: 80%;
    resize: both;
    max-width: 600px;

    img {
      height: auto;
      width: 100%;
    }
  }

  .title {
    color: var(--uduxYellowPrimary);
    font-size: 2.5rem;
    font-weight: bolder;
    margin-top: 1rem;
    font-family: "Baguede", sans-serif;
    text-align: center;
  }

  .you-are {
    color: white;
    font-weight: bolder;
    -webkit-text-stroke: var(--w400);
    font-family: "Baguede", sans-serif;
  }

  .extra-info {
    color: #fdfeff;
    opacity: 0.55;
    letter-spacing: -0.96px;
    font-family: "Baguede", sans-serif;
    font-size: 0.92rem;
    margin-top: 0;
    text-align: center;
  }

  .btn_wrap {
    margin-top: 2rem;
    width: 220px;
  }

  .udux_green_logo_wrap {
    position: absolute;
    bottom: 8%;
  }

  @media screen and (max-width: 600px) {
    padding: 2rem;

    .border-line {
      width: 100%;
      height: 100%;
    }

    .title {
      font-size: 1.8rem;
      margin: 1.5rem 0;
      line-height: 1.2;
    }

    .extra-info {
      font-size: 1rem;
      margin-top: 0;
    }

    .btn_wrap {
      margin-top: 1.5rem;
      button {
        span {
          font-size: 0.85rem;
        }
      }
    }
    .udux_green_logo_wrap {
      bottom: 6%;
      svg {
        width: 70px;
      }
    }
  }
`
