export const LikedSongsIcon = ({
  color = "#eaeaea",
  width = "22.338",
  height = "22.198",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className="fill__player__svg"
  >
    <g
      id="Group_3659"
      data-name="Group 3659"
      transform="translate(720 -1899)"
      opacity="0.9"
    >
      <g
        id="Group_3658"
        data-name="Group 3658"
        transform="translate(-720 1899)"
      >
        <g id="Group_3657" data-name="Group 3657" transform="translate(0)">
          <g id="romantic-music">
            <path
              id="Path_1187"
              data-name="Path 1187"
              d="M293.677,172.055a2.772,2.772,0,0,0-2.621-.239l-1.233.524v8.2A5.791,5.791,0,0,0,285,182.462a2.048,2.048,0,0,0,.6,1.458l2.461,2.461,2.461-2.461a2.05,2.05,0,0,0,.6-1.6h.005v-4.211l2.324-1.284a2.787,2.787,0,0,0,.222-4.769ZM289.6,183l-1.536,1.536L286.529,183a.749.749,0,0,1-.221-.533.759.759,0,0,1,.754-.754.751.751,0,0,1,.532.22l.471.472.47-.471A.754.754,0,1,1,289.6,183Zm3.233-7.324-1.7.942V173.2l.437-.186a1.449,1.449,0,0,1,1.388.127A1.476,1.476,0,0,1,292.835,175.672Z"
              transform="translate(-272.577 -164.183)"
              fill={color}
            />
            <path
              id="Path_1188"
              data-name="Path 1188"
              d="M13.541,14.3s0-.009,0-.013h0V1.6L4.823,4.168v8.217a2.072,2.072,0,0,0-1.757.117A2.069,2.069,0,0,0,0,14.3a2.048,2.048,0,0,0,.6,1.458l2.461,2.461,2.461-2.461a2.052,2.052,0,0,0,.6-1.558h0V8.732l6.1-1.816v5.469a2.072,2.072,0,0,0-1.757.117,2.069,2.069,0,0,0-3.065,1.8,2.048,2.048,0,0,0,.6,1.458l2.461,2.461,2.461-2.461a2.048,2.048,0,0,0,.6-1.458ZM4.6,14.837,3.066,16.374,1.529,14.837a.749.749,0,0,1-.221-.533.759.759,0,0,1,.754-.754.751.751,0,0,1,.532.22l.471.472.47-.471A.754.754,0,1,1,4.6,14.837ZM6.131,7.368V5.147l6.1-1.795v2.2Zm5.882,7.469-1.537,1.537L8.939,14.837a.749.749,0,0,1-.221-.533.759.759,0,0,1,.754-.754.751.751,0,0,1,.532.22l.471.472.47-.471a.754.754,0,1,1,1.067,1.067Z"
              transform="translate(0 -1.604)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
