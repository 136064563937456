import React from "react";
import { Container } from "./checkMail.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, FlexibleDiv } from "components";
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout";
import galleryImage from "assets/images/Auth/enter_otp.jpg"

const CheckMail = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const isPrevRoute = state?.prevRoute === "/signup";
  const isResetPasswordRoute = state?.prevRoute === "/reset-password";

  React.useEffect(() => {
    /* If you aren't coming from these two routes, you shouldn't see the check-mail page. */
    if (!isPrevRoute && !isResetPasswordRoute) {
      navigate("/login");
    }
  }, [isPrevRoute, isResetPasswordRoute]);

  return (
    <AuthLayout title="Check your email" galleryImage={galleryImage}>
      <Container>
        <FlexibleDiv className="form_box">
          {isPrevRoute ? (
            <p>Check your Email to confirm your mail</p>
          ) : (
            <p>We just sent over password reset instructions... See you soon</p>
          )}
        </FlexibleDiv>

        {isPrevRoute ? (
          <FlexibleDiv className="btn_box">
            <Button
              width="220px"
              height="50px"
              font_size="16px"
              htmlType="submit"
              color="var(--black)"
              margin="20px 0px 0px 0px"
              contained
              bold
              ltr
              onClick={() => navigate("/login")} // since they can't continue without confirming their email address
            >
              <span>Continue</span>
            </Button>
          </FlexibleDiv>
        ) : (
          <FlexibleDiv className="btn_box">
            <Button
              width="220px"
              height="50px"
              font_size="16px"
              htmlType="submit"
              color="var(--black)"
              margin="20px 0px 0px 0px"
              contained
              bold
              ltr
              onClick={() => navigate("/login")}
            >
              <span>Login</span>
            </Button>
          </FlexibleDiv>
        )}

        {!isPrevRoute && (
          <FlexibleDiv className="action_text_box" flexDir="column">
            <p className="action_text">Create an Account</p>
          </FlexibleDiv>
        )}
      </Container>
    </AuthLayout>
  );
};

export default CheckMail;
